import React from "react";
import styled from "styled-components";
import { MdDone, MdClear } from "react-icons/md";

import Container from "./container";

const CategoryTitleComponent = ({
  _id,
  title,
  setResetCategory,
  label,
  enable,
  categoryInput,
  checkIfEdited,
  InputRef,
  inputError,
  saveCategoryDetails,
  setCategoryInput,
  group,
  rowInfo,
  removeNodeFromTree,
  setParentDetails,
  level,
  isNewlyAdded,
  selectedParentDetails,
  editInput,
  children,
  resetNewFocusIndex,
  isEditedFlag,
  categoryTitle,
  filename
}) => {
  let parentIdOfSelectedParent = Object.keys(selectedParentDetails).length
    ? selectedParentDetails._id
    : null;

  return (
    <>
      {categoryInput ? (
        <CategoryWrapper className="cat-title-input">
          <span>
            <input
              onChange={checkIfEdited}
              ref={InputRef}
              autoFocus
              type="text"
              defaultValue={isEditedFlag ? categoryTitle : title}
            />
            {inputError && <ErrorMessage>{inputError}</ErrorMessage>}
          </span>
          <button
            onClick={() => {
              let data = {};
              if (label === "category") {
                data = {
                  level: level,
                  title: InputRef.current && InputRef.current.value
                };
              } else {
                data = {
                  level: level,
                  title: InputRef.current && InputRef.current.value,
                  parent: parentIdOfSelectedParent
                };
              }

              if (label !== "slide" && editInput && !inputError) {
                saveCategoryDetails(data, rowInfo, editInput);
              } else if (label !== "slide" && !inputError) {
                saveCategoryDetails(data, rowInfo);
              }
            }}
            title="save"
          >
            <SaveIcon />
          </button>
          <button
            title="Close"
            onClick={() => {
              // reset focus index
              resetNewFocusIndex({ key: "newFocusIndex", value: 0 });
              resetNewFocusIndex({ key: "isEditedFlag", value: false });
              resetNewFocusIndex({
                key: "categoryTitle",
                value: ""
              });
              isNewlyAdded && removeNodeFromTree(rowInfo);
              setResetCategory(null, true);
              setCategoryInput(false);
              setParentDetails({}, true);
            }}
          >
            <ClearIcon />
          </button>
        </CategoryWrapper>
      ) : (
        <TitleComp
          key={_id}
          enable={enable}
          title={title}
          tabIndex={0}
          onClick={() => {
            let { treeIndex } = rowInfo;

            if (["category", "header"].indexOf(label) > -1) {
              if (parentIdOfSelectedParent === _id) {
                setParentDetails({}, true);
              } else {
                setParentDetails({
                  treeIndex: treeIndex,
                  _id: _id,
                  level: level,
                  children
                });
              }
            }
          }}
          label={label}
        >
          <TiltleHolder>{title}</TiltleHolder>
        </TitleComp>
      )}
      {label === "slide" && (
        <FilnameWrapper>
          {group && <GroupName title={group.title}>{group.title}</GroupName>}
          {filename && (
            <FileName title={filename}>File Name: {filename}</FileName>
          )}
        </FilnameWrapper>
      )}
    </>
  );
};

const CategoryWrapper = styled.div`
  position: absolute;
  width: 60%;
  left: 5px;
  top: 6px;

  span {
    display: inline !important;
  }

  input {
    width: 70%;
    padding: 5px 5px 0;
    border: none;
    border-bottom: 2px solid ${props => props.theme.COLOR.USER_PRIMARY};
    outline: none;
    color: ${props => props.theme.COLOR.HEX_GRAY};
    background: transparent;
    transform: translateY(-3px);
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
  }
`;

const SaveIcon = styled(MdDone)`
  font-size: 16px;
  cursor: pointer;
`;

const ClearIcon = styled(MdClear)`
  font-size: 16px;
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
  font-weight: normal;
  position: absolute;
  left: 0;
  bottom: -8px;
`;

const FilnameWrapper = styled.div`
  width: 78%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GroupName = styled.span`
  padding: 0 4px 2px;
  width: auto !important;
  max-width: 100px;
  @media (max-width: 1024px) {
    max-width: 50px;
  }
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  border-radius: 1px;
  margin-right: 6px;
  display: inline-block !important;
  vertical-align: text-bottom;
  background: ${props => props.theme.COLOR_PALLETE.APPLE_GREEN};
  color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 10px;
`;

const FileName = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  display: inline !important;
  vertical-align: bottom;
  @media (max-width: 1024px) {
    max-width: 50px;
  }
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  font-size: 10px;
`;

const TitleComp = styled.span`
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  font-size: 12px;
  opacity: ${props => (props.enable ? 1 : 0.5)};
  ${props =>
    ["category", "header"].indexOf(props.label) > -1 && "cursor:pointer"};
  outline: none;
  width: 100%;
  text-overflow: "ellipsis";
`;

const TiltleHolder = styled.span`
  width: 78%;
`;
export default Container(CategoryTitleComponent);
