//import network services
import * as services from "./services";

const mapStateToProps = state => {
  const { SUCCESS_USER_LIST, LOADING_USER_LIST, SUCCESS_USER_PROFILE } = state;
  return {
    ...SUCCESS_USER_LIST,
    ...LOADING_USER_LIST,
    ...SUCCESS_USER_PROFILE
  };
};

const actions = {
  ...services
};

export { mapStateToProps, actions };
