import React, { Component } from "react";
import { get } from "lodash";

const Container = Main =>
  class ThemePreview extends Component {
    constructor(props) {
      super(props);

      this.state = {
        slideData: [],
        activeThemeName: {
          value: "",
          error: "",
          initialValue: ""
        },
        isEditing: false,

        activeSlideDetails: {},
        isSlidePreviewToggleOpen: true,
        showShadowScroll: true,
        currentActiveIndex: 0,
        presentationAddStatus: false,
        slideNo: 0,
        imageDetails: [],
        selectedLayoutType: "",
        selectedPlaceholder: {}
      };
    }

    componentDidMount() {
      let {
        slideData = [],
        activeIndex,
        selectedLayoutType,
        selectedPlaceholderData
      } = this.props;
      let {
        activeThemeName,
        activeSlideDetails,
        currentActiveIndex,
        selectedPlaceholder,
        slideNo
      } = this.state;

      currentActiveIndex = activeIndex || 0;
      slideNo = slideData[currentActiveIndex].activeSlide || 0;
      selectedPlaceholder = selectedPlaceholderData;

      if (slideData.length) {
        activeThemeName.value =
          slideData[currentActiveIndex].slideTitle[this.state.slideNo]
            .fileName || "";
        activeThemeName.value =
          slideData[currentActiveIndex].slideTitle[this.state.slideNo]
            .fileName || "";
        activeSlideDetails = slideData[currentActiveIndex];
      }

      this.setState({
        slideData,
        activeThemeName,
        activeSlideDetails,
        currentActiveIndex,
        selectedLayoutType,
        selectedPlaceholder,
        slideNo
      });

      activeSlideDetails.imageData.imageData &&
        this.setState({
          imageDetails: activeSlideDetails.imageData.imageData[0] || {}
        });
    }

    /**
     * Function when title editingis cancelled
     *
     */
    _onThemeTitleCancel = () => {
      let { activeThemeName } = this.state;
      activeThemeName["value"] = activeThemeName.initialValue;
      this.setState({
        activeThemeName,
        isEditing: false
      });
    };

    /**
     * Function for saving the updated title value
     */
    _onThemeTitleSave = () => {
      let { activeThemeName } = this.state;
      activeThemeName["initialValue"] = activeThemeName.value;
      this.setState({
        activeThemeName,
        isEditing: false
      });

      this.props.onSaveSlideTitle && this.props.onSaveSlideTitle();
    };

    /**
     * Function to handle the title input change
     * @param {String} the value of the title input
     */
    _onThemeTitleChange = value => {
      let { activeThemeName } = this.state;
      activeThemeName["value"] = value;
      this.setState({
        activeThemeName
      });
    };

    /**
     * Function to handle the edit icon click of title
     */
    _onThemeTitleEdit = () => {
      this.setState({
        isEditing: true
      });
    };

    /**
     * Handle the Toggle bar
     */
    _onSlideBarToggle = () => {
      this.setState({
        isSlidePreviewToggleOpen: !this.state.isSlidePreviewToggleOpen
      });

      //This is used to display the content once the full width is achievent
      setTimeout(() => {
        this.setState({
          showShadowScroll: !this.state.showShadowScroll
        });
      }, 450);
    };

    /**
     * Function to go to the Previous slide
     */
    _prevCtaHandler = () => {
      let {
        currentActiveIndex,
        activeSlideDetails,
        activeThemeName
      } = this.state;

      currentActiveIndex -= 1;
      activeSlideDetails = this.state.slideData[currentActiveIndex];
      activeThemeName["value"] =
        get(activeSlideDetails, ["slideTitle", 0, "fileName"]) || "";

      this.setState({
        currentActiveIndex,
        activeSlideDetails,
        activeThemeName,
        isEditing: false,
        slideNo: 0
      });
    };

    /**
     * Function to go to the Next slide
     */
    _nextCtaHandler = () => {
      let {
        currentActiveIndex,
        activeSlideDetails,
        activeThemeName
      } = this.state;

      currentActiveIndex += 1;
      activeSlideDetails = this.state.slideData[currentActiveIndex];
      activeThemeName["value"] =
        get(activeSlideDetails, ["slideTitle", 0, "fileName"]) || "";

      this.setState({
        currentActiveIndex,
        activeSlideDetails,
        activeThemeName,
        isEditing: false,
        slideNo: 0
      });
    };

    addRemoveSlideFromPreview = () => {
      let {
          selectedPlaceholder,
          activeSlideDetails,
          selectedLayoutType,
          slideNo
        } = this.state,
        activeRemoveButton =
          selectedPlaceholder.selectedThumbnailId ===
            get(activeSlideDetails, ["moduleId"]) &&
          get(activeSlideDetails, ["imageData", slideNo, "imageMatrix"]) ===
            selectedLayoutType;

      this.setState({
        presentationAddStatus: !!activeRemoveButton
      });

      this.props.onAddRemovePresentation &&
        this.props.onAddRemovePresentation(
          activeRemoveButton,
          this.state.activeSlideDetails,
          this.state.slideNo
        );
    };

    onSliderNavigation = (slideNo, btnDirection) => {
      let { activeThemeName, activeSlideDetails } = this.state;
      if (btnDirection === "previous" && slideNo > 0) {
        activeThemeName["value"] =
          get(activeSlideDetails, ["slideTitle", slideNo - 1, "fileName"]) ||
          "";

        this.setState({
          slideNo: slideNo - 1,
          activeThemeName
        });
      }
      if (
        btnDirection === "next" &&
        slideNo < this.state.activeSlideDetails.imageData.length - 1
      ) {
        activeThemeName["value"] =
          get(activeSlideDetails, ["slideTitle", slideNo + 1, "fileName"]) ||
          "";

        this.setState({
          slideNo: slideNo + 1,
          activeThemeName
        });
      }
    };

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props,
        onThemeTitleEdit: this._onThemeTitleEdit,
        onThemeTitleChange: this._onThemeTitleChange,
        onThemeTitleSave: this._onThemeTitleSave,
        onThemeTitleCancel: this._onThemeTitleCancel,
        onSlideBarToggle: this._onSlideBarToggle,
        prevCtaHandler: this._prevCtaHandler,
        nextCtaHandler: this._nextCtaHandler
      };

      return <Main {...stateMethodProps} />;
    }
  };

export default Container;
