/**
 * import all app reducers and export them to combine and integrate in the store
 * !Do not modify the store to combine reducers.
 */
import sampleReducer from "./sampleReducer";

// services associated with renewal tool
import users from "pages/users/services/reducer";
import reports from "pages/reports/services/reports/reducer";

// services associated with presentation tool
import presentation from "tools/presentation/pages/dashboard/services/reducer";
import presentationSetupReducers from "tools/presentation/pages/build/services";
import userProfile from "pages/app/services/getUserDetail/reducer";
import contentRepoList from "tools/presentation/pages/admin/contentRepo/dashboard/services/reducer";
import setup from "tools/presentation/pages/admin/setup/services";
import themeList from "tools/presentation/pages/admin/contentRepo/themes/services/reducer";
import contentSlideList from "tools/presentation/pages/admin/contentRepo/contentSlides/services/reducer";
import presentationHistoryList from "tools/presentation/pages/admin/history/services/reducer";
import announcementList from "components/header/components/announcement/services/reducer";
import filters from "tools/presentation/pages/filters/services/reducer";
import imageList from "tools/presentation/pages/admin/imageFolder/services/reducer";

// services associated with marketingMaterial tool
import fetchDeleteCategories from "tools/marketingMaterial/pages/admin/categories/dashboard/services/reducer";
import getCategories from "tools/marketingMaterial/pages/admin/categories/categoryCreate/services/reducer";
import documentList from "tools/marketingMaterial/pages/documents/services/reducer";
import documentAdminData from "tools/marketingMaterial/pages/admin/dashboard/services/reducer";
import createDocument from "tools/marketingMaterial/pages/documentBuild/services/reducer";
import moduleList from "tools/marketingMaterial/pages/admin/modules/services/reducer";
import clientConfig from "services/clientConfig/reducer";

// services associated with summary maker tool
import summaryMakerReducer from "tools/summaryMaker/pages/dashboard/services/reducer";

export default {
  ...sampleReducer,
  ...reports,
  ...presentationSetupReducers,
  ...presentation,
  ...userProfile,
  ...setup,
  ...contentRepoList,
  ...themeList,
  ...getCategories,
  ...fetchDeleteCategories,
  ...documentList,
  ...documentAdminData,
  ...createDocument,
  ...contentSlideList,
  ...moduleList,
  ...presentationHistoryList,
  ...announcementList,
  ...users,
  ...clientConfig,
  ...filters,
  ...imageList,
  ...summaryMakerReducer
};
