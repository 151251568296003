import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { MdDone, MdClear } from "react-icons/md";
import {
  Accordion,
  Checkbox,
  CollapsibleList,
  MetaData,
  FolderTooltip
} from "./components";
import {
  SlidePreviewEdit,
  SlidePreviewLeftArrow,
  SlidePreviewRightArrow,
  Delete,
  Save,
  Attach,
  Close
} from "assets/icons";

import hexToRgba from "utils/hexToRgba";
import container from "./container";

class ImagePreview extends Component {
  renderList = list => {
    let Option = list.children.map((item, index) => {
      return <Checkbox key={item.key} label={item.title} />;
    });
    return Option;
  };

  render() {
    const {
      handleOperation,
      isEditing,
      selectedData,
      isImageEditing,
      accordionTypes,
      categoryData,
      isShowImagePreview,
      handleImageEdit,
      currentIndex,
      addMetaData,
      clipboard,
      handleCopy,
      handlePaste,
      handleMetaDelete,
      isFolderSelecting,
      folderRef,
      onAddNewFolder,
      handleFolderPopup,
      handleMoveFolder,
      onSaveImageData,
      onSave,
      handleImageDelete,
      handleCategorySelection,
      isSaved,
      handleImageLoad,
      isImageLoaded,
      handleTitleChange,
      handleImageChange,
      selectedCategory,
      //custom change
      imageFolder,
      selectedImageData,
      editData,
      handlePreviewModal,
      activeIndex,
      imageIdList,
      imageTitle
    } = this.props;
   
    let $isTitleEditable = !!isEditing;
    let $isImageEditable = !!isImageEditing;
    let $folderPath = selectedData.selectedPath;
    let $isDisabled = !!isSaved;
    let $isImageLoaded = !!isImageLoaded;
    let isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    const UI_STRINGS = {
      NOTE:
        "Click the edit icon above to choose a focal point of the image. Then click the save icon when done."
    };

    return (
      <PreviewWrapper>
        <PreviewCloseWrapper onClick={() => handlePreviewModal($isDisabled)} />
        <PreviewPopup>
          <CloseIconWrapper
            title="Close"
            onClick={() => handlePreviewModal($isDisabled)}
          >
            <CloseIcon title="Close" />
          </CloseIconWrapper>
          <InfoSection>
            <TitleWrapper>
              {$isTitleEditable ? (
                <>
                  <Title
                    type="text"
                    autoFocus={true}
                    ref={ref => {
                      this.input = ref;
                    }}
                    value={editData.value}
                    disabled={!$isTitleEditable}
                    onChange={handleTitleChange}
                  />
                  {editData.error && (
                    <ErrorMessage>{editData.error}</ErrorMessage>
                  )}
                </>
              ) : (
                <TitleSpan title={editData.value || selectedImageData.title}>
                  {editData.value || selectedImageData.title}
                </TitleSpan>
              )}
              {!$isTitleEditable && (selectedImageData || {}).folderParent ? (
                <IconWrapper>
                  <EditIcon
                    onClick={() => handleOperation("edit", this.input)}
                    title="Edit"
                  />
                  <AttachIcon
                    onClick={() => handleOperation("attach", this.input)}
                    title="Attach"
                  />
                </IconWrapper>
              ) : (
                (selectedImageData || {}).folderParent && (
                  <IconWrapper>
                    <SaveIcon
                      onClick={() => handleOperation("save", this.input)}
                      title="Save"
                    />
                    <ClearIcon
                      onClick={() => handleOperation("clear", this.input)}
                      title="Reset"
                    />
                  </IconWrapper>
                )
              )}
            </TitleWrapper>
            {
              <Accordion isFixed={true}>
                {Array.isArray(accordionTypes)
                  ? accordionTypes.map((item, index) => (
                      <ul
                        label={item.label}
                        title={item.title}
                        isFixed={item.isFixed}
                        isOpen={item.isOpen}
                        key={index}
                      >
                        {item.value === "categoryAttributes" ? (
                          !categoryData.length ? (
                            <HelperText>
                              Please create a new category to tag it to an
                              image.
                            </HelperText>
                          ) : (
                            categoryData.map((ele, index) => {
                              return (
                                <Fragment key={index}>
                                  {/* Category head */}
                                  <CategoryTitle key={ele.key}>
                                    {ele.title}
                                  </CategoryTitle>

                                  {Array.isArray(ele.children) &&
                                    ele.children.length > 0 && (
                                      <CheckboxList>
                                        <CollapsibleList
                                          list={ele.children}
                                          onClick={handleCategorySelection}
                                          selectedCategory={selectedCategory}
                                          selectedImageData={selectedImageData}
                                        />
                                      </CheckboxList>
                                    )}
                                </Fragment>
                              );
                            })
                          )
                        ) : (
                          Object.keys(selectedImageData).length && (
                            <MetaData
                              metaData={selectedImageData.metaData}
                              addMetaData={addMetaData}
                              clipboard={clipboard}
                              handleCopy={handleCopy}
                              handlePaste={handlePaste}
                              handleDelete={handleMetaDelete}
                              selectedImageData={selectedImageData}
                            />
                          )
                        )}
                      </ul>
                    ))
                  : null}
              </Accordion>
            }
          </InfoSection>
          <ImageSection>
            <FolderTab>
              <FolderSection>
                {(selectedImageData || {}).folderParent !== null ? (
                  <>
                    <NewFolderWrapper>
                      <FolderTooltip
                        handleFolderPopup={handleFolderPopup}
                        folderData={imageFolder}
                        ref={folderRef}
                        onAddNewFolder={onAddNewFolder}
                        isVisible={isFolderSelecting}
                        handleMoveFolder={handleMoveFolder}
                        {...this.props}
                      />
                    </NewFolderWrapper>
                    <FolderPath title={$folderPath}>{$folderPath}</FolderPath>
                  </>
                ) : (
                  <NoteText>
                    This image has been removed. It will remain available for
                    previously created presentations only.
                  </NoteText>
                )}
              </FolderSection>
              <SmallButton
                onClick={!$isDisabled ? () => onSave(false) : null}
                isDisabled={$isDisabled}
                title="Save"
              >
                Save
              </SmallButton>
            </FolderTab>
            {isShowImagePreview ? (
              <ImageWrapper
                className="image-preview-wrapper"
                showOverflow={isChrome}
              >
                <ImageContainer>
                  <Image
                    key={`${currentIndex}`}
                    ref={ref => (this.image = ref)}
                    src={selectedImageData.imageLocation}
                    alt={selectedData.title}
                    onLoad={handleImageLoad}
                    isHidden={!$isImageLoaded}
                  />
                  {$isImageLoaded || (
                    <ImageLoader>
                      <StyledSpinner />
                    </ImageLoader>
                  )}
                </ImageContainer>
                {!$isImageEditable ? <OverlayPreview /> : null}
                {!$isImageEditable ? (
                  <Fragment>
                    <IconBg
                      title="Edit"
                      onClick={event =>
                        (selectedImageData || {}).folderParent &&
                        handleImageEdit(this.image, event)
                      }
                      disbaleCheckbox={(selectedImageData || {}).folderParent}
                    >
                      <EditIcon
                        title="Edit"
                        hasbg="true"
                        disbaleCheckbox={(selectedImageData || {}).folderParent}
                      />
                    </IconBg>
                  </Fragment>
                ) : (
                  <IconBg
                    title="Save"
                    onClick={event => onSaveImageData(this.image, event)}
                  >
                    <BigSaveIcon title="Save" hasbg="true" />
                  </IconBg>
                )}
                <IconBottomBg title="Delete" onClick={handleImageDelete}>
                  <DeleteIcon title="Delete" hasbg="true" />
                </IconBottomBg>
              </ImageWrapper>
            ) : null}
            {
              <NoteContainer>
                <Note>Note: </Note>
                {UI_STRINGS.NOTE}
              </NoteContainer>
            }
          </ImageSection>
          <Footer>
            <SlideCtaBar>
              <NextPrevSlideCtaWrapper>
                <NextPrevSlideCta
                  title="Previous Image"
                  onClick={
                    !$isImageEditable && !$isTitleEditable
                      ? () => handleImageChange("previous")
                      : null
                  }
                  isDisabled={
                    $isImageEditable || $isTitleEditable || activeIndex === 0
                  }
                >
                  <SlidePrevIcon />
                </NextPrevSlideCta>
                <NextPrevSlideText
                  title={imageTitle || selectedImageData.title}
                >
                  {imageTitle || selectedImageData.title}
                </NextPrevSlideText>
                <NextPrevSlideCta
                  title="Next Image"
                  onClick={
                    !$isImageEditable && !$isTitleEditable
                      ? () => handleImageChange("next")
                      : null
                  }
                  isDisabled={
                    $isImageEditable ||
                    $isTitleEditable ||
                    activeIndex === imageIdList.length - 1
                  }
                >
                  <SlideNextIcon />
                </NextPrevSlideCta>
              </NextPrevSlideCtaWrapper>
            </SlideCtaBar>
          </Footer>
        </PreviewPopup>
      </PreviewWrapper>
    );
  }
}
const NewFolderWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const CloseIcon = styled(Close)`
  height: 25px;
  width: 26px;
`;

const CheckboxList = styled.ul`
  padding-left: 10px;
  margin-bottom: 16px;
  list-style: none;
  box-sizing: border-box;
`;

const NoteContainer = styled.div`
  margin-left: 30px;
  padding: 9px 0;
  background-color: ${props => props.theme.COLOR.WHITE};
  text-align: left;
`;

const Note = styled.span`
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  font-weight: bold;
`;

// z-index ref starts from 15
const PreviewWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 15;
`;

const PreviewCloseWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 15;
`;

const PreviewPopup = styled.div`
  width: 85%;
  max-width: 873px;
  position: absolute;
  padding: 40px 30px 84px 30px;
  top: calc(50% + 53px);
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props =>
    props.faded ? hexToRgba("#d8d8d8", 1) : "#fff"};
  border-radius: 4px;
  z-index: 16;
  box-sizing: border-box;
  ${props => props.theme.SNIPPETS.BOX_SHADOW}
`;

const ImageSection = styled.div`
  width: 70.2%;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
`;

const IconBg = styled.div`
  position: absolute;
  top: 15px;
  right: 18px;
  background-color: #fff;
  box-sizing: border-box;
  height: 35px;
  width: 35px;
  z-index: 12;
  border-radius: 50%;
  ${props => props.theme.SNIPPETS.BOX_SHADOW}
  cursor: ${props => (!props.disbaleCheckbox ? "not-allowed" : "pointer")};
`;

const IconBottomBg = styled.div`
  position: absolute;
  bottom: 15px;
  right: 18px;
  background-color: #fff;
  box-sizing: border-box;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  z-index: 12;
  ${props => props.theme.SNIPPETS.BOX_SHADOW}
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-left: 30px;
  box-sizing: border-box;
  border: solid 2px ${props => props.theme.COLOR_PALLETE.LIGHT_GREY};
  display: flex;
  justify-content: center;
  min-height: 364px;
  align-items: center;
`;

const ImageContainer = styled.div`
  max-height: 360px;
  box-sizing: border-box;
  overflow: ${props => (props.showOverflow ? "hidden" : "visible")};

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const FolderPath = styled.span`
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
  display: inline-block;
  vertical-align: top;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 1)};
`;

const Image = styled.img`
  max-width: 540px;
  max-height: 355px;
  margin: auto;
  display: ${props => (props.isHidden ? "none" : "block")};
`;

const ImageLoader = styled.div`
  position: relative;
  width: 457px;
  height: 360px;
`;

const OverlayPreview = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const InfoSection = styled.div`
  width: 29.8%;
  max-width: 245px;
  height: 400px;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
`;

const StyledSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const EditIcon = styled(SlidePreviewEdit)`
  position: absolute;
  top: ${props => (props.hasbg ? `50%` : `0`)};
  left: ${props => (props.hasbg ? `50%` : `auto`)};
  right: ${props => (props.hasbg ? `auto` : `21px`)};
  transform: ${props => (props.hasbg ? `translate(-50%,-50%)` : null)};
  height: 16px;
  width: 16px;
  cursor: ${props => (!props.disbaleCheckbox ? "not-allowed" : "pointer")};
  g {
    stroke: #979797;
  }
`;

const DeleteIcon = styled(Delete)`
  position: absolute;
  top: ${props => (props.hasbg ? `50%` : `5px`)};
  left: ${props => (props.hasbg ? `50%` : `auto`)};
  right: ${props => (props.hasbg ? `auto` : `10px`)};
  transform: ${props => (props.hasbg ? `translate(-50%,-50%)` : null)};
  height: 18px;
  width: 19px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 10px;
`;

const SaveIcon = styled(MdDone)`
  position: absolute;
  right: 21px;
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
  color: #979797;
  cursor: pointer;
`;

const AttachIcon = styled(Attach)`
  position: absolute;
  right: 0px;
  height: 13px;
  width: 13px;
  cursor: pointer;
  g {
    fill: #979797;
  }
`;

const BigSaveIcon = styled(Save)`
  height: 16px;
  width: 16px;
  position: absolute;
  top: ${props => (props.hasbg ? `50%` : `5px`)};
  left: ${props => (props.hasbg ? `50%` : `auto`)};
  right: ${props => (props.hasbg ? `auto` : `10px`)};
  transform: ${props => (props.hasbg ? `translate(-50%,-50%)` : null)};
  color: #979797;
  cursor: pointer;
`;

const ClearIcon = styled(MdClear)`
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
  color: #979797;
  cursor: pointer;
`;

const TitleWrapper = styled.div`
  padding: 0 50px 0 0;
  height: 24px;
  position: relative;
`;

const TitleSpan = styled.span`
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: super;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: bold;
  font-family: "Maven Pro", sans-serif;
  opacity: 0.7;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid transparent;
  cursor: auto;
`;

const Title = styled.input`
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: super;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: bold;
  font-family: "Maven Pro", sans-serif;
  opacity: 0.7;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: ${props => `1px solid ${props.theme.COLOR.USER_PRIMARY}`};
`;

const CloseIconWrapper = styled.span`
  padding: 3px 3px 1px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.14);
  transform: translate(50%, -50%);
  z-index: 10;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const Footer = styled.div`
  padding: 18px 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const FolderTab = styled.div`
  margin-left: 30px;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const FolderSection = styled.div`
  width: 73%;
  float: left;
  margin-bottom: 14px;
`;

const SmallButton = styled.button`
  float: right;
  box-sizing: border-box;
  display: block;
  padding: 2px 56px 4px;
  border: 1px solid
    ${props =>
      props.isDisabled ? "transparent" : props.theme.COLOR.USER_PRIMARY};
  border-radius: 3px;
  background-color: ${props =>
    props.isDisabled
      ? hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.54)
      : props.theme.COLOR.USER_PRIMARY};
  color: #fff;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-weight: bold;
  font-size: 14px;
  cursor: ${props => (props.isDisabled ? "normal" : "pointer")};
  transition: all 1s;

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${props =>
      props.isDisabled
        ? hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.54)
        : "#fff"};
    color: ${props =>
      props.isDisabled ? "#fff" : props.theme.COLOR.USER_PRIMARY};
  }
`;

const SlideCtaBar = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  float: right;
`;

const NextPrevSlideCtaWrapper = styled.div``;

const SlidePrevIcon = styled(SlidePreviewLeftArrow)`
  path {
    fill: #fff;
  }
`;

const SlideNextIcon = styled(SlidePreviewRightArrow)`
  path {
    fill: #fff;
  }
`;

const NextPrevSlideText = styled.span`
  width: calc(100% - 114px);
  max-width: 700px;
  padding: 0 40px;
  display: inline-block;
  vertical-align: top;
  color: #fff;
  font-size: 14px;
  font-family: ${props => `${props.theme.FONT.REG}`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NextPrevSlideCta = styled.span`
  padding: 0 5px;
  display: inline-block;
  cursor: ${props => (props.isDisabled ? "auto" : "pointer")};
  text-transform: uppercase;
  color: #fff;
  vertical-align: middle;
  opacity: ${props => (props.isDisabled ? 0.3 : 1)};
  pointer-events: ${props => (props.isDisabled ? "none" : "auto")};
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
  font-weight: normal;
  position: absolute;
  left: 0;
  bottom: -8px;
`;

const HelperText = styled.span`
  ${props => props.theme.SNIPPETS.HELPER_TEXT};
`;

const NoteText = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
  font-weight: normal;
`;

const CategoryTitle = styled.span`
  width: calc(100% - 30px);
  display: inline-block;
  box-sizing: border-box;
  margin-left: 13px;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.7)};
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default container(ImagePreview);
