import React from "react";
import styled from "styled-components";
import Styled, { withTheme } from "styled-components";
import { MdDone, MdClear } from "react-icons/md";
import { FaPlus, FaTimes } from "react-icons/fa";
import { get, filter } from "lodash";

import {
  SlidePreviewDownload,
  AngleDown,
  EditWithNoShadow
} from "assets/icons";

const Button = props => {
  return (
    <ButtonWrapper
      title={props.title}
      backgroundColor={props.backgroundColor || props.theme.COLOR.USER_PRIMARY}
    >
      {props.children}
    </ButtonWrapper>
  );
};

const ButtonWrapper = Styled.a`
  box-sizing: border-box;
  display: block;
  padding: 6px 20px;
  border: 1px solid ${props => props.backgroundColor};
  border-radius: 3px; 
  background-color: ${props => props.backgroundColor};
  color: #fff;
  font-size: 12px;
  font-family: ${props => props.theme.FONT.REG};
  font-weight: bold;
  cursor: pointer;
  transition: all 1s;
  &:hover {
    background-color: #fff;
    color: ${props => props.backgroundColor};
  }
`;

const StyledButton = withTheme(Button);

export const SlidePreviewHeaderComponent = props => {
  const {
    previewData,
    isSlideTitleEditable,	
    onClickSlideTitleEdit,	
    onClickSaveSlideTitle,	
    onClickResetSlideTitle,
    isRerenderdSlideTitle,
    slideTitle,
    showDownloadDropdown,
    handleDropDown,
    addRemovePresentation,
    slideName,
    setSlideTitle,
    activeSlideDetail,
    getThumbnailBasedOnSlideType,
    selectedThemeLayout,
    checkIfAddedToPresentation,
    selectedSlidesListDetail,
    buildSetupDetails,
    isInputFocused,
    slideNameError,
    downloadDeckHandler,
    downloadDeckUrlHandler,
    _checkSlideIsDeck,
    presentationData
  } = props;

  // get cover title from setup title field
  let coverTitle = get(buildSetupDetails, `title.value`);

  // Check if is cover
  let isCover = get(activeSlideDetail, "isCover");
  
  // Check if is cover
  let isRequired = get(activeSlideDetail, "isRequired");

  // Check if overview
  let isOverview = get(activeSlideDetail, "isOverview");

  let dataWithChangedTitle = filter(selectedSlidesListDetail, slideData => {
    return get(slideData, `_id`) === get(activeSlideDetail, `_id`);
  });

  // Check if the current slide is part f a deck
  let isCurrentOpenSlideDeck =
    _checkSlideIsDeck && _checkSlideIsDeck(get(activeSlideDetail, `_id`));

  // File url based on theme
  let downloadUrl =
    get(activeSlideDetail, "fileLocation.url") ||
    (getThumbnailBasedOnSlideType &&
      getThumbnailBasedOnSlideType(
        selectedThemeLayout,
        activeSlideDetail,
        "file"
      ));

  // if both changedTitle and slideName are not set, use the original title, else use slideName
  let slideNameValue =
    !get(dataWithChangedTitle[0], `changedTitle`) &&
    !slideName &&
    !isInputFocused
      ? get(activeSlideDetail, `title`, "")
      : slideName || isInputFocused
      ? slideName
      : get(dataWithChangedTitle[0], `changedTitle`);

  let downloadRef;

  let isAddedToPresentation = checkIfAddedToPresentation();
       
  const DownloadDropDown = showDownloadDropdown ? (
    <DownloadDropDownWrapper className="drop-down-wrapper">
      <DownloadDropDownContainer>
        <DownloadCtaList
          title="Download Slide"
          onClick={() =>
            downloadDeckUrlHandler(
              downloadUrl,
              activeSlideDetail._id,
              "contentSlide",
              selectedThemeLayout
            )
          }
          isCurrentOpenSlideDeck={isCurrentOpenSlideDeck}
        >
          <DownLoadCtaListText> Download Slide </DownLoadCtaListText>
        </DownloadCtaList>
        <DownloadCtaList
          title="Download Deck"
          onClick={() =>
            downloadDeckHandler(
              activeSlideDetail,
              "contentSlideCategory",
              selectedThemeLayout
            )
          }
          isCurrentOpenSlideDeck={isCurrentOpenSlideDeck}
        >
          <DownLoadCtaListText> Download Deck </DownLoadCtaListText>
        </DownloadCtaList>
      </DownloadDropDownContainer>
    </DownloadDropDownWrapper>
  ) : null;
  return (
    <SlidePreviewHeader>
      <SlideTitleWrapper coverSlide={isCover}
       title={isCover ? presentationData.title : activeSlideDetail.title}
      >
        {!isRerenderdSlideTitle ? (
          <SlideTitle
            value={isCover ? coverTitle : slideNameValue}
            disabled={!isSlideTitleEditable}
            // onBlur={onBlurFolderTitle}
            id="slideTitle"
            title={previewData.title}
            ref={slideTitle}
            onChange={e => {	
              setSlideTitle(e);	
            }}
          />
        ) : null}
        <ErrorMessage>{slideNameError}</ErrorMessage>
        {isAddedToPresentation && !activeSlideDetail.hideEditIcon && (	
          <>	
            {!isSlideTitleEditable ? (	
              <EditIconWrapper onClick={onClickSlideTitleEdit} title="Edit">	
                <EditWithNoShadow />	
              </EditIconWrapper>	
            ) : (	
              <SaveClearIconWrapper>	
                <SaveIconWrapper onClick={onClickSaveSlideTitle} title="Save">	
                  <MdDone size={20} color="#a9a9a9" />	
                </SaveIconWrapper>	
                <ResetIconWrapper	
                  title="Reset"	
                  onClick={onClickResetSlideTitle}	
                >	
                  <MdClear size={20} color="#a9a9a9" />	
                </ResetIconWrapper>	
              </SaveClearIconWrapper>	
            )}	
          </>	
        )}
      </SlideTitleWrapper>
      <SlideHeaderButtonWrapper coverSlide={isCover}>
        {!isOverview && (
          <DownloadCtaWrapper className="DownloadCtaWrapper">
            {isCurrentOpenSlideDeck ? (
              <>
                <DownloadCtaContainer
                  ref={ref => (downloadRef = ref)}
                  onClick={e => {
                    handleDropDown(e, downloadRef);
                  }}
                >
                  <DownloadCta>
                    <DownloadCtaIcon />
                    <DownloadCtaText> Download </DownloadCtaText>
                  </DownloadCta>
                  <DownloadDropDownIcon
                    className="download-dropdown-icon"
                    showDownloadDropdown={showDownloadDropdown}
                  >
                    <DropdownIcon />
                  </DownloadDropDownIcon>
                </DownloadCtaContainer>
                {DownloadDropDown}
              </>
            ) : (
              <DownloadCtaList
                title="Download"
                onClick={() =>
                  downloadDeckUrlHandler(
                    downloadUrl,
                    activeSlideDetail._id,
                    "contentSlide",
                    selectedThemeLayout
                  )
                }
                isCurrentOpenSlideDeck={isCurrentOpenSlideDeck}
              >
                <DownLoadCtaListText> Download </DownLoadCtaListText>
              </DownloadCtaList>
            )}
          </DownloadCtaWrapper>
        )}
        {!isCover && !isOverview && (
          <AddPresentationCtaWrapper
            isRequired={isRequired}
            onClick={() =>
              addRemovePresentation(!isAddedToPresentation, activeSlideDetail)
            }
            className={isAddedToPresentation ? "remove-slide-icon" : ""}
          >
            <StyledButton
              title={
                isRequired
                  ? ""
                  : isAddedToPresentation
                  ? "Remove from presentation"
                  : "Add to presentation"
              }
            >
              <AddPresentationCtaIcon>
                {isAddedToPresentation ? (
                  <FaTimes size={10} />
                ) : (
                  <FaPlus size={10} />
                )}
              </AddPresentationCtaIcon>
              <AddPresentationCtaText>
                {isAddedToPresentation
                  ? "Remove from presentation"
                  : "Add to presentation"}
              </AddPresentationCtaText>
            </StyledButton>
          </AddPresentationCtaWrapper>
        )}
      </SlideHeaderButtonWrapper>
    </SlidePreviewHeader>
  );
};

const SlidePreviewHeader = styled.div`
  padding: 0 28px;
`;

const DropdownIcon = styled(AngleDown)`
  height: 10px;
  width: 9px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  g {
    opacity: 1;
  }
  path {
    fill: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  }
`;

const AddPresentationCtaWrapper = styled.div`
  display: inline-block;
  text-align: center;
  &.remove-slide-icon {
    ${props => props.isRequired && `cursor :not-allowed;`}
    a {
      ${props => props.isRequired && `pointer-events: none;`}
      border: 1px solid #ff4d4d;
      background: #ff4d4d;
      ${props =>
        props.isRequired &&
        `border: 1px solid ${props.theme.COLOR.LIGHT_GREY};
      background: ${props.theme.COLOR.LIGHT_GREY};`}
      &:hover {
        color: #ff4d4d;
        background: transparent;
      }
    }
  }
`;

const SlideHeaderButtonWrapper = styled.div`
  width: ${props => props.coverSlide ? "15%" : "70%"};
  float: right;
  padding-left: 8px;
  text-align: right;
`;

const AddPresentationCtaIcon = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
`;

const AddPresentationCtaText = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

const DownloadCtaWrapper = styled.div`
  display: inline-block;
  margin-right: 3.6%;
  position: relative;
`;

const DownloadDropDownWrapper = styled.div`
  width: 120%;
  padding-top: 5px;
  position: absolute;
  background-color: ${props => props.theme.COLOR.WHITE};
  bottom: 0;
  right: 0;
  z-index: 11;
  transform: translateY(100%);
`;

const DownLoadCtaListText = styled.span`
  color: ${props => props.theme.COLOR.SECONDARY};
  font-size: 12px;
  line-height: 24px;
  font-family: ${props => props.theme.FONT.REG};
  font-weight: bold;
  transition: all 1s;
  display: block;
`;

const DownloadCtaList = styled.a`
  padding: ${props => (props.isCurrentOpenSlideDeck ? "10px 5px" : "0 27px")};
  text-align: center;
  display: block;
  cursor: pointer;
  text-decoration: none;
  border-radius: ${props => !props.isCurrentOpenSlideDeck && "3px"};
  border: 1px solid
    ${props =>
      props.isCurrentOpenSlideDeck ? "transparent" : props.theme.COLOR.HEADING};
  &:last-child {
    border: ${props => props.isCurrentOpenSlideDeck && "none"};
  }
  &:hover {
    background: ${props =>
      !props.isCurrentOpenSlideDeck && props.theme.COLOR.MAIN};
    color: ${props => !props.isCurrentOpenSlideDeck && props.theme.COLOR.WHITE};
  }
  &:hover ${DownLoadCtaListText} {
    color: ${props =>
      props.isCurrentOpenSlideDeck
        ? props.theme.COLOR.MAIN
        : props.theme.COLOR.WHITE};
  }
  border-bottom: ${props =>
    props.isCurrentOpenSlideDeck && `1px solid ${props.theme.COLOR.SECONDARY}`};
`;

const DownloadDropDownContainer = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.COLOR.SECONDARY};
  border-radius: 3px;
`;

const DownloadCta = styled.span`
  box-sizing: border-box;
  padding: 0px 20px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  font-size: 12px;
  line-height: 24px;
  font-family: ${props => props.theme.FONT.REG};
  font-weight: bold;
  transition: all 1s;
  display: inline-block;
`;

const DownloadCtaContainer = styled.div`
  padding: 0;
  outline: none;
  border: 1px solid ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  border-radius: 3px;
  display: block;
  background: none;
  cursor: pointer;
`;

const DownloadCtaIcon = styled(SlidePreviewDownload)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
`;

const DownloadDropDownIcon = styled.span`
  padding: 6px 8px 4px;
  display: inline-block;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  border-left: 1px solid ${props => props.theme.COLOR.MAIN};
  vertical-align: middle;
  background-color: "transparent";

  svg {
    transform: ${props =>
      props.showDownloadDropdown ? "rotate(180deg)" : "none"};
  }
`;

const DownloadCtaText = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

const SlideTitleWrapper = styled.div`
  width: ${props => props.coverSlide ? "85%" : "30%"};
  position: relative;
  margin: 5px 0 9px;
  display: inline-block;
`;

const SlideTitle = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 3px 50px 0 0;
  display: block;
  color: grey;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: bold;
  font-family: ${props => `${props.theme.FONT.REG}`};
  opacity: 0.7;
  color: ${props => props.theme.COLOR.HEADING};
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow:hidden;

  &::-ms-clear {
    display: none;
  }
`;

const EditIconWrapper = styled.a`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const SaveClearIconWrapper = styled.div`
  position: absolute;
  top: 11px;
  right: 0;
  transform: translateY(-50%);
`;

const SaveIconWrapper = styled.a`
  cursor: pointer;
  margin-right: 2px;
`;

const ResetIconWrapper = styled.a`
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  font-family: ${props => props.theme.FONT.REG};
  display: block;
  color: ${props => props.theme.COLOR.ERROR};
`;
