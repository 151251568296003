export const CONTENT_SLIDE_SUCCESS = "CONTENT_SLIDE_SUCCESS";
export const LOADING_CONTENT_SLIDE_LIST = "LOADING_CONTENT_SLIDE_LIST";
export const CONTENT_REPO_SLIDE__SUCCESS = "CONTENT_REPO_SLIDE__SUCCESS";
export const LOADING_CONTENT_REPO_SLIDE = "LOADING_CONTENT_REPO_SLIDE";
export const UPLOADING_SLIDE = "UPLOADING_SLIDE";
export const GET_THEME_DATA = "GET_THEME_DATA";
export const FILTERS_LIST_LOADING = "FILTERS_LIST_LOADING";
export const FILTERS_LIST_SUCCESS = "FILTERS_LIST_SUCCESS";
export const CONTENT_FILTER_LOADING = "CONTENT_FILTER_LOADING";
export const CONTENT_FILTER_UPDATE_SUCCESS = "CONTENT_FILTER_UPDATE_SUCCESS";
