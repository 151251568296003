import FetchUtils from "utils/FetchUtils.js";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";

// import report actions
import {
  imageListSuccess,
  imageListLoading,
  imageUploadLoader
} from "./action";

import { ConvertQueryParamsArrayObjectToString } from "utils/queryString";

const fetchImageFolderCategoryList = (type, flag) => async dispatch => {
  const URL =
    type === "folder"
      ? `/image-folders`
      : flag
      ? "/image-categories?excludeEmptyParents=true"
      : "/image-categories";

  const key =
    type === "folder" ? "imageFolderListData" : "imageCategoryListData";
  // start loading
  dispatch(
    imageListLoading({
      imageListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    imageListLoading({
      imageListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      imageListSuccess({
        [key]: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const imageFolderCRUD = ({
  payload,
  message,
  id,
  deleteFolder,
  deleteImages = false
}) => async dispatch => {
  let response = {};

  const URL = deleteFolder
    ? `/image-folders/${id}${`?deleteImages=${deleteImages}`}`
    : id
    ? `/image-folders/${id}`
    : `/image-folders`;

  // start loading
  dispatch(
    imageListLoading({
      imageListLoading: true
    })
  );

  if (deleteFolder) {
    response = await FetchUtils.deleteData(URL);
  } else {
    response = id
      ? await FetchUtils.patchData(URL, payload)
      : await FetchUtils.postData(URL, payload);
  }

  dispatch(
    imageListLoading({
      imageListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    ToastUtils.handleToast({
      operation: "success",
      message: message
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const imageFolderCategoryReorder = (payload, tab) => async dispatch => {
  const URL =
    tab === "folder" ? `/image-folders/reorder` : `/image-categories/reorder`;

  // start loading
  dispatch(
    imageListLoading({
      imageListLoading: true
    })
  );

  const response = await FetchUtils.patchData(URL, payload);

  dispatch(
    imageListLoading({
      imageListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    ToastUtils.handleToast({
      operation: "success",
      message: "List updated successfully."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

// image category
const imageCategoryCRUD = ({
  payload,
  message,
  id,
  deleteImages
}) => async dispatch => {
  let response = {};

  const URL = id ? `/image-categories/${id}` : `/image-categories`;

  // start loading
  dispatch(
    imageListLoading({
      imageListLoading: true
    })
  );

  if (deleteImages) {
    response = await FetchUtils.deleteData(URL);
  } else {
    response = id
      ? await FetchUtils.patchData(URL, payload)
      : await FetchUtils.postData(URL, payload);
  }

  dispatch(
    imageListLoading({
      imageListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    ToastUtils.handleToast({
      operation: "success",
      message: message
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const imageUpload = body => async dispatch => {
  const URL = `/images`;

  // start loading
  dispatch(
    imageUploadLoader({
      imageUploadLoading: true
    })
  );

  const response = await FetchUtils.postData(URL, body, "Post New Image");

  // stop loading
  dispatch(
    imageUploadLoader({
      imageUploadLoading: false
    })
  );

  if (!response.success) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const fetchImageList = (id, selectedTab) => async dispatch => {
  let tab = selectedTab === "folder" ? "folderId" : "categoryId";

  const params = ConvertQueryParamsArrayObjectToString([
    {
      [tab]: id
    }
  ]);
  const URL = `/images/${params ? `?${params}` : ""}`;

  // start loading
  dispatch(
    imageListLoading({
      imageListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL, "Fetch Image List");

  dispatch(
    imageListLoading({
      imageListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      imageListSuccess({
        specificFolderImageList: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const fetchSpecificImageData = id => async dispatch => {
  const URL = `/images/${id}`;

  // start loading
  dispatch(
    imageListLoading({
      imageListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL, "Fetch Image List");

  dispatch(
    imageListLoading({
      imageListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      imageListSuccess({
        imageAttributes: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const updateImageDataAttributes = (body, id, hideToast) => async dispatch => {
  const URL = `/images/${id}`;

  // start loading
  dispatch(
    imageUploadLoader({
      imageUploadLoading: true
    })
  );

  const response = await FetchUtils.patchData(URL, body, "Update Image Data");

  // stop loading
  dispatch(
    imageUploadLoader({
      imageUploadLoading: false
    })
  );

  if (response.success) {
    !hideToast &&
      ToastUtils.handleToast({
        operation: "success",
        message: "Your data has been updated successfully."
      });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const deleteImageFromList = (id, removeImage) => async dispatch => {
  const params = ConvertQueryParamsArrayObjectToString([
    {
      removeImage: `${removeImage}`
    }
  ]);

  const URL = `/images/${id}/${params ? `?${params}` : ""}`;

  // start loading
  dispatch(
    imageUploadLoader({
      imageUploadLoading: true
    })
  );

  const response = await FetchUtils.deleteData(URL, "Delete Image Data");

  // stop loading
  dispatch(
    imageUploadLoader({
      imageUploadLoading: false
    })
  );

  if (response.success) {
    ToastUtils.handleToast({
      operation: "success",
      message: "Your image has been deleted successfully."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const downloadExcel = (id, isSkip) => async dispatch => {
  let params;
  if (isSkip) {
    params = ConvertQueryParamsArrayObjectToString([
      {
        skip: "true"
      }
    ]);
  }

  const URL = `/images/${id}/excel/${params ? `?${params}` : ""}`;

  // start loading
  dispatch(
    imageUploadLoader({
      imageUploadLoading: true
    })
  );

  const response = await FetchUtils.getData(URL, "Fetch Excel Sheet URL");

  // stop loading
  dispatch(
    imageUploadLoader({
      imageUploadLoading: false
    })
  );

  if (response.success) {
    //dispatch data list on success
    dispatch(
      imageListSuccess({
        excelUrl: response.data.location
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const uploadExcel = body => async dispatch => {
  const URL = `/images/excel`;

  // start loading
  dispatch(
    imageUploadLoader({
      imageUploadLoading: true
    })
  );

  const response = await FetchUtils.postData(
    URL,
    body,
    "Fetch Excel Sheet URL"
  );

  // stop loading
  dispatch(
    imageUploadLoader({
      imageUploadLoading: false
    })
  );

  if (!response.success) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const checkIncompleteUploadStatus = userId => async dispatch => {
  const params = ConvertQueryParamsArrayObjectToString([
    {
      excelStatus: "true"
    }
  ]);

  const URL = `/users/${userId}/${params ? `?${params}` : ""}`;

  // start loading
  dispatch(
    imageUploadLoader({
      imageUploadLoading: true
    })
  );

  const response = await FetchUtils.getData(URL, "Get User Details");

  // stop loading
  dispatch(
    imageUploadLoader({
      imageUploadLoading: false
    })
  );

  if (!response.success) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

export {
  fetchImageFolderCategoryList,
  imageFolderCRUD,
  imageFolderCategoryReorder,
  imageCategoryCRUD,
  imageUpload,
  fetchImageList,
  fetchSpecificImageData,
  updateImageDataAttributes,
  deleteImageFromList,
  downloadExcel,
  uploadExcel,
  checkIncompleteUploadStatus
};
