import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { ProfileProvider } from "context/ProfileContext";
import Routes from "routes";
import GlobalStyle from "globalStyle";
import Helmet from "pages/helmet";

// configure redux store
import configureStore from "./store";

import CustomThemeProvider from "./utils/themeProvider";

function AllProvider() {
  return (
    <Provider store={configureStore()}>
      <CustomThemeProvider>
        <ProfileProvider>
          <Helmet />
          <Fragment>
            <Routes />
            <GlobalStyle />
          </Fragment>
        </ProfileProvider>
      </CustomThemeProvider>
    </Provider>
  );
}

export default AllProvider;
