import React from "react";
import styled, { css } from "styled-components";
import StepDetails from "components/buildProcess/stepDetails";
import Container from "./container";
import hexToRgba from "utils/hexToRgba";
import handleBodyScroll from "utils/handleBodyScroll";
import "react-accessible-accordion/dist/fancy-example.css";
import Checkbox from "components/checkbox";
import { PreviewBig, Close, CobrandStamp } from "assets/icons";
import { map } from "lodash";

const renderAccordion = ({
  coverList,
  handleStateChange,
  handleCoverPreview,
  selectedCoverLayout,
  contentRepo
}) => {
  return (
    <StyledAccordion>
      {Array.isArray(coverList) && coverList.length ? (
        map(coverList, cover => {
          let { _id, thumbnailLocation, title } = cover;
          return (
            <CoverImageWrapper
              key={_id}
              isSelected={_id === selectedCoverLayout}
            >
              <CoverImg
                src={thumbnailLocation.url}
                title={title}
                onClick={() => {
                  handleStateChange({
                    key: "selectedCoverLayout",
                    value: _id
                  });
                  handleStateChange({
                    key: "coverDetails",
                    value: cover
                  });
                }}
              />
              <PreviewIcon
                onClick={() => handleCoverPreview(thumbnailLocation.url)}
              />
            </CoverImageWrapper>
          );
        })
      ) : (
        <NoCoverData>
          {contentRepo && contentRepo.title
            ? `No cover available under selected theme.`
            : `Please select content repo.`}
        </NoCoverData>
      )}
    </StyledAccordion>
  );
};

const Theme = props => {
  const {
    themeList,
    coverList,
    handleStateChange,
    includeCoverPage,
    selectedThemeLayout,
    selectedCoverLayout,
    handleCoverPreview,
    showPreview,
    overlayCoverImage,
    contentRepo,
    fetchCoverList,
    nextStepHandler,
    coverListHandler,
    skipBtnHandler,
    isEdited,
    onSave,
    doesCobrandLogoExist
  } = props;

  return (
    <>
      {showPreview
        ? handleBodyScroll({ action: "open" })
        : handleBodyScroll({ action: "close" })}
      {showPreview && (
        <>
          <Overlay onClick={handleCoverPreview}></Overlay>
          <OverlayImageWrapper>
            <OverlayCoverImg src={overlayCoverImage} />
            <CloseIconWrapper>
              <CloseIcon onClick={() => handleCoverPreview()} />
            </CloseIconWrapper>
          </OverlayImageWrapper>
        </>
      )}
      <ThemeWrapper>
        <StepDetails
          _next
          _save={isEdited}
          _skip
          onNext={() => nextStepHandler(2)}
          onSkip={skipBtnHandler}
          title={"Theme"}
          notDangerously={true}
          onSave={onSave}
          description={
            doesCobrandLogoExist ? (
              <>
                <CobrandLogoStampIcon />
                <CoBrandText>
                  This represents that the given theme has co-brand logo
                  placeholder.
                </CoBrandText>
              </>
            ) : (
              ""
            )
          }
          {...props}
        />
        <ThemeBox>
          <ThemeBoxHeading>Theme</ThemeBoxHeading>
          <ThemeList>
            {Array.isArray(themeList) && themeList.length ? (
              map(themeList, theme => {
                let { thumbnailLocation, _id, title, isCobrandLogo } = theme;

                return (
                  <ThemeContainer key={_id}>
                    {isCobrandLogo && (
                      <CobrandStampWrapper>
                        <CobrandLogoStampIcon />
                      </CobrandStampWrapper>
                    )}
                    <ThemeImageWrap
                      isSelected={_id === selectedThemeLayout}
                      onClick={() => {
                        if (_id !== selectedThemeLayout) {
                          handleStateChange({
                            key: "selectedThemeLayout",
                            value: _id,
                            cb: () => {
                              fetchCoverList && fetchCoverList();
                            }
                          });

                          handleStateChange({
                            key: "includeCoverPage",
                            value: true
                          });

                          handleStateChange({
                            key: "selectedThemeDetails",
                            value: theme
                          });
                        }
                      }}
                    >
                      <ThemeImg src={thumbnailLocation} />
                    </ThemeImageWrap>
                    <ThemeTitle title={title}>{title}</ThemeTitle>
                  </ThemeContainer>
                );
              })
            ) : (
              <NoThemeData>
                {contentRepo && contentRepo.title
                  ? `No theme available under Content Repo.`
                  : `Please select content repo.`}
              </NoThemeData>
            )}
          </ThemeList>
        </ThemeBox>
        <CoverBox>
          <CoverBoxHeading>Cover</CoverBoxHeading>
          <CheckBoxWrapper>
            <Checkbox
              label={"Include Cover"}
              checked={includeCoverPage}
              handleChange={e =>
                handleStateChange({
                  key: "includeCoverPage",
                  value: e.target.checked,
                  cb: coverListHandler && coverListHandler()
                })
              }
            />
          </CheckBoxWrapper>
          <CoverListWrapper>
            <CoverList>
              {renderAccordion({
                coverList,
                handleStateChange,
                selectedCoverLayout,
                handleCoverPreview,
                contentRepo
              })}
            </CoverList>
          </CoverListWrapper>
        </CoverBox>
      </ThemeWrapper>
    </>
  );
};

const ThemeWrapper = styled.div`
  * {
    box-sizing: border-box;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => hexToRgba(props.theme.COLOR.BLACK, 0.36)};
  z-index: 12;
`;

const CoBrandText = styled.span`
  vertical-align: super;
  margin-left: 5px;
`;

const CobrandLogoStampIcon = styled(CobrandStamp)`
  width: 15px;
  height: 18px;
`;

const CobrandStampWrapper = styled.span`
  position: absolute;
  top: 6px;
  left: 6px;
`;

const OverlayCoverImg = styled.img`
  width: 100%;
  height: 100%;
`;

const PreviewIcon = styled(PreviewBig)`
  width: 19px;
  height: 15px;
  position: absolute;
  top: 3px;
  right: 10px;
  cursor: pointer;
`;

const OverlayImageWrapper = styled.div`
  width: 40%;
  min-width: 600px;
  z-index: 21;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CloseIcon = styled(Close)`
  transform: translate(-50%, -50%);
  margin-left: 50%;
  margin-top: 50%;
  width: 26px;
  height: 26px;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: -14px;
  right: -13px;
  background-color: ${props => props.theme.COLOR.WHITE};
  width: 34px;
  border-radius: 50%;
  height: 34px;
  cursor: pointer;
`;

const CoverListWrapper = styled.div`
  position: relative;
`;

const CoverImageWrapper = styled.div`
  width: 23.125%;
  padding: 1px;
  border: ${props =>
    props.isSelected ? `5px solid ${props.theme.COLOR.USER_PRIMARY}` : ""};
  margin: 0 2.5% 20px 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  &:nth-child(4n) {
    margin-right: 0;
  }
`;

const StyledAccordion = styled.div`
  padding: 31px 30px 34px;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
`;

const CoverList = styled.div``;

const CheckBoxWrapper = styled.div`
  margin: 23px 28px;
  input {
    margin-right: 12px;
  }
`;

const CoverImg = styled.img`
  width: 100%;
  display: block;
`;

const CoverBox = styled.div`
  margin: 30px 0 100px;
  width: 68.65%;
  background-color: ${props => props.theme.COLOR.WHITE};
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  padding: 35px 0 0;
`;

const ThemeBox = styled.div`
  margin-top: 19px;
  width: 68.65%;
  background-color: ${props => props.theme.COLOR.WHITE};
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  padding: 31px 30px 34px;
`;

const ThemeImageWrap = styled.div`
  border: ${props =>
    props.isSelected ? `5px solid ${props.theme.COLOR.USER_PRIMARY}` : ""};
  opacity: 0.74;
  cursor: pointer;
  padding: ${props => (props.isSelected ? `1px` : "")};
  display: inline-block;
  vertical-align: top;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
`;

const ThemeImg = styled.img`
  width: 100%;
  display: block;
`;

const ThemeList = styled.div``;

const ThemeBoxHeading = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  color: ${props => props.theme.COLOR.MAIN};
  font-weight: bold;
  margin-bottom: 17px;
  display: inline-block;
`;

const CoverBoxHeading = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  color: ${props => props.theme.COLOR.MAIN};
  font-weight: bold;
  display: inline-block;
  margin-left: 27px;
`;

const ThemeCover = css`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR_PALLETE.LIPSTICK};
`;
const NoThemeData = styled.div`
  ${ThemeCover}
`;

const NoCoverData = styled.div`
  ${ThemeCover};
  padding: 0 28px 34px;
`;

const ThemeTitle = styled.h4`
  display: inline-block;
  font-size: 14px;
  margin: 5px 0;
  opacity: 0.7;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  max-width: 400px;
  line-height: 1.4;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ThemeContainer = styled.div`
  width: 23.125%;
  margin: 0 2.5% 20px 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  &:nth-child(4n) {
    margin-right: 0;
  }
`;

export default Container(Theme);
