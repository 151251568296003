import React, { Component } from "react";
import ToastUtils from "utils/handleToast";

import { get } from "lodash";
import ValidationUtils from "utils/ValidationUtils";

const container = Main =>
  class Container extends Component {
    state = {
      showUpdate: false,
      timePeriod: null,
      isDataChanged: false // states if the data is changed if not, dont hit api
    };

    inputRef = React.createRef();

    // get previously saved data
    componentDidMount() {
      // show last filled details
      this.resetDataHandler();
    }

    inputTimeHandler = e => {
      // block the below characters in the input field

      let charCode = e.which || e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      }

      this.setState({
        isDataChanged: true
      });
    };

    updatesDataHandler = async () => {
      let { showUpdate, isDataChanged } = this.state;
      let { _id } = this.props.systemConfigurationData;

      let data = ValidationUtils.validateNumber(
        get(this.inputRef, "current.value")
      );

      // dont post data if showUpdate is checked and timeperiod is empty
      if (showUpdate && !data) {
        ToastUtils.handleToast({
          operation: "error",
          message: "Please enter the number of days field."
        });
        return;
      }

      let postData = {
        slideUpdates: {
          showUpdate,
          ...(showUpdate && { timePeriod: get(this.inputRef, "current.value") })
        }
      };

      // only save if data has been changed
      if (isDataChanged) {
        await this.props.saveSystemConfiguration(postData, _id);
        this.setState({
          isDataChanged: false
        });
        this.props.getSystemConfiguration();
      }
    };

    /**
     * function to be called on checkbox change and reset button
     *
     * @param {Object} e boolean stating the state of checkbox
     * @param {boolean} [reset=false] if the data should be resetted
     */
    handleStateChange = e => {
      this.setState({
        showUpdate: e.target.checked,
        isDataChanged: true
      });
    };

    // called on reset button click
    resetDataHandler = () => {
      let { showUpdate = false, timePeriod = null } =
        get(this.props.systemConfigurationData, "slideUpdates") || {};

      if (get(this.inputRef, "current"))
        this.inputRef.current.value = timePeriod;

      this.setState({
        timePeriod,
        showUpdate
      });
    };

    /**
     * Function to handle the input changes
     * @param {Number} The value which user inserts in the field
     */
    handleChange = timePeriod => {
      //check if the first value entered is 0
      if (/^0/.test(timePeriod)) {
        timePeriod = timePeriod.replace(/^0/, "");
      }

      this.setState({
        timePeriod,
        isDataChanged: true
      });
    };

    render() {
      const { state } = this;

      const MainProps = {
        ...this,
        ...state,
        ...this.props
      };

      return <Main {...MainProps} />;
    }
  };

export default container;
