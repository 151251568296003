import React from "react";
import styled from "styled-components";

//components
import Button from "components/button";
import Container from "./container";

import hexToRgba from "utils/hexToRgba";
import { NounGear } from "assets/icons";

const SettingDropdown = props => {
  return (
    <SettingsContainer>
      <IconButton title="Settings">
        <NounGear onClick={props.onGearClick} />
      </IconButton>
      {props.isOverlayOpen && props.data && (
        <>
          <SettingsOverlay onClick={props.onGearClick} />
          <SettingsContentContainer>
            <Title>Settings</Title>
            <SettingsContent>
              {props.data}
              <ButtonContainer>
                <Button
                  text="Save changes"
                  width="100%"
                  float="none"
                  onClick={props.saveChangesHandler}
                />
              </ButtonContainer>
            </SettingsContent>
          </SettingsContentContainer>
        </>
      )}
    </SettingsContainer>
  );
};

const SettingsContainer = styled.div`
  display: inline;
`;

const SettingsOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => hexToRgba(props.theme.COLOR.BLACK, 0.36)};
  z-index: 25;
`;

const Title = styled.h3`
  padding: 16px 6.9%;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.14);
  color: ${props => props.theme.COLOR.MAIN};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 16px;
  font-weight: bold;
`;

const SettingsContentContainer = styled.div`
  width: 203px;
  border-radius: 2px;
  margin-top: 8px;
  position: absolute;
  z-index: 26;
  right: 0;
  background-color: ${props => props.theme.COLOR.WHITE};

  &::before {
    content: "";
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 11px solid ${props => props.theme.COLOR.WHITE};
    position: absolute;
    top: -11px;
    right: 7px;
    z-index: 26;
  }
`;

const IconButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: transparent;
  vertical-align: top;
  cursor: pointer;
  margin-left: 20px;
`;

const SettingsContent = styled.div`
  padding: 16px 7.9%;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  button {
    height: 30px;
  }
`;

export default Container(SettingDropdown);
