import React from "react";
import styled from "styled-components";

import Button from "components/button";

import ChooseFile from "components/chooseFile";

const UI_STRINGS = {
  DOWNLOAD_FILE:
    "Download the Image Template Excel file (You can customize image names, keyword, meta data)",
  UPLOAD_FILE: "Upload Image Template Excel file"
};

const UploadFile = props => {
  let { onExcelDownload, onExcelUpload, activeIngestId } = props;
  return (
    <UploadFileContainer>
      {activeIngestId && (
        <>
          <IntroText>{UI_STRINGS.DOWNLOAD_FILE}</IntroText>
          <Button
            text="Download"
            float="none"
            buttonClass="download"
            width="100%"
            onClick={() => onExcelDownload(false)}
          />
        </>
      )}
      <UploadFileWrapper>
        <IntroText>{UI_STRINGS.UPLOAD_FILE}</IntroText>
        <ChooseFile uploadFileToRepo={onExcelUpload} />
      </UploadFileWrapper>
      {activeIngestId && (
        <SkipCtaContainer>
          <Button
            text="Skip"
            float="none"
            width="100%"
            onClick={() => onExcelDownload(true)}
          />
        </SkipCtaContainer>
      )}
    </UploadFileContainer>
  );
};

export default UploadFile;

const UploadFileContainer = styled.div`
  padding: 0 20px;

  .download {
    margin-top: 10px;
  }
`;

const IntroText = styled.span`
  display: block;
  font-size: 14px;
  color: ${props => props.theme.COLOR.HEADING};
`;

const UploadFileWrapper = styled.div`
  margin-top: 20px;

  .container {
    margin-top: 5px;
  }
`;

const SkipCtaContainer = styled.div`
  width: 100%;
  padding: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  /* background-color: ${props => props.theme.COLOR.WHITE}; */
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.5);
`;
