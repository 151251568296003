import * as types from "./types";

/**
 *
 * Store/Reset/Remove data dynamically based on .
 * @param {Object} data - API response on success/loading
 * @param {String} type - type for sucess/error/loading
 */

export const imageListSuccess = (
  payload = {},
  type = types.IMAGE_LIST_SUCCESS
) => {
  return {
    type,
    payload
  };
};

export const imageListLoading = (
  payload = {},
  type = types.IMAGE_LIST_LOADING
) => {
  return {
    type,
    payload
  };
};

export const imageUploadLoader = (
  payload = {},
  type = types.IMAGE_UPLOAD_LOADING
) => {
  return {
    type,
    payload
  };
};
