import React from "react";
import styled from "styled-components";

import { SlidePreviewLeftArrow, SlidePreviewRightArrow } from "assets/icons";

const ThemeFooter = props => {
  let {
    activeSlideDetails,
    slideData,
    currentActiveIndex,
    prevCtaHandler,
    nextCtaHandler,
    type,
    mainSliderTitle
  } = props;

  return (
    <FooterContainer>
      {activeSlideDetails["slideType"] && (
        <ThemeType>{`Type: ${activeSlideDetails["slideType"]}`}</ThemeType>
      )}

      {activeSlideDetails["slideTypeName"] && (
        <ThemeTypeName>{`Name : ${activeSlideDetails["slideTypeName"]}`}</ThemeTypeName>
      )}
      <SlideCta>
        {slideData.length > 1 && (
          <IconWrapper
            title={`Previous ${mainSliderTitle}`}
            disabled={currentActiveIndex === 0}
            onClick={prevCtaHandler}
          >
            <SlidePrevIcon />
          </IconWrapper>
        )}
        <SlideText>{`${type || ""} ${currentActiveIndex + 1} of ${
          slideData.length
        }`}</SlideText>
        {slideData.length > 1 && (
          <IconWrapper
            title={`Next ${mainSliderTitle}`}
            disabled={currentActiveIndex === slideData.length - 1}
            onClick={nextCtaHandler}
          >
            <SlideNextIcon />
          </IconWrapper>
        )}
      </SlideCta>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  padding: 18px 20px 19px 28px;
  background-color: ${props => props.theme.COLOR.MAIN};

  span {
    color: ${props => props.theme.COLOR.WHITE};
    font-size: 14px;
    line-height: 18px;
    font-family: ${props => `${props.theme.FONT.REG}`};
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }
`;

const ThemeType = styled.span`
  font-weight: bold;
`;

const ThemeTypeName = styled.span`
  margin-left: 36px;
  font-weight: bold;
`;

const SlideCta = styled.div`
  float: right;
  vertical-align: top;
`;

const SlideText = styled.span`
  margin: 0 20px;
  display: inline-block;
  font-weight: normal;
`;

const IconWrapper = styled.button`
  border: none;
  outline: none;
  display: inline-block;
  vertical-align: middle;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  background: none;
`;

const SlidePrevIcon = styled(SlidePreviewLeftArrow)`
  path {
    fill: ${props => props.theme.COLOR.WHITE};
  }
`;

const SlideNextIcon = styled(SlidePreviewRightArrow)`
  path {
    fill: ${props => props.theme.COLOR.WHITE};
  }
`;

export default ThemeFooter;
