import FetchUtils from "utils/FetchUtils.js";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";
// import report actions
import { documentSuccess, documentLoading } from "./action";
import queryParamsObject from "utils/queryString";

/**
 * Fetch all document type with respective page layouts data
 */
const getPagelayouts = () => async dispatch => {
  // start loading
  dispatch(documentLoading({ pagelayoutsLoading: true }));

  const URL = `/marketing-materials/document-types/pagelayouts`,
    response = await FetchUtils.getData(URL, "Fetch all pagelayout ==>");

  // stop loading
  dispatch(documentLoading({ pagelayoutsLoading: false }));

  if (response.success && response.data) {
    //dispatch reports data on success
    dispatch(
      documentSuccess({
        pagelayoutList: response.data
      })
    );
  } else {
    // stop loading
    dispatch(documentLoading({ pagelayoutsLoading: false }));

    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Fetch all users modules
 */
const getUsersModules = ({ term }) => async dispatch => {
  let params = queryParamsObject({
    term
  });

  // start loading
  dispatch(documentLoading({ usersModulesLoading: true }));

  const URL = `/marketing-materials/users/modules${params ? `?${params}` : ""}`,
    response = await FetchUtils.getData(URL, "Fetch all users modules ==>");

  // stop loading
  dispatch(documentLoading({ usersModulesLoading: false }));

  if (response.success && response.data) {
    //dispatch reports data on success
    dispatch(
      documentSuccess({
        usersModules: response.data
      })
    );
  } else {
    // stop loading
    dispatch(documentLoading({ usersModulesLoading: false }));

    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Create document
 *  @param {Object} body - document data
 *  @param {String} documentId - documentId
 */
const createDocument = (body, userId, documentId) => async dispatch => {
  const URL = documentId
    ? `/marketing-materials/${userId}/documents/${documentId}`
    : `/marketing-materials/documents`;

  // start loading
  dispatch(
    documentLoading({
      isDocumentLoading: true
    })
  );

  let response;

  if (documentId) {
    response = await FetchUtils.patchData(URL, body, "Create document");
  } else {
    response = await FetchUtils.postData(URL, body, "Create document");
  }

  if (response.success && response.data) {
    // stop loading
    dispatch(
      documentLoading({
        isDocumentLoading: false
      })
    );

    dispatch(
      documentSuccess({
        documentData: response.data
      })
    );
  } else {
    // stop loading
    dispatch(
      documentLoading({
        isDocumentLoading: false
      })
    );
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

/**
 * Create document
 *  @param {String} userId - userId
 *  @param {String} documentId - documentId
 */
const getDocument = (userId, documentId) => async dispatch => {
  // start loading
  dispatch(documentLoading({ documentDataLoading: true }));

  const HEADER = {
    "Content-Type": `application/json`
  };

  const URL = `/marketing-materials/${userId}/documents/${documentId}`,
    response = await FetchUtils.getDataWithHeader(
      URL,
      "Fetch users document ==>",
      HEADER
    );

  // stop loading
  dispatch(documentLoading({ documentDataLoading: false }));

  if (response.success && response.data) {
    //dispatch reports data on success
    dispatch(
      documentSuccess({
        documentData: response.data
      })
    );
  } else {
    // stop loading
    dispatch(documentLoading({ documentDataLoading: false }));

    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

/**  Get Document detail
 *  @param {String} - userId
 *  @param {String} - documentId
 *  @param {String} - contentType - Set content type for Document
 *  Content Type - Default is JSON to get Documents detail
 *                 `ppt` to trigger Document build in ppt ext.
 *                 `pdf` to trigger Document build in pdf ext
 *                 `zip` to trigger Document build in both ppt and pdf ext.
 *
 */
const triggerDocumentBuild = (
  userId,
  documentId,
  contentType = "ppt"
) => async dispatch => {
  const URL = `/marketing-materials/${userId}/documents/${documentId}`;

  // start loading
  dispatch(documentLoading({ documentBuildLoading: true }));

  //set header
  const HEADER = {
    "Content-Type": `application/${contentType}`
  };

  const response = await FetchUtils.getDataWithHeader(
    URL,
    "Get Document Detail ==>",
    HEADER
  );

  if (response.success && response.data) {
    dispatch(documentSuccess({ documentBuildDetail: response.data }));

    dispatch(documentLoading({ documentBuildLoading: false }));
  } else {
    dispatch(documentLoading({ documentBuildLoading: false }));
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "response.data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Keep polling for build status
 * @param {Object}  This object will have the keys depending upon the Steps selected. This body will be unique for different steps
 */
const getPresentationBuildStatus = buildId => async dispatch => {
  const URL = `/marketing-materials/build-requests/${buildId}`;

  // initiate loader
  dispatch(documentLoading({ isDocumentBuildActive: true }));

  const response = await FetchUtils.getData(URL, "Build Status ==>");
  if (response.success && response.data) {
    // save status in store
    dispatch(documentSuccess({ documentBuildStatus: response.data }));

    //stop loader
    dispatch(documentLoading({ isDocumentBuildActive: false }));
  } else {
    //stop loader
    dispatch(documentLoading({ isDocumentBuildActive: false }));
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "response.data.error.message")
    });
  }
  //return reponse to check in component if required
  return response;
};

export {
  getPagelayouts,
  getUsersModules,
  createDocument,
  getDocument,
  triggerDocumentBuild,
  getPresentationBuildStatus
};
