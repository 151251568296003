import React from "react";
import styled from "styled-components";
import { MdDone, MdClear } from "react-icons/md";

import Container from "./container";

const CategoryTitleComponent = ({
  _id,
  title,
  setResetCategory,
  label,
  enable,
  categoryInput,
  checkIfEdited,
  InputRef,
  inputError,
  saveCategoryDetails,
  setCategoryInput
}) => {
  return categoryInput ? (
    <CategoryWrapper>
      <span>
        <input
          onChange={checkIfEdited}
          ref={InputRef}
          type="text"
          defaultValue={title}
        />
        {inputError && <ErrorMessage>{inputError}</ErrorMessage>}
      </span>
      <button
        onClick={() => {
          saveCategoryDetails({
            label,
            title: (InputRef.current && InputRef.current.value) || title,
            id: _id
          });
        }}
        title="save"
      >
        <SaveIcon />
      </button>
      <button
        title="Close"
        onClick={() => {
          setResetCategory(null);
          setCategoryInput(false);
        }}
      >
        <ClearIcon />
      </button>
    </CategoryWrapper>
  ) : (
    <span
      key={_id}
      style={{
        color: "#636363",
        fontSize: "12px",
        opacity: enable ? 1 : 0.5
      }}
      title={title}
      tabIndex={0}
    >
      {title}
    </span>
  );
};

const CategoryWrapper = styled.div`
  position: absolute;
  width: 60%;
  left: 5px;
  top: 10px;
  input {
    width: 70%;
    padding: 5px 5px 0;
    border: none;
    border-bottom: 2px solid ${props => props.theme.COLOR.USER_PRIMARY};
    outline: none;
    color: #333;
    background: transparent;
    transform: translateY(-3px);
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
  }
`;

const SaveIcon = styled(MdDone)`
  font-size: 16px;
  cursor: pointer;
`;

const ClearIcon = styled(MdClear)`
  font-size: 16px;
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
  font-weight: normal;
  position: absolute;
  left: 0;
  bottom: -8px;
`;

export default Container(CategoryTitleComponent);
