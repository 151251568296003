import React, { Component } from "react";
import styled from "styled-components";
import handleBodyScroll from "utils/handleBodyScroll";
import { get } from "lodash";
import { connect } from "react-redux";
import { downloadFile } from "utils/download";
import {
  DownloadColored,
  ShareColored,
  shareDisable,
  DownloadDisable
} from "assets/icons";

import { getAdminDocumentList } from "./services";

/**
 * Map the state to props.
 */

const mapStateToProps = state => {
  const { LOADING_ADMIN_DOCUMENT_LIST, SUCCESS_ADMIN_DOCUMENT_LIST } = state;
  return {
    ...LOADING_ADMIN_DOCUMENT_LIST,
    ...SUCCESS_ADMIN_DOCUMENT_LIST
  };
};

const Container = Main =>
  connect(mapStateToProps, {
    getAdminDocumentList
  })(
    class Dashboard extends Component {
      static defaultProps = {
        role: "admin"
      };
      state = {
        dashboardData: [],
        tableHeader: [
          {
            col1: "Name",
            col2: "Client Name",
            col3: "User",
            col4: "Created Date",
            col5: "Updated Date",
            col6: "Type",
            col7: "Status",
            col8: "Actions"
          }
        ],
        tableColumnHeader: [
          "projectName",
          "clientName",
          "createdBy.email",
          "createdAt",
          "updatedAt",
          "documentType.title",
          "status"
        ],
        isShowOverlay: false,
        isPopShow: false,
        selectedPresentation: {}
      };

      columnWidth = [170, 115, 115, 115, 120, 90, 90, 90];
      searchFields = ["projectName", "clientName"];

      componentDidMount() {
        this._fetchDocumentList();
      }

      /**
       * Function to get the doucment list
       */
      _fetchDocumentList = async () => {
        await this.props.getAdminDocumentList();
        this.setState({
          dashboardData: this.props.documentAdminDashboardDataList || []
        });
      };

      // function to show last column in table
      showIcon = rowData => {
        return (
          <>
            <IconWrapper
              title={rowData.status === "Completed" ? "Download" : ""}
            >
              {get(rowData, `status`) === "Saved" ? (
                <DownloadIcon />
              ) : (
                <CompletedDownload
                  onClick={() => this.downloadOverlayHandler(rowData)}
                />
              )}
            </IconWrapper>
            <IconWrapper title="Share">
              {get(rowData, `status`) === "Saved" ? (
                <ShareIcon />
              ) : (
                <CompletedShare onClick={() => this.overlayHandler(rowData)} />
              )}
            </IconWrapper>
          </>
        );
      };

      renderHead = () => {
        const HELPER_TEXT = "Welcome to the documents dashboard.";
        return (
          <div className="heading">
            <HeadingName>All Documents</HeadingName>
            <HelperText>{HELPER_TEXT}</HelperText>
          </div>
        );
      };

      /**
       * Function to show the Overlay module
       * @param {Object} Object of the selected row
       */
      overlayHandler = selectedPresentation => {
        handleBodyScroll(
          !this.state.isShowOverlay ? { action: "open" } : { action: "close" }
        );

        this.setState({
          selectedPresentation,
          isShowOverlay: !this.state.isShowOverlay
        });
      };

      /**
       * Function to download document
       * @param {Object} Object of the selected row
       */
      downloadOverlayHandler = selectedPresentation => {
        let { pdfLocation, pptLocation, zipLocation } =
            selectedPresentation || {},
          urlLocations = [pdfLocation, pptLocation, zipLocation],
          moreThanOne = urlLocations.filter((item = {}) => item.url);

        if (moreThanOne.length === 1) {
          downloadFile(moreThanOne[0].url);
          return;
        }

        this.setState({
          isPopShow: true,
          selectedPresentation
        });
      };

      //handle closing of modal on click of close icon button and outside of content area
      closeModalHandler = () => {
        this.setState({
          isPopShow: false
        });
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.props,
          ...$this.state
        };
        return <Main {...stateMethodProps} />;
      }
    }
  );

const HelperText = styled.p`
  ${props => props.theme.SNIPPETS.HELPER_TEXT};
  margin-bottom: 16px;
  min-height: 30px;
`;

const HeadingName = styled.span`
  margin-left: -2px;
  display: inline-block;
  margin-bottom: 10px;
`;

const IconWrapper = styled.span`
  height: auto;
  display: inline-block;
  padding: 0 8px;

  &:first-of-type {
    padding: 0 8px 0 0;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const DownloadIcon = styled(DownloadDisable)`
  cursor: pointer;
  width: 16px;
  height: 17px;
  pointer-events: none;
`;

const CompletedDownload = styled(DownloadColored)`
  cursor: pointer;
  width: 16px;
  height: 17px;
  cursor: pointer;
`;

const ShareIcon = styled(shareDisable)`
  width: 16px;
  height: 17px;
  pointer-events: "none";
`;

const CompletedShare = styled(ShareColored)`
  width: 16px;
  height: 17px;
  cursor: pointer;
`;

export default Container;
