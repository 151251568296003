import React from "react";
import styled from "styled-components";
import hexToRgba from "utils/hexToRgba";
import SearchBox from "components/searchBox";
import ContentAccordian from "./components/contentAccordian";

const Content = props => {
  let {
    handleChooseContentSearchChange,
    openExapand,
    preExapandedFlag,
    chooseContentData
  } = props;

  return (
    <ContentHolder>
      <SearchBoxWrapper className="choose-content-search">
        <SearchBox
          handleChange={e =>
            handleChooseContentSearchChange({ isReset: false, e })
          }
          float="initial"
          placeholder={"Search..."}
        />
        <ResetButton
          onClick={e => handleChooseContentSearchChange({ isReset: true, e })}
        >
          reset
        </ResetButton>
      </SearchBoxWrapper>
      {chooseContentData.length ? (
        <AccordianHolder>
          <ExpandCollapseButtonWrapper>
            <ExpandCollapseButton
              title={preExapandedFlag ? "Collapse All" : "Expand All"}
              onClick={openExapand}
            >
              {preExapandedFlag ? "Collapse All" : "Expand All"}
            </ExpandCollapseButton>
          </ExpandCollapseButtonWrapper>
          <TableTitle>
            <Head>Content</Head>
            <Head>Document Type</Head>
          </TableTitle>
          <ContentAccordian {...props} />
        </AccordianHolder>
      ) : (
        <NoResult>No data found</NoResult>
      )}
    </ContentHolder>
  );
};

const ContentHolder = styled.div`
  padding-top: 10px;
  width: 66%;
  margin-right: 4.5%;
  display: inline-block;
  vertical-align: top;
`;

const SearchBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 79.2%;
  max-width: 482px;

  .search-wrapper {
    margin-bottom: 16px;
    flex-basis: 78.8%;
    svg {
      top: 8px;
    }
    input {
      min-width: auto;
      width: calc(100% - 45px);
      font-size: 14px;
      height: 40px;
    }
  }
`;

const ResetButton = styled.button`
  flex-basis: 16.5%;
  ${props => props.theme.SNIPPETS.SHARED_BUTTON_CSS}
  color: ${props => props.theme.COLOR.WHITE};
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  &:hover { color: ${props =>
    props.theme.COLOR.USER_PRIMARY};  background-color: ${props =>
  props.theme.COLOR.WHITE}; border: 1px solid; }
  cursor: ${props => (!props.disabled ? "pointer" : "not-allowed")};
  height: 40px;
  font-size: 14px;
  text-transform: capitalize;
`;

const AccordianHolder = styled.div`
  border-radius: 4px;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  background-color: ${props => props.theme.COLOR.WHITE};
  margin-top: 16px;
  padding: 20px 30px;
`;

const ExpandCollapseButtonWrapper = styled.div`
  text-align: end;
`;

const ExpandCollapseButton = styled.button`
  width: 92px;
  height: 20px;
  border-radius: 4px;
  font-size: 10px;
  display: inline-block;
  border: 1px solid ${props => hexToRgba(props.theme.COLOR.MAIN, "0.7")};
  color: ${props => hexToRgba(props.theme.COLOR.HEADING, "0.7")};
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  text-align: center;
  background: transparent;
  outline: none;
  font-family: ${props => props.theme.FONT.REG};

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const TableTitle = styled.div`
  margin: 22px 0 8px;
`;

const Head = styled.h3`
  width: 50%;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  letter-spacing: normal;
  color: ${props => props.theme.COLOR.HEADING};

  &:first-of-type {
    width: calc(38.1% - 18px);
    margin-right: 11.8%;
  }
`;

const NoResult = styled.div`
  color: ${props => props.theme.COLOR_PALLETE.DARK_GREY};
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  margin-left: 20%;
`;

export default Content;
