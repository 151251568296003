import FetchUtils from "utils/FetchUtils";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import { getProfileDetail } from "../../app/services/getUserDetail";

import { onUserListSuccess, onUserListLoading } from "./actions";

/**  Get Users list for under writer */
const getUsersList = () => async dispatch => {
  const URL = `/users`;

  // start loading
  dispatch(
    onUserListLoading({
      isLoading: true
    })
  );

  const response = await FetchUtils.getData(URL, "Get Users data==>");

  // stop loading
  dispatch(
    onUserListLoading({
      isLoading: false
    })
  );

  if (!response || !response.success) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  return response;
};

/**
 * Create new user
 */
const createNewUser = body => async dispatch => {
  const URL = `/users`;

  // start loading
  dispatch(
    onUserListLoading({
      isLoading: true
    })
  );

  const response = await FetchUtils.postData(URL, body, "post user data");

  // stop loading
  dispatch(
    onUserListLoading({
      isLoading: false
    })
  );

  if (!response || !response.success) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

/**
 *
 * @param {String} id id of the user to be deleted
 * @returns appropriate response
 */
const deleteUser = id => async dispatch => {
  const URL = `/users/${id}`;
  // start loading
  dispatch(
    onUserListLoading({
      isLoading: true
    })
  );

  const response = await FetchUtils.deleteData(URL, "delete user data");

  // stop loading
  dispatch(
    onUserListLoading({
      isLoading: false
    })
  );

  if (!response || !response.success) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  return response;
};

/**
 *
 * @param {*} { body, id } body payload and id of the user to be edited
 * @returns appropriate response message
 */
const editUser = (body, id) => async dispatch => {
  const URL = `/users/${id}`;

  // start loading
  dispatch(
    onUserListLoading({
      isLoading: true
    })
  );

  const response = await FetchUtils.patchData(URL, body, " edit user details");

  // stop loading
  dispatch(
    onUserListLoading({
      isLoading: false
    })
  );

  return response;
};

/**
 * Get State List
 */
const fetchStateList = () => async dispatch => {
  const URL = `/states`;

  // start loading
  dispatch(
    onUserListLoading({
      isLoading: true
    })
  );

  const response = await FetchUtils.getData(URL, "Get State List ==>");
  // stop loading
  dispatch(
    onUserListLoading({
      isLoading: false
    })
  );

  if (!response.success) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Get Content repositories list
 *
 */
const getContentRepoList = () => async dispatch => {
  const URL = `/content-repositories`;

  // start loading
  dispatch(
    onUserListLoading({
      isLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);
  // stop loading
  dispatch(
    onUserListLoading({
      isLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onUserListSuccess({
        contentRepoDropdown: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

export {
  getUsersList,
  createNewUser,
  deleteUser,
  fetchStateList,
  editUser,
  getContentRepoList,
  getProfileDetail
};
