import React from "react";
import styled from "styled-components";

import PreviewImage from "./components/previewImage";

const ThemePreviewImage = props => {
  let {
    isSlidePreviewToggleOpen,
    activeSlideDetails,
    layoutHeight,
    internalSliderTitle
  } = props;

  return (
    <ThemeImageContainer
      isSlidePreviewToggleOpen={isSlidePreviewToggleOpen}
      layoutHeight={layoutHeight}
    >
      {activeSlideDetails.imageData && (
        <PreviewImage
          imageData={activeSlideDetails.imageData || []}
          isSlidePreviewToggleOpen={isSlidePreviewToggleOpen}
          layoutHeight={layoutHeight}
          internalSliderTitle={internalSliderTitle}
          {...props}
        />
      )}
    </ThemeImageContainer>
  );
};

const ThemeImageContainer = styled.div`
  box-sizing: border-box;
  width: ${props =>
    props.isSlidePreviewToggleOpen ? `68.4%` : `calc(100% - 76px)`};
  height: 100%;
  height: ${props => (props.layoutHeight ? props.layoutHeight : `500px`)};
  border-left: 1px solid ${props => props.theme.COLOR.LIGHT_GREY};
  display: inline-block;
  vertical-align: top;
  position: relative;
  transition: 0.4s width ease-in;
`;

export default ThemePreviewImage;
