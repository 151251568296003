import React from "react";
import styled from "styled-components";
import { MdDone, MdClear } from "react-icons/md";

import { EditWithNoShadow } from "assets/icons";

const ThemePreviewHeaderComponent = props => {
  let {
    activeThemeName,
    isThemeTitleEditable,
    onThemeTitleEdit,
    onThemeTitleChange,
    onThemeTitleSave,
    onThemeTitleCancel,
    titleRef
  } = props;

  return (
    <ThemePreviewHeader>
      <ThemeTitle
        value={activeThemeName.value}
        disabled={!isThemeTitleEditable}
        title={activeThemeName.value}
        onChange={e => onThemeTitleChange(e.target.value)}
        ref={titleRef}
      />
      <ErrorMessage>{activeThemeName.error}</ErrorMessage>
      {!isThemeTitleEditable ? (
        <EditIconWrapper title="Edit" onClick={onThemeTitleEdit}>
          <EditWithNoShadow />
        </EditIconWrapper>
      ) : (
        <SaveClearIconWrapper>
          <SaveIconWrapper title="Save" onClick={onThemeTitleSave}>
            <MdDone size={20} color="#a9a9a9" />
          </SaveIconWrapper>
          <ResetIconWrapper title="Reset">
            <MdClear size={20} color="#a9a9a9" onClick={onThemeTitleCancel} />
          </ResetIconWrapper>
        </SaveClearIconWrapper>
      )}
    </ThemePreviewHeader>
  );
};

export default ThemePreviewHeaderComponent;

const ThemePreviewHeader = styled.div`
  width: 30%;
  padding-left: 26px;
  margin-bottom: 9px;
  position: relative;
`;

const ThemeTitle = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 3px 50px 0 0;
  display: block;
  color: grey;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: bold;
  font-family: ${props => `${props.theme.FONT.REG}`};
  opacity: 0.7;
  color: ${props => props.theme.COLOR.HEADING};
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::-ms-clear {
    display: none;
  }
`;

const EditIconWrapper = styled.a`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const SaveClearIconWrapper = styled.div`
  position: absolute;
  top: 11px;
  right: 0;
  transform: translateY(-50%);
`;

const SaveIconWrapper = styled.a`
  cursor: pointer;
  margin-right: 2px;
`;

const ResetIconWrapper = styled.a`
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
  position: absolute;
`;
