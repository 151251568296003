import React from "react";
import Container from "./container";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import styled from "styled-components";

import { map, includes } from "lodash";

//components
import SortableTreeHeader from "./components/sortableTreeHeader";

const SortableTreeContainer = props => {
  let height = props.windowNode.innerHeight - 60;

  //keep expanded parent when selected to hide/unhide/delete/edit
  let treeData = map(props.treeData, elem => {
    if (
      Array.isArray(props.selectedParent) &&
      includes(props.selectedParent, elem._id)
    ) {
      elem.expanded = true;
    }
    return elem;
  });

  return (
    <CategoryLevelContainer
      ref={props.categoryLevelContainerRef}
      innerHeight={height}
    >
      <SortableTreeHeader {...props} />
      <ContainerSortable className="sort-container">
        <SortableTree
          className={"sortable-wrapper"}
          treeData={treeData}
          onChange={props.handleTreeOnChange}
          maxDepth={2}
          onMoveNode={props.onMoveNode}
          onDragStateChanged={props.onDragStateChanged}
          onVisibilityToggle={props.onVisibilityToggle}
          generateNodeProps={rowInfo => {
            const {
              node: { level, name, enable }
            } = rowInfo;

            return {
              style: { marginRight: level === 1 ? `47px` : 0 },
              buttons:
                (props.generateButtonNodeList &&
                  props.generateButtonNodeList(rowInfo)) ||
                [],
              title: (
                <CategoryTitle title={name} enable={enable}>
                  {name}
                </CategoryTitle>
              )
            };
          }}
        />
      </ContainerSortable>
    </CategoryLevelContainer>
  );
};

const CategoryLevelContainer = styled.div`
  background: ${props => props.theme.COLOR.WHITE};
  width: 100%;
  margin-top: 34px;
  display: inline-block;
  border-radius: 3px;
  font-family: ${props => props.theme.FONT.REG};
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  height: ${props => props.innerHeight}px;
  position: relative;
  overflow: hidden;

  .rst__rowLabel {
    max-width: 488px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1024px) {
      max-width: 260px;
    }
  }

  .rst__rowSearchMatch,
  .rst__rowSearchFocus {
    outline: solid 2px transparent;
  }

  .search-active {
    outline: solid 2px #0080ff;
  }

  .rst__nodeContent {
    width: 92%;
    @media (max-width: 1100px) {
      width: 91%;
    }
    @media (max-width: 1024px) {
      width: 90%;
    }
  }

  .rst__virtualScrollOverride {
    overflow-x: hidden !important;
  }
  .rst__tree {
    padding-top: 10px;
  }

  .rst__node:last-of-type {
    margin-bottom: 40px;
  }

  .rst__rowLabel {
    width: 30%;
    text-overflow: ellipsis;
  }
`;

const ContainerSortable = styled.div`
  width: 100%;
  height: calc(100% - 25px);
  padding-bottom: 30px;
  position: absolute;
  right: -9px;
  top: 60px;
`;

const CategoryTitle = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: #363636;
  opacity: ${props => (props.enable ? "1" : ".5")};
`;

export default Container(SortableTreeContainer);
