import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import AccordionSection from "../AccordionSection";

class Accordion extends Component {
  static propTypes = {
    isFixed: PropTypes.bool,
    allowMultipleOpen: PropTypes.bool,
    children: PropTypes.instanceOf(Object).isRequired
  };

  static defaultProps = {
    allowMultipleOpen: false
  };

  constructor(props) {
    super(props);

    const openSections = {};

    this.props.children.forEach(child => {
      if (child.props.isOpen) {
        openSections[child.props.label] = true;
      }
    });

    this.state = { openSections };
  }

  onClick = (label, isFixedItem) => {
    const {
      props: { allowMultipleOpen },
      state: { openSections }
    } = this;

    const isOpen = !!openSections[label];
    if (allowMultipleOpen) {
      this.setState({
        openSections: {
          ...openSections,
          [label]: !isOpen
        }
      });
    } else {
      if (isOpen && label === "Category") {
        this.setState({
          openSections: {
            MetaData: true,
            Category: false
          }
        });
      } else if (!isOpen && label === "Category") {
        this.setState({
          openSections: {
            MetaData: false,
            Category: true
          }
        });
      } else if (isOpen) {
        this.setState({
          openSections: {
            MetaData: false,
            Category: true
          }
        });
      } else {
        this.setState({
          openSections: {
            MetaData: true,
            Category: false
          }
        });
      }
    }
  };

  render() {
    const {
      onClick,
      props: { children },
      state: { openSections }
    } = this;

    return (
      <Fragment>
        {children.map((child, index) => {
          return (
            <AccordionSection
              key={child.props.label}
              isOpen={!!openSections[child.props.label]}
              label={child.props.label}
              title={child.props.title}
              onClick={onClick}
              isFirst={index === 0}
            >
              {child.props.children}
            </AccordionSection>
          );
        })}
      </Fragment>
    );
  }
}

export default Accordion;
