import React from "react";
import styled from "styled-components";
import { MdDone, MdClear } from "react-icons/md";
import { FaPlus, FaTimes } from "react-icons/fa";
import { get } from "lodash";
import { EditWithNoShadow } from "assets/icons";

import Button from "../Button";

const ThemePreviewHeaderComponent = props => {
  let {
    activeThemeName,
    isEditing,
    onThemeTitleEdit,
    onThemeTitleChange,
    onThemeTitleSave,
    onThemeTitleCancel,
    activeSlideDetails,
    showAddCta,
    addRemoveSlideFromPreview,
    isTitleEditable,
    documentType,
    slideNo,
    selectedPlaceholder,
    selectedLayoutType
  } = props;

  let activeRemoveButton =
    selectedPlaceholder.selectedThumbnailId ===
      get(activeSlideDetails, ["moduleId"]) &&
    get(activeSlideDetails, ["imageData", slideNo, "imageMatrix"]) ===
      selectedLayoutType;

  return (
    <ThemePreviewHeader>
      <ThemeHeading>
        {activeSlideDetails.type && (
          <ThemeLabel>{`${activeSlideDetails.type}name:`}</ThemeLabel>
        )}
        <InputContainer>
          <ThemeTitle
            value={activeThemeName.value}
            disabled={!isEditing}
            title={activeThemeName.value}
            onChange={e => onThemeTitleChange(e.target.value)}
          />
          {isTitleEditable ? (
            !isEditing ? (
              <EditIconWrapper title="Edit" onClick={onThemeTitleEdit}>
                <EditWithNoShadow />
              </EditIconWrapper>
            ) : (
              <SaveClearIconWrapper>
                <SaveIconWrapper title="Save" onClick={onThemeTitleSave}>
                  <MdDone size={20} color="#a9a9a9" />
                </SaveIconWrapper>
                <ResetIconWrapper title="Reset">
                  <MdClear
                    size={20}
                    color="#a9a9a9"
                    onClick={onThemeTitleCancel}
                  />
                </ResetIconWrapper>
              </SaveClearIconWrapper>
            )
          ) : null}
        </InputContainer>
      </ThemeHeading>
      {showAddCta && (
        <ButtonContainer>
          <AddPresentationCtaWrapper
            className={activeRemoveButton ? "remove-slide-icon" : ""}
          >
            <Button
              title="Add to Document"
              onClick={addRemoveSlideFromPreview}
              disabledAddCta={get(props.activeSlideDetails, [
                "imageData",
                slideNo,
                "disableAddButton"
              ])}
            >
              <AddPresentationCtaIcon>
                {activeRemoveButton ? (
                  <FaTimes size={10} />
                ) : (
                  <FaPlus size={10} />
                )}
              </AddPresentationCtaIcon>
              <AddPresentationCtaText>
                {activeRemoveButton
                  ? `Remove from ${documentType || ""}`
                  : `Add to ${documentType || ""}`}
              </AddPresentationCtaText>
            </Button>
            {get(props.activeSlideDetails, [
              "imageData",
              slideNo,
              "disableAddButton"
            ]) && (
              <HelperText>
                A region in your document must be selected prior to adding a
                module
              </HelperText>
            )}
          </AddPresentationCtaWrapper>
        </ButtonContainer>
      )}
    </ThemePreviewHeader>
  );
};

export default ThemePreviewHeaderComponent;

const ThemePreviewHeader = styled.div`
  padding: 0 26px;
  margin-bottom: 14px;
  display: flex;
  flex-direction: row;
`;

const ThemeHeading = styled.div`
  flex-basis: 30%;
`;

const InputContainer = styled.div`
  position: relative;
`;

const ThemeLabel = styled.span`
  display: block;
  font-family: ${props => `${props.theme.FONT.REG}`};
  font-size: 12px;
  font-weight: bold;
  opacity: 0.7;
  color: ${props => props.theme.COLOR.HEADING};
`;

const ThemeTitle = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 3px 50px 0 0;
  display: block;
  color: grey;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: normal;
  font-family: ${props => `${props.theme.FONT.REG}`};
  opacity: 0.7;
  color: ${props => props.theme.COLOR.HEADING};
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::-ms-clear {
    display: none;
  }
`;

const EditIconWrapper = styled.a`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const SaveClearIconWrapper = styled.div`
  position: absolute;
  top: 11px;
  right: 0;
  transform: translateY(-50%);
`;

const SaveIconWrapper = styled.a`
  cursor: pointer;
  margin-right: 2px;
`;

const ResetIconWrapper = styled.a`
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  flex-basis: 70%;
  text-align: right;

  .remove-slide-icon {
    button {
      border: 1px solid ${props => props.theme.COLOR.ERROR};
      background: ${props => props.theme.COLOR.ERROR};
      &:hover:enabled {
        color: ${props => props.theme.COLOR.ERROR};
        background: transparent;
      }
    }
  }
`;

const AddPresentationCtaWrapper = styled.div`
  display: inline-block;
`;

const AddPresentationCtaIcon = styled.span`
  margin: 0 6px 0 0;
  display: inline-block;
  vertical-align: top;
`;

const AddPresentationCtaText = styled.span`
  margin-top: 1px;
  display: inline-block;
  vertical-align: top;
  line-height: 1;
`;

const HelperText = styled.span`
  margin-top: 4px;
  display: block;
  font-size: 12px;
  color: ${props => props.theme.COLOR_PALLETE.ERROR};
`;
