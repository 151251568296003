import React from "react";
import Container from "./container";
import styled from "styled-components";
import hexToRgba from "utils/hexToRgba";
import { map, get } from "lodash";

const Tags = props => {
  let {
    emailItems,
    email,
    emailInputHandler,
    handleInputKeyDown,
    handleRemoveItem,
    emailInput
  } = props;

  return (
    <InputWrapper>
      <EmailList
        onClick={() => emailInput.current.focus()}
        emailItems={emailItems.length}
      >
        {map(emailItems, (email, index) => {
          return (
            <Email key={index}>
              <EmailText>{email}</EmailText>
              <Cross onClick={() => handleRemoveItem(index)}>x</Cross>
            </Email>
          );
        })}
        <Input
          type="text"
          name="email"
          defaultValue={email.value}
          onKeyDown={handleInputKeyDown}
          ref={emailInput}
          onChange={e => emailInputHandler(e)}
        />
      </EmailList>
      {get(emailInput, `current.value`) && email.error ? (
        <Error>{email.error}</Error>
      ) : null}
    </InputWrapper>
  );
};

Tags.defaultProps = {
  emailItems: [],
  handleEmailItemsList: () => {},
  validateFor: "email"
};

export default Container(Tags);

const Email = styled.li`
  display: inline-block;
  background-color: ${props => props.theme.COLOR_PALLETE.LIGHT_GREY};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 10px;
  color: ${props => props.theme.COLOR.WHITE};
  padding: 3px;
  margin: 2px;
  border-radius: 3px;
  max-width: 99%;
`;

const EmailText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 94%;
  vertical-align: bottom;
`;

const Cross = styled.span`
  font-size: 9px;
  transform: scale(1.7) translateY(-0.5px);
  margin-left: 10px;
  display: inline-block;
  padding-bottom: 2px;
  cursor: pointer;
  color: ${props => props.theme.COLOR_PALLETE.WHITE};
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const EmailList = styled.ul`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.COLOR_PALLETE.LIGHT_GREY};
  padding: ${props => (props.emailItems !== 0 ? "4.5px 3px" : "7.5px 3px")};
  cursor: text;
`;

const Input = styled.input`
  height: 100%;
  max-width: 100%;
  border: none;
  outline: none;
  display: inline-block;
  min-width: 5px;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-family: ${props => props.theme.FONT.LATO};
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.8)};
  font-size: 12px;
`;

const Error = styled.span`
  position: absolute;
  bottom: -10px;
  left: 0;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.ERROR, "1")};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 10px;
  font-weight: normal;
  pointer-events: none;
`;
