import React from "react";
import styled from "styled-components";
import StepDetails from "components/buildProcess/stepDetails";
import RadioButton from "components/radioBtn";
import ModuleSelector from "./components/moduleSelector";
import PreviewModal from "components/previewModal";
import LayoutSelector from "./components/layoutSelector";
import PreviewOverlay from "components/previewOverlay";
/**
 * Main library component
 * @param {*} props
 */

const _rendersModules = props => {
  return <ModuleSelector {...props} />;
};

const _renderLayouts = props => {
  let layouts = (props.layoutData || []).map((eachlayout, index) => {
    return (
      <LayoutSelector
        key={index}
        index={index}
        eachlayout={eachlayout}
        {...props}
      />
    );
  });
  return layouts;
};

const Layout = props => {
  return (
    <LayoutContainer>
      <StepDetails
        _next={true}
        _save={!(props.documentId && !props.isDocumentEdited)}
        onNext={() => props.handleMainButtonClick(true, 2)}
        onSave={() => props.handleCreateDocument(false)}
        {...props}
        title={`${props.selectedOption && props.selectedOption.value} layout`}
        description="Choose the region to populate in the middle area below then click a module from the left to fill the space."
      />
      <ContentContainer>
        <RadioWrapper>
          <RadioButtonWrapper>
            <RadioButton
              name="options"
              label="Show all modules"
              id="allModules"
              defaultChecked={props.activeModuleFilter === "allModules"}
              handleChange={() => props.handleModuleRadioChange("allModules")}
            />
          </RadioButtonWrapper>

          <RadioButtonWrapper>
            <RadioButton
              name="options"
              label="Show only modules for this layout"
              id="layoutSpecificModule"
              handleChange={() =>
                props.handleModuleRadioChange("layoutSpecificModule")
              }
              defaultChecked={
                props.activeModuleFilter === "layoutSpecificModule"
              }
            />
          </RadioButtonWrapper>
        </RadioWrapper>
        <ContentWrapper>
          {_rendersModules(props)}

          {props.modulePreviewData.length ? (
            <PreviewModal
              closeModal={props.onOpenCloseModulePreview}
              width="86%"
            >
              <PreviewOverlay
                showAddCta={true}
                layoutHeight={`450px`}
                slideData={props.modulePreviewData && props.modulePreviewData}
                documentType="Document"
                isTitleEditable={false}
                type="Module"
                internalSliderTitle="Layout"
                mainSliderTitle="Module"
                onAddRemovePresentation={props.onAddRemovePresentation}
                selectedLayoutType={props.selectedLayoutType}
                activeIndex={props.activePreviewModuleIndex}
                selectedPlaceholderData={props.selectedPlaceholder}
              />
            </PreviewModal>
          ) : null}

          <LayoutSelectorContainer>
            {_renderLayouts(props)}
          </LayoutSelectorContainer>
        </ContentWrapper>
      </ContentContainer>
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  margin: 0 auto;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  margin-bottom: 50px;
`;

const ContentContainer = styled.div`
  margin-top: 8px;
`;

const RadioButtonWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  &:not(:first-of-type) {
    margin-left: 50px;
  }

  label {
    opacity: 0.8;
    font-weight: bold;
    padding-left: 0;
    color: ${props => props.theme.COLOR.HEADING};
    text-transform: capitalize;
  }
  .checkmark {
    top: -2px;
    height: 16px;
    width: 16px;
    margin-left: -30px;
    border: 2px solid ${props => props.theme.COLOR.MAIN};
    &:after {
      content: "";
      position: absolute;
      display: none;
      background-color: ${props => props.theme.COLOR.MAIN};
      width: 8px;
      height: 8px;
    }
  }
`;

const RadioWrapper = styled.div`
  width: 100%;
  text-align: right;
`;

const ContentWrapper = styled.div`
  margin: 20px 0;

  &::after {
    content: "";
    display: table;
    clear: both;
  }
`;

const LayoutSelectorContainer = styled.div`
  height: auto;
  min-height: 530px;
  padding: 40px 0 44px;
  background-color: ${props => props.theme.COLOR.WHITE};
  border-radius: 3px;
  width: 68.42%;
  text-align: center;
  float: right;

  & > div:last-of-type {
    & > div:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export default Layout;
