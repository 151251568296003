import React from "react";
import styled from "styled-components";
import BgWrapper from "components/bgWrapper";

const summaryMakerAdmin = props => {
  return (
    <>
      <HeadingName>Summary Maker - Admin</HeadingName>
    </>
  );
};

const HeadingName = styled.span`
  margin-left: -2px;
  display: inline-block;
  margin-bottom: 10px;
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 30px;
  font-weight: bold;
  margin-left: 8%;
  margin-top: 2%;
  color: ${props => props.theme.COLOR.MAIN};
`;

export default BgWrapper(summaryMakerAdmin);
