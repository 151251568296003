//import network services
import {
  fetchImageFolderCategoryList,
  imageFolderCRUD,
  imageFolderCategoryReorder,
  imageCategoryCRUD,
  imageUpload,
  fetchImageList,
  fetchSpecificImageData,
  updateImageDataAttributes,
  deleteImageFromList,
  downloadExcel,
  uploadExcel,
  checkIncompleteUploadStatus
} from "./services";

import { getClientConfig } from "services/clientConfig";

const mapStateToProps = state => {
  const {
    SUCCESS_USER_PROFILE,
    IMAGE_LIST_SUCCESS,
    IMAGE_LIST_LOADING,
    CLIENT_CONFIG_SUCCESS,
    CLIENT_CONFIG_LOADING,
    IMAGE_UPLOAD_LOADING
  } = state;

  return {
    ...SUCCESS_USER_PROFILE,
    ...IMAGE_LIST_SUCCESS,
    ...IMAGE_LIST_LOADING,
    ...CLIENT_CONFIG_SUCCESS,
    ...CLIENT_CONFIG_LOADING,
    ...IMAGE_UPLOAD_LOADING
  };
};

const actions = {
  fetchImageFolderCategoryList,
  imageFolderCRUD,
  imageFolderCategoryReorder,
  imageCategoryCRUD,
  getClientConfig,
  imageUpload,
  fetchImageList,
  fetchSpecificImageData,
  updateImageDataAttributes,
  deleteImageFromList,
  downloadExcel,
  uploadExcel,
  checkIncompleteUploadStatus
};

export { mapStateToProps, actions };
