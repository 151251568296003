import React from "react";
import styled from "styled-components";

import hexToRgba from "utils/hexToRgba";
import ShadowScrollbars from "components/custom-scrollbars/ShadowScrollbars";

import Button from "components/button";
import ImageStatusInfo from "./components/imageStatusInfo";

const ImageUpload = props => {
  let { uploadFileDetails, isPollingActive, onContinueHandler } = props;

  return (
    <>
      <ImageContainer>
        <ShadowScrollbars style={{ height: `calc(100vh - 230px)` }}>
          <Title>Upload Images</Title>
          {Array.isArray(uploadFileDetails.uploadedFileStatus) &&
          uploadFileDetails.uploadedFileStatus.length ? (
            <ImageStatusContainer>
              {uploadFileDetails.uploadedFileStatus.map((item, index) => (
                <ImageStatusInfo
                  key={index}
                  item={item}
                  isPollingActive={isPollingActive}
                />
              ))}
            </ImageStatusContainer>
          ) : null}
        </ShadowScrollbars>
      </ImageContainer>
      {!isPollingActive && (
        <ConfirmationCta>
          <Button
            text="Continue"
            width="100%"
            float="none"
            onClick={onContinueHandler}
          />
        </ConfirmationCta>
      )}
    </>
  );
};

export default ImageUpload;

const ImageContainer = styled.div`
  padding: 0 10px 0 20px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: ${props => hexToRgba(props.theme.COLOR.HEADING, 0.7)};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 20px;
  font-weight: bold;
`;

const ImageStatusContainer = styled.div`
  padding: 35px 18px 19px;
  border: 2px dashed ${props => props.theme.COLOR.DEFAULT_USER_PRIMARY};
  border-radius: 4px;
  margin-right: 15px;
`;

const ConfirmationCta = styled.div`
  width: 100%;
  padding: 20px;
  position: absolute;
  bottom: 0;
  background-color: ${props => props.theme.COLOR.WHITE};
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.5);
`;
