import React from "react";
import styled, { css } from "styled-components";
import Checkbox from "components/checkbox";

const Slides = ({
  title,
  _id,
  handleCheckBoxChange,
  parentObj,
  selectSlides,
  group,
  firstGroupElem,
  lastGroupElem,
  adjacentGroupElem,
  addPresentation,
  slideDetail,
  isUpdated,
  isNew,
  isRequired
}) => {
  let className = `${group ? "group" : ""}  slides ${
    firstGroupElem ? "first" : ""
  } ${lastGroupElem ? "last" : ""} ${
    adjacentGroupElem ? "adjacent-group" : ""
  }`;

  return (
    <CheckboxWrapper className={className} isRequired={isRequired}>
      <Checkbox
        label={title}
        id={_id}
        checked={selectSlides.indexOf(_id) > -1 ? true : false}
        handleChange={e => {
          handleCheckBoxChange(
            e.target.checked,
            _id,
            (group && group.title) || "",
            parentObj,
            slideDetail
          );
        }}
        useButton={true}
        addPresentation={() => addPresentation(_id, parentObj, slideDetail)}
      />
      {isNew && <UpdateNewLabel>New</UpdateNewLabel>}
      {isUpdated && <UpdateNewLabel>Updated</UpdateNewLabel>}
    </CheckboxWrapper>
  );
};

const BorderCSS = css`
  content: "";
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  background-color: ${props => props.theme.COLOR_PALLETE.COOL_BLUE};
  opacity: 0.3;
`;

const UpdateNewLabel = styled.span`
  box-sizing: border-box;
  padding: 2px 5px 3px;
  border-radius: 2px;
  display: inline-block;
  background-color: ${props => props.theme.COLOR_PALLETE.APPLE_GREEN};
  color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 10px;
  font-weight: bold;
`;

const CheckboxWrapper = styled.div`
  vertical-align: top;
  .checkbox-container {
    width: 78%;
    padding: 5px 0 5px 12px;
    display: inline-block;
  }

  &.group {
    background: #eef7f9;
  }

  &.first {
    padding-top: 4px;
    position: relative;

    &:not(:first-child) {
      margin-top: 2px;
    }

    &:after {
      ${props => props.theme.SNIPPETS.BOX_SHADOW_DARK};
      ${BorderCSS};
      top: 0;
    }
  }

  &.group:not(:first-of-type).adjacent-group {
    margin-top: 10px;
  }

  &.last {
    padding-bottom: 7px;
    position: relative;
    &:before {
      ${BorderCSS};
      bottom: 0;
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.38);
    }
  }

  button {
    color: ${props => props.theme.COLOR.DEFAULT_USER_PRIMARY};
  }

  input[type="checkbox"] {
    ${props => props.isRequired && `cursor :not-allowed`}
    &:checked {
      ${props =>
        props.isRequired &&
        `background-color: ${props.theme.COLOR_PALLETE.BROWNISH_GREY}; border: solid 2px ${props.theme.COLOR_PALLETE.BROWNISH_GREY};`}
    }
  }
`;

export default Slides;
