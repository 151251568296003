import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import hexToRgba from "utils/hexToRgba";
import StyledDropzone from "components/chooseImage";
import { FaQuestionCircle } from "react-icons/fa";
import ReactHintFactory from "react-hint";
import "react-hint/css/index.css";
import featureFlags from "utils/featureFlags";

const ReactHint = ReactHintFactory(React);

/**
 * render hint on hover
 */
const onRenderContent = (target, content) => {
  return (
    <div className="custom-hint__content">
      <span>{content}</span>
    </div>
  );
};

const SetupForm = props => {
  let {
    buildSetupDetails,
    onChangeInput,
    editImage,
    clientLogoImageData,
    onFileUpload
  } = props;

  return (
    <DetailsContainer>
      <ReactHint events onRenderContent={onRenderContent} />
      {buildSetupDetails &&
        buildSetupDetails.map(
          (
            { label, value, error, type, editable, key, hint, required },
            index
          ) => {
            // label group item for setup fields
            const labelGroupItem = (
              <Fragment key={index}>
                {type === "text" && (
                  <LabelGroup key={index}>
                    {label && (
                      <Label>
                        {label}
                        {required && "*"}
                      </Label>
                    )}

                    {hint && (
                      <IconWrapper>
                        <StyledInfoIcon
                          size={12}
                          data-rh={
                            "Document titles may be displayed on the cover depending on your cover selection."
                          }
                          data-rh-at="right"
                        />
                      </IconWrapper>
                    )}

                    {type === "text" && editable && (
                      <LabelInput
                        type={type}
                        value={value}
                        onChange={e =>
                          onChangeInput({
                            value: e.target.value,
                            label,
                            key
                          })
                        }
                      />
                    )}
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </LabelGroup>
                )}
              </Fragment>
            );
            return label === "Document Title" &&
              !featureFlags.marketingMaterials.showDocumentTitle
              ? null
              : labelGroupItem;
          }
        )}
      {featureFlags.marketingMaterials.allowClientLogoUpload && (
        <DropZoneContainer>
          <StyledDropzone
            title="Client Logo"
            handleEdit={(img, data) => editImage(img, data)}
            imageDetails={clientLogoImageData.editImageDimensions}
            onFileUpload={file => onFileUpload(file)}
            path={clientLogoImageData.dataUri}
          />
        </DropZoneContainer>
      )}
    </DetailsContainer>
  );
};

const DetailsContainer = styled.div`
  padding: 24px 12%;
  background-color: ${props => props.theme.COLOR.WHITE};
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);

  .custom-hint__content {
    width: 200px;
    padding: 10px;
    background-color: ${props => props.theme.COLOR.BLACK};
    border-radius: 4px;

    span {
      color: ${props => props.theme.COLOR.WHITE};
    }
  }
`;

const sharedInputStyle = css`
  ${props => props.theme.SNIPPETS.SHARED_INPUT_STYLE}
  height: 30px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  font-family: ${props => props.theme.FONT.LATO};
  background: ${props => props.theme.COLOR.INPUT};
  text-transform: none;
`;

const LabelGroup = styled.div`
  margin-bottom: 10px;
`;

const LabelCSS = css`
  margin-bottom: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.64)};
`;

const Label = styled.span`
  ${LabelCSS}
`;

const LabelInput = styled.input`
  ${sharedInputStyle};

  &:focus {
    border: 2px solid ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  font-family: ${props => props.theme.FONT.REG};
  color: ${props => props.theme.COLOR_PALLETE.ERROR};
  text-align: left;
`;

const IconWrapper = styled.span`
  width: 19px;
  height: auto;
  display: inline-block;
  padding: 5px;
  margin: -5px;
  cursor: pointer;
`;

const StyledInfoIcon = styled(FaQuestionCircle)`
  margin-top: 2px;
  size: 10px;
  transform: translate(5px, 1px);
  cursor: pointer;
  color: ${props => props.theme.COLOR.MAIN};
`;

const DropZoneContainer = styled.div`
  .img-container {
    height: auto;
    img {
      max-height: 145px;
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;

    & > div {
      flex-basis: 50%;
      margin-right: 0;
      button {
        width: 100%;
        min-width: 58px;
      }
    }

    & > button {
      flex-basis: 50%;
      min-width: 58px;
      margin-left: 6%;
    }
  }

  span {
    ${LabelCSS}
  }
`;

export default SetupForm;
