import React from "react";
import styled from "styled-components";
import hexToRgba from "utils/hexToRgba";

/**
 * @param {Array} children - It will consist of the elemennts to be displayed inside the overlay
 * @param {Function} closeSidebarOverlay - function to handle the status of the overlay
 * @param {String} width - Width of the overlay
 * @param {String} maxWidth - Max-width of the overlay
 */
const UploadOverlay = ({ children, closeSidebarOverlay, width, maxWidth }) => (
  <OverlayContainer
    onClick={e => closeSidebarOverlay(e)}
    className="overlay-contianer"
  >
    <SideBar
      width={width}
      maxWidth={maxWidth}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </SideBar>
  </OverlayContainer>
);

UploadOverlay.defaultProps = {
  closeSidebarOverlay: () => {},
  width: "30%",
  maxWidth: "400px"
};

const OverlayContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: ${props => hexToRgba(props.theme.COLOR.BLACK, 0.36)};
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const SideBar = styled.div`
  max-width: ${props => props.maxWidth};
  width: 326px;
  height: 100%;
  padding: 133px 0 0;
  background-color: ${props => props.theme.COLOR.WHITE};
  position: absolute;
  z-index: 5;
  right: 0;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
`;

export default UploadOverlay;
