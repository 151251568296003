import React from "react";
import styled from "styled-components";
import hexToRgba from "utils/hexToRgba";
import { PlusIcon, Download, SquareCross } from "assets/icons";
import SearchBox from "components/searchBox";
import { get, map, each, find } from "lodash";

/**
 * Helper function to fetch view tabs data
 * @param {View tabs data} data
 */
export const ViewBySearch = ({
  data,
  addPresentation,
  contentRepo,
  getThumbnailBasedOnSlideType,
  selectSlides,
  librarySearchString,
  isTopicSearchLoading,
  selectedThemeLayout,
  addRemoveSlideFromPreview,
  handleLibrarySearchChange,
  downloadDeckUrlHandler
}) => {
  let searchResult = null;
  let groupIdList = [];

  const librarySearchStringActive =
    librarySearchString && !isTopicSearchLoading;
  if (Array.isArray(data) && data.length) {
    searchResult = map(data, (eachItem, index) => {
      let { group, groupSlides } = eachItem;
      let { _id: groupId } = group || {};

      let groupSlidesArray = [];
      if ((groupIdList.indexOf(groupId) < 0 && groupId) || !groupId) {
        groupIdList.push(groupId);
        // push associated group slides of current slide to a array
        groupSlidesArray = [eachItem];
        Array.isArray(groupSlides) &&
          groupSlides.length &&
          each(groupSlides, singleSlide => groupSlidesArray.push(singleSlide));

        groupSlidesArray.sort((a, b) => a.order - b.order);
      }

      return (
        groupSlidesArray.length > 0 && (
          <SinglePresentation key={index}>
            {map(groupSlidesArray, item => {
              let { _id, title, path, isRequired, group: groupItem } = item;

              let { title: groupTitle } = groupItem || {};

              // File url based on theme
              let downloadUrl =
                get(item, "fileLocation") ||
                (getThumbnailBasedOnSlideType &&
                  getThumbnailBasedOnSlideType(
                    selectedThemeLayout,
                    item,
                    "file"
                  ));

              return (
                <PresentationData>
                  <PresentationDetails>
                    <PresentationName
                      onClick={e => addPresentation(e, _id, item)}
                      highLightThis={group ? find(data, { _id }) : false}
                    >
                      {title}
                    </PresentationName>
                    <PresentationSet>
                      {groupTitle && <GroupTitle>{groupTitle}</GroupTitle>}
                      {path && <PresentationPath>{path}</PresentationPath>}
                    </PresentationSet>
                  </PresentationDetails>
                  <Icons>
                    {selectSlides.indexOf(_id) > -1 ? (
                      <IconContainer
                        className="remove-icon"
                        title={isRequired ? "" : "Remove from presentation"}
                        isRequired={isRequired}
                      >
                        <SquareCross
                          onClick={() =>
                            addRemoveSlideFromPreview(false, eachItem)
                          }
                        />
                      </IconContainer>
                    ) : (
                      <IconContainer title="Add to presentation">
                        <PlusIcon
                          onClick={() =>
                            addRemoveSlideFromPreview(true, eachItem)
                          }
                        />
                      </IconContainer>
                    )}
                    <IconContainer>
                      <Button
                        onClick={() =>
                          downloadDeckUrlHandler(
                            downloadUrl,
                            _id,
                            "contentSlide",
                            selectedThemeLayout
                          )
                        }
                      >
                        <Download />
                      </Button>
                    </IconContainer>
                  </Icons>
                </PresentationData>
              );
            })}
          </SinglePresentation>
        )
      );
    });
  } else {
    searchResult = (
      <NoSearchData
        repoExist={
          librarySearchStringActive
            ? false
            : !!(contentRepo && contentRepo.title)
        }
      >
        {contentRepo && contentRepo.title
          ? librarySearchStringActive
            ? `No content slides available under selected theme.`
            : ``
          : "Please select a content repo."}
      </NoSearchData>
    );
  }

  return (
    <>
      <SearchBox
        handleChange={handleLibrarySearchChange}
        float="initial"
        placeholder={"Please enter a slide name..."}
        defaultValue={librarySearchString || ""}
      />
      {searchResult}
    </>
  );
};

const SinglePresentation = styled.div`
  margin: 0 3%;
  border-bottom: 1px solid
    ${props => hexToRgba(props.theme.COLOR_PALLETE.LIGHT_GREY, 0.5)};
`;

const PresentationData = styled.div`
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const PresentationDetails = styled.div`
  width: 80%;
`;

const PresentationName = styled.span`
  margin: 10px 0 5px;
  display: inline-block;
  color: ${props => props.theme.COLOR.DEFAULT_USER_PRIMARY};
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  opacity: 0.7;
  cursor: pointer;
  ${props =>
    props.highLightThis &&
    `background-color:${props.theme.COLOR_PALLETE.GOLDEN}`}
`;

const PresentationSet = styled.div`
  font-size: 10px;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
`;

const GroupTitle = styled.span`
  padding: 2px 10px;
  border-radius: 2px;
  margin-right: 8px;
  background-color: ${props => props.theme.COLOR_PALLETE.APPLE_GREEN};
  color: ${props => props.theme.COLOR.WHITE};
  font-weight: bold;
`;

const PresentationPath = styled.span``;

const Icons = styled.div`
  flex-basis: 20%;
  display: inline-flex;
  justify-content: flex-end;
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translateY(-50%);
  .remove-icon {
    svg {
      width: 24px;
      height: 20px;
    }
  }
  span {
    width: 40px;
    height: 20px;
    margin-right: 19%;
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const IconContainer = styled.span`
  svg {
    width: 20px !important;
    border-radius: 2px;
    ${props => props.isRequired && `cursor :not-allowed`}
    path {
      ${props => props.isRequired && `fill :${props.theme.COLOR.WHITE} `}
    }

    ${props =>
      props.isRequired &&
      `background-color :${props.theme.COLOR_PALLETE.BROWNISH_GREY} `}

    rect {
      ${props =>
        props.isRequired &&
        `stroke :${props.theme.COLOR_PALLETE.BROWNISH_GREY} `}
    }
  }

  &:last-of-type {
    svg {
      height: 15px;
    }
  }
`;

const NoSearchData = styled.p`
  padding: 10px 10px 22px 29px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props =>
    !props.repoExist ? props.theme.COLOR_PALLETE.LIPSTICK : "inherit"};
`;

const Button = styled.button`
  border: none;
  margin-right: 6px;
  background-color: transparent;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  cursor: pointer;
  outline: none;
`;
