import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getReportingData, getAdvanceReportingData } from "./services/reports";
import { downloadFile } from "utils/download";
import { reportingStats, toolOptionsList } from "./constant";

/**
 * Map the state to props.
 */
const mapStateToProps = state => {
  const {
    REPORTING_SUCCESS,
    REPORTING_LOADING,
    SUCCESS_USER_PROFILE,
    CLIENT_CONFIG_SUCCESS
  } = state;
  return {
    ...REPORTING_SUCCESS,
    ...REPORTING_LOADING,
    ...SUCCESS_USER_PROFILE,
    ...CLIENT_CONFIG_SUCCESS
  };
};

const Container = Main =>
  connect(mapStateToProps, {
    getReportingData,
    getAdvanceReportingData
  })(
    class Reports extends Component {
      constructor(props) {
        super(props);

        this.state = {
          startDate: moment()
            .subtract(30, "days")
            .toDate(),
          endDate: moment().toDate(),
          generateParam: {},
          reportingStats,
          selectedTool: "rg",
          toolOptions: toolOptionsList
        };
      }

      componentDidMount() {
        this.props.userProfileMeta && this.setToolOptions();
      }

      //Check current Active user roles and set the tool options
      setToolOptions = () => {
        let { reportingStats } = this.state;

        //get tool
        let selectedTool = null;
        if (this.props.location.search) {
          selectedTool = this.props.location.search.slice(0).split("=")[1];
        }

        let renewalRoles = ["RG-Sales", "RG-Admin", "RG-Underwriter"];
        let pgRoles = ["PG-Users", "PG-System-Admin"];
        let mgRoles = ["MG-Users", "MG-Admin"];

        let { userProfileMeta: profile } = this.props;
        let toolOptions = [];
        let renewalToolAccess = false,
          pgToolAccess = false,
          mgToolAccess = false;

        if (profile && Object.keys(profile).length) {
          let { roles: userRoles } = profile;
          for (let index = userRoles.length; index--; ) {
            // set renewal tool permission

            if (
              renewalRoles.indexOf(userRoles[index]) > -1 &&
              !renewalToolAccess
            ) {
              if (!selectedTool) selectedTool = "rg";
              toolOptions.push(toolOptionsList[0]);
              renewalToolAccess = true;
            }

            // set pg tool permission
            if (pgRoles.indexOf(userRoles[index]) > -1 && !pgToolAccess) {
              if (!selectedTool) selectedTool = "pg";

              let pgToolOptions = toolOptionsList[1];
              pgToolOptions.default = !renewalToolAccess;
              toolOptions.push(pgToolOptions);
              pgToolAccess = true;
            }

            // set mg tool permission
            if (mgRoles.indexOf(userRoles[index]) > -1 && !mgToolAccess) {
              if (!selectedTool) selectedTool = "mg";

              let mgToolOptions = toolOptionsList[2];
              mgToolOptions.default = !renewalToolAccess && !pgToolAccess;
              toolOptions.push(mgToolOptions);
              mgToolAccess = true;
            }
          }
        }

        //set advance stats default params based on default tool
        let advanceStatsDefaultParam =
          reportingStats[selectedTool].advanceStats.filter(
            (elem = {}) => elem.default
          ) || [];

        // Show default radion button selected in advanceed stats
        reportingStats[selectedTool]["selectedOption"] =
          advanceStatsDefaultParam[0]["id"] || "";

        this.setState(
          {
            toolOptions,
            selectedTool,
            generateParam: (advanceStatsDefaultParam[0] || {}).value || {},
            reportingStats
          },
          () => {
            this.fetchReportingData();
          }
        );
      };

      componentDidUpdate(prevProps) {
        if (this.props.userProfileMeta !== prevProps.userProfileMeta) {
          this.setToolOptions();
        }
      }

      //set param for advance data
      setAdvanceDataParam = ({ key, value, generateParam }) => {
        
        let { reportingStats, selectedTool } = this.state;
        reportingStats[selectedTool][key] = value;

        this.setState(prevState => {
          return {
            reportingStats,
            generateParam
          };
        });
      };

      //handle tools selection dropdown
      handleChange = e => {
        let generateParam = { allRenewals: true };
        if (e.value === "mg") {
          generateParam = { allDocuments: true };
        } else if (e.value === "pg") {
          generateParam = { allPresentations: true };
        }
        this.setState(
          {
            selectedTool: e.value,
            generateParam
          },
          this.fetchReportingData
        );
      };

      // Fetch reporting data call back
      fetchReportingData = () => {
        const startDate = moment(this.state.startDate).format("YYYY-MM-DD"),
          endDate = moment(this.state.endDate).format("YYYY-MM-DD");
        // Generate Quick Stats Data
        this.props.getReportingData(
          {
            startDate,
            endDate,
            tool: this.state.selectedTool
          },
          "application/json"
        );

        // Generate Quick Stats Download link
        this.props.getReportingData(
          {
            startDate,
            endDate,
            tool: this.state.selectedTool
          },
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      };

      // Generate Advance Data
      getAdvanceData = async () => {
        const { generateParam, selectedTool } = this.state;
        const startDate = moment(this.state.startDate).format("YYYY-MM-DD"),
          endDate = moment(this.state.endDate).format("YYYY-MM-DD");
        
        await this.props.getAdvanceReportingData(
          {
            startDate,
            endDate,
            generateParam: {
              tool: selectedTool,
              tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
              ...generateParam
            }
          },
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const reportAdvanceLocation = this.props.reportAdvanceLocation || {};

        //exit if no location is present
        if (!reportAdvanceLocation.location) {
          return;
        }

        downloadFile(reportAdvanceLocation.location);
      };

      // handle date change
      handleDateChange = (date, toDate) => {
        const currentDate = new Date();

        if (date === "startDate" && toDate > this.state.endDate) {
          // if selected start date exceeds current end date
          const selectedDate = moment(toDate);
          const addedDate = selectedDate.add(30, "days")._d;

          // If added date exceeds today's/current date
          const newEndDate = addedDate > currentDate ? currentDate : addedDate;

          this.setState(
            {
              [date]: toDate,
              endDate: newEndDate
            },
            () => {
              this.fetchReportingData();
            }
          );
        } else if (date === "endDate" && toDate < this.state.startDate) {
          // if selected end date is less current start date
          const selectedDate = moment(toDate);
          const subtractedDate = selectedDate.subtract(30, "days")._d;

          this.setState(
            {
              startDate: subtractedDate,
              [date]: toDate
            },
            () => {
              this.fetchReportingData();
            }
          );
        } else {
          this.setState(
            {
              [date]: toDate
            },
            () => {
              this.fetchReportingData();
            }
          );
        }
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...JSON.parse(JSON.stringify($this.state)),
          clientConfig: this.props.clientConfig
        };
        return <Main {...stateMethodProps} />;
      }
    }
  );

export default Container;
