import React, { Component } from "react";

const container = Main =>
  class Container extends Component {
    render() {
      return <Main {...this.props} {...this.state} />;
    }
  };

export default container;
