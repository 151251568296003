import React from "react";
import styled from "styled-components";
import { get } from "lodash";

import { SlidePreviewLeftArrow, SlidePreviewRightArrow } from "assets/icons";

const ThemeFooter = props => {
  let {
    activeThemeDetails,
    currentActiveIndex,
    navigationHandler,
    availableSlides
  } = props;

  return (
    <FooterContainer>
      <ThemeType>{`Type: ${
        activeThemeDetails.isMaster ? "Master" : "SubMaster"
      }`}</ThemeType>
      <ThemeTypeName>
        Name: {get(availableSlides[currentActiveIndex], `title`)}
      </ThemeTypeName>
      <SlideCta>
        <IconWrapper
          title="Previous Slide"
          disabled={currentActiveIndex === 0}
          onClick={() => navigationHandler(true, false)}
        >
          <SlidePrevIcon />
        </IconWrapper>
        <SlideText>{`Slide ${currentActiveIndex + 1} of ${
          availableSlides.length
        }`}</SlideText>
        <IconWrapper
          title="Next Slide"
          disabled={currentActiveIndex === availableSlides.length - 1}
          onClick={() => navigationHandler(false, true)}
        >
          <SlideNextIcon />
        </IconWrapper>
      </SlideCta>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  padding: 18px 20px 19px 28px;
  background-color: ${props => props.theme.COLOR.MAIN};

  span {
    color: ${props => props.theme.COLOR.WHITE};
    font-size: 14px;
    line-height: 18px;
    font-family: ${props => `${props.theme.FONT.REG}`};
  }
`;

const ThemeType = styled.span`
  font-weight: bold;
`;

const ThemeTypeName = styled.span`
  margin-left: 36px;
  font-weight: bold;
`;

const SlideCta = styled.div`
  float: right;
  vertical-align: top;
`;

const SlideText = styled.span`
  margin: 0 20px;
  display: inline-block;
  font-weight: normal;
`;

const IconWrapper = styled.button`
  border: none;
  outline: none;
  display: inline-block;
  vertical-align: middle;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  background: none;
`;

const SlidePrevIcon = styled(SlidePreviewLeftArrow)`
  path {
    fill: ${props => props.theme.COLOR.WHITE};
  }
`;

const SlideNextIcon = styled(SlidePreviewRightArrow)`
  path {
    fill: ${props => props.theme.COLOR.WHITE};
  }
`;

export default ThemeFooter;
