import React from "react";
import styled from "styled-components";
import Button from "components/button";
import { FilterAll } from "./components/FilterAll";
import hexToRgba from "utils/hexToRgba";
import { SlideDropDown } from "../slideContainer/components";
import { map } from "lodash";
import ShadowScrollbars from "components/custom-scrollbars/ShadowScrollbars";

/**
 * Component defined for aside filter
 * @param {*} props
 */
const Filters = ({
  selectedFilters,
  onChangeHandleFiltersCheck,
  handleStateChange,
  filter,
  handleCheckBoxChange,
  filterOnDropChange,
  dropDownOptionsNew,
  getParentCategories,
  selectedFilterCategory,
  emptyState,
  saveFilters,
  displayFiltersList,
  resetFilters
}) => {
  const Message = "Filter does not exist in this category.";
  const props = {
    filter,
    selectedFilters,
    onChangeHandleFiltersCheck,
    handleStateChange,
    handleCheckBoxChange,
    getParentCategories,
    filterOnDropChange,
    selectedFilterCategory,
    emptyState,
    dropDownOptionsNew,
    saveFilters
  };

  return (
    <>
      <FilterHead>
        <FiltersTitle>filters</FiltersTitle>
        {Array.isArray(selectedFilters) && !!selectedFilters.length && (
          <Button
            buttonClass="clear"
            text="Clear All"
            onClick={() => resetFilters()}
            width="74px"
          />
        )}
      </FilterHead>
      <FilterDropdown>
        <SlideDropDown
          parentSlide={true}
          option={[
            { title: "Select Category", _id: 0 },
            ...map(dropDownOptionsNew, option => {
              return {
                title: `${Array(option.level + 1).join("--")}` + option.title,
                _id: option._id
              };
            })
          ]}
          elemId={"parent"}
          onDropDownChange={filterOnDropChange}
          selectedValue={selectedFilterCategory}
        />
      </FilterDropdown>
      {selectedFilterCategory && !filter.length && (
        <NoFilterExistText>{Message}</NoFilterExistText>
      )}
      {displayFiltersList && (
        <FilterTypesContainer>
          <ShadowScrollbars scrollcontenttotop={"yes"} style={{ height: 400 }}>
            <FilterAll {...props} />
          </ShadowScrollbars>
          <FilterAddContainer>
            <AddFilter
              filter={filter}
              onClick={() => {
                saveFilters(props.emptyState);
              }}
            >
              Save
            </AddFilter>
            <CancelFilter onClick={emptyState}>Cancel</CancelFilter>
          </FilterAddContainer>
        </FilterTypesContainer>
      )}
    </>
  );
};

export default Filters;

const FilterHead = styled.div`
  margin: 21px 0 0;
  display: flex;
  align-items: center;
  padding: 0 12px 0 12px;
  justify-content: space-between;

  .clear {
    width: auto;
    height: auto;
    padding: 5px 14px;
    border: solid 1px ${props => props.theme.COLOR_PALLETE.GREY};
    background-color: ${props => props.theme.COLOR.WHITE};
    color: ${props => props.theme.COLOR_PALLETE.GREY};
    font-size: 12px;
    font-weight: normal;

    &:hover {
      color: ${props => props.theme.COLOR.WHITE};
      background-color: ${props => props.theme.COLOR_PALLETE.GREY};
    }
  }
`;

const FiltersTitle = styled.h3`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.7)};
  font-weight: bold;
  text-transform: capitalize;
  padding-bottom: 10px;
`;

const FilterTypesContainer = styled.div`
  padding-bottom: 85px;
  h4 {
    color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.7)};
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
  }
  label {
    white-space: nowrap;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 86%;
    vertical-align: middle;
  }

  .scrollContainer {
    & > div {
      &:first-of-type {
        /* Important is added so as to override plugin styling */
        margin-right: 0 !important;
        margin-bottom: 0 !important;
        overflow: auto !important;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
    }
  }

  .shadowBottom {
    width: 100%;
    height: 35px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(
      to bottom,
      ${props => props.theme.COLOR.SCROLL_SHADOW},
      ${props => props.theme.COLOR.WHITE}
    );
    pointer-events: none;
  }
`;

const FilterAddContainer = styled.div`
  width: 100%;
  padding: 20px 10px;
  position: absolute;
  background: #fff;
  border-top: 2px solid #eee;
  bottom: 0;
  left: 0;
`;

const AddFilter = styled.button`
  width: calc(50% - 4px);
  height: 40px;
  border-radius: 4px;
  border: none;
  outline: none;
  color: ${props => props.theme.COLOR.WHITE};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  cursor: pointer;
  opacity: ${props =>
    Array.isArray(props.filter) && props.filter.length ? "1" : "0.5"};
  pointer-events: ${props =>
    Array.isArray(props.filter) && props.filter.length ? "auto" : "none"};
`;

const CancelFilter = styled.button`
  width: calc(50% - 4px);
  height: 40px;
  margin-left: 6px;
  background: transparent;
  font-family: ${props => props.theme.FONT.REG};
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  border: solid 1px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  cursor: pointer;
`;

const FilterDropdown = styled.div`
  padding: 0 12px 0 12px;
  margin-bottom: 17px;
`;

const NoFilterExistText = styled.p`
  text-align: center;
`;
