import React, { Component } from 'react'
import styled from 'styled-components'
import {
  DownloadColored,
  ShareColored,
  shareDisable,
  DownloadDisable
} from 'assets/icons'
import { getPresentationHistory } from './services'
import { connect } from 'react-redux'
import { get } from 'lodash'
import handleBodyScroll from 'utils/handleBodyScroll'
import { downloadFile } from 'utils/download'

/**
 * Map the state to props.
 */

const mapStateToProps = state => {
  const {
    LOADING_PRESENTATION_HISTORY,
    FETCH_PRESENTATION_HISTORY_SUCCESS
  } = state
  return {
    ...LOADING_PRESENTATION_HISTORY,
    ...FETCH_PRESENTATION_HISTORY_SUCCESS
  }
}
const Container = Main =>
  connect(mapStateToProps, {
    getPresentationHistory
  })(
    class Presentation extends Component {
      static defaultProps = {
        role: 'admin'
      }

      state = {
        cols: [],
        isRenderTable: false,
        tableColumnHeader: [
          'name',
          'email',
          'presentationName',
          'contentRepository',
          'createdAt',
          ''
        ],
        presentationData: [],
        isShowOverlay: false,
        isPopShow: false,
        selectedPresentation: {}
      }

      presentationColumns = [
        {
          col1: 'Name',
          col2: 'Email',
          col3: 'Presentation Name',
          col4: 'Repo Name',
          col5: 'Created Date',
          col6: 'Actions'
        }
      ]
      columnWidth = [100, 165, 171, 169, 209, 200]

      renderHead = () => {
        return (
          <div className='heading'>
            <HeadingName>Presentation History</HeadingName>
          </div>
        )
      }

      componentDidMount () {
        this._fetchPresentationHistory()
      }

      /**
       * Function to get the pagelayout list
       */
      _fetchPresentationHistory = async () => {
        await this.props.getPresentationHistory()
        this.formatResponse(this.props.presentationHistoryList || [])
      }

      formatResponse = presentationData => {
        let tempPresentationData = []

        tempPresentationData = presentationData.map(item => {
          return {
            name: item.createdBy.name,
            email: item.createdBy.email,
            ...item
          }
        })

        this.setState({
          cols: this.presentationColumns,
          isRenderTable: true,
          presentationData: tempPresentationData
        })
      }

      // function to show last column in table
      showIcon = rowData => {
        return (
          <>
            <IconWrapper
              title={rowData.status === 'Completed' ? 'Download' : ''}
            >
              {get(rowData, `status`) === 'Saved' ? (
                <DownloadIcon />
              ) : (
                <CompletedDownload
                  onClick={() => this.downloadHandler(rowData)}
                />
              )}
            </IconWrapper>
            <IconWrapper title='Share'>
              {get(rowData, `status`) === 'Saved' ? (
                <ShareIcon />
              ) : (
                <CompletedShare onClick={() => this.overlayHandler(rowData)} />
              )}
            </IconWrapper>
          </>
        )
      }

      // downloader handler
      downloadHandler = selectedPresentation => {
        const { pdfLocation, pptLocation, zipLocation } =
          selectedPresentation || {}
        let urlLocations = [pdfLocation, pptLocation, zipLocation]

        let moreThanOne = urlLocations.filter((item = {}) => item.url)

        if (moreThanOne.length === 1) {
          downloadFile(moreThanOne[0].url)
          return
        }

        this.setState({
          isPopShow: true,
          selectedPresentation
        })
      }

       //handle closing of modal on click of close icon button and outside of content area
      closeModalHandler = () => {
        this.setState({
          isPopShow: false
        })
      }

      // show share overlay
      overlayHandler = rowData => {
        if (rowData) rowData.isHistory = true

        let selectedPresentation = rowData

        if (!this.state.isShowOverlay) {
          handleBodyScroll({
            action: 'open'
          })
        } else {
          handleBodyScroll({
            action: 'close'
          })
        }

        this.setState({
          selectedPresentation,
          isShowOverlay: !this.state.isShowOverlay
        })
      }

      render () {
        const $this = this

        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props
        }
        return <Main {...stateMethodProps} />
      }
    }
  )

const IconWrapper = styled.span`
  height: auto;
  display: inline-block;
  padding: 0 8px;

  &:first-of-type {
    padding: 0 8px 0 0;
  }
  &:hover {
    opacity: 0.7;
  }
`

const DownloadIcon = styled(DownloadDisable)`
  cursor: pointer;
  width: 16px;
  height: 17px;
  pointer-events: none;
`

const CompletedDownload = styled(DownloadColored)`
  cursor: pointer;
  width: 16px;
  height: 17px;
  cursor: pointer;
`

const ShareIcon = styled(shareDisable)`
  width: 16px;
  height: 17px;
  pointer-events: 'none';
`

const CompletedShare = styled(ShareColored)`
  width: 16px;
  height: 17px;
  cursor: pointer;
`

const HeadingName = styled.span`
  margin-left: -2px;
  display: inline-block;
  margin-bottom: 10px;
`

export default Container
