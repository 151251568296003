import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";
//import content repo actions
import { onGetUserDetailsSuccess, isGetUserDetailsLoading } from "./actions";
/**
 * Get Content Repo List for presentation setup
 */
const getProfileDetail = (isProfileUpdating = false) => async dispatch => {
  const URL = `/user`;
  // start loading

  if (isProfileUpdating) {
    dispatch(isGetUserDetailsLoading({ isProfileUpdating: true }));
  } else {
    dispatch(isGetUserDetailsLoading({ isProfileLoading: true }));
  }

  const response = await FetchUtils.getData(URL);

  if (isProfileUpdating) {
    dispatch(isGetUserDetailsLoading({ isProfileUpdating: false }));
  } else {
    dispatch(isGetUserDetailsLoading({ isProfileLoading: false }));
  }
  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onGetUserDetailsSuccess({
        userProfileMeta: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }
  //return reponse to check in component if required
  return response;
};
export { getProfileDetail };
