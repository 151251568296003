import React from "react";
import styled from "styled-components";

import Container from "./container";
import StepDetails from "components/buildProcess/stepDetails";
import SetupDetails from "./components/SetupDetails";
import CustomerLogo from "./components/CustomerLogo";

//utils
import featureFlags from "utils/featureFlags.js";
import { get } from "lodash";

const Setup = props => {
  let { showCustomerLogo } = get(featureFlags, `presentation`);

  return (
    <>
      <SetupContainer>
        <StepDetails
          title={"Setup"}
          onNext={() => props.nextStepHandler(1)}
          _next
          _save={props.isEdited}
          {...props}
        />
        <Content isCenterAligned={!showCustomerLogo}>
          <SetupDetails {...props} />
          {showCustomerLogo && <CustomerLogo {...props} />}
        </Content>
      </SetupContainer>
    </>
  );
};

const SetupContainer = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  margin: 0 auto;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  margin-bottom: 50px;
`;

const Content = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: ${props =>
    props.isCenterAligned ? "center" : "space-between"};
`;

export default Container(Setup);
