import React, { Component } from "react";
import styled from "styled-components";
import Placeholder from "./components/placeholder";

class LayoutSelector extends Component {
  _renderPlaceholder = ({ placeholders, eachlayout }) => {
    let {
      onPlaceholderClick,
      selectedPlaceholder,
      deleteSelectedModule
    } = this.props;

    let { layoutId, selectedLayout, imageMatrix } = eachlayout;

    let placeholderData = {
      layoutId,
      selectedLayout
    };

    let layoutType = selectedLayout;

    let placeholderEle = placeholders.map((ele, index) => {
      return (
        <Placeholder
          id={`${layoutId}-${index}`}
          key={index}
          index={index}
          selectedThumbnail={ele.thumbnailSrc || ""}
          selectedThumbnailId={ele.moduleId || ""}
          layoutType={layoutType}
          placeholderData={placeholderData}
          onSelect={onPlaceholderClick}
          selectedPlaceholder={
            selectedPlaceholder &&
            Object.keys(selectedPlaceholder).length &&
            selectedPlaceholder.id
          }
          deleteSelectedModule={deleteSelectedModule}
          imageMatrix={imageMatrix}
        />
      );
    });
    return placeholderEle;
  };

  render() {
    let { eachlayout, handleChangeTemplate, selectedOption } = this.props;

    return (
      eachlayout.selectedLayout !== "No Back" && (
        <LayoutSelectorWrapper>
          <LayoutTitle>
            {eachlayout.layoutId}
            {!(
              selectedOption.value === "Brochure" &&
              eachlayout.selectedLayout === "Full Page" &&
              eachlayout.layoutId === "Front Cover"
            ) && (
              <LinkContainer onClick={handleChangeTemplate}>
                (Change Template)
              </LinkContainer>
            )}
          </LayoutTitle>
          <ContentWrapper className={eachlayout.classString}>
            {this._renderPlaceholder({
              placeholders: eachlayout["placeholder"],
              eachlayout
            })}
          </ContentWrapper>
        </LayoutSelectorWrapper>
      )
    );
  }
}

const LayoutSelectorWrapper = styled.div``;

const LayoutTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: ${props => props.theme.COLOR.GREY};
`;

const LinkContainer = styled.button`
  display: inline-block;
  color: ${props => props.theme.COLOR.DEFAULT_USER_PRIMARY};
  line-height: 12px;
  font-size: 10px;
  background: none;
  border: none;
  height: auto;
  cursor: pointer;
  font-family: ${props => props.theme.FONT.REG};
  margin-left: 10px;
  padding: 0;
  position: relative;

  &:focus {
    outline: none;
  }

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.COLOR.USER_PRIMARY};
    opacity: 0.7;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translateX(-50%);
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  height: 253px;
  width: 46%;
  margin: 31px auto 42px;

  @media (max-width: 1200px) {
    height: 219px;
  }

  @media (max-width: 1024px) {
    height: 189px;
  }

  @media (max-width: 768px) {
    height: 136px;
  }

  &.single {
    & > div {
      width: 50%;
      margin: 0 auto;
      min-height: 190px;

      @media (max-width: 1200px) {
        min-height: 150px;
      }

      @media (max-width: 768px) {
        min-height: 114px;
      }
    }
  }

  &.wide-single {
    & > div {
      width: 100%;
      min-height: 190px;

      @media (max-width: 1200px) {
        min-height: 150px;
      }

      @media (max-width: 768px) {
        min-height: 114px;
      }
    }
  }

  &.col-double {
    flex-direction: row;
    width: 50%;

    & > div:nth-of-type(1),
    & > div:nth-of-type(2) {
      width: 46%;
      min-height: 190px;

      @media (max-width: 1200px) {
        min-height: 150px;
      }

      @media (max-width: 768px) {
        min-height: 114px;
      }
    }
  }

  &.row-double {
    flex-direction: column;

    & > div:nth-of-type(1),
    & > div:nth-of-type(2) {
      min-height: 91px;
      width: 50%;

      @media (max-width: 1200px) {
       min-height: 72.60px;
      }

      @media (max-width: 768px) {
        min-height: 55.73px;
      }
    }
  }

  &.row-first-tri {
    flex-direction: column;
    width: 50%;

    & > div:nth-of-type(1),
    & > div:nth-of-type(2) {
      min-height: 91px;
      width: 46%;

      @media (max-width: 1200px) {
       min-height: 72.60px;
      }

      @media (max-width: 768px) {
        min-height: 55.73px;
      }
    }

    & > div:nth-of-type(3) {
      width: 46%;
      min-height: 190px;

      @media (max-width: 1200px) {
        min-height: 150px;
      }

      @media (max-width: 768px) {
        min-height: 114px;
      }
    }
  }

  &.col-first-tri {
    flex-direction: column;
    width: 50%;

    & > div:nth-of-type(2),
    & > div:nth-of-type(3) {
      min-height: 91px;
      width: 46%;

      @media (max-width: 1200px) {
       min-height: 72.60px;
      }

      @media (max-width: 768px) {
        min-height: 55.73px;
      }
    }

    & > div:nth-of-type(1) {
      width: 46%;
      min-height: 190px;

      @media (max-width: 1200px) {
        min-height: 150px;
      }

      @media (max-width: 768px) {
        min-height: 114px;
      }
    }
  }

  &.quad {
    flex-direction: column;
    width: 50%;

    & > div:nth-of-type(1),
    & > div:nth-of-type(2),
    & > div:nth-of-type(3),
    & > div:nth-of-type(4) {
      min-height: 91px;
      width: 46%;

      @media (max-width: 1200px) {
       min-height: 72.60px;
      }

      @media (max-width: 768px) {
        min-height: 55.73px;
      }
    }
  }
`;

export default LayoutSelector;
