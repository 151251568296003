import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";

//import Document Dashboard actions
import { onAdminDocumentSuccess, isAdminDocumentLoading } from "./actions";

/**
 * Get Document Dashboard List
 */
const getAdminDocumentList = () => async dispatch => {
  const URL = `/marketing-materials/documents`;
  // start loading
  dispatch(isAdminDocumentLoading({ isAdminDocumentDataLoading: true }));
  const response = await FetchUtils.getData(URL);

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onAdminDocumentSuccess({
        documentAdminDashboardDataList: response.data
      })
    );
    // stop loading
    dispatch(isAdminDocumentLoading({ isAdminDocumentDataLoading: false }));
  } else {
    // stop loading
    dispatch(isAdminDocumentLoading({ isAdminDocumentDataLoading: false }));
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

export { getAdminDocumentList };
