import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import AllProvider from "./Provider";
import TagManager from "react-gtm-module";
import "reset-css";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

//configure GTM
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  preview: process.env.REACT_APP_GTM_PREVIEW,
};

if (process.env.REACT_APP_GTM_ID !== null) {
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(<AllProvider />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
