import React from "react";
import styled from "styled-components";
import SetupForm from "./components/setupForm";
import SetupType from "./components/setupType";
import ChooseTemplate from "./components/chooseTemplate";
import StepDetails from "components/buildProcess/stepDetails";
import Popup from "components/previewModal";
import ChooseContent from "./components/chooseContent";
/**
 * Main library component
 * @param {*} props
 */
const DocumentSetup = props => {
  return (
    <DocumentSetupContainer>
      <StepDetails
        _next={!props.chooseContent && props.selectedOption}
        onNext={() => props.handleMainButtonClick(true, 1)}
        _save={
          !(props.documentId && !props.isDocumentEdited) && props.selectedOption
        }
        _back={!!props.chooseContent}
        onSave={() => props.handleTabChange(false)}
        onBack={props.chooseContent && props.handleBackClick}
        {...props}
        title={props.chooseContent ? "Choose Content" : ""}
        description={
          props.chooseContent
            ? "Select the content you’re interested in and we’ll recommend a document type."
            : ""
        }
      />

      {!props.chooseContent ? (
        <>
          <LeftContainer>
            <SetupForm {...props} />
            <SetupType {...props} />
          </LeftContainer>
          {props.selectedOption && (
            <RightContainer>
              <ChooseTemplate {...props} />
            </RightContainer>
          )}

          {props.isPopupOpen && props.templatePreviewSource && (
            <Popup
              isOpen={props.isPopupOpen}
              closeModal={props.handlePopupClose}
              width={"auto"}
            >
              <PopupWrapper>
                <ImageContainer src={props.templatePreviewSource} />
              </PopupWrapper>
            </Popup>
          )}
        </>
      ) : (
        <ChooseContent {...props} />
      )}
    </DocumentSetupContainer>
  );
};

const DocumentSetupContainer = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  margin: 0 auto;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  margin-bottom: 50px;
`;

const LeftContainer = styled.div`
  width: 35.5%;
  margin-top: 40px;
  display: inline-block;
  vertical-align: top;
`;

const RightContainer = styled.div`
  width: 61%;
  margin: 40px 0 0 3.5%;
  display: inline-block;
  vertical-align: top;
`;

const PopupWrapper = styled.div`
  padding: 36px;
`;

const ImageContainer = styled.img`
  width: 100%;
`;

export default DocumentSetup;
