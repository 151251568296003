import React from "react";
import styled from "styled-components";

const ImageDetails = ({ item, editImageDetailsHandler }) => {
  return item.thumbnailLocation ? (
    <ImageContainer
      title={item.title}
      onClick={() => editImageDetailsHandler(item._id)}
    >
      <ImageWrapper>
        <Image src={item.thumbnailLocation} />
      </ImageWrapper>
      <Title>{item.title || ""}</Title>
    </ImageContainer>
  ) : null;
};

export default ImageDetails;

const ImageContainer = styled.div`
  width: calc(25% - 16px);
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 18px 0;
  cursor: pointer;
  padding: 6px 6px 8px;
  transition: 0.3s ease-in-out;
  &:nth-of-type(4n) {
    margin-right: 0;
  }

  &:hover {
    background-color: ${props => props.theme.COLOR.WHITE};
    ${props => props.theme.SNIPPETS.BOX_SHADOW};

    figure {
      transition: 0.3s ease-in-out;
      transform: scale(0.99);
    }
  }
`;

const ImageWrapper = styled.figure`
  margin-bottom: 12px;
`;

const Image = styled.img`
  width: 100%;
`;

const Title = styled.span`
  width: 100%;
  display: inline-block;
  vertical-align: super;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.COLORHEADING};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 12px;
  white-space: nowrap;
`;
