import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Dropdown } from "assets/icons";
import hexToRgba from "utils/hexToRgba";

class AccordionSection extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.label, this.props.isFixed);
  };

  render() {
    const {
      onClick,
      props: { isOpen, title, isFixed, isFirst }
    } = this;
    return (
      <AccordianItem>
        <AccHead isFirst={isFirst} onClick={onClick}>
          <span>{title}</span>
          <StyledDropdown active={isOpen ? "active" : null} />
        </AccHead>
        <AccContent isOpen={isOpen} isFixed={isFixed}>
          {this.props.children}
        </AccContent>
      </AccordianItem>
    );
  }
}

const AccordianItem = styled.div`
  overflow-y: hidden;
`;

const AccContent = styled.div`
  height: ${props => (props.isOpen ? "313px" : "0")};
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 4px;
    outline: 1px solid slategrey;
  }
`;

const AccHead = styled.div`
  padding: 12px 0;
  margin-right: 11px;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-weight: bold;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.7)};
  border-top: 1px solid
    ${props =>
      props.isFirst
        ? "none"
        : hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.18)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledDropdown = styled(Dropdown)`
  transform: ${props => (props.active ? "rotate(0)" : "rotate(-90deg)")};
  transition: 0.3s;
`;

export default AccordionSection;
