import React, { Component } from "react";

const Container = Main =>
  class SlidesWithGroup extends Component {
    constructor(props) {
      super(props);
      this.state = {
        searchGroupSlidesList: []
      };
    }

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      };

      return <Main {...stateMethodProps} />;
    }
  };
export default Container;
