import React from "react";
import styled from "styled-components";
import { Check } from "assets/icons";

const ChooseTemplate = props => {
  let {
    templateTypes,
    handleTemplateClick,
    selectedOption,
    handleViewClick
  } = props;

  return (
    <DetailsContainer>
      <TemplateContainerTitle>
        Choose {selectedOption.value} Template
      </TemplateContainerTitle>

      {Object.keys(templateTypes).map(
        tempCat =>
          tempCat === selectedOption.value.toLowerCase() &&
          Object.keys(templateTypes[tempCat]).map((tempSubCat, id) => (
            <TemplateHolder
              key={tempSubCat}
              itemLength={templateTypes[tempCat][tempSubCat].value.length}
            >
              <Label>{templateTypes[tempCat][tempSubCat].label}</Label>
              <TemplateContainer
                key={tempSubCat + id}
                layoutType={selectedOption.value}
              >
                {templateTypes[tempCat][tempSubCat].value.map((item, i) => (
                  <ContentWrapper
                    key={item.category + i}
                    itemLength={templateTypes[tempCat][tempSubCat].value.length}
                  >
                    <FigureContainer
                      className={
                        item.active
                          ? "active"
                          : item.title === "No Back"
                          ? "no-back"
                          : ""
                      }
                      id={item.id}
                    >
                      <ImageContainer
                        src={item.src}
                        alt={item.title}
                        onClick={() =>
                          handleTemplateClick(i, item.category, tempSubCat)
                        }
                      />
                      {item.active ? <Check /> : null}
                    </FigureContainer>
                    {item.view && (
                      <LinkContainer onClick={() => handleViewClick(item)}>
                        View
                      </LinkContainer>
                    )}
                  </ContentWrapper>
                ))}
              </TemplateContainer>
            </TemplateHolder>
          ))
      )}
    </DetailsContainer>
  );
};

const DetailsContainer = styled.div`
  padding: 24px 7% 30px;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  background-color: ${props => props.theme.COLOR.WHITE};
`;

const TemplateContainerTitle = styled.h2`
  margin-bottom: 30px;
  opacity: 0.7;
  font-size: 20px;
  line-height: 15px;
  font-weight: bold;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
`;

const TemplateHolder = styled.div`
  width: ${props => (props.itemLength <= 3 ? "48%" : "100%")};
  display: ${props => (props.itemLength <= 3 ? "inline-block" : "block")};
  vertical-align: top;
  &:nth-of-type(1) {
    margin-right: ${props => (props.itemLength <= 2 ? "4%" : "0")};
  }
`;

const ContentWrapper = styled.div`
  width: ${props => (props.itemLength <= 3 ? "28.8%" : "13.8%")};
  display: inline-block;
  vertical-align: top;
  &:not(:last-of-type) {
    margin-right: ${props => (props.itemLength <= 3 ? "6.6%" : "3.4%")};
  }
`;

const TemplateContainer = styled.div`
  padding: 7px 14px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.3);
  text-align: ${props => (props.layoutType === "Placemat" ? "left" : "center")};
  .checkbox-container {
    margin-right: 0;
    display: inline-block;
    div {
      margin-bottom: 0;
    }
    input {
      margin: 0;
    }
  }
`;

const LinkContainer = styled.button`
  display: block;
  color: ${props => props.theme.COLOR.USER_PRIMARY};
  line-height: 12px;
  font-size: 10px;
  background: none;
  border: none;
  width: 100%;
  height: auto;
  cursor: pointer;
  font-family: ${props => props.theme.FONT.REG};
  margin: 6px auto 0;
  padding: 0;
  position: relative;

  &:focus {
    outline: none;
  }

  &::after {
    content: "";
    width: 22px;
    height: 1px;
    background-color: ${props => props.theme.COLOR.USER_PRIMARY};
    opacity: 0.7;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translateX(-50%);
  }
`;

const Label = styled.div`
  margin-bottom: 6px;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  line-height: 15px;
  opacity: 0.7;
  font-size: 12px;
`;

const FigureContainer = styled.figure`
  cursor: pointer;
  border: 2px solid ${props => props.theme.COLOR.WHITE};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  &.no-back {
    box-shadow: none;
  }

  &.active {
    border: 2px solid ${props => props.theme.COLOR.USER_PRIMARY};
    position: relative;
  }

  svg {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    g {
      g {
        fill: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }
`;

const ImageContainer = styled.img`
  width: 100%;
  display: block;
`;

export default ChooseTemplate;
