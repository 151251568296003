import FetchUtils from "utils/FetchUtils.js";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";
import ConvertQueryParamsObjectToString from "utils/queryString";

// import report actions
import {
  contentSlideListSuccess,
  contentRepoSlideLoading,
  contentRepoSlideUpdateSuccess,
  isSlideUploading,
  getThemeData,
  filtersListSuccess,
  filtersListLoading,
  saveContentCategoryLoading,
  contentFilterUpdateSuccess
} from "./action";

const fetchContentSlideList = (
  id,
  excludeContentSlides = false
) => async dispatch => {
  const params = ConvertQueryParamsObjectToString({
    excludeContentSlides
  });

  const URL = `/content-repositories/${id}/content-categories${
    params ? `?${params}` : ""
  }`;

  // start loading
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: !excludeContentSlides // do not stop loading if fetchContentSlideList is called again to avoid hiding of loader for few seconds
    })
  );

  if (response.success && response.data) {
    if (excludeContentSlides) {
      //dispatch data list on success
      dispatch(
        contentSlideListSuccess({
          categoryData: response.data
        })
      );
    } else {
      //dispatch data list on success
      dispatch(
        contentSlideListSuccess({
          contentSlideList: response.data
        })
      );
    }
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Content slide Setting Integration
 */
const updateSlideSettings = (body, id) => async dispatch => {
  // start loading
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: true
    })
  );

  const URL = `/content-repositories/${id}`,
    response = await FetchUtils.patchData(
      URL,
      body,
      "UpdateContent Slide Settings ==>"
    );

  // stop loading
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch  data on success
    ToastUtils.handleToast({
      operation: "success",
      message: "Content Slide settings has been updated successfully."
    });
  } else {
    // stop loading

    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

// create contentslide heirarchy
const createContentSlideCategory = (
  body,
  contentRepoId,
  message,
  contentSlideId = null
) => async dispatch => {
  // start loading
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/content-categories/${
    contentSlideId ? contentSlideId : ""
  }`;

  const response = await (contentSlideId
    ? FetchUtils.patchData(URL, body, "Update content slide Heirarchy ==>")
    : FetchUtils.postData(URL, body, "Create content slide Heirarchy ==>"));

  // stop loading
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: false
    })
  );
  if (response.success && response.data) {
    dispatch(
      contentRepoSlideUpdateSuccess({
        contentSlideHeirarchyData: response.data
      })
    );
    //dispatch  data on success
    ToastUtils.handleToast({
      operation: "success",
      message: message
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  //return reponse to check in component if required
  return response;
};

//Fetch the content repo slide group
const fetchSlideGroup = id => async dispatch => {
  const URL = `/content-repositories/${id}/groups`;

  // start loading
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      contentSlideListSuccess({
        contentSlideGroupList: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const updateContentRepogroup = (
  body,
  contentRepoId,
  message,
  contentSlideId = null
) => async dispatch => {
  // start loading
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/groups/${
    contentSlideId ? contentSlideId : ""
  }`;

  const response = await (contentSlideId
    ? FetchUtils.patchData(URL, body, "Update content slide group ==>")
    : FetchUtils.postData(URL, body, "Create content slide group ==>"));

  // stop loading
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: false
    })
  );
  if (response.success && response.data) {
    //dispatch  data on success
    ToastUtils.handleToast({
      operation: "success",
      message: message
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  //return reponse to check in component if required
  return response;
};

const deleteContentRepogroup = (
  contentRepoId,
  contentSlideId
) => async dispatch => {
  // start loading
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/groups/${contentSlideId}`;

  const response = await FetchUtils.deleteData(
    URL,
    "Delete content slide group ==>"
  );

  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch  data on success
    ToastUtils.handleToast({
      operation: "success",
      message: "Group has been successfully deleted."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

const searchSlideGroup = (
  contentRepoId,
  contentSlideId,
  value
) => async dispatch => {
  const params = ConvertQueryParamsObjectToString({
    searchText: value
  });
  // start loading
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/groups/${contentSlideId}/search${
    params ? `?${params}` : ""
  }`;

  const response = await FetchUtils.getData(
    URL,
    "Fetch content slide group ==>"
  );

  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch  data on success
    dispatch(
      contentRepoSlideUpdateSuccess({
        searchSlideList: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

const getSlideListOfGroup = (
  contentRepoId,
  contentSlideId
) => async dispatch => {
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/groups/${contentSlideId}/content-slides`;

  const response = await FetchUtils.getData(
    URL,
    "Get content slide of a group ==>"
  );

  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch  data on success
    dispatch(
      contentRepoSlideUpdateSuccess({
        groupContentSlideList: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

const deleteContentSlide = (
  contentRepoId,
  slideId,
  removeSlide = false
) => async dispatch => {
  const params = ConvertQueryParamsObjectToString({
    removeSlide
  });

  const URL = `/content-repositories/${contentRepoId}/content-slides/${slideId}${
    params ? `?${params}` : ""
  }`;

  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: true
    })
  );
  const response = await FetchUtils.deleteData(URL, "Delete content slide ==>");

  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch  data on success

    ToastUtils.handleToast({
      operation: "success",
      message: "Slide has been successfully deleted."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

const deleteContentSlideCategory = (
  contentRepoId,
  contentSlideId
) => async dispatch => {
  // start loading
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/content-categories/${contentSlideId}`;

  const response = await FetchUtils.deleteData(
    URL,
    "Delete content slide category ==>"
  );

  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch  data on success

    ToastUtils.handleToast({
      operation: "success",
      message: "Category has been successfully deleted."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

/**
 * Update content slide
 * @param {String} - contentRepoId It will be the Content Repo ID
 * @param {String} - contentSlideCategoryId It will be the Content Slide category Repo ID
 * @param {String} - slideIdIt will be the Content Slide ID
 * @param {Object} - body The Body of the API which has to be updated for the particular content slides
 */
const updateContentSlide = (
  contentRepoId,
  contentSlideCategoryId,
  slideId,
  body,
  message
) => async dispatch => {
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/content-categories/${contentSlideCategoryId}/content-slide/${slideId}`;

  const response = await FetchUtils.patchData(
    URL,
    body,
    "Update content slide category ==>"
  );

  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: false
    })
  );

  if (response.success) {
    ToastUtils.handleToast({
      operation: "success",
      message
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

/**
 * Save Content Slide
 * @param {String} - contentRepoId It will be the Content Repo ID
 * @param {String} - contentSlideCategoryId It will be the Content Slide category Repo ID
 * @param {Object} - body The Body of the API which has to be updated for the particular content slides
 */
const saveContentSlide = (
  contentRepoId,
  contentSlideCategoryId,
  body,
  slideId
) => async dispatch => {
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/content-categories/${contentSlideCategoryId}/content-slide${
    slideId ? `/${slideId}` : ``
  }`;

  // Check if slideId is present and make patch or post accordingly
  let saveData = slideId ? FetchUtils.patchData : FetchUtils.postData;

  const response = await saveData(URL, body, "Save content slide ==>");

  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: false
    })
  );

  if (!response.success && response.data) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

/**
 *Get the upload status of the slide
 *
 * @param {*} id Ingest id whose status is to be checked
 */
const getSlidePollingStatus = id => async dispatch => {
  const URL = `/status/${id}`;

  const response = await FetchUtils.getData(URL, "Slide polling status");

  dispatch(
    isSlideUploading({
      isSlideUploading: true
    })
  );

  if (!response.success) {
    // stop loading
    dispatch(
      isSlideUploading({
        isSlideUploading: false
      })
    );

    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

// Stop slide polling loader
const stopSlidePolling = () => dispatch => {
  dispatch(
    isSlideUploading({
      isSlideUploading: false
    })
  );
};

/**
 *Upload slide to aws
 *
 * @param {*} presignedUrl The presigned url where the content needs to be uploaded
 * @param {*} file File object
 */
const uploadSlideToAws = (presignedUrl, file) => async dispatch => {
  const headers = {
    "Content-Type": file.type
  };

  dispatch(
    isSlideUploading({
      isSlideUploading: true
    })
  );

  const response = await FetchUtils.awsPutData(
    presignedUrl,
    file,
    headers,
    "Upload Slide to aws"
  );

  //  Stop loading if incorrect response
  if (!response.success) {
    // stop loading
    dispatch(
      isSlideUploading({
        isSlideUploading: false
      })
    );

    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const contentSlideReorder = (body, contentRepoId) => async dispatch => {
  // show loader
  dispatch(contentRepoSlideLoading({ contentSlideListLoading: true }));
  const URL = `/content-repositories/${contentRepoId}/content-slide-categories/reorder`;

  const response = await FetchUtils.patchData(
    URL,
    body,
    "Update content slide Heirarchy ==>"
  );

  // stop loader
  dispatch(
    contentRepoSlideLoading({
      contentSlideListLoading: false
    })
  );
  if (response.success && response.data) {
    //dispatch  data on success
    ToastUtils.handleToast({
      operation: "success",
      message: "List updated successfully."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }
  //return reponse to check in component if required
  return response;
};

const fetchThemeData = (
  contentRepoId,
  slideId,
  themeDetails = true,
  type = "contentSlide"
) => async dispatch => {
  const params = ConvertQueryParamsObjectToString({
    themeDetails,
    type
  });

  const URL = `/content-repository/${contentRepoId}/slides/${slideId}${
    params ? `?${params}` : ""
  }`;

  // start loading
  dispatch(
    getThemeData({
      isThemeFetching: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    getThemeData({
      isThemeFetching: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      getThemeData({
        themeData: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

// Clear theme data
const clearThemeData = () => dispatch => {
  //dispatch data list on success
  dispatch(
    getThemeData({
      themeData: []
    })
  );
};

const getFilterList = (
  contentRepoId,
  excludeEmptyParents = false
) => async dispatch => {
  const URL = `/content-repositories/${contentRepoId}/content-filters${
    excludeEmptyParents ? "?excludeEmptyParents=true" : ""
  }`;

  // start loading
  dispatch(
    filtersListLoading({
      filtersListLoading: true
    })
  );

  let response = await FetchUtils.getData(
    URL,
    "Fetch all Modules categories ==>"
  );

  // stop loading
  dispatch(
    filtersListLoading({
      filtersListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch reports data on success
    dispatch(
      filtersListSuccess({
        filters: response.data
      })
    );
  } else {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

// create contentslide heirarchy
const saveContentFilterCategory = (
  body,
  message,
  contentRepoId,
  contentCategoryId = null
) => async dispatch => {
  // start loading
  dispatch(
    saveContentCategoryLoading({
      contentFilterLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/content-categories/${contentCategoryId}`;

  const response = await FetchUtils.patchData(
    URL,
    body,
    "Update content category filter ==>"
  );

  // stop loading
  dispatch(
    saveContentCategoryLoading({
      contentFilterLoading: false
    })
  );
  if (response.success && response.data) {
    dispatch(
      contentFilterUpdateSuccess({
        contentFilterCategoryData: response.data
      })
    );
    //dispatch  data on success
    ToastUtils.handleToast({
      operation: "success",
      message: message || get(response, "data")
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  //return reponse to check in component if required
  return response;
};

export {
  updateSlideSettings,
  createContentSlideCategory,
  fetchContentSlideList,
  fetchSlideGroup,
  updateContentRepogroup,
  deleteContentRepogroup,
  searchSlideGroup,
  getSlideListOfGroup,
  deleteContentSlideCategory,
  updateContentSlide,
  contentSlideReorder,
  saveContentSlide,
  getSlidePollingStatus,
  stopSlidePolling,
  fetchThemeData,
  clearThemeData,
  uploadSlideToAws,
  getFilterList,
  saveContentFilterCategory,
  deleteContentSlide
};
