import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";

/**
 * API to track tool based dashboard visits count
 */
const trackToolDashboardCount = async (userId, tool) => {
  const URL = `/${userId}/dashboard?tool=${tool}`;
  const response = await FetchUtils.postData(
    URL,
    `Track ${tool} dashboard`
  );

  if (!response.success) {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  return response;
};

export { trackToolDashboardCount };
