/**
 *Convert date into utc format before using it in client side to remove timezone based variation
 *
 * @param {*} date Date ISO string which needs to be converted
 * @returns UTC date and time
 */

export const getUTCDate = date => {
  let timezonedDate = date || new Date();
  let parsedDate = new Date(Date.parse(timezonedDate));
  let UTCDate = new Date(
    parsedDate.getUTCMonth() +
      1 +
      "-" +
      parsedDate.getUTCDate() +
      "-" +
      parsedDate.getUTCFullYear()
  );
  return UTCDate;
};
