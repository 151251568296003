import React from "react";
import styled from "styled-components";
import SelectedButtons from "./components/selectedButtons";
import SelectedModules from "./components/selectedModules";

const ContentSummary = props => {
  const infoText = `You have currently selected ${props.selectedModules.length} modules. The
  following document type(s) represent the best fit to help you get
  started:`;

  return (
    <ContentSummaryHolder>
      <InfoText>{infoText}</InfoText>
      <SelectedButtons {...props} />
      <SelectedModules {...props} />
    </ContentSummaryHolder>
  );
};

const ContentSummaryHolder = styled.div`
  padding-top: 10px;
  width: 29.3%;
  display: inline-block;
  vertical-align: top;
`;

const InfoText = styled.p`
  opacity: 0.8;
  font-size: 14px;
  margin-bottom: 20px;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
`;

export default ContentSummary;
