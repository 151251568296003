import React from "react";
import styled from "styled-components";

import { ErrorTriangle, TickWithNoBg, ZipIcon } from "assets/icons";

import hexToRgba from "utils/hexToRgba";
import Loader from "components/loader";

//Get icon based on the polling status
const statusIcon = (item, isPollingActive) => {
  if (item.status === "Completed") {
    return <TickWithNoBg />;
  } else if (item.status === "Failed" || !isPollingActive) {
    return <FailedIndicator />;
  } else {
    return <Loader size={15} />;
  }
};

const ImageStatusInfo = ({ item, index, isPollingActive }) => {
  return (
    <ImageBlock key={index}>
      <ImageInfo>
        <IconContainer>
          <ZipIcon />
        </IconContainer>
        <FileName title={item.fileName}>{item.fileName}</FileName>
        <UploadStatusIcon>{statusIcon(item, isPollingActive)}</UploadStatusIcon>
      </ImageInfo>
      {item.message && <Error>{item.message}</Error>}
    </ImageBlock>
  );
};

export default ImageStatusInfo;

const ImageBlock = styled.div`
  margin-bottom: 14px;
`;

const ImageInfo = styled.div`
  padding: 11px 13px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.BOX_SHADOW_DARK};
`;

const FileName = styled.span`
  width: 75%;
  margin-left: 17px;
  position: relative;
  color: ${props => hexToRgba(props.theme.COLOR.HEADING, 0.7)};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconContainer = styled.span``;

const UploadStatusIcon = styled.span`
  margin-left: auto;
  position: relative;
  svg {
    width: 14px;
  }

  > div {
    position: absolute;
    top: 50%;
    right: -8px;
    left: unset;
  }
`;

const FailedIndicator = styled(ErrorTriangle)`
  width: 14px;
  height: 14px;
`;

const Error = styled.span`
  margin-left: 4px;
  color: ${props => props.theme.COLOR.ERROR};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 12px;
  font-weight: normal;
`;
