import React, { Component } from "react";
import { filter, each, get } from "lodash";
import ToastUtils from "utils/handleToast";

const UI_STRINGS = {
  userSelect: "User can select delivery method ",
  emailOnly: "Delivery by email only "
};

const container = Main =>
  class Container extends Component {
    state = {
      emailItems: [],
      methodList: [],
      isDataChanged: false,
      isEmptyInput: false,
      emailValue: ""
    };

    componentDidMount() {
      // show details of previously saved data
      this.resetDataHandler();
      this.setMethodList();
    }

    setMethodList = () => {
      let methodList = [
        {
          label: UI_STRINGS.userSelect,
          name: "deliveryMethod",
          id: "canUserSelect"
        },
        {
          label: UI_STRINGS.emailOnly,
          name: "deliveryMethod",
          id: "sendEmail"
        }
      ];
      this.setState({
        methodList
      });
    };

    handleEmailItemsList = emailItems => {
      this.setState({
        emailItems,
        isDataChanged: true
      });
    };

    /**
     * called on radio button click
     * @param {*} selectedMethod selected delivery option
     */
    deliveryMethodHandler = selectedMethod => {
      this.setState({
        activeDeliveryMethod: selectedMethod,
        isDataChanged: true
      });
    };

    /**
     * called on save button click
     *
     */
    saveDataHandler = async () => {
      let {
        activeDeliveryMethod,
        emailItems,
        isDataChanged,
        isEmptyInput,
        emailValue
      } = this.state; 
     
      let deliveryOptions =
        { ...get(this.props.systemConfigurationData, `deliveryOptions`) } || {};

      each(Object.keys(deliveryOptions), option => {
        deliveryOptions[option] = false;
      });

      let postData = {
        deliveryOptions: {
          ...deliveryOptions,
          [activeDeliveryMethod]: true
        },
        emailWhiteList: emailItems
      };

      // dont save the if email input has value
      if (!isEmptyInput && emailValue.length) {
        ToastUtils.handleToast({
          operation: "error",
          message: "Please enter a valid domain."
        });
        return;
      }

      let id = get(this.props.systemConfigurationData, `_id`);

      if (isDataChanged) {
        await this.props.saveSystemConfiguration(postData, id);
        this.props.getSystemConfiguration();

        this.setState({
          isDataChanged: false
        });
      }

    };

    /**
     * called to empty the input on cancel click
     * @param {*} emailValue value entered in email field
     */
    emptyInput = emailValue => {
      this.setState({
        isEmptyInput: false,
        emailValue
      });
    };

    resetDataHandler = () => {
      let { systemConfigurationData } = this.props;
      let { deliveryOptions = {}, emailWhiteList = [] } =
        systemConfigurationData || {};

      let activeDeliveryMethod = filter(
        Object.keys(deliveryOptions),
        option => {
          return deliveryOptions[option];
        }
      );
     
      this.setState({
        emailItems: emailWhiteList,
        activeDeliveryMethod: activeDeliveryMethod[0],
        isEmptyInput: true
      });
    };

    render() {
      const { state } = this;
      const MainProps = {
        ...state,
        ...this,
        ...this.props
      };

      return <Main {...MainProps} />;
    }
  };

export default container;
