import ClientConfig from 'config'

const coversData = [
  {
    categoryName: `About ${ClientConfig.title}`,
    covers: [
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: false
      }
    ]
  },
  {
    categoryName: "test2",
    covers: [
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      },
      {
        title: "",
        thumbnailLocation: "https://via.placeholder.com/123x84",
        fileLocation: "",
        enable: true
      }
    ]
  }
];

export default coversData;
