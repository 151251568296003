import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import {
  get
} from "lodash";

// import actions
import {
  onSearchPrioritiesSuccess,
  isSearchPrioritiesLoading
} from "./actions";

/**
 * fetch search priorities data
 */
const getSearchPriorities = () => async dispatch => {
  const URL = `/system-configuration/search-priority`;

  // start loading
  dispatch(
    isSearchPrioritiesLoading({
      isSearchPrioritiesLoading: true
    })
  );

  const response = await FetchUtils.getData(URL, "get search priorities data");

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onSearchPrioritiesSuccess({
        searchPrioritiesData: response.data
      })
    );
    // stop loading
    dispatch(
      isSearchPrioritiesLoading({
        isSearchPrioritiesLoading: false
      })
    );
  } else {
    // stop loading
    dispatch(
      isSearchPrioritiesLoading({
        isSearchPrioritiesLoading: false
      })
    );
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};


/**
 *save search priorities data
 *
 * @param {Object} body array of ids 
 */
const saveSearchPrioritiesData = (body) => async dispatch => {
  const URL = `/system-configuration/search-priority`;

  // start loading
  dispatch(
    isSearchPrioritiesLoading({
      isSearchPrioritiesLoading: true
    })
  );

  const response = await FetchUtils.patchData(URL, body, "save search priorities data");

  if (response && response.success) {
    // dispatch logo list on success
    ToastUtils.handleToast({
      operation: "success",
      message: "Search Priorities have been saved successfully.",
      autoclose: 3000
    });
    // hide loader
    dispatch(
      isSearchPrioritiesLoading({
        isSearchPrioritiesLoading: false
      })
    );
  } else {
    // hide loader
    dispatch(
      isSearchPrioritiesLoading({
        isSearchPrioritiesLoading: false
      })
    );

    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

export {
  getSearchPriorities,
  saveSearchPrioritiesData
};
