import React from "react";
import styled from "styled-components";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

import ThemeSlideDetails from "./components/themeSlideDetails";

const ThemeDetails = ({
  activeThemeDetails,
  isSlidePreviewToggleOpen,
  onSlideBarToggle,
  showContent
}) => {
  return (
    <ThemeDetailsContainer isSlidePreviewToggleOpen={isSlidePreviewToggleOpen}>
      <ThemeContentContainer
        isSlidePreviewToggleOpen={isSlidePreviewToggleOpen}
      >
        <ThemeDetailsWrapper
          isSlidePreviewToggleOpen={isSlidePreviewToggleOpen}
          showContent={showContent}
        >
          <ThemeSlideDetails activeThemeDetails={activeThemeDetails} />
        </ThemeDetailsWrapper>
      </ThemeContentContainer>
      <ToogleCtaWrapper
        onClick={onSlideBarToggle}
        isSlidePreviewToggleOpen={isSlidePreviewToggleOpen}
      >
        <ToogleCta title={isSlidePreviewToggleOpen ? "Close" : "Open"}>
          {isSlidePreviewToggleOpen ? "Close" : "Open"}
          {isSlidePreviewToggleOpen ? (
            <ToogleCtaIconWrapper>
              <MdExpandMore size={18} color="#636363" strokeWidth={0.2} />
            </ToogleCtaIconWrapper>
          ) : (
            <ToogleCtaIconWrapper>
              <MdExpandLess size={18} color="#636363" strokeWidth={0.2} />
            </ToogleCtaIconWrapper>
          )}
        </ToogleCta>
      </ToogleCtaWrapper>
    </ThemeDetailsContainer>
  );
};

export default ThemeDetails;

const ThemeDetailsContainer = styled.div`
  box-sizing: border-box;
  width: ${props => (props.isSlidePreviewToggleOpen ? "29.3%" : "50px")};
  padding-top: 11px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  transition: 0.4s width ease-in;
  height: 400px;
`;

const ThemeContentContainer = styled.div`
  height: 100%;
  position: relative;
  visibility: ${props =>
    props.isSlidePreviewToggleOpen ? "visible" : "hidden"};
`;

const ThemeDetailsWrapper = styled.div`
  box-sizing: border-box;
  height: ${props => (props.isSlidePreviewToggleOpen ? `100%` : `400px`)};
  padding: 0 28px 28px 0;
  opacity: ${props => (props.showContent ? 1 : 0)};

  span {
    display: block;
    color: ${props => props.theme.COLOR.HEADING};
    font-family: ${props => `${props.theme.FONT.REG}`};
    font-size: 12px;
  }
`;

const ToogleCtaWrapper = styled.div`
  padding: 4px 24px 4px 19px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  top: 46%;
  right: ${props => (props.isSlidePreviewToggleOpen ? -48 : -49)}px;
  transform: translateY(-50%);
  transform: rotate(90deg);
  background-color: ${props => props.theme.COLOR.LIGHT_GREY};
  cursor: pointer;
`;

const ToogleCta = styled.span`
  display: block;
  position: relative;
  font-family: ${props => `${props.theme.FONT.REG}`};
  font-size: 12px;
  font-weight: bold;
  opacity: 0.7;
  color: ${props => props.theme.COLOR.HEADING};
`;

const ToogleCtaIconWrapper = styled.span`
  display: inline-block;
  position: absolute;
  right: -18px;
  top: 0;
`;
