import React, { Component } from "react";
import { get } from "lodash";

const Container = Main =>
  class ImageEditor extends Component {
    state = {};

    componentDidMount() {
      let dimensions = get(this.props, `imageDetails.dimensions`);

      if (dimensions) {
        this.props.setDimensions(
          get(dimensions, `scale`),
          get(dimensions, `x`),
          get(dimensions, `y`)
        );
      } else {
        this.props.setDimensions(1, 0, 0, 1);
      }
    }

    componentDidUpdate(prevProps) {
      let { imageDetails } = this.props;
      if (
        JSON.stringify(get(prevProps, `imageDetails.dimensions`)) !==
        JSON.stringify(get(this.props, `imageDetails.dimensions`))
      ) {
        this.props.setDimensions(
          get(imageDetails.dimensions, `scale`),
          get(imageDetails.dimensions, `x`),
          get(imageDetails.dimensions, `y`)
        );
      }
    }

    render() {
      const { state } = this;

      const MainProps = {
        ...state,
        ...this,
        ...this.props
      };

      return <Main {...MainProps} />;
    }
  };

export default Container;
