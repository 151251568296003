import React from "react";
import CustomTable from "components/customTable";
import styled from "styled-components";

//components
import BgWrapper from "components/bgWrapper";
import Container from "./container";
import PresentationShare from "components/presentationShareForm";
import FullPageLoader from "components/FullPageLoader";
import PresentationDownload from "components/presentationDownloadForm";

const Documents = props => {
  return (
    <>
      {props.isDocumentDataLoading ? <FullPageLoader /> : null}
      {props.isShowOverlay && (
        <PresentationShare
          {...props}
          showRadioBtn
          shareDescription="Choose the format below to share the document"
        />
      )}
      {props.isPopShow && <PresentationDownload {...props} />}
      <PageWrapper>
        <CustomTable
          data={props.documentData || []}
          tableHeader={props.tableHeader}
          columnWidth={props.columnWidth}
          searchFields={props.searchFields}
          tableColumnHeader={props.tableColumnHeader}
          heading="Documents"
          renderHead={props.renderHead}
          showIcon={props.showIcon}
          expanderWidth="35"
          searchFieldName="projectName"
          handleNewButtonClick={() => {
            props.history.push("/marketing-materials/document/setup");
          }}
          role="admin" // eslint-disable-line jsx-a11y/aria-role
          defaultSorted={[
            {
              id: "updatedAt",
              desc: true
            }
          ]}
        />
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  position: relative;
  .ReactTable .rt-tbody .rt-td:last-child {
    position: relative;
    overflow: visible;
    bottom: 4px;
  }
  .ReactTable .rt-tbody .rt-tr-group {
    padding: 15.5px 0px 2.5px;
  }
  .ReactTable {
    margin-top: 66px;
  }
  .ReactTable .rt-th {
    padding: 22px 0 19px !important;
  }
  .admin-search {
    top: 122px;
  }
  .new-button {
    button {
      top: -82px;
    }
  }
  .active {
    display: block;
  }
`;

export default Container(BgWrapper(Documents));
