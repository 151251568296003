const coversList = [
  {
    title: "Lifestyle",
    _id: 12345
  },
  {
    title: "Lorem Lorem ipsus dolor…",
    _id: 123456
  },
  {
    title: "Geography",
    _id: 123457
  },
  {
    title: "Ad Campaign",
    _id: 123458
  }
];

export default coversList;
