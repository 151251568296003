//import network services
import * as services from "./services";
import { reorderedCategoryList } from "../categories/dashboard/services";

const mapStateToProps = state => {
  const {
    MODULE_LIST_SUCCESS,
    LOADING_MODULE_LIST,
    CLIENT_CONFIG_SUCCESS,
    CLIENT_CONFIG_LOADING
  } = state;
  return {
    ...MODULE_LIST_SUCCESS,
    ...LOADING_MODULE_LIST,
    ...CLIENT_CONFIG_SUCCESS,
    ...CLIENT_CONFIG_LOADING
  };
};

const actions = {
  ...services,
  reorderedCategoryList
};

export { mapStateToProps, actions };
