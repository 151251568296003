import React from "react";
import styled from "styled-components";
import BgWrapper from "components/bgWrapper";
import DummyTemplateData, { documentList, tableMetaData } from "./data";
import { get, map } from "lodash";
import Container from "./container";
import CustomTable from "components/customTable";

const summaryMakerUser = props => {
  let {
    templateClickHandler,
    tableColumnsKeys,
    tableColumns,
    columnWidth,
    renderHead,
    showIcon
  } = props;

  return (
    <PageWrapper>
      <SectionHeading>Summary Maker Templates</SectionHeading>
      <TemplateGallery>
        {map(DummyTemplateData, eachTemplateData => {
          return (
            <TemplateWrapper key={get(eachTemplateData, `_id`)}>
              <TemplateImageWrapper>
                <TemplateImage
                  src={get(eachTemplateData, "imageSrc")}
                  title={get(eachTemplateData, `description`)}
                  onClick={() => templateClickHandler(eachTemplateData)}
                />
              </TemplateImageWrapper>
              <TemplateTitle title={get(eachTemplateData, `templateName`)}>
                {get(eachTemplateData, `templateName`)}
              </TemplateTitle>
            </TemplateWrapper>
          );
        })}
      </TemplateGallery>
      <DashboardTable>
        <CustomTable
          data={documentList || []}
          columnWidth={columnWidth}
          searchFields={tableMetaData[0].searchFields}
          renderHead={renderHead}
          showIcon={showIcon}
          expanderWidth="25"
          tableColumnHeader={tableColumnsKeys}
          role="admin" // eslint-disable-line jsx-a11y/aria-role
          tableHeader={tableColumns}
          searchFieldName="name"
          defaultSorted={[
            {
              id: "name",
              desc: false
            }
          ]}
        />
      </DashboardTable>
    </PageWrapper>
  );
};

const DashboardTable = styled.div``;

const TemplateWrapper = styled.div`
  margin-right: 2%;
  cursor: pointer;
  width: 16%;
`;

const PageWrapper = styled.div`
  max-width: 1024px;
  margin: 0px auto 30px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  .new-button {
    visibility: hidden;
  }

  .admin-search {
    position: absolute;
    top: 80px;
  }

  .heading {
    margin-bottom: 86px;
    font-size: 21px;
  }

  .action-buttons {
    width: 94%;
    display: flex;
    justify-content: space-between;
  }
`;

const TemplateImageWrapper = styled.div``;

const TemplateImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  border-radius: 12px;
`;

const TemplateTitle = styled.h3`
  margin-top: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  white-space: nowrap;
`;

const SectionHeading = styled.span`
  margin-left: 40px;
  display: inline-block;
  margin-bottom: 20px;
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  color: ${props => props.theme.COLOR.MAIN};
  font-size: 21px;
  font-weight: bold;
  margin-top: 2%;
`;

const TemplateGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 42px;
`;

export default Container(BgWrapper(summaryMakerUser));
