import React, { Component, Fragment } from "react";
import styled, { keyframes, css } from "styled-components";
import hexToRgba from "utils/hexToRgba";
import ValidationUtils from "utils/ValidationUtils";
import ShadowScrollbars from "components/custom-scrollbars/ShadowScrollbars";
class MetaData extends Component {
  state = {
    isOpen: false,
    isEditing: false,
    text: "",
    error: false
  };

  inputField = React.createRef();

  handleClick = e => {
    if (!(this.props.selectedImageData || {}).folderParent) return;

    this.inputField.current && this.inputField.current.focus();

    this.setState({
      isEditing: true,
      text: ""
    });
  };

  keyDown = e => {
    if (e.keyCode === 13) {
      this.handleOnBlur(e);
    } else if (e.keyCode === 8 && !e.target.value.length) {
      e.preventDefault();
      this.props.handleDelete()(e);
    }
    this.setState({ error: false });
  };

  handleChange = e => {
    let inputText = e.target.value;
    if (ValidationUtils.checkIfspecialChar(inputText)) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false, text: inputText });
    }
  };

  // remove if array element is empty
  _validateIfEmpty = array => {
    if (array.length) {
      return array.filter(ele => ele.trim().length !== 0);
    }
  };

  handleOnBlur = e => {
    let trimmedText = e.target.value.trim();
    let commaSepratedArray = trimmedText.split(",");
    let validatedArray = this._validateIfEmpty(commaSepratedArray);
    if (this.props.addMetaData && trimmedText.length && validatedArray.length) {
      this.props.addMetaData(validatedArray);
    }

    this.setState({
      isEditing: false,
      text: ""
    });
  };

  render() {
    let {
      metaData,
      handleCopy,
      clipboard,
      handlePaste,
      handleDelete
    } = this.props;
    let { isEditing } = this.state;
    let { selectedImageData } = this.props;
    let $isClipBoardActive = clipboard.length !== 0;
    let $isMetaAvailable =
      metaData && Array.isArray(metaData) && metaData.length !== 0;
    return (
      <Fragment>
        <MetaDataWrapper
          onClick={this.handleClick}
          disbaleCheckbox={(selectedImageData || {}).folderParent}
        >
          <ShadowScrollbars
            style={{
              height: 265,
              paddingTop: 8,
              paddingLeft: 8
            }}
          >
            {$isMetaAvailable &&
              metaData.map((ele, index) => (
                <Pill key={index}>
                  <PillClose
                    title="Remove"
                    onClick={e =>
                      (selectedImageData || {}).folderParent &&
                      handleDelete(index)(e)
                    }
                    disbaleCheckbox={(selectedImageData || {}).folderParent}
                  >
                    &times;
                  </PillClose>
                  {ele}
                </Pill>
              ))}
            {!$isMetaAvailable && !isEditing && (
              <Text>
                Please click here and add comma separated values to add
                metadata.
              </Text>
            )}

            <InputBox
              ref={this.inputField}
              autoFocus={true}
              type="text"
              value={this.state.text}
              onChange={this.handleChange}
              onKeyDown={this.keyDown}
              onBlur={this.handleOnBlur}
              error={this.state.error}
            />
          </ShadowScrollbars>
        </MetaDataWrapper>
        <TextOperationWrapper>
          {$isMetaAvailable && <Copy onClick={handleCopy}>Copy All</Copy>}
          {$isClipBoardActive && <Paste onClick={handlePaste}>Paste</Paste>}
        </TextOperationWrapper>
      </Fragment>
    );
  }
}

const MetaDataWrapper = styled.div`
  overflow-y: auto;
  margin: 2px 12px 0 0;
  height: 267px;
  border-radius: 4px;
  box-sizing: border-box;
  border: solid 1px rgba(151, 151, 151, 0.6);
  cursor: ${props => (!props.disbaleCheckbox ? "not-allowed" : "text")};
`;

const Text = styled.span`
  padding-right: 8px;
  display: block;
  word-break: break-word;
  ${props => props.theme.SNIPPETS.HELPER_TEXT};
`;

const TextOperationWrapper = styled.div`
  margin-top: 5px;
  text-align: right;
`;

const highLight = keyframes`
  from {
    background-color: ${hexToRgba("#ff0000", 0.29)};
  }
  to {
    background-color: transparent;
  }
`;

const Copy = styled.span`
  margin-right: 11px;
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.7)};
  cursor: pointer;
`;

const Paste = styled.span`
  margin-right: 11px;
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.7)};
  cursor: pointer;
`;

const Pill = styled.span`
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 3px 10px 5px 20px;
  border-radius: 10px;
  position: relative;
  background-color: rgba(99, 99, 99, 0.18);
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  text-transform: capitalize;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.8)};
  font-size: 10px;
  font-weight: bold;
  cursor: default;
  word-break: break-all;
`;

const PillClose = styled.span`
  color: #000;
  position: absolute;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 18px;
  top: -3px;
  left: 7px;
  cursor: ${props => (!props.disbaleCheckbox ? "not-allowed" : "pointer")};
`;

const fadeIn = css`
  animation: ${highLight} 0.5s linear 1 alternate both;
`;
const InputBox = styled.input`
  width: 95%;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: bold;
  font-family: "Maven Pro", sans-serif;
  opacity: 0.7;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  ${props => props.error && fadeIn};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default MetaData;
