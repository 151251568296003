import React from "react";
import styled, { css } from "styled-components";
import { BulkEdit, Search, Filter } from "assets/icons";
import { Link } from "react-router-dom";

import hexToRgba from "utils/hexToRgba";
import GearModal from "components/gearModal";
import RadioBtn from "components/radioBtn";
import Checkbox from "components/checkbox";
import featureFlags from "utils/featureFlags";

/**
 * Header category component to add module/category
 * @param {Function} manageStates
 */

const CategoryDropDownContainer = ({ manageStates, ...props }) => {
  const { addNewSlide } = props;

  //module form handler for buttons
  const openModuleContainer = () => {
    manageStates({
      propName: "createNewModule",
      value: false,
      //reset form fields selected to edit if clicked to add new module
      cb: () => {
        addNewSlide && addNewSlide();
        props.resetRightPanelForm();
      }
    });
  };

  return (
    <AddButtonContainer>
      <AddButtonWrapper onClick={openModuleContainer}>
        <AddButton>Add Slide/Groups/Filters</AddButton>
      </AddButtonWrapper>
    </AddButtonContainer>
  );
};

const DividerCSS = css`
  &::after {
    content: "";
    width: 110%;
    position: absolute;
    bottom: -10px;
    left: -5%;
    border-top: 1px solid ${props => props.theme.COLOR.LINE_BREAK};
  }
`;

const AddSlide = styled.button`
  ${props => props.theme.SNIPPETS.SHARED_BUTTON_CSS};
  width: ${props => (props.divider ? "100%" : `calc(80% - 3px)`)};
  height: 30px;
  margin-bottom: ${props => (props.divider ? "20px" : 0)};
  color: ${props => props.theme.COLOR.WHITE};
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  opacity: ${props => (props.isLoading ? "0.8" : "1")};
  cursor: ${props => (props.isLoading ? "initial" : "pointer")};
  position: relative;
  ${props => props.divider && DividerCSS}
  a {
    color: ${props => props.theme.COLOR.WHITE};
    text-decoration: none;
    font-size: inherit;
  }
`;

const AddButtonContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 180px;
  position: relative;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
`;

const AddButtonWrapper = styled.ul`
  display: inline-block;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  color: ${props => props.theme.COLOR.WHITE};
  font-family: ${props => props.theme.FONT.REG};
  line-height: 38px;
  font-size: ${props => props.theme.SIZE.MAIN};
`;

const AddButton = styled.li``;

const HeaderComponent = ({
  repoTitle,
  settings,
  addActive,
  search,
  createNewModule,
  contentRepoId,
  handleCheckboxChange,
  handleInputChange,
  ...props
}) => {
  const {
    contentRepoSettings: { orderOptions, orderValue, checkbox }
  } = props;

  let settingDataValue = (
    <>
      {featureFlags.presentation.hideIntractivity && (
        <AddSlide onClick={() => {}} divider={true}>
          <Link
            to={`/presentation/admin/content-repo/${props.match.params.contentRepoId}/interactivity`}
          >
            Manage Interactivity
          </Link>
        </AddSlide>
      )}
      <RadioButtonContainer>
        {Array.isArray(orderOptions) &&
          orderOptions.map((item, index) => {
            return (
              <RadioBtn
                key={index}
                id={item.id}
                name="order"
                label={item.label}
                defaultChecked={item.id === orderValue}
                handleChange={e =>
                  handleInputChange({ key: "orderValue", value: e.target.id })
                }
              />
            );
          })}
      </RadioButtonContainer>
      <CheckBoxWrapper>
        {checkbox.map((item, index) => {
          return (
            <Checkbox
              key={index}
              id={item.id}
              label={item.label}
              defaultChecked={item.checked}
              name="interactivity"
              handleChange={e =>
                handleInputChange({
                  key: item.id,
                  value: e.target.checked
                })
              }
            />
          );
        })}
      </CheckBoxWrapper>
    </>
  );
  return (
    <Header>
      <>
        <TitleAndSearchContainer>
          {repoTitle && <Repotitle title={repoTitle}>{repoTitle}</Repotitle>}
        </TitleAndSearchContainer>
        <SubContainer>
          <Link
            to={`/presentation/admin/content-repo/${contentRepoId}/filters`}
          >
            <EditContainer title="Create/Manage Filters">
              <Filter />
            </EditContainer>
          </Link>
          <Link to={`/presentation/admin/content-repo/${contentRepoId}/themes`}>
            <EditContainer title="Manage Themes">
              <BulkEdit />
            </EditContainer>
          </Link>
          {settings && (
            <SettingsContainer>
              <GearModal
                data={settingDataValue}
                saveChanges={props.saveChangesHandler}
                onGearClickHandler={props.resetValueHandler}
              />
            </SettingsContainer>
          )}
          {addActive && (
            <CategoryDropDownContainer
              createNewModule={createNewModule}
              manageStates={props.manageStates}
              {...props}
            />
          )}
        </SubContainer>
      </>
      {search && (
        <SearchBoxWrapper>
          <SearchIcon size={20} />
          <SearchBox
            placeholder="Search..."
            onChange={props.handleRepoSearch}
            value={props.searchQueryString}
          />
        </SearchBoxWrapper>
      )}
    </Header>
  );
};

HeaderComponent.defaultProps = {
  repoTitle: "Content Repo 0.2.2",
  settings: false,
  addActive: false,
  search: false
};

const TitleAndSearchContainer = styled.div`
  float: left;
  width: 60%;
  &:after {
    content: " ";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
  @media (max-width: 1024px) {
    width: 648px;
  }
`;

const Header = styled.div`
  margin-top: 30px;
  &:after {
    content: " ";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
`;

const Repotitle = styled.h2`
  opacity: 0.9;
  font-family: "Maven Pro", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.COLOR.HEADING};
  display: inline-block;
  padding-top: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
`;

const SearchBoxWrapper = styled.div`
  width: 380px;
  display: inline-block;
  margin: 20px 0 25px;
  border-radius: 4px;
  border: solid 1px ${hexToRgba("#cbcbcb", 0.51)};
  background-color: ${hexToRgba("#e6e6e6", 0.51)};
  position: relative;
`;

const SearchBox = styled.input`
  width: 100%;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  text-indent: 38px;
  font-size: 14px;
  font-weight: normal;
  font-family: ${props => props.theme.FONT.REG};
`;

const SearchIcon = styled(Search)`
  color: ${props => props.theme.COLOR.MAIN};
  transform: translate(12px, 2px);
  position: absolute;
  top: 11px;
  left: 0;
`;

const SubContainer = styled.div`
  float: right;

  a {
    display: inline-block;
    margin-left: 20px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  svg {
    width: 40px;
    height: 40px;
    display: inline-block;
  }
`;

const SettingsContainer = styled.div`
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: pointer;
`;

const EditContainer = styled.button`
  padding: 0;
  border: none;
  display: inline-block;
  vertical-align: top;
  background: none;
  cursor: pointer;
  outline: none;
`;

const RadioButtonContainer = styled.div`
  div {
    margin-bottom: 11px;
    color: #363636;

    label {
      padding-left: 31px;
    }
  }
  .checkmark {
    box-sizing: unset;
  }
`;

const CheckBoxWrapper = styled.div`
  padding-top: 12px;
  position: relative;
  &:before {
    content: "";
    width: 110%;
    position: absolute;
    top: 0;
    left: -5%;
    border-top: 1px solid ${props => props.theme.COLOR.LINE_BREAK};
  }
  input {
    margin-right: 15px;
    vertical-align: unset;
  }
  label {
    width: calc(100% - 31px);
    margin-bottom: 12px;
    display: inline-block;
    color: ${props => props.theme.COLOR.HEADING};
    vertical-align: middle;
  }
`;

export default HeaderComponent;
