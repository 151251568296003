import React from "react";
import styled from "styled-components";

const Logo = ({ item, onLogoClick }) => {
  return (
    <LogoContainer>
      <img
        src={item}
        onClick={() => onLogoClick(item._id)}
        alt="Customer Logo"
      />
    </LogoContainer>
  );
};

Logo.defaultProps = {
  onLogoClick: () => {},
  item: {}
};

const LogoContainer = styled.figure`
  * {
    box-sizing: border-box;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    margin: auto;
    display: block;
    padding: 1px;
  }
`;

export default Logo;
