import React from "react";
import { default as ReactSelect } from "react-select";

const MultiSelect = (props = {}) => {
  let { showSelectAll, allowSelectAll, allOption, options, onChange } = props;
  if (allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={[
          ...(showSelectAll && allowSelectAll ? [allOption] : []),
          ...options
        ]}
        onChange={selected => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === allOption.value
          ) {
            return onChange(options);
          }
          return onChange(selected);
        }}
      />
    );
  }

  return <ReactSelect {...props} />;
};

MultiSelect.defaultProps = {
  allOption: {
    state: "Select all",
    value: "*"
  }
};

export default MultiSelect;
