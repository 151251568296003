import React from "react";
import { Route } from "react-router-dom";
import { DocumentBuild } from "tools/marketingMaterial/pages";
import { Documents } from "tools/marketingMaterial/pages";
/**
 * Presentation Route list
 * @param {Object} auth
 * @param {Function} checkAuthSession
 */
const MarketingMaterialRoutesComponent = (auth, checkAuthSession) => {
  const routes = (
    <>
      <Route
        exact
        path="/marketing-materials/document/setup"
        render={props =>
          checkAuthSession(<DocumentBuild auth={auth} {...props} />)
        }
      />
      <Route
        exact
        path="/marketing-materials/documents"
        render={props => checkAuthSession(<Documents auth={auth} {...props} />)}
      />
      <Route
        exact
        path="/marketing-materials/document/setup/:documentId"
        render={props =>
          checkAuthSession(<DocumentBuild auth={auth} {...props} />)
        }
      />
    </>
  );

  return routes;
};

export default MarketingMaterialRoutesComponent;
