import React, { Component } from "react";
import ValidationUtils from "utils/ValidationUtils";

const container = Main =>
  class Container extends Component {
    state = {
      email: {
        value: "",
        error: ""
      }
    };

    emailInput = React.createRef();

    emailInputHandler = e => {
      let { email } = this.state;
      let { emptyInput } = this.props;
      email.value = e.target.value;

      emptyInput && emptyInput(e.target.value);
      this.setState({
        email
      });
    };

    componentDidUpdate(prevProps) {
      if (prevProps.isEmptyInput !== this.props.isEmptyInput) {
        this.setState({
          email: {
            value: ""
          }
        });
      }
    }

    handleInputKeyDown = e => {
      let newItem = e.target.value.trim();

      let { validateFor, emptyInput } = this.props;
      let { email } = this.state;
      let emailItems = [];

      // increase input size as value inside it increases
      this.emailInput.current.style.width = this.emailInput.current.value.length
        ? `${this.emailInput.current.value.length * 10 + 20}px`
        : `20px`;

      // if enter is pressed
      if (e.keyCode === 13 && newItem.length) {
        let validValueCheck;
        if (validateFor === "domain") {
          validValueCheck = ValidationUtils.validateDomainName(newItem);
        } else {
          validValueCheck = ValidationUtils.validateEmail(newItem);
        }

        if (validValueCheck) {
          this.emailInput.current.value = "";
          email.value = "";
          email.error = "";
          emptyInput && emptyInput(email.value);

          emailItems = [...this.props.emailItems, newItem];

          this.setState(
            {
              email
            },
            () => {
              this.props.handleEmailItemsList(emailItems);
            }
          );
        } else {
          email.error = `Please enter a valid ${validateFor}`;

          this.setState({
            email
          });
        }
      }

      // if backspace is clicked
      if (e.keyCode === 8 && !newItem.length) {
        emailItems = this.props.emailItems.slice(
          0,
          this.props.emailItems.length - 1
        );
        this.props.handleEmailItemsList(emailItems);
      }
    };

    handleRemoveItem = index => {
      let emailItems = this.props.emailItems.filter((item, i) => i !== index);
      this.props.handleEmailItemsList(emailItems);
    };

    render() {
      const { state } = this;

      const MainProps = {
        ...state,
        ...this,
        ...this.props
      };

      return <Main {...MainProps} />;
    }
  };

export default container;
