import React from "react";
import styled from "styled-components";

//components
import ModuleList from "./components/moduleList";
import Sidebar from "./components/sidebar";

const MainContainer = props => {
  return (
    <MainContentContainer>
      <ModuleContainer>
        <ModuleList {...props} />
      </ModuleContainer>
      <SideBarContainer>
        <Sidebar {...props} />
      </SideBarContainer>
    </MainContentContainer>
  );
};

export default MainContainer;

const MainContentContainer = styled.div``;

const ModuleContainer = styled.div`
  width: 69.25%;
  border-radius: 4px;
  margin-right: 0.7%;
  display: inline-block;
  vertical-align: top;
  background-color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.BOX_SHADOW_PRESENTATION}
  overflow: hidden;
`;

const SideBarContainer = styled.div`
  width: 30%;
  min-height: 746px;
  padding: 20px;
  border-radius: 4px;
  display: inline-block;
  vertical-align: top;
  background-color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.BOX_SHADOW_PRESENTATION}
`;
