//import network services
import * as services from "./components/announcement/services";

const mapStateToProps = state => {
  const {
    SUCCESS_USER_PROFILE,
    SUCCESS_ANNOUNCEMENT,
    LOADING_ANNOUNCEMENT
  } = state;
  return {
    ...SUCCESS_USER_PROFILE,
    ...SUCCESS_ANNOUNCEMENT,
    ...LOADING_ANNOUNCEMENT
  };
};

const actions = {
  ...services
};

export {
  mapStateToProps,
  actions
};
