import React, { Component } from "react";
import { get, remove, pullAllBy } from "lodash";

import ToastUtils from "utils/handleToast";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import uniqId from "uniqid";

const UI_STRINGS = {
  GROUP_ERROR_MESSAGE: "Cannot move slides into the middle of a locked group."
};

const container = Main =>
  class Sort extends Component {
    state = {
      selectedLayout: "thumbnail",
      selectedTab: "dividers",
      slidesInEachRow: 4,
      allowExternalDrop: false,
      selectedList: [],
      clearIndex: false
    };

    sideBarRef = React.createRef();
    thumbnailLayoutRef = React.createRef();

    componentDidMount() {
      this.getSlidesData();
      window.addEventListener("scroll", this.scrollHandler);
    }

    componentDidUpdate(prevProps) {
      let {
        selectedSlidesListDetail,
        buildSetupDetails,
        coverDetails
      } = this.props;
      // Check if selectedSlidesData was updated and reformat the slide list data
      if (
        selectedSlidesListDetail !== prevProps.selectedSlidesListDetail ||
        buildSetupDetails !== prevProps.buildSetupDetails ||
        coverDetails._id !== prevProps.coverDetails._id
      ) {
        this.getSlidesData();
      }
    }

    componentWillUnmount() {
      let { selectedSlidesListDetail } = this.props;
      this.props.resetNewlyAddedSlides(selectedSlidesListDetail);
      window.removeEventListener("scroll", this.scrollHandler);
    }

    // Sticky sidebar handler
    scrollHandler = () => {
      let { isSidebarSticky } = this.state;
      // Run JavaScript stuff here
      let sideBarHeight = get(this.sideBarRef, "current.clientHeight") || 0;

      let thumbnailLayoutHeight =
        get(this.thumbnailLayoutRef, "current.clientHeight") || 0;

      let scrollPosition = Math.round(window.scrollY);
      let isSticky = scrollPosition > 255;
      if (
        isSidebarSticky !== isSticky &&
        thumbnailLayoutHeight > sideBarHeight
      ) {
        this.setState({
          isSidebarSticky: isSticky
        });
      }
    };

    /**
     *Callback for list update after drag and drop
     *
     */
    onListUpdate = listData => {
      this.setState({
        slideList: listData,
        selectedList: []
      });
      this.props.onPresentationEdit(listData);
    };

    /**
     *Drag over callback for the draggable element
     *@param {String} index Index position where the element is supposed to be injected
     */
    handleExternalDragOver = index => {
      let { newSlideIndex } = this.state;
      if (newSlideIndex !== index) {
        this.setState({
          newSlideIndex: index
        });
      }
    };

    /**
     * Callback function for on drop event of the an external draggable element
     * This function is called when any external element is dragged and drop over the draggable element and also allowExternalDrop flag is set true
     */
    onNewSlideDrop = () => {
      let { newSlideIndex, draggedElement } = this.state;
      let { maximumSlideCount, addRequiredSlidesSlides } = this.props;
      let oldItemData = [...this.state.slideList];

      // With Required Slides
      let withRequiredSlides = addRequiredSlidesSlides(oldItemData);

      if (maximumSlideCount && maximumSlideCount < withRequiredSlides.length) {
        // Show error message popup
        ToastUtils.handleToast({
          operation: "error",
          message: `You have reached a limit of ${maximumSlideCount} slides.`
        });
        return null;
      }

      let newUniqueId = uniqId();

      // Save the id of divider as ref Id to not add duplicate id data
      let newDraggableElement = {
        ...draggedElement,
        refId: draggedElement._id,
        _id: newUniqueId,
        isDraggable: true
      };

      // Check if first element in the group
      let isFirstGroupEle =
        oldItemData[newSlideIndex - 1] &&
        get(oldItemData[newSlideIndex - 1], "group.groupId") ===
          get(oldItemData[newSlideIndex], "group.groupId");

      // Check if the element is dropped between a group
      if (get(oldItemData[newSlideIndex], "isGrouped") && isFirstGroupEle) {
        // Show error message popup
        ToastUtils.handleToast({
          operation: "error",
          message: UI_STRINGS.GROUP_ERROR_MESSAGE,
          autoClose: 2000
        });
        return null;
      }

      // Insert the new slide in array
      oldItemData.splice(newSlideIndex, 0, newDraggableElement);
      // Update List
      this.onListUpdate(oldItemData);
      this.setState({
        allowExternalDrop: false,
        draggedElement: {},
        clearIndex: true
      });
    };

    // clear data on dragsort container when newSlide is dragged in
    setClearIndex = () => {
      this.setState({
        clearIndex: false
      });
    };

    /**
     * Callback function for on drag end event of the an external draggable element
     * This function is called when any external element is dragged and then stopped
     */
    onNewSlideDragEnd = () => {
      this.setState({
        allowExternalDrop: false
      });
    };

    /**
     *On selected a element for multiple drag and drop
     *
     */
    onSelectedChild = list => {
      let { selectedSlidesListDetail } = this.props;
      this.setState({
        selectedList: list
      });
      this.props.resetNewlyAddedSlides(selectedSlidesListDetail);
    };

    /**
     *On drag event of the new slides selector elements
     *@param {Object} event The event Object
     *@param {Number} index Index of the dragged element
     */
    onNewSlideDrag = (event, index) => {
      let { selectedTab, allowExternalDrop } = this.state;

      if (!allowExternalDrop) {
        this.setState({
          draggedElement: this.props[selectedTab][index],
          allowExternalDrop: true,
          newSlideIndex: null
        });
      }
    };

    /**
     *  Get slides list data
     */
    getSlidesData = () => {
      let {
        selectedSlidesListDetail,
        addRequiredSlidesSlides,
        formatSlidesForDragAndDrop
      } = this.props;

      // With Required Slides
      let withRequiredSlides = addRequiredSlidesSlides(
        selectedSlidesListDetail
      );

      let formattedList = formatSlidesForDragAndDrop
        ? formatSlidesForDragAndDrop(withRequiredSlides)
        : withRequiredSlides;

      this.setState({
        slideList: formattedList
      });
    };

    /**
     *  Callback for change of thumbnail size change range slider
     *  The value in param will be between 0 -100.
     * @param {Array} value An array of the cuurent thumb position.
     */
    onRangeSliderChange = value => {
      this.setThumbnailSize(value[0]);
    };

    /**
     * Set state to maintain thumbnail size on the basis of the range slider
     * @param {String} size Size of the range 0-100.
     */
    setThumbnailSize = size => {
      let getSlidesInEachRow = size => {
        switch (size) {
          case 0:
            return 10;
          case 20:
            return 5;
          case 40:
            return 4;
          case 60:
            return 3;
          case 80:
            return 2;
          case 100:
            return 1;
          default:
            return 5;
        }
      };
      this.setState({
        slidesInEachRow: getSlidesInEachRow(size)
      });
    };

    /**
     * Callback for selected layout
     * @param {Object} selectedLayout Consist the name, value, icon of the select layout
     *
     */
    onLayoutSelect = selectedLayout => {
      this.setState({
        slidesInEachRow: 4,
        selectedLayout: selectedLayout.value
      });
    };

    /**
     * Handle slide selector tab selection
     * @param {String} selectedTab value of the selected tab
     *
     */
    handleSlideSelectorTab = selectedTab => {
      this.setState({ selectedTab: selectedTab.value });
    };

    /**
     * Handle Group Slide delete
     *
     * @param {*} data The deleted slide data
     */
    onDeleteGroupSlide = data => {
      let oldSlideList = [...this.state.slideList];
      // Alert popup
      DeleteConfirmationAlert({
        message:
          "Deleting a grouped slide will remove all the slides in the group. Do you want to continue?",
        onYesClick: () => {
          let groupSetId = get(data, "group.groupSet");
          // mutates oldSlideList to create an array by removing any duplicate element in groupSetId
          pullAllBy(oldSlideList, groupSetId, "_id");
          this.onListUpdate(oldSlideList);
        }
      });
    };

    /**
     * Handle Single Slide delete
     *
     * @param {*} data The deleted slide data
     */
    onDeleteSlide = data => {
      let oldSlideList = [...this.state.slideList];
      let isGrouped = get(data, "isGrouped");
      let isRequired = get(data, "isRequired");

      // Do not do delete operation if required slide
      if (isRequired) return;

      if (isGrouped) {
        this.onDeleteGroupSlide(data);
      } else {
        remove(oldSlideList, ele => ele._id === data._id);
        this.onListUpdate(oldSlideList);
      }
    };

    /**
     *Handle slide preview
     *
     * @param {*} data Slide preview
     */
    onPreviewSlide = ({ index }) => {
      let { onPreview } = this.props;

      onPreview && onPreview(index);
    };

    render() {
      let propsForSort = {
        UI_STRINGS,
        ...this,
        ...this.props,
        ...this.state
      };
      return <Main {...propsForSort} />;
    }
  };

export default container;
