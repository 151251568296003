import React from "react";
import styled, { css } from "styled-components";

//generate admin module repo header
const GenerateAdminModulesTab = () => {
  return (
    <TableColumns>
      <CategoryName>Name</CategoryName>
      <CreatedDate>Created Date</CreatedDate>
    </TableColumns>
  );
};

const header = props => {
  return (
    <CreateContentTabs>
      <GenerateAdminModulesTab />
    </CreateContentTabs>
  );
};

// Admin Module Header Styled components
const LabelSharedCSS = css`
  margin-top: 3px;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  opacity: 0.64;
  outline: none;
  border: none;
  background: transparent;
  display: inline-block;
  vertical-align: top;
  text-align: left;
`;

const CategoryName = styled.span`
  ${LabelSharedCSS}

  margin-left: 15px;
`;

const CreatedDate = styled.span`
  ${LabelSharedCSS}
  position: absolute;
  right: 196px;

  @media screen and (max-width: 1024px) {
    right: 205px;
  }
`;

const CreateContentTabs = styled.div`
  min-height: 58px;
  max-height: 64px;
  background: #fff;
  position: relative;
  z-index: 1;
  padding: 17px 4% 17px 40px;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  &:after {
    content: " ";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
`;

const TableColumns = styled.div`
  width: 100%;
  position: relative;
`;

export default header;
