import { Paint, ConfigRate, Cart } from "assets/icons";

export const UI_STRINGS = {
  PROJECT_NAME: "Project Name",
  CLIENT_NAME: "Client Name",
  DOCUMENT_TITLE: "Document Title",
  CLIENT_LOGO: "Client Logo",
  EMPTY_FIELD_ERROR_MESSAGE: "This field is required.",
  SPECIAL_CHAR_ERROR_MESSAGE: "Please do not enter the special character.",
  WHITE_SPACE_ERROR_MESSAGE: "Please enter a valid input.",
  UNSAVED_BUILD_ERROR: "Please save your document before proceeding.",
  BUILD_ERROR: "Build could not be completed. Please try again.",
  UNSAVED_PROMPT_ERROR:
    "You haven't saved your progress. Hitting refresh or back will lose your work.",
  POLLING_PROMPT_ERROR: "Build is in progress",
  DOCUMENT_TYPE_CHANGE_MESSAGE:
    "All your data will be lost. Do you want to continue?"
};

const state = {
  activeStep: 0,
  stepItems: [
    {
      title: "Document Setup",
      src: ConfigRate
    },
    {
      title: "Layout",
      src: Paint
    },
    {
      title: "Build",
      src: Cart
    }
  ],
  buildSetupDetails: [
    {
      key: "projectName",
      label: UI_STRINGS.PROJECT_NAME,
      type: "text",
      editable: true,
      value: "",
      error: "",
      required: true,
      hint: false,
      isEdited: false
    },
    {
      key: "clientName",
      label: UI_STRINGS.CLIENT_NAME,
      type: "text",
      editable: true,
      value: "",
      error: "",
      required: true,
      hint: false,
      isEdited: false
    },
    {
      key: "documentTitles",
      label: UI_STRINGS.DOCUMENT_TITLE,
      type: "text",
      editable: true,
      value: "",
      error: "",
      required: false,
      hint: true,
      isEdited: false
    }
  ],
  selectedOption: null,
  documentTypeOption: [],
  templateTypes: {},
  isPopupOpen: false,
  templatePreviewSource: null,
  clientLogoImageData: {
    editImageDimensions: {
      left: "",
      right: "",
      x: "",
      y: ""
    },
    dataUri: "",
    isEdited: false
  },
  // layout variables
  selectedTabId: "thumbnails",
  moduleList: [],
  modulePreviewData: [],
  selectedPlaceholder: {},
  selectedLayoutType: "",
  layoutData: [],
  chooseContent: false,
  matchModules: [],
  selectedDoctype: [],
  selectedModules: [],
  chooseContentData: [],
  preExapandedFlag: false,
  randomKey: "",
  documentId: "",
  isDocumentEdited: false,
  moduleEdited: false,
  pageLayoutEdited: false,
  catagorySearchResults: [],
  isSearchActive: false,
  showAllModules: false,
  originalBodyObject: {},
  userDetail: null,
  preExpandedCatArray: [],
  layoutSpecificModuleResults: [],
  activeModuleFilter: "layoutSpecificModule",
  activePreviewModuleIndex: 0,
  isDataFilledOnEdit: true,
  documentByChooseContent: false,
  searchAndLayoutSpecificModules: [],
  searchValue: "",
  options: [
    {
      label: "PowerPoint (.PPTX)",
      id: "ppt"
    },
    {
      label: "PDF",
      id: "pdf"
    },
    {
      label: "Zip",
      id: "zip"
    }
  ],
  buildValue: "ppt",
  buildProgress: {
    status: "InProgress"
  },
  isBuilding: false,
  showProgressModal: false,
  tempStepToSetActive: 0,
  tabClicked: false,
  userId: "",
  documentData: {},
  completedSteps: [],
  isDocumentEditFlow: false,
  isDocumentTypeEdited: false,
  hidePreviouslySelectedAccItem: false,
  isChooseModulesEdited: false,
  isModuleChanged: false
};

export default state;
