import React from "react";
import { Route } from "react-router-dom";

import Setup from "tools/presentation/pages/admin/setup";
import History from "tools/presentation/pages/admin/history";
import Filters from "tools/presentation/pages/filters";

import {
  ContentSlides,
  Dashboard,
  Theme,
  Interactivity,
  Layouts
} from "tools/presentation/pages/admin/contentRepo";

// image folder
import ImageFolder from "tools/presentation/pages/admin/imageFolder";

import featureFlags from "utils/featureFlags";
import { get } from "lodash";

/**
 * Admin Routes list
 * @param {Object} auth
 * @param {Function} checkAuthSession
 */
const adminRoutes = (auth, checkAuthSession) => {
  const routes = (
    <>
      <Route
        exact
        path="/presentation/admin/content-repo"
        render={props => checkAuthSession(<Dashboard auth={auth} {...props} />)}
      />
      <Route
        exact
        path="/presentation/admin/setup"
        render={props => checkAuthSession(<Setup auth={auth} {...props} />)}
      />
      {get(featureFlags, "presentation.showAdminLayouts") && (
        <Route
          exact
          path="/presentation/admin/content-repo/layouts"
          render={props => checkAuthSession(<Layouts auth={auth} {...props} />)}
        />
      )}
      {featureFlags.presentation.hideIntractivity && (
        <Route
          exact
          path="/presentation/admin/content-repo/:contentRepoId/interactivity"
          render={props =>
            checkAuthSession(<Interactivity auth={auth} {...props} />)
          }
        />
      )}
      <Route
        exact
        path="/presentation/admin/content-repo/:contentRepoId/content-slides"
        render={props =>
          checkAuthSession(<ContentSlides auth={auth} {...props} />)
        }
      />
      <Route
        exact
        path="/presentation/admin/content-repo/:contentRepoId/themes"
        render={props => checkAuthSession(<Theme auth={auth} {...props} />)}
      />
      {get(featureFlags, "presentation.showFilter") && (
        <Route
          exact
          path="/presentation/admin/content-repo/:contentRepoId/filters"
          render={props => checkAuthSession(<Filters auth={auth} {...props} />)}
        />
      )}
      <Route
        exact
        path="/presentation/admin/history"
        render={props => checkAuthSession(<History auth={auth} {...props} />)}
      />
      <Route
        exact
        path="/presentation/admin/images"
        render={props =>
          checkAuthSession(<ImageFolder auth={auth} {...props} />)
        }
      />
    </>
  );
  return routes;
};

export default adminRoutes;
