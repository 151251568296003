import React, { PureComponent } from "react";

const Container = Main =>
  class SortableTree extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {};
      this.selectedParent = [];
    }

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      };

      return <Main {...stateMethodProps} />;
    }
  };

export default Container;
