import React, { Component, Fragment } from "react";
import container from "./container";
import styled from "styled-components";
import Folder from "../sortableTree";
import { MoveFolder } from "assets/icons";

class FolderTooltip extends Component {
  render() {
    let {
      folderData,
      folderRef,
      onAddNewFolder,
      isVisible,
      onSelected,
      handleFolderPopup
    } = this.props;

    return (
      <Fragment>
        {isVisible && (
          <MoveFolderWrapper onClick={handleFolderPopup("close")} />
        )}
        <StyledMoveFolder
          title="Move to Folder"
          onClick={handleFolderPopup("open")}
        />
        <MoveWrapper ref="btn" isVisible={isVisible}>
          <TooltipContainer isVisible={isVisible}>
            <Folder
              isDraggable={false}
              imageFolder={folderData}
              ref={folderRef}
              onSelected={onSelected}
              onAddNewFolder={onAddNewFolder}
              {...this.props}
            />
          </TooltipContainer>
        </MoveWrapper>
      </Fragment>
    );
  }
}

export default container(FolderTooltip);

const StyledMoveFolder = styled(MoveFolder)`
  cursor: pointer;
`;

const TooltipContainer = styled.div`
  padding: 10px 0;
  width: 292px;
  height: 315px;
  background-color: #fff;
  position: absolute;
  z-index: 18;
  left: 10px;
  top: -30px;
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.14);
  display: ${props => (props.isVisible ? "block" : "none")};

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #fff;
    position: absolute;
    top: 10px;
    left: -11px;
  }
`;

const MoveFolderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 17;
`;

const MoveWrapper = styled.div`
  border: 0;
  padding: 0;
  display: inline-block;
  background-color: transparent;
  text-align: left;
  position: relative;

  &:focus {
    outline: 0;
  }

  &:focus {
    ${TooltipContainer} {
      display: ${props => props.isVisible && "block"};
    }
  }
`;
