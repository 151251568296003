import React from "react";
import styled from "styled-components";

//Components
import Checkbox from "components/checkbox";
import CoOrdinates from "./coordinates";
import RadioBtn from "components/radioBtn";

const Logo = props => {
  const {
    title,
    checkbox,
    instruction,
    radios = [],
    coordinates = {},
    setAlignmentDetails,
    activeAlignment,
    setLogoOptions,
    onChangeInput,
    disableCobrand,
    selectedContentRepo
  } = props;

  return (
    <SectionDetail>
      <Title>{title}</Title>
      {!!(checkbox || []).length &&
        checkbox.map(({ label, defaultChecked, id, type }, index) => {
          return (
            <Checkbox
              key={id}
              label={label}
              id={id}
              checked={defaultChecked}
              handleChange={() =>
                setLogoOptions({ type, index, defaultChecked })
              }
            />
          );
        })}
      <DisableWrapper className={disableCobrand ? "disable" : ""}>
        {instruction && <InstructionalText>{instruction}</InstructionalText>}
        {!!(radios || []).length && (
          <RadioButtonContainer>
            {radios.map(({ label, defaultChecked, name, id }, index) => {
              return (
                <RadioBtn
                  key={id}
                  id={id}
                  name={name}
                  label={label}
                  defaultChecked={defaultChecked}
                  handleChange={e => setAlignmentDetails(e, index)}
                />
              );
            })}
          </RadioButtonContainer>
        )}
        {activeAlignment === 0 && (
          <CoOrdinates
            coordinatesValue={radios[0]}
            radios={radios}
            coordinates={coordinates}
            onChangeInput={onChangeInput}
            selectedContentRepo={selectedContentRepo}
          />
        )}
        {activeAlignment === 1 && (
          <CoOrdinates
            coordinatesValue={radios[1]}
            radios={radios}
            coordinates={coordinates}
            onChangeInput={onChangeInput}
          />
        )}
      </DisableWrapper>
    </SectionDetail>
  );
};

export default Logo;

//styles
const DisableWrapper = styled.div`
  position: relative;
  &.disable {
    &:before {
      width: calc(100% + 5.7% + 5.7% + 16px);
      height: 118%;
      content: "";
      position: absolute;
      z-index: 10;
      top: -10px;
      left: calc(-5.7% - 8px);
      background: rgba(0, 0, 0, 0.3);
    }
  }
`;

const RadioButtonContainer = styled.div`
  margin-bottom: 20px;
  display: inline-block;
  vertical-align: top;
  div {
    margin-bottom: 10px;
  }
`;

const SectionDetail = styled.div`
  padding: 27px 5.7% 30px;
  border-radius: 4px;
  margin: 30px;
  ${props => props.theme.SNIPPETS.BOX_SHADOW_PRESENTATION}
  .checkbox-container {
    margin-bottom: 14px;
  }
`;

const Title = styled.h3`
  margin-bottom: 20px;
`;

const InstructionalText = styled.p`
  margin: 20px 0;
  font-family: ${props => props.theme.FONT.REG};
  font-size: 10px;
  color: ${props => props.theme.COLOR.HEADING};
`;
