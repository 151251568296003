import React from "react";
import styled from "styled-components";

//Components
import InputField from "../../InputField";

const CoOrdinates = ({ coordinates, coordinatesValue, onChangeInput }) => {
  const { values = {} } = coordinatesValue || {};

  return (
    <>
      {!!Object.keys(coordinates || {}).length && (
        <CoordinatesContainer>
          <CoordinateBox>
            {!!(coordinates.min || []).length && (
              <InputContainer>
                {coordinates.min.map(
                  ({ id, name, label, type, value, error }, index) => (
                    <InputBox key={index}>
                      <InputField
                        id={id}
                        name={name}
                        label={label}
                        type={type}
                        value={(values.min[name] || {}).value || 0}
                        onChange={e =>
                          onChangeInput({ e, id, min: true, name })
                        }
                      />
                      {(values.min[name] || {}).error && (
                        <ErrorValue>{values.min[name].error}</ErrorValue>
                      )}
                    </InputBox>
                  )
                )}
              </InputContainer>
            )}
            {!!(coordinates.max || []).length &&
              coordinates.max.map(
                ({ id, name, label, type, unit, error }, index) => (
                  <InputBox top={"20px"} key={index}>
                    <InputField
                      value={(values.max[name] || {}).value || 0}
                      id={id}
                      name={name}
                      label={label}
                      type={type}
                      onChange={e => onChangeInput({ e, id, max: true, name })}
                    />
                    <Unit>{unit}</Unit>
                    {(values.max[name] || {}).error && (
                      <ErrorValue>{values.max[name].error}</ErrorValue>
                    )}
                  </InputBox>
                )
              )}
          </CoordinateBox>
        </CoordinatesContainer>
      )}
    </>
  );
};

export default CoOrdinates;

//styles
const ErrorValue = styled.span`
  font-size: 10px;
  font-family: ${props => props.theme.FONT.REG};
  color: ${props => props.theme.COLOR_PALLETE.ERROR};
  position: absolute;
  white-space: nowrap;
`;

const CoordinatesContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 15%;
`;

const CoordinateBox = styled.div`
  flex-basis: 45.6%;
  flex-wrap: wrap;

  &:last-of-type {
    margin-left: 8.7%;
  }
`;

const InputBox = styled.div`
  position: relative;
  margin-top: ${props => props.top || 0};
`;

const Unit = styled.span`
  position: absolute;
  right: -20px;
  bottom: 2px;
  font-weight: 900;
`;

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  div {
    margin-top: 0;
    flex-basis: 44.2%;

    &:nth-of-type(2) {
      margin-left: 11.5%;
    }
  }
`;
