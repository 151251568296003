import React, { Component } from "react";
import styled from "styled-components";
import { map, get } from "lodash";

import hexToRgba from "utils/hexToRgba";
import featureFlags from "utils/featureFlags";
import {
  FiltersContainer,
  UploadSlideContainer,
  DescriptionContainer,
  EnableLabelNameOnEdit,
  SlideDropDown,
  ListedTheme
} from "./components";
import ShadowScrollbars from "components/custom-scrollbars/ShadowScrollbars";
import GroupOptionContainerMain from "../GroupOptionContainerMain";
import CheckBox from "components/checkbox";

class SlidesContainer extends Component {
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }
  state = {
    enableNameInput: false
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.editedSlide._id !== prevProps.editedSlide._id &&
      get(this.sidebarRef, "current.refs.scrollbars")
    ) {
      this.sidebarRef.current.refs.scrollbars.scrollToTop();
    }

    // set container scroll top when filter is open
    if (
      this.props.scrollValue &&
      prevProps.scrollValue !== this.props.scrollValue
    ) {
      let scrollVal =
        this.sidebarRef.current.refs.scrollbars.getScrollTop() + 300;
      this.sidebarRef.current.refs.scrollbars.scrollTop(scrollVal);
    }
  }

  /**
   * Method to manage states within this component
   */
  manageSlideComponentStates = ({ propName, value }) => {
    this.setState({
      [propName]: value
    });
  };

  render() {
    /** Extract states and props */
    const {
      currentSelectedSlide,
      manageStates,
      enableNameInput,
      saveLevelTitleOnEdit,
      handleInputChange,
      onDropDownChange,
      emptyState,
      onSlideSave,
      onLayoutUpload,
      slideFormValidations,
      dropDownOptionsNew,
      onRequiredClick,
      themeData,
      themeListData,
      handleFile,
      isBuilding,
      handleUploadClick,
      removeFile,
      filterListData,
      handleFilterChange,
      selectedOption,
      slideName,
      slideParent,
      slideDescription,
      slideAuthor,
      slideUpload,
      onMenuOpen,
      onMenuClose
    } = this.props;

    /**
     * Data to manage input enable/disable
     */
    const manageNameInput = {
      propName: "enableNameInput",
      value: !enableNameInput
    };

    const LabelComponentProps = {
      manageStates,
      manageNameInput,
      currentSelectedSlide,
      saveLevelTitleOnEdit,
      handleInputChange,
      title: slideFormValidations.name.value || ""
    };

    const LayoutUploadProps = {
      onLayoutUpload,
      slideFormValidations,
      handleUploadClick,
      removeFile
    };

    const DescComponentProps = {
      limit: 250,
      handleInputChange,
      value: slideFormValidations.description.value || ""
    };

    return (
      <SliderFormContainer>
        <ShadowScrollbars
          scrollcontenttotop={"yes"}
          style={{ height: 400 }}
          ref={this.sidebarRef}
        >
          <SlidesComponent>
            {/* Slide/Level Name */}
            <LabelGroup ref={slideName}>
              <Label noCursor={true}>Name*</Label>
              <LabelNameContainer>
                <EnableLabelNameOnEdit {...LabelComponentProps} />
                {slideFormValidations.name.errorMessage && (
                  <ErrorContainer>
                    {slideFormValidations.name.errorMessage}
                  </ErrorContainer>
                )}
              </LabelNameContainer>
            </LabelGroup>

            {/* Slide/Level Required */}
            <LabelGroup>
              <CheckBox
                label="Required"
                name="required"
                handleChange={e => onRequiredClick(e)}
                checked={get(slideFormValidations, "required.value", false)}
              />
            </LabelGroup>

            {/* Parent Slide Drop Down */}
            <LabelGroup ref={slideParent}>
              <SlideDropDown
                parentSlide={true}
                option={[
                  { title: "Please select a parent", _id: 0 },
                  ...map(dropDownOptionsNew, option => {
                    return {
                      title:
                        `${Array(option.level + 1).join("--")}` + option.title,
                      _id: option._id
                    };
                  })
                ]}
                elemId={"parent"}
                onDropDownChange={onDropDownChange}
                selectedValue={slideFormValidations.parent.value}
              />

              {slideFormValidations.parent.errorMessage && (
                <ErrorContainer>
                  {slideFormValidations.parent.errorMessage}
                </ErrorContainer>
              )}
            </LabelGroup>
            {!dropDownOptionsNew.length ? (
              <CategoryNotPresentText
                error={slideFormValidations.parent.errorMessage}
              >
                <Note>Note:</Note> You may need to create a new level on the
                left to start.
              </CategoryNotPresentText>
            ) : null}
            {/* Slide Description */}
            <LabelGroup description={true} ref={slideDescription}>
              <Label htmlFor="description">Description</Label>
              <DescriptionContainer {...DescComponentProps} />
              {slideFormValidations.description.errorMessage && (
                <ErrorContainer>
                  {slideFormValidations.description.errorMessage}
                </ErrorContainer>
              )}
            </LabelGroup>

            {/* Slide Author */}
            <LabelGroup ref={slideAuthor}>
              <Label htmlFor={"author"}>Author*</Label>
              <AuthorInputWrapper>
                <AuthorInput
                  onChange={e =>
                    handleInputChange({
                      slideValue: e.target.value,
                      slideLabel: "author"
                    })
                  }
                  id={"author"}
                  value={slideFormValidations.author.value || ""}
                />
              </AuthorInputWrapper>
              {slideFormValidations.author.errorMessage && (
                <ErrorContainer>
                  {slideFormValidations.author.errorMessage}
                </ErrorContainer>
              )}
            </LabelGroup>

            {/* Group Manager */}
            <LabelGroup description={true}>
              <Label htmlFor="group">Group</Label>
              <GroupContainer>
                <GroupOptionContainerMain {...this.props} />
              </GroupContainer>
            </LabelGroup>

            {/* Upload Manager */}
            <LabelGroup ref={slideUpload}>
              <Label htmlFor="upload">Upload*</Label>
              <UploadSlideContainer {...LayoutUploadProps} />
              {slideFormValidations.upload.errorMessage && (
                <ErrorContainer>
                  {slideFormValidations.upload.errorMessage}
                </ErrorContainer>
              )}
            </LabelGroup>

            {/* Filter Manager */}
            {featureFlags.presentation.showFilter &&
              (filterListData.length ? (
                <LabelGroup description={true}>
                  <Label filters={true}>Filters</Label>
                  <FiltersContainer
                    filterContent={filterListData}
                    handleFilterChange={handleFilterChange}
                    selectedOption={selectedOption}
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                  />
                </LabelGroup>
              ) : null)}

            {/* Link download/preview */}
            {themeListData &&
            themeData &&
            themeData.length &&
            themeListData.length ? (
              <LabelGroup>
                <Label>Themes</Label>
                <ListedTheme
                  themeListData={themeListData}
                  handleFile={handleFile}
                />
              </LabelGroup>
            ) : null}
          </SlidesComponent>
        </ShadowScrollbars>
        <SlideAddContainer>
          <AddSlide
            onClick={() => !isBuilding && onSlideSave()}
            disable={isBuilding}
          >
            Save
          </AddSlide>
          <CancelSlide onClick={emptyState}>Cancel</CancelSlide>
        </SlideAddContainer>
      </SliderFormContainer>
    );
  }
}

FiltersContainer.defaultProps = {
  filterContent: [
    {
      "Line of Business": ["Select Slide", "Slide 1", "Slide 2"]
    },
    {
      Audiance: ["Select Slide", "Slide 1", "Slide 2"]
    },
    {
      "Slides cycle": ["Select Slide", "Slide 1", "Slide 2"]
    }
  ]
};

const SliderFormContainer = styled.div`
  position: relative;

  .slide-scrollbar {
    > div > div {
      overflow-x: hidden !important;
    }
  }
`;

const SlidesComponent = styled.div`
  font-family: ${props => props.theme.FONT.REG};
  padding: 16px 0 17px 12px;
  margin-right: 15px;
`;

const LabelGroup = styled.div`
  padding-top: ${props => (props.description ? "15px" : "12px")};
  position: relative;

  .checkbox-container {
    padding-top: 4px;
  }
`;

const Label = styled.label`
  font-size: ${props => (props.filters ? "12px" : "10px")};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.64)};
  cursor: ${props => (props.noCursor ? "auto" : "pointer")};
`;

const LabelNameContainer = styled.div`
  position: relative;
`;

const AuthorInputWrapper = styled.div`
  margin-top: 7px;
`;

const AuthorInput = styled.input`
  width: 100%;
  height: 30px;
  padding: 2px 10px;
  outline: none;
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.4);
  font-family: ${props => props.theme.FONT.LATO};
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.8)};
`;

const SlideAddContainer = styled.div`
  width: 100%;
  padding: 30px 10px 20px;
`;

const AddSlide = styled.button`
  width: calc(50% - 4px);
  height: 40px;
  border-radius: 4px;
  border: none;
  outline: none;
  color: ${props => props.theme.COLOR.WHITE};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  ${props => props.disable && `pointer-events: none;`}
  cursor: pointer;
`;

const CancelSlide = styled.button`
  width: calc(50% - 4px);
  height: 40px;
  margin-left: 6px;
  background: transparent;
  font-family: ${props => props.theme.FONT.REG};
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  border: solid 1px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  cursor: pointer;
`;

const ErrorContainer = styled.span`
  font-size: 10px;
  font-family: ${props => props.theme.FONT.REG};
  color: ${props => props.theme.COLOR_PALLETE.ERROR};
  position: absolute;
  left: 0;
`;

const GroupContainer = styled.div`
  padding-top: 8px;
  font-family: "Maven Pro", sans-serif;
`;

const CategoryNotPresentText = styled.p`
  margin-top: ${props => (props.error ? "16px" : "8px")};
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};
  font-size: 10px;
`;

const Note = styled.span`
  color: ${props => props.theme.COLOR.HEADING};
  font-weight: bold;
  opacity: 0.9;
`;

export default SlidesContainer;
