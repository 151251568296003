import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";

import { onContentRepoListSuccess, isContentRepoListLoading } from "./actions";

/**
 * Get Content repositories list
 *
 */

const getContentRepoList = (flag = false) => async dispatch => {
  const URL = flag
    ? `/content-repositories?includesContentFilter=true:`
    : `/content-repositories`;

  // start loading
  dispatch(
    isContentRepoListLoading({
      isLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  // stop loading
  dispatch(
    isContentRepoListLoading({
      isLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onContentRepoListSuccess({
        contentRepoList: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const createAndEditContentRepo = (
  body,
  id = null,
  messageData = ""
) => async dispatch => {
  const URL = id ? `/content-repositories/${id}` : `/content-repositories`;
  dispatch(
    isContentRepoListLoading({
      isLoading: true
    })
  );

  let response;
  if (id) {
    response = await FetchUtils.patchData(URL, body, "post content repo data");
  } else {
    response = await FetchUtils.postData(URL, body, "patch content repo data");
  }

  if (response.success && response.data) {
    // stop loading
    dispatch(
      isContentRepoListLoading({
        isLoading: false
      })
    );
    ToastUtils.handleToast({
      operation: "success",
      message: `${
        messageData ? messageData : "Content repo has been saved successfully."
      }`
    });
  } else {
    dispatch(
      isContentRepoListLoading({
        isLoading: false
      })
    );

    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

const cloneContentRepo = contentRepoId => async dispatch => {
  const URL = `/content-repositories/${contentRepoId}`;
  dispatch(
    isContentRepoListLoading({
      isLoading: true
    })
  );

  let response = await FetchUtils.postData(URL, "clone content repo");

  if (response.success && response.data) {
    // stop loading
    dispatch(
      isContentRepoListLoading({
        isLoading: false
      })
    );
    ToastUtils.handleToast({
      operation: "success",
      message: "Content repo has been cloned successfully."
    });
  } else {
    dispatch(
      isContentRepoListLoading({
        isLoading: false
      })
    );

    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

/**
 * Delete content repo
 * @param {String} contentRepoId id of the content repo to be deleted
 */

const deleteContentRepo = contentRepoId => async dispatch => {
  const URL = `/content-repositories/${contentRepoId}`;
  dispatch(
    isContentRepoListLoading({
      isLoading: true
    })
  );

  let response = await FetchUtils.deleteData(URL, "clone content repo");
  // stop loading
  dispatch(
    isContentRepoListLoading({
      isLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: "Content repo has been deleted successfully."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

export {
  getContentRepoList,
  createAndEditContentRepo,
  cloneContentRepo,
  deleteContentRepo
};
