import React, { Component } from "react";
import { downloadFile } from 'utils/download'
const Container = Main => 
  class presentationDownloadForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        format:'pdf'
      };
    }
   
    //function to handle onchange of radio button 
    handleChange = (id) => {
      this.setState({
        format:id
      })
    }

    // function to handle download button click
    formHandler = () => {
      const { format } = this.state;
      const { pdfLocation,pptLocation,zipLocation } = this.props.selectedPresentation || {};
      
      if(format === "pdf") {
        downloadFile(pdfLocation.url);
      }
      else if(format === "ppt") {
        downloadFile(pptLocation.url);
      }
      else if(format === "zip"){
        downloadFile(zipLocation.url)
      }
    }

    render() {
      const $this = this;

      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      };
      return <Main {...stateMethodProps} />;
    }
  };

  export default Container;
