import React from "react";
import Content from "./components/content";
import ContentSummary from "./components/contentSummary";

const ChooseContent = props => {
  return (
    <>
      <Content {...props} />
      {props.selectedModules.length ? <ContentSummary {...props} /> : null}
    </>
  );
};

export default ChooseContent;
