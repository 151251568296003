import React from "react";
import styled from "styled-components";
import { MdFolder } from "react-icons/md";
import { FaFolderOpen } from "react-icons/fa";

const CategoryTitleComponent = ({
  _id,
  title,
  children,
  imageFolderDetails,
  handleFolderSelection,
  selectedFolder
}) => {
  let isChildrenExist = Array.isArray(children) && children.length;

  // class to highlight selected folder
  let selectedClass = selectedFolder === _id ? "elem-selected" : "";

  return (
    <>
      <TitleComp
        key={_id}
        title={title}
        tabIndex={0}
        className={selectedClass}
        onClick={() => {
          handleFolderSelection(_id);
        }}
      >
        {!isChildrenExist ? (
          (imageFolderDetails || {})._id === _id ? (
            <FaFolderOpen className="svg-icon" />
          ) : (
            <MdFolder className="svg-icon" />
          )
        ) : null}
        <TiltleHolder>{title}</TiltleHolder>
      </TitleComp>
    </>
  );
};

const TitleComp = styled.span`
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  font-size: 12px;
  outline: none;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
  .svg-icon {
    position: absolute;
    top: -1px;
    left: -21px;
  }

  &.elem-selected {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const TiltleHolder = styled.span`
  width: 78%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
`;
export default CategoryTitleComponent;
