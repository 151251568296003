import React from "react";
import { Route } from "react-router-dom";

// App Components
import Reports from "pages/reports";

/**
 * Users Routes list
 * @param {Object} auth
 * @param {Function} checkAuthSession
 */
const reportRoutes = (auth, checkAuthSession) => {
  const routes = (
    <>
      <Route
        exact
        path="/admin/reports"
        render={props => checkAuthSession(<Reports auth={auth} {...props} />)}
      />
    </>
  );

  return routes;
};

export default reportRoutes;
