import React, { useState } from "react";
import Moment from "react-moment";
import { get } from "lodash";
import styled from "styled-components";
import hexToRgba from "utils/hexToRgba";
import { getUTCDate } from "utils/handleDate";

const AnnouncementList = props => {
  let { announcement, updateAnnouncementHandler,pageNumber,announcementLimit } = props;
  const [showAnnouncement, setShowAnnouncement] = useState(
    props.selectedId === get(announcement, "_id")
  );
 
  return (
    <ShowMoreLessWrapper
      onClick={() =>
        get(announcement, `announcementState`) === "Unread" &&
        updateAnnouncementHandler(get(announcement, "_id"),announcementLimit,pageNumber)
      }
    >
      <AnnouncementHeader
        onClick={() => {
          setShowAnnouncement(!showAnnouncement);
        }}
      >
        <AnnouncementTitle
          announcementStatus={
            get(announcement, `announcementState`) === "Unread"
          }
          title={get(announcement, `title`)}
        >
          {get(announcement, `title`)}
        </AnnouncementTitle>
        <AnnouncementDate>
          <Moment format="MM/DD/YYYY">{getUTCDate(get(announcement, `date`))}</Moment>
        </AnnouncementDate>
      </AnnouncementHeader>
      {showAnnouncement && (
        <AccouncementBody
         {...props}
          ref={ref => ref && props.showAllContent(ref)}
          dangerouslySetInnerHTML={{
            __html: get(announcement, `description`)
          }}
        />
      )}
    </ShowMoreLessWrapper>
  );
};

export default AnnouncementList;

const AnnouncementHeader = styled.div`
  cursor: pointer;
`;

const ShowMoreLessWrapper = styled.div`
  padding: 20px 0 12px;

  border-bottom: 1px solid ${props => props.theme.COLOR_PALLETE.SEPARATOR};
  &:last-child {
    border-bottom: 0;
  }

  > a {
    margin-top: 13px;
  }

  ul {
    list-style-type: initial;
    list-style-position: inside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  .clipped-content {
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
`;

const AnnouncementDate = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => hexToRgba(props.theme.COLOR.HEADING, 0.9)};
  float: right;
  margin-top: 5px;
  font-size: 16px;
`;

const AnnouncementTitle = styled.h3`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props =>
    props.announcementStatus
      ? props.theme.COLOR.USER_PRIMARY
      : hexToRgba(props.theme.COLOR.HEADING, 0.5)};
  display: inline-block;
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 20px;
  text-decoration: underline;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const AccouncementBody = styled.p`
  width: 100%;
  font-family: ${props => props.theme.FONT.LATO};
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  word-break: break-all;
  overflow: hidden;
  letter-spacing: normal;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  text-align: left;
  opacity: 0.67;

  ul {
    ul {
      margin-left: 20px;
      list-style-type: circle;
    }
  }

  ol {
    ol {
      margin-left: 20px;
      list-style-type:number;
    }
  }
`;

 