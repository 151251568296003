import FetchUtils from "utils/FetchUtils.js";
import ToastUtils from "utils/handleToast";
import ConvertQueryParamsObjectToString from "utils/queryString";
import {
  get
} from "lodash";

// import report actions
import {
  categorySuccess,
  categoryLoading
} from "./action";

/**
 * Fetch all categories
 */
const getCategoriesData = () => async dispatch => {
  const params = ConvertQueryParamsObjectToString({
    field: "categories"
  });

  // start loading
  dispatch(categoryLoading({
    categoryDataLoading: true
  }));

  const URL = `/marketing-materials/module-categories${
      params ? `?${params}` : ""
    }`,
    response = await FetchUtils.getData(URL, "Fetch all categories ==>");

  // stop loading
  dispatch(categoryLoading({
    categoryDataLoading: false
  }));

  if (response.success && response.data) {
    //dispatch reports data on success
    dispatch(
      categorySuccess({
        categoriesData: response.data
      })
    );
  } else {
    // stop loading
    dispatch(categoryLoading({
      categoryDataLoading: false
    }));

    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Fetch all document types with respective pagelayouts.
 */
const getDocumentTypes = () => async dispatch => {
  // start loading
  dispatch(categoryLoading({
    categoryDataDocumentLoading: true
  }));

  const URL = `/marketing-materials/document-types/pagelayouts`,
    response = await FetchUtils.getData(
      URL,
      "Fetch all document types with respective pagelayouts ==>"
    );

  // stop loading
  dispatch(categoryLoading({
    categoryDataDocumentLoading: false
  }));

  if (response.success && response.data) {
    //dispatch reports data on success
    dispatch(
      categorySuccess({
        categoriesDocumentData: response.data
      })
    );
  } else {
    // stop loading
    dispatch(categoryLoading({
      categoryDataDocumentLoading: false
    }));

    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Create presentation
 */
const createCategory = (body, catId) => async dispatch => {
  const URL = `/marketing-materials/module-categories/${catId}`;

  // start loading
  dispatch(
    categoryLoading({
      isCategoryCreateLoading: true
    })
  );

  let response;

  if (catId) {
    response = await FetchUtils.patchData(URL, body, "Create presentation");
  } else {
    response = await FetchUtils.postData(URL, body, "Create presentation");
  }

  if (response.success && response.data) {
    // stop loading
    dispatch(
      categoryLoading({
        isCategoryCreateLoading: false
      })
    );

    const successMessage = catId ?
      "Category has been updated successfully." :
      "Category has been created successfully.";

    // Show success message on successfull category creation
    ToastUtils.handleToast({
      operation: "success",
      message: successMessage
    });
  } else {
    // stop loading
    dispatch(
      categoryLoading({
        isCategoryCreateLoading: false
      })
    );
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

const fetchSpecificCategory = id => async dispatch => {
  // start loading
  dispatch(categoryLoading({
    categoryListLoading: true
  }));

  const URL = `/marketing-materials/module-categories/${id}`,
    response = await FetchUtils.getData(URL, "Fetch specific categories ==>");

  // stop loading
  dispatch(categoryLoading({
    categoryListLoading: false
  }));

  if (response.success && response.data) {
    //dispatch reports data on success
    dispatch(
      categorySuccess({
        currentCategoryData: response.data
      })
    );
  } else {
    // stop loading
    dispatch(categoryLoading({
      categoryListLoading: false
    }));

    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

export {
  getCategoriesData,
  getDocumentTypes,
  createCategory,
  fetchSpecificCategory
};
