import FetchUtils from "utils/FetchUtils.js";
import ToastUtils from "utils/handleToast";

// import report actions
import {
  categoryDataSuccess,
  categoryDataLoading
} from "./action";

/**
 * Fetch all categories
 */
const getCategoriesList = () => async dispatch => {
  // start loading
  dispatch(categoryDataLoading({
    categoryDataLoading: true
  }));

  const URL = `/marketing-materials/admin/modules`,
    response = await FetchUtils.getData(URL, "Fetch all categories ==>");

  // stop loading
  dispatch(categoryDataLoading({
    categoryDataLoading: false
  }));

  if (response.success && response.data) {
    //dispatch reports data on success
    dispatch(
      categoryDataSuccess({
        categoryList: response.data
      })
    );
  } else {
    // stop loading
    dispatch(categoryDataLoading({
      categoryDataLoading: false
    }));

    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Delete category
 */
const deleteCategory = catId => async dispatch => {
  // start loading
  dispatch(categoryDataLoading({
    categoryDeleteLoading: true
  }));

  const URL = `/marketing-materials/module-categories/${catId}`,
    response = await FetchUtils.deleteData(URL, "Fetch all categories ==>");

  // stop loading
  dispatch(categoryDataLoading({
    categoryDeleteLoading: false
  }));

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: "Record has been successfully deleted."
    });
  } else {
    // stop loading
    dispatch(categoryDataLoading({
      categoryDeleteLoading: false
    }));

    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

const reorderedCategoryList = body => async dispatch => {
  // start loading
  dispatch(categoryDataLoading({
    categoryDeleteLoading: true
  }));

  const URL = `/marketing-materials/module-categories/reorder`,
    response = await FetchUtils.patchData(
      URL,
      body,
      "Reorder categories and sub categories ==>"
    );

  // stop loading
  dispatch(categoryDataLoading({
    categoryDeleteLoading: false
  }));

  if (response.success) {
    //dispatch reports data on success
    ToastUtils.handleToast({
      operation: "success",
      message: "List updated successfully."
    });
  } else {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

export {
  getCategoriesList,
  deleteCategory,
  reorderedCategoryList
};
