import React from "react";
import styled from "styled-components";
import Container from "./container";
//components
import Stepper from "components/buildProcess/stepper";
import DocumentSetup from "./components/documentSetup";
import Layout from "./components/layout";
import Build from "./components/build";
import { Prompt } from "react-router";
import FullPageLoader from "components/FullPageLoader";
import ProgressPopup from "components/progressPopup";
// switch component based on step value
const SwitchComponentByStep = props => {
  const { activeStep } = props;

  // switch activeStep based on value
  switch (activeStep) {
    case 0:
      return <DocumentSetup {...props} />;
    case 1:
      return <Layout {...props} />;
    case 2:
      return <Build {...props} />;
    default:
      return null;
  }
};

const PlacematComponent = props => {
  const { activeStep, nextStepHandler, isDocumentEdited, isBuilding } = props;
  let promptMsg = isBuilding
    ? "Build is in progress"
    : "You haven't saved your progress. Hitting refresh or back will lose your work.";

  return (
    <>
      {props.pagelayoutsLoading ||
      props.usersModulesLoading ||
      props.documentDataLoading ? (
        <FullPageLoader layerIndex={21} />
      ) : null}
      {props.isDocumentLoading && (
        <ProgressPopup isPopupOpen={props.isDocumentLoading} text="Saving..." />
      )}
      {(isDocumentEdited || isBuilding) && (
        <Prompt when={isDocumentEdited || isBuilding} message={promptMsg} />
      )}
      <Stepper {...props} modifyStep={nextStepHandler} />
      <PlacematContainer>
        <SwitchComponentByStep activeStep={activeStep} {...props} />
      </PlacematContainer>
    </>
  );
};

const PlacematContainer = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  width: ${props => props.theme.WRAPPER.WIDTH};
  margin: 0 auto;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  @media (max-width: 1024px) {
    width: calc(100% - 80px);
  }
`;

export default Container(PlacematComponent);
