import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";

import { onAnnouncementSuccess, isAnnouncementLoading } from "./actions";
import { ConvertQueryParamsArrayObjectToString } from "utils/queryString";

const getAnnouncementList = (limit, page, userId) => async dispatch => {
  const params = ConvertQueryParamsArrayObjectToString([
    {
      paginate: true
    },
    {
      limit
    },
    {
      page
    }
  ]);

  const URL = `/${userId}/announcements/?${params}`;

  // start loading
  dispatch(
    isAnnouncementLoading({
      isAnnouncementLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  // stop loading
  dispatch(
    isAnnouncementLoading({
      isAnnouncementLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onAnnouncementSuccess({
        announcementList: response
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * @param {String} userId user id
 * @param {String} announcementId id of the announcement to be updated
 */
const updateAnnouncement = (userId, announcementId) => async dispatch => {
  const URL = `/${userId}/announcements/${announcementId}`;
  const response = await FetchUtils.patchData(URL, {});

  if (!response.success) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const clearAnnouncementList = () => async dispatch => {
  dispatch(
    onAnnouncementSuccess({
      announcementList: {}
    })
  );
};

export { getAnnouncementList, updateAnnouncement, clearAnnouncementList };
