/**
 * Client configuration file:
 * Imports Client Details
 * 1. Company Logo Url
 * 2. Favicon Url
 * 3. Description
 * 4. Support Email
 * 5. Primary and Secondary Color
 * 6. Company Hero Image
 * 7. SiteName
 * 7. Password Reset Heading
 * This file can be imported and used, for any client specific data/details.
 *
 * Note: This file will be remove when technical admin has been configured to setup client details
 *
 */

/**
 * Initiated with default values to determine what properties the `clientConfig.json` should be having.
 */
let clientConfig = {
  qbe: {
    getClientConfig: false,
    passwordResetPageHeading: "",
    favicon: ""
  },
  uhc: {
    getClientConfig: true,
    color: {
      primaryColor: "",
      secondaryColor: ""
    },
    companyHeroImage: {
      dimensions: { height: 0, width: 0, x: 0, y: 0 },
      location: ""
    },
    companyLogo: {
      dimensions: { height: 0, width: 0, x: 0, y: 0 },
      location: ""
    },
    description: "",
    siteName: "",
    supportEmail: "",
    passwordResetPageHeading: "",
    logo: "",
    favicon: ""
  }
};

try {
  /**
   * Create a `clientConfig.json` with the above properties in the config folder
   */
  clientConfig = require("./clientConfig.json");
} catch (err) {
  console.log("Error In Importing Client Config File");
  console.log(err);
}

export default clientConfig;
