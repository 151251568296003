import React, { Component } from "react";
import { each, map } from "lodash";

const container = Main =>
  class Container extends Component {
    state = {
      listData: [],
      isListDragged: false
    };

    componentDidMount() {
      this.resetSearchPrioritiesData();
    }

    /**
     * called on every drag and drop
     * @param {*} searchOptions new updated list data
     */
    onListUpdate = searchOptions => {
      this.setState({
        searchOptions,
        isListDragged: true
      });
    };

    // reset the search priorities list
    resetSearchPrioritiesData = () => {
      let searchOptions = this.props.searchPrioritiesData;

      // add key isDraggable to all the object inside array
      each(searchOptions, option => {
        option.isDraggable = true;
      });

      this.setState({
        searchOptions
      });
    };

    onSaveSearchPrioritiesData = async () => {
      let { searchOptions, isListDragged } = this.state;

      let searchPriority = map(searchOptions, option => {
        return option._id;
      });

      // only save data if the list has been dragged
      if (isListDragged) {
        await this.props.saveSearchPrioritiesData({ searchPriority });
        this.setState({
          isListDragged: false
        });
        this.props.getSearchPriorities();
      }
    };

    render() {
      const { state } = this;

      const MainProps = {
        ...state,
        ...this,
        ...this.props
      };

      return <Main {...MainProps} />;
    }
  };

export default container;
