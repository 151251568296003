//Lib
import React from "react";
import styled from "styled-components";

//Components

import StepDetails from "components/buildProcess/stepDetails";
import { BuildOption } from "./components/BuildOption";
import { buildMarketingDoucment } from "assets/images";
import ProgressPopup from "components/buildProcess/progressPopup";
import { get } from "lodash";

const Build = props => {
  let {
    buildValue,
    documentBuildHandler,
    changeHandler,
    editHandler,
    downloadHandler,
    checkIfCompleted,
    isBuilding,
    buildProgress,
    selectedOption
  } = props;

  const UI_STRINGS = {
    BUILD_INCOMPLETE: "Choose the format below and click Build to export.",
    BUILD_INPROGRESS: "Building your document",
    BUILD_COMPLETED: `Your ${selectedOption && selectedOption.value.toLowerCase()} has been created. Please download it here.`
  };

  // Check if build is complete
  let isBuildComplete = checkIfCompleted(buildValue);

  let isBuildProgress = isBuilding && !isBuildComplete;
  // Presentation Build Options
  let option = props.options.map(build => {
    return (
      <BuildOption
        labelValue={build.id === "zip" ? "Both" : build.label}
        idValue={build.id}
        selected={buildValue === build.id}
        key={build.id}
        changedValue={changeHandler}
      />
    );
  });

  return (
    <BuildWrapper>
      <StepDetails
        title="Build"
        description={isBuildComplete ? UI_STRINGS.BUILD_COMPLETED :UI_STRINGS.BUILD_INCOMPLETE}
        _edit="Edit"
        _download={!!isBuildComplete}
        _build={!!!isBuildComplete}
        onBuild={documentBuildHandler}
        onEdit={editHandler}
        onDownload={downloadHandler}
      />
      <MainContainer>{option}</MainContainer>
      <ProgressPopup
        show={isBuildProgress}
        gifSrc={buildMarketingDoucment}
        width={get(buildProgress, "percentage")}
        message={UI_STRINGS.BUILD_INPROGRESS}
        status={get(buildProgress, "status")}
      />
    </BuildWrapper>
  );
};

const BuildWrapper = styled.div``;

const MainContainer = styled.div`
  margin-top: 40px;

  .form-group {
    margin-bottom: 10px;
    color: ${props => props.theme.COLOR.HEADING};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default Build;
