import React from "react";
import styled from "styled-components";

import hexToRgba from "utils/hexToRgba";

import InputField from "./components/inputField";
import Button from "components/button";

const ImageFileUpload = props => {
  let {
    uploadFileDetails: { uploadInputDetails },
    skipToDownload,
    activeIngestId
  } = props;

  let checkIfReupload = uploadInputDetails[0].hideDropdown;
  const UI_STRINGS = {
    NOTE:
      "Please either complete or skip your previous incomplete bulk upload process to upload a new zip file."
  };
  return (
    <ImageFileUploadContainer>
      <Title>Upload Images</Title>
      <Form>
        {uploadInputDetails.map((item, index) => {
          let { label, type, options, value, error, hideDropdown } = item;
          return !hideDropdown ? (
            <InputContainer key={index}>
              <InputField
                placeholder={label}
                type={type}
                handleChange={props.handleInputChange}
                error={error || null}
                value={value}
                option={options}
                handleFileChange={props.handleFileChange}
                reupload={checkIfReupload}
                {...props}
              />
            </InputContainer>
          ) : null;
        })}
        <SaveCtaContainer>
          <Button
            text="Upload"
            width="100%"
            float="none"
            onClick={props.onSavehandler}
          />
          {activeIngestId && (
            <NoteContainer>
              <Note>Note: </Note>
              {UI_STRINGS.NOTE}
            </NoteContainer>
          )}
          {activeIngestId && (
            <Skip
              title="Already uploaded a .ZIP file of your images? Click here to upload your excel file with the image details."
              onClick={skipToDownload}
            >
              Already uploaded a .ZIP file of your images?
              <ExcelLink>Click here</ExcelLink> to upload your excel file with
              the image details.
            </Skip>
          )}
        </SaveCtaContainer>
      </Form>
    </ImageFileUploadContainer>
  );
};

export default ImageFileUpload;

const ImageFileUploadContainer = styled.div`
  padding: 0 20px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: ${props => hexToRgba(props.theme.COLOR.HEADING, 0.7)};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 20px;
  font-weight: bold;
`;

const Form = styled.div`
  .container {
    > p {
      margin-bottom: 5px;
    }
  }
`;

const NoteContainer = styled.div`
  padding: 9px 0;
  background-color: ${props => props.theme.COLOR.WHITE};
  text-align: left;
  font-size: 12px;
`;

const Note = styled.span`
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  font-weight: bold;
`;

const InputContainer = styled.div``;

const SaveCtaContainer = styled.div`
  margin-top: 30px;
  text-align: center;
`;

const Skip = styled.span`
  margin: 20px auto 0;
  display: inline-block;
  color: ${props => hexToRgba(props.theme.COLOR.HEADING, 0.7)};
  text-align: center;
`;

const ExcelLink = styled.span`
  color: ${props => props.theme.COLOR.BROWN_GREY};
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;
