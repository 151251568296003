import React, { Component, Fragment } from "react";
import styled from "styled-components";
import hexToRgba from "utils/hexToRgba";
class Checkbox extends Component {
  render() {
    let {
      label,
      value,
      isChecked,
      count,
      isDark,
      handleChange,
      selectedImageData
    } = this.props;
    return (
      <List>
        <Clickable disbaleCheckbox={(selectedImageData || {}).folderParent}>
          <Fragment>
            <StyledCheckbox
              type="checkbox"
              name={value}
              onChange={handleChange}
              checked={!!isChecked}
              disbaleCheckbox={(selectedImageData || {}).folderParent}
            />
            <Label isDark={isDark}>
              {label} {count && `(${count})`}
            </Label>
          </Fragment>
        </Clickable>
      </List>
    );
  }
}

const Clickable = styled.label`
  width: 100%;
  display: inline-block;
  cursor: ${props => (!props.disbaleCheckbox ? "not-allowed" : "pointer")};
`;

const List = styled.li`
  margin-top: 9px;
  box-sizing: border-box;
  list-style: none;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
`;

const Label = styled.span`
  width: calc(100% - 30px);
  display: inline-block;
  box-sizing: border-box;
  margin-left: 13px;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  color: ${props =>
    props.isDark
      ? hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.7)
      : props.theme.COLOR_PALLETE.BROWNISH_GREY};
  font-weight: ${props => (props.isDark ? "bold" : "normal")};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledCheckbox = styled.input`
  margin: 0;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #979797;
  border-radius: 1px;
  position: relative;
  vertical-align: top;
  &:focus {
    outline: none;
  }
  &:after {
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    left: -1px;
    top: -1px;
    color: #fff;
    text-align: center;
    cursor: ${props => (!props.disbaleCheckbox ? "not-allowed" : "pointer")};
  }
  &:checked::after {
    content: "✔";
    color: #fff;
    font-size: 12px;
  }
  &:checked {
    background-color: ${props => props.theme.COLOR_PALLETE.COOL_BLUE};
    border: 1px solid ${props => props.theme.COLOR_PALLETE.COOL_BLUE};
  }
  -webkit-appearance: none;
`;

export default Checkbox;
