import React from "react";
import styled, { css } from "styled-components";
import { InputBox } from "./inputComponents";
import CheckBox from "components/checkbox";
import ReactHintFactory from "react-hint";
import "react-hint/css/index.css";
import { FaInfoCircle } from "react-icons/fa";
import { includes } from "lodash";
import MultiSelect from "../../MultiSelect.jsx";

const ReactHint = ReactHintFactory(React);

/**
 * render hint on hover
 */
const onRenderContent = (target, content) => {
  return (
    <div className="custom-hint__content">
      <span>{content}</span>
    </div>
  );
};

const UserDetails = props => {
  let {
    firstName,
    lastName,
    email,
    password,
    repeatPassword,
    roles,
    emailAccess,
    stateListValue
  } = props.form;
  let { rolesList, checkAvailableRoles, accessibleTools } = props;

  // Is renewal tool access
  let isRenewal = includes(accessibleTools, "RG");

  // Is presentation tool access
  let isPresentation = includes(accessibleTools, "PG");

  return (
    <UserDetailsWrapper>
      <ReactHint events onRenderContent={onRenderContent} />
      <InputBoxWrapper>
        <InputBox
          type="text"
          name="firstName"
          id="firstName"
          label="First Name*"
          maxLength={50}
          hintData={"First name should not be more than 50 characters."}
          value={firstName.value}
          inputChangeHandler={props.manageInputStates}
        />
        {firstName.error && <ErrorMessage>{firstName.error}</ErrorMessage>}
      </InputBoxWrapper>
      <InputBoxWrapper>
        <InputBox
          type="text"
          name="lastName"
          hintData={"Last name should not be more than 50 characters."}
          id="lastName"
          maxLength={50}
          label="Last Name*"
          value={lastName.value}
          inputChangeHandler={props.manageInputStates}
        />
        {lastName.error && <ErrorMessage>{lastName.error}</ErrorMessage>}
      </InputBoxWrapper>
      <InputBoxWrapper>
        <InputBox
          type="text"
          name="email"
          id="email"
          label="Email*"
          value={email.value}
          inputChangeHandler={props.manageInputStates}
        />
        {email.error && <ErrorMessage>{email.error}</ErrorMessage>}
      </InputBoxWrapper>
      <InputBoxWrapper>
        <InputBox
          type="password"
          name="password"
          id="password"
          hintData={
            "Password must contain at least 8 characters, including Uppercase, Lowercase and Special Character(!@#$%^&*)."
          }
          disabled={!!props.editedUserId}
          label="Password*"
          value={props.editedUserId ? "" : password.value}
          inputChangeHandler={props.manageInputStates}
        />

        {password.error && <ErrorMessage>{password.error}</ErrorMessage>}
      </InputBoxWrapper>
      <InputBoxWrapper>
        <InputBox
          type="password"
          name="repeatPassword"
          id="repeatPassword"
          label="Repeat Password*"
          disabled={!!props.editedUserId}
          value={props.editedUserId ? "" : repeatPassword.value}
          inputChangeHandler={props.manageInputStates}
        />
        {repeatPassword.error && (
          <ErrorMessage>{repeatPassword.error}</ErrorMessage>
        )}
      </InputBoxWrapper>

      <RadioButtonContainer>
        <RadioWrapperLabel>Role*</RadioWrapperLabel>
        {rolesList.map((item, index) => {
          if (!checkAvailableRoles(item.accessTo)) return null;
          return (
            <CheckboxWrapper key={index}>
              <CheckBox
                name={item.id}
                label={item.label}
                id={item.id}
                type="roleCheckbox"
                handleChange={e =>
                  props.manageInputStates(e, {
                    propName: item.id,
                    value: e.target.checked,
                    type: "roleCheckbox",
                    label: item.id,
                    keyName: "roles"
                  })
                }
                checked={includes(roles.value, item.id)}
              />
              <IconWrapper>
                <StyledInfoIcon
                  size={12}
                  data-rh={item.hint}
                  data-rh-at="left"
                />
              </IconWrapper>
            </CheckboxWrapper>
          );
        })}
      </RadioButtonContainer>
      {roles.error && <ErrorMessage>{roles.error}</ErrorMessage>}
      {isRenewal && (
        <MultiSelectWrapper>
          <StateListLabel>State*</StateListLabel>
          <MultiSelect
            value={props.stateListArray ? props.stateListArray : []}
            onChange={props.selectMultipleOption}
            options={props.stateList}
            isMulti={true}
            placeholder={"Select States"}
            getOptionLabel={option => option.state}
            getOptionValue={option => option._id}
            closeMenuOnSelect={false}
            className="react-select-container"
            classNamePrefix="react-select"
            maxMenuHeight={150}
            allowSelectAll={true}
            showSelectAll={
              !!!(
                (props.stateListArray || []).length ===
                (props.stateList || []).length
              )
            }
          />
          {stateListValue.error && (
            <ErrorMessage>{stateListValue.error}</ErrorMessage>
          )}
        </MultiSelectWrapper>
      )}
      <EmailCheckboxWrapper>
        <CheckBox
          label="Email access to user upon saving"
          id="access"
          name="emailAccess"
          type="accessCheckbox"
          handleChange={e => {
            props.manageInputStates(e, {
              propName: "emailAccess",
              value: e.target.checked,
              type: "accessCheckbox",
              label: "Email access to user upon saving"
            });
          }}
          checked={emailAccess.value}
        />
      </EmailCheckboxWrapper>
      <ButtonWrapper className={isPresentation && "next-cta"}>
        <AddButton
          onClick={
            isPresentation
              ? props.onNext
              : props.isEdit
              ? props.saveEditedUser
              : props.addNewUser
          }
          editedFields={props.editedFields}
        >
          {isPresentation ? "Next" : "Save"}
        </AddButton>
        {!isPresentation && (
          <CancelButton onClick={() => props.clearFields()}>
            Cancel
          </CancelButton>
        )}
      </ButtonWrapper>
    </UserDetailsWrapper>
  );
};

const SharedLabelCss = css`
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 10px;
  opacity: 0.64;
  display: inline-block;
  color: ${props => props.theme.COLOR.MAIN};
  font-weight: bold;
  margin-bottom: 9px;
`;

const UserDetailsWrapper = styled.div`
  .save-btn {
    margin-left: 8px;
  }
  .custom-hint__content {
    width: 220px;
    padding: 10px;
    background-color: ${props => props.theme.COLOR.BLACK};
    border-radius: 4px;

    span {
      color: ${props => props.theme.COLOR.WHITE};
    }
  }
  .form-group {
    display: inline-block;
  }
  .checkbox-container {
    display: inline-block;
  }
  .react-select__placeholder {
    ${SharedLabelCss};
    top: 48%;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select-container {
    .react-select__control {
      cursor: pointer;
      min-height: 30px;
      box-shadow: none;
      outline: none;
    }
    .react-select__placeholder {
      ${SharedLabelCss};
      top: 48%;
    }
    .react-select__indicator-separator {
      display: none;
    }
  }
`;

const MultiSelectWrapper = styled.div`
  margin-bottom: 16px;
`;

const IconWrapper = styled.span`
  width: 19px;
  height: auto;
  display: inline-block;
  padding: 5px;
  margin: -5px;
  cursor: pointer;
`;

const InputBoxWrapper = styled.div`
  margin-bottom: 16px;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
`;

const AddButton = styled.button`
  ${props => props.theme.SNIPPETS.SHARED_BUTTON_CSS}
  color: ${props => props.theme.COLOR.WHITE};
  pointer-events :${props =>
    (Array.isArray(props.editedFields) &&
      props.editedFields.length &&
      props.isEdit) ||
    !props.isEdit
      ? "initial"
      : "none"};
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  &:hover {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
    background-color: ${props => props.theme.COLOR.WHITE};
    border: 1px solid;
  }
`;

const CancelButton = styled.button`
  ${props => props.theme.SNIPPETS.SHARED_BUTTON_CSS}
  margin-left: 6px;
  background: transparent;
  border: solid 1px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  &:hover {
    color: ${props => props.theme.COLOR.WHITE};
    background-color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  }
`;

const RadioButtonContainer = styled.div`
  .checkmark {
    width: 10px;
    height: 10px;
    box-sizing: content-box;
  }
`;

const RadioWrapperLabel = styled.span`
  ${SharedLabelCss};
`;

const StateListLabel = styled.span`
  ${SharedLabelCss};
`;

const CheckboxWrapper = styled.div`
  margin-top: -7px;
  padding: 10px;
`;

const EmailCheckboxWrapper = styled.div`
  padding: 10px;
  margin: 16px 0 20px;
`;

const StyledInfoIcon = styled(FaInfoCircle)`
  margin-top: 2px;
  size: 10px;
  transform: translate(5px, 1px);
  cursor: pointer;
  color: ${props => props.theme.COLOR.MAIN};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: inline-block;
  margin-top: 3px;

  &.next-cta {
    text-align: center;
  }
`;

export default UserDetails;
