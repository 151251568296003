import React, { Component } from "react";
import { connect } from "react-redux";
import ValidationUtils from "utils/ValidationUtils";
import {
  getCategoriesData,
  getDocumentTypes,
  createCategory,
  fetchSpecificCategory
} from "./services";
import ToastUtils from "utils/handleToast";
import { forEach, get } from "lodash";

const UI_STRINGS = {
  EMPTY_FIELD_ERROR_MESSAGE: "This field is required.",
  SPECIAL_CHAR_ERROR_MESSAGE: "Please do not enter the special character.",
  WHITE_SPACE_ERROR_MESSAGE: "Please enter a valid input."
};

/**
 * Map the state to props.
 */
const mapStateToProps = state => {
  const { CATEGORY_SUCCESS, CATEGORY_LOADING } = state;
  return {
    ...CATEGORY_SUCCESS,
    ...CATEGORY_LOADING
  };
};

const Container = Main =>
  connect(mapStateToProps, {
    getCategoriesData,
    getDocumentTypes,
    createCategory,
    fetchSpecificCategory
  })(
    class CategoryCreate extends Component {
      constructor(props) {
        super(props);
        this.state = {
          categoryDetails: [
            {
              key: "parent",
              title: "Choose Parent Category",
              type: "dropDown",
              value: "",
              isEdited: false
            },
            {
              key: "name",
              title: "Category Name",
              type: "text",
              value: "",
              error: "",
              isEdited: false
            },
            {
              key: "enable",
              title: "Make category visible",
              type: "checkbox",
              value: false,
              isEdited: false
            },
            {
              key: "description",
              title: "Description",
              type: "textarea",
              limit: "250",
              value: "",
              isEdited: false
            }
          ],
          documentTypes: [],
          isDocumentLayoutChanged: false,
          displayAll: false,
          categoriesOptions: [],
          categoriesIds: [],
          documentLayoutsError: false,
          showNote: false
        };

        this.disabledCategoryNote = `The selected parent is in disabled state. Creating a sub-category will enable the selected parent. Please uncheck "Make subcategory visible" in case you wish to keep the parent disabled.`;
      }

      componentDidMount() {
        if (!this.props.match.params.id) {
          let categoryDetails = this.state.categoryDetails;
          categoryDetails[2].value = true;
          this.setState({
            categoryDetails
          });
        }
        this.getCategoriesDocuments();
      }

      getCategoriesDocuments = async () => {
        // get parent categories data
        await this.props.getCategoriesData();

        // get document page layouts
        this.props.getDocumentTypes();

        // get categories options
        this.getCategoriesNames();
      };

      /**
       * Fetch the specific category data.
       */
      _updatedTheCategoryValue = async id => {
        const response = await this.props.fetchSpecificCategory(id);

        // update the state with specific category data only when response is received
        if (response && response.data) {
          const parentValue = this.getParentValue(response.data.parent);

          const countLayoutTypes = get(
            this.props,
            `categoriesDocumentData.length`
          );

          let temp = { ...this.state };

          temp["documentTypes"] = get(response, `data.documentTypes`);

          forEach(temp["categoryDetails"], element => {
            element.value = response.data[element.key]
              ? element.key === "parent"
                ? get(parentValue, `name`)
                : response.data[element.key]
              : "";
          });

          // if all the layouts are selected then display all should be true
          if (temp.documentTypes.length === countLayoutTypes)
            temp["displayAll"] = true;

          this.setState({ ...temp });
        }
      };

      // get parent value on edit
      getParentValue = parentId => {
        const { categoriesIds, categoriesOptions } = this.state;
        const getIndexParent = categoriesIds.indexOf(parentId);

        return categoriesOptions[getIndexParent];
      };

      // handle form value change
      handleInputChange = ({ parentProp, key, value }, isSubmitClicked) => {
        let { categoriesData } = this.props || [];

        if (parentProp) {
          let temp = this.state[parentProp];

          forEach(temp, element => {
            if (element.key === key) {
              element.value = value;
              const textLimit = element.limit;
              // Assign value when submit is not clicked
              if (!isSubmitClicked) {
                element.isEdited = true;
              }

              if (
                (element.key === "parent" || element.key === "enable") &&
                !isSubmitClicked
              ) {
                forEach(categoriesData, eachCategory => {
                  if (
                    eachCategory.name === temp[0].value ||
                    value === "[Top Level Category]"
                  ) {
                    this.setState({
                      showNote: !eachCategory.enable && temp[2].value // show note if the selected category is disabled and category visible checkbox is selected
                    });
                  }
                });
              }

              if ("error" in element)
                element.error =
                  textLimit && value.length > textLimit
                    ? "character limit reached"
                    : this.handleAccountValidation(value);
            }
          });

          this.setState({
            [parentProp]: temp
          });
        } else {
          this.setState({
            [key]: value
          });

          // when display all value is true
          if (key === "displayAll" && value) {
            this.displayAllHandler();
          } else if (key === "displayAll") {
            this.setState({
              documentTypes: [],
              documentLayoutsError: true
            });
          }
        }
      };

      // set page layouts values for creating post data
      documentCheckboxHandler = ({ page, value }) => {
        const countLayoutTypes = get(
          this.props,
          `categoriesDocumentData.length`
        );
        const tempPageLayouts = this.state.documentTypes;

        if (value) {
          this.state.documentTypes.indexOf(page._id) === -1 &&
            this.setState(
              {
                documentTypes: [...this.state.documentTypes, page._id],
                isDocumentLayoutChanged: true
              },
              () => {
                // if all the layouts are selected then display all should be true
                if (this.state.documentTypes.length === countLayoutTypes)
                  this.setState({
                    displayAll: true
                  });
              }
            );
        } else {
          tempPageLayouts.splice(tempPageLayouts.indexOf(page._id), 1);

          // display all false if any one document type is unchecked
          this.setState({
            documentTypes: tempPageLayouts,
            displayAll: false,
            isDocumentLayoutChanged: true
          });
        }
      };

      // set page layouts values when display all is checked
      displayAllHandler = () => {
        let pageLayoutsAll = [];

        forEach(this.props.categoriesDocumentData, documentData => {
          pageLayoutsAll = [...pageLayoutsAll, documentData._id];
        });

        this.setState({
          documentTypes: pageLayoutsAll,
          isDocumentLayoutChanged: true
        });
      };

      // validate data on submit click
      onSubmitClick = async () => {
        let breakFlag = true;

        forEach(this.state.categoryDetails, element => {
          this.handleInputChange(
            {
              parentProp: "categoryDetails",
              key: element.key,
              value: element.value
            },
            true
          );

          if (
            "error" in element &&
            this.handleAccountValidation(element.value) &&
            breakFlag
          ) {
            ToastUtils.handleToast({
              operation: "error",
              message: "Please fill all the required fields."
            });

            breakFlag = false;
          }
        });

        if (!this.state.documentTypes.length) {
          if (breakFlag) {
            ToastUtils.handleToast({
              operation: "error",
              message: "Please fill all the required fields."
            });

            breakFlag = false;
          }

          this.setState({
            documentLayoutsError: true
          });
        }

        let isEditFlag = this.state.categoryDetails.filter(
          element => element.isEdited
        );

        // create category if breakFlag is true
        if (
          breakFlag &&
          (isEditFlag.length || this.state.isDocumentLayoutChanged)
        ) {
          const {
            categoryDetails,
            documentTypes,
            isDocumentLayoutChanged
          } = this.state;

          if (documentTypes.length) {
            const categoryName =
              categoryDetails[0]["value"] === "[Top Level Category]"
                ? ""
                : categoryDetails[0]["value"];

            let parentID = null;

            forEach(this.props.categoriesData, category => {
              if (categoryName && category.name === categoryName) {
                parentID = category._id;
              }
            });

            let body = {};

            const catId = this.props.match.params.id
              ? this.props.match.params.id
              : "";

            if (catId) {
              forEach(categoryDetails, categoryDetail => {
                // send only those values which are edited
                if (categoryDetail.isEdited) {
                  body = {
                    ...body,
                    [categoryDetail.key]:
                      categoryDetail.key === "parent"
                        ? parentID
                        : categoryDetail.value
                  };
                }
              });

              // check if documentTypes are changed
              if (isDocumentLayoutChanged) {
                body = { ...body, documentTypes };
              }
            } else {
              body = {
                parent: parentID,
                name: categoryDetails[1]["value"],
                enable: categoryDetails[2]["value"],
                description: categoryDetails[3]["value"],
                documentTypes: documentTypes
              };
            }

            const response = Object.keys(body).length
              ? await this.props.createCategory(body, catId)
              : null;

            if (response && response.success && response.data) {
              this.props.history.push(`/marketing-materials/admin/modules`);
            }
          }
        }
      };

      // get categories names
      getCategoriesNames = () => {
        let { categoriesData } = this.props || [];

        const temp = { ...this.state };

        forEach(categoriesData, category => {
          temp["categoriesOptions"] = [...temp["categoriesOptions"], category];
          temp["categoriesIds"] = [...temp["categoriesIds"], category._id];
        });

        this.setState({ ...temp });

        // fetch the specific category data.
        this.props.match.params.id &&
          this._updatedTheCategoryValue(this.props.match.params.id);
      };

      /**
       * Function to validate the input data
       * @param {String} will have the value of an input field
       */
      handleAccountValidation = value => {
        if (ValidationUtils.checkIfspecialChar(value)) {
          return UI_STRINGS.SPECIAL_CHAR_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfEmptyField(value)) {
          return UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfWhiteSpace(value)) {
          return UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
        } else {
          return "";
        }
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props
        };

        return <Main {...stateMethodProps} />;
      }
    }
  );

export default Container;
