import React from "react";
import styled from "styled-components";
import ValidationUtils from "utils/ValidationUtils";
import { MdDone, MdClear } from "react-icons/md";

const EMPTY_FEILD_ERROR_MESSAGE = "This field is required.";
const SPECIAL_CHAR_ERROR_MESSAGE = "Please do not enter the special character.";
const WHITE_SPACE_ERROR_MESSAGE = "Please enter a valid input.";

class CategoryTitleComponent extends React.Component {
  constructor(props) {
    super(props);
    this.InputRef = React.createRef();

    this.state = {
      categoryInput: false,
      isEdited: false,
      inputError: "",
      inputValue: ""
    };
  }

  checkValidation = value => {
    if (ValidationUtils.checkIfEmptyField(value)) {
      return EMPTY_FEILD_ERROR_MESSAGE;
    } else if (ValidationUtils.checkIfWhiteSpace(value)) {
      return WHITE_SPACE_ERROR_MESSAGE;
    } else if (ValidationUtils.checkIfspecialChar(value)) {
      return SPECIAL_CHAR_ERROR_MESSAGE;
    } else {
      return null;
    }
  };

  setCategoryInput = () => {
    this.setState({
      categoryInput: this.props.categoryIdToEdit === this.props._id,
      inputError: ""
    });
  };

  checkIfEdited = e => {
    this.setState({
      isEdited: true,
      inputError: this.checkValidation(e.target.value),
      inputValue: e.target.value
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.categoryIdToEdit !== prevProps.categoryIdToEdit) {
      this.setCategoryInput();
    }
  }

  saveCategoryDetails = ({ title, parent, _id }) => {
    if (this.state.inputError) {
      return;
    }

    this.props.setResetCategory(null);

    if (this.state.isEdited) {
      const body = {
        title: this.state.inputValue,
        parent
      };

      // call back for update content filter
      this.props.updateContentFilter(_id, body);
    }
  };

  render() {
    const {
      _id,
      title,
      setResetCategory,
      label,
      showEditDeleteRevert,
      parent
    } = this.props;

    return this.state.categoryInput ? (
      <CategoryWrapper>
        <span>
          <input
            onChange={this.checkIfEdited}
            type="text"
            defaultValue={title}
          />
          {this.state.inputError && (
            <ErrorMessage>{this.state.inputError}</ErrorMessage>
          )}
        </span>
        <button
          onClick={() => {
            this.saveCategoryDetails({
              label,
              title: this.state.inputValue,
              _id,
              parent
            });

            showEditDeleteRevert();
          }}
          title="save"
        >
          <SaveIcon />
        </button>
        <button
          title="Close"
          onClick={() => {
            setResetCategory(null);
            this.setCategoryInput(false);
            showEditDeleteRevert();
          }}
        >
          <ClearIcon />
        </button>
      </CategoryWrapper>
    ) : (
      <span
        key={_id}
        style={{
          color: "#636363",
          fontSize: "12px"
        }}
        title={title}
        tabIndex={0}
      >
        {title}
      </span>
    );
  }
}

const CategoryWrapper = styled.div`
  position: absolute;
  width: 60%;
  left: 5px;
  top: 10px;
  input {
    width: 70%;
    padding: 5px 5px 0;
    border: none;
    border-bottom: 2px solid ${props => props.theme.COLOR.USER_PRIMARY};
    outline: none;
    color: ${props => props.theme.COLOR.HEX_GRAY};
    background: transparent;
    transform: translateY(-3px);
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
  }
`;

const SaveIcon = styled(MdDone)`
  font-size: 16px;
  cursor: pointer;
`;

const ClearIcon = styled(MdClear)`
  font-size: 16px;
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
  font-weight: normal;
  position: absolute;
  left: 0;
  bottom: -8px;
`;

export default CategoryTitleComponent;
