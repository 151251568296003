import React, { Component } from "react";
import styled from "styled-components";
import handleBodyScroll from "utils/handleBodyScroll";
import { connect } from "react-redux";
import { get } from "lodash";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import { downloadFile } from "utils/download";
import {
  Delete,
  EditWithNoShadow,
  Clone,
  DownloadColored,
  ShareColored,
  shareDisable,
  DownloadDisable
} from "assets/icons";
import { getDocumentList, cloneDocument, deleteDocument } from "./services";
import { trackToolDashboardCount } from "services/trackDashboardCount";

/**
 * Map the state to props.
 */
const mapStateToProps = state => {
  const {
    LOADING_DOCUMENT_LIST,
    SUCCESS_DOCUMENT_LIST,
    SUCCESS_USER_PROFILE
  } = state;
  return {
    ...LOADING_DOCUMENT_LIST,
    ...SUCCESS_DOCUMENT_LIST,
    ...SUCCESS_USER_PROFILE
  };
};

const Container = Main =>
  connect(mapStateToProps, {
    getDocumentList,
    cloneDocument,
    deleteDocument
  })(
    class Documents extends Component {
      static defaultProps = {
        role: "admin"
      };
      state = {
        documentData: [],
        tableHeader: [
          {
            col1: "Name",
            col2: "Client Name",
            col3: "Created Date",
            col4: "Updated Date",
            col5: "Type",
            col6: "Status",
            col7: "Actions"
          }
        ],
        tableColumnHeader: [
          "projectName",
          "clientName",
          "createdAt",
          "updatedAt",
          "documentType.title",
          "status"
        ],
        isShowOverlay: false,
        isPopShow: false,
        selectedPresentation: {}
      };

      columnWidth = [170, 115, 115, 125, 85, 100, 140];
      searchFields = ["projectName", "clientName"];

      componentDidMount() {
        this._fetchDocumentList();
        this.trackPlacematDashboardCount();
      }

      componentDidUpdate(prevProps) {
        // check if user profile data is recieved and get the url
        if (prevProps.userProfileMeta !== this.props.userProfileMeta) {
          this._fetchDocumentList();
          this.trackPlacematDashboardCount();
        }
      }

      /**
       *Log whenever a user hits dashboard
       *
       */
      trackPlacematDashboardCount = async () => {
        let userId = (this.props.userProfileMeta || {})._id;
        userId && (await trackToolDashboardCount(userId, "mg"));
      };

      /**
       * Function to get the doucment list
       */
      _fetchDocumentList = async () => {
        const userID =
          this.props.userProfileMeta && this.props.userProfileMeta._id;
        userID && (await this.props.getDocumentList(userID));
        this.setState({
          documentData: this.props.documentDataList || []
        });
      };

      /**
       * Function to perfrom clone document action
       * @param {String} id of the Document to be deleted
       */
      cloneDocumentHandler = async id => {
        const userID =
          this.props.userProfileMeta && this.props.userProfileMeta._id;
        // Clone Document
        let response = await this.props.cloneDocument(userID, id);

        if (response.success) {
          let cloneDocumentId = get(response, "data._id");
          cloneDocumentId &&
            this.props.history.push(
              `/marketing-materials/document/setup/${cloneDocumentId}`
            );
        }
      };

      /**
       * Function to perfrom edit document action
       * @param {String} id of the Document to be edited
       */
      editDocumentHandler = id => {
        id &&
          this.props.history.push(`/marketing-materials/document/setup/${id}`);
      };

      /**
       * Delete Document handler
       * @param {String} id of the Document to be deleted
       */
      deleteDocumentData = async id => {
        const userID =
          this.props.userProfileMeta && this.props.userProfileMeta._id;
        userID && (await deleteDocument(userID, id));
        this._fetchDocumentList();
      };

      // function to show last column in table
      showIcon = rowData => {
        return (
          <>
            <IconWrapper
              title={rowData.status === "Completed" ? "Download" : ""}
            >
              {get(rowData, `status`) === "Saved" ? (
                <DownloadIcon />
              ) : (
                <CompletedDownload
                  onClick={() => this.downloadOverlayHandler(rowData)}
                />
              )}
            </IconWrapper>
            <IconWrapper title="Edit">
              <EditSquareIcon
                onClick={() => this.editDocumentHandler(rowData._id)}
              />
            </IconWrapper>
            <IconWrapper title="Delete">
              <DeleteIcon
                onClick={() => {
                  DeleteConfirmationAlert({
                    onYesClick: () => {
                      this.deleteDocumentData(rowData._id);
                    }
                  });
                }}
              />
            </IconWrapper>
            <IconWrapper title="Clone">
              <CloneIcon
                onClick={() => this.cloneDocumentHandler(rowData._id)}
              />
            </IconWrapper>
            <IconWrapper title="Share">
              {get(rowData, `status`) === "Saved" ? (
                <ShareIcon />
              ) : (
                <CompletedShare onClick={() => this.overlayHandler(rowData)} />
              )}
            </IconWrapper>
          </>
        );
      };

      renderHead = () => {
        const HELPER_TEXT = "Create, edit or delete a document below.";
        return (
          <div className="heading">
            <HeadingName>Documents</HeadingName>
            <HelperText>{HELPER_TEXT}</HelperText>
          </div>
        );
      };

      /**
       * Function to show the Overlay module
       * @param {Object} Object of the selected row
       */
      overlayHandler = selectedPresentation => {
        handleBodyScroll(
          !this.state.isShowOverlay ? { action: "open" } : { action: "close" }
        );

        this.setState({
          selectedPresentation,
          isShowOverlay: !this.state.isShowOverlay
        });
      };

      /**
       * Function to download document
       * @param {Object} Object of the selected row
       */
      downloadOverlayHandler = selectedPresentation => {
        let { pdfLocation, pptLocation, zipLocation } =
            selectedPresentation || {},
          urlLocations = [pdfLocation, pptLocation, zipLocation],
          moreThanOne = urlLocations.filter((item = {}) => item.url);

        if (moreThanOne.length === 1) {
          downloadFile(moreThanOne[0].url);
          return;
        }

        this.setState({
          isPopShow: true,
          selectedPresentation
        });
      };

      //handle closing of modal on click of close icon button and outside of content area
      closeModalHandler = () => {
        this.setState({
          isPopShow: false
        });
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.props,
          ...$this.state
        };
        return <Main {...stateMethodProps} />;
      }
    }
  );

const IconWrapper = styled.span`
  height: auto;
  display: inline-block;
  padding: 0 8px;

  &:first-of-type {
    padding: 0 8px 0 0;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const DownloadIcon = styled(DownloadDisable)`
  cursor: pointer;
  width: 16px;
  height: 17px;
  pointer-events: none;
`;

const CompletedDownload = styled(DownloadColored)`
  cursor: pointer;
  width: 16px;
  height: 17px;
  cursor: pointer;
`;

const ShareIcon = styled(shareDisable)`
  width: 16px;
  height: 17px;
  pointer-events: "none";
`;

const CompletedShare = styled(ShareColored)`
  width: 16px;
  height: 17px;
  cursor: pointer;
`;

const DeleteIcon = styled(Delete)`
  cursor: pointer;
  width: 16px;
  height: 17px;
`;

const EditSquareIcon = styled(EditWithNoShadow)`
  cursor: pointer;
  width: 16px;
  height: 17px;
`;

const CloneIcon = styled(Clone)`
  cursor: pointer;
  width: 16px;
  height: 17px;
`;

const HelperText = styled.p`
  ${props => props.theme.SNIPPETS.HELPER_TEXT};
  margin-bottom: 16px;
  min-height: 30px;
`;

const HeadingName = styled.span`
  margin-left: -2px;
  display: inline-block;
  margin-bottom: 10px;
`;

export default Container;
