import React from "react";
import Styled, { withTheme } from "styled-components";
import hexToRgba from "utils/hexToRgba";

const Button = props => {
  return (
    <ButtonWrapper
      disabled={props.disabledAddCta}
      title={props.title}
      backgroundColor={props.backgroundColor || props.theme.COLOR.USER_PRIMARY}
      onClick={props.onClick && props.onClick}
    >
      {props.children}
    </ButtonWrapper>
  );
};

const ButtonWrapper = Styled.button`
  box-sizing: border-box;
  display: inline-block;
  padding: 6px 20px;
  border: 1px solid ${props => props.backgroundColor};
  border-radius: 3px; 
  outline: none
  background-color: ${props => props.backgroundColor};
  color: #fff;
  font-size: 12px;
  font-family: ${props => props.theme.FONT.REG};
  font-weight: bold;
  cursor: pointer;
  transition: none;
  &:hover:enabled {
    background-color: #fff;
    color: ${props => props.backgroundColor};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed; 
    background-color: ${props =>
      hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.3)};
    border: 1px solid ${props =>
      hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.3)};
  }
`;

export default withTheme(Button);
