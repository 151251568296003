import React from "react";
import styled from "styled-components";

// container
import Container from "./container";

// components
import BgWrapper from "components/bgWrapper";
import CategoryHeader from "../categoryHeader";
import SortableTree from "./components/sortableTree";
import FullPageLoader from "components/FullPageLoader";

const CategoriesComponent = props => {
  const { categoryDataLoading, categoryDeleteLoading } = props;

  return (
    <>
      {(categoryDataLoading || categoryDeleteLoading) && (
        <FullPageLoader layerIndex={2} />
      )}
      <CategoriesContainer>
        <CategoryHeader {...props} titleText={"Categories"} _new />
        <SortableTree {...props} />
      </CategoriesContainer>
    </>
  );
};

const CategoriesContainer = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  width: ${props => props.theme.WRAPPER.WIDTH};
  margin: 0 auto;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  @media (max-width: 1024px) {
    width: calc(100% - 80px);
  }
`;

export default Container(BgWrapper(CategoriesComponent));
