import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get, has, map } from "lodash";
import {
  isSlideDetailLoading,
  onSlideDetailsSuccess,
  onMultipleSlideMetadataSuccess,
  onDownloadDeckSuccess
} from "./actions";
import { ConvertQueryParamsArrayObjectToString } from "utils/queryString";

const getSlideDetails = (
  slideId,
  contentRepositoryId,
  type,
  attributes
) => async dispatch => {
  // TODO: Pass the image attributes in params for dynamic imagery
  const attributeList = map(attributes, attribute => ({
    "imageCategory[]": attribute
  }));

  const params = ConvertQueryParamsArrayObjectToString([
    {
      type
    },
    ...attributeList
  ]);

  const URL = `/content-repository/${contentRepositoryId}/slides/${slideId}${
    params ? `?${params}` : ""
  }`;

  //show loader
  dispatch(
    isSlideDetailLoading({
      isSlideDetailLoading: true
    })
  );

  const response = await FetchUtils.getData(URL, "Get Slide Details");

  if (response && response.success) {
    // dispatch slide details on success
    dispatch(
      onSlideDetailsSuccess({
        currentDynamicDetails: response.data
      })
    );

    // hide loader
    dispatch(
      isSlideDetailLoading({
        isSlideDetailLoading: false
      })
    );
  } else {
    // hide loader
    dispatch(
      isSlideDetailLoading({
        isSlideDetailLoading: false
      })
    );

    ToastUtils.handleToast({
      operation: "dismiss"
    });
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

/**
 *
 * @param {Array} slidesIdArray array containing the slide ids whose overlay was not seen
 * @param {String} themeId selected theme Id
 */
const getMultipleSlidesIdForMetadata = (
  slidesIdArray,
  themeId,
  type
) => async dispatch => {
  const params = ConvertQueryParamsArrayObjectToString([
    {
      type
    }
  ]);

  const URL = `/content-repository/${themeId}/slides?${params}`;

  // show loader
  dispatch(
    isSlideDetailLoading({
      isSlideDetailLoading: true
    })
  );

  const response = await FetchUtils.postData(
    URL,
    slidesIdArray,
    "post slides id"
  );

  if (response && response.success) {
    // hide loader

    dispatch(
      onMultipleSlideMetadataSuccess({
        multipleSlideMetaData: response.data
      })
    );

    dispatch(
      isSlideDetailLoading({
        isSlideDetailLoading: false
      })
    );
  } else {
    dispatch(
      isSlideDetailLoading({
        isSlideDetailLoading: false
      })
    );

    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

/**
 * @param {String} contentRepositoryId currently selected content repo id
 * @param {String} categoryId parent id for the currently open slide
 * @param {Object} body body to be sent in post
 */
const downloadDeck = (
  contentRepositoryId,
  categoryId,
  body
) => async dispatch => {
  const URL = `/content-repository/${contentRepositoryId}/category/${categoryId}/deck`;

  dispatch(
    isSlideDetailLoading({
      isSlideDetailLoading: true
    })
  );

  const response = await FetchUtils.postData(URL, body, "save theme data");

  // hide loader
  dispatch(
    isSlideDetailLoading({
      isSlideDetailLoading: !has(get(response, "data"), "url")
    })
  );

  if (response.success && response.data) {
    // dispatch logo list on success
    dispatch(
      onDownloadDeckSuccess({
        downloadDeckDetails: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const stopDownloadDeckLoading = () => async dispatch => {
  dispatch(
    onDownloadDeckSuccess({
      isSlideDetailLoading: false
    })
  );
};

export {
  getSlideDetails,
  getMultipleSlidesIdForMetadata,
  downloadDeck,
  stopDownloadDeckLoading
};
