import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { get } from "lodash";

//Components
import Container from "./container";
import MainSection from "./components/MainSection";
import SideBar from "./components/SideBar";
import Button from "components/button";
import FullPageLoader from "components/FullPageLoader";
import featureFlags from "utils/featureFlags";

const Interactivity = props => {
  let { match, selectedContentRepo, isEdited } = props;
  const breadCrumbs = () => {
    return (
      <LinkContainer>
        <StyledLink to={`/presentation/list`}>Home/</StyledLink>
        <StyledLink to={`/presentation/admin/content-repo`}>
          Content Repo/
        </StyledLink>
        <StyledLink
          to={`/presentation/admin/content-repo/${match.params.contentRepoId}/content-slides`}
        >
          {get(selectedContentRepo, `name`)}/
        </StyledLink>
        <InteractivityBreadCrumb>Interactivity</InteractivityBreadCrumb>
      </LinkContainer>
    );
  };

  let showContentMasterSideBar = get(
    featureFlags,
    `presentation.showContentMasterSideBar`
  );
  return (
    <InteractivityContainer>
      {props.isLoading && <FullPageLoader layerIndex={21} />}
      <PageTitle>
        {(selectedContentRepo || {}).name || "Content Repo"}
      </PageTitle>
      <BreadCrumbs>{breadCrumbs()}</BreadCrumbs>
      <ContentContainer>
        <MainSectionContainer
          showContentMasterSideBar={showContentMasterSideBar}
        >
          <MainSection {...props} />
          <ButtonContainer disabled={!isEdited}>
            <Button
              text="Save"
              onClick={props.saveContentRepoInteractityMetaDetails}
              float="none"
              width="30%"
            />
          </ButtonContainer>
        </MainSectionContainer>
        {showContentMasterSideBar && (
          <SideBarContainer>
            <SideBar {...props} />
          </SideBarContainer>
        )}
      </ContentContainer>
    </InteractivityContainer>
  );
};

const ButtonContainer = styled.div`
  padding: 0 5.7% 30px;
  pointer-events: ${props => props.disabled && "none"};
  opacity: ${props => props.disabled && 0.6};
`;

const InteractivityContainer = styled.div`
  padding: 40px 0 78px;
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  width: ${props => props.theme.WRAPPER.WIDTH};
  margin: 106px auto 0;
  color: ${props => props.theme.COLOR.MAIN};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  @media (max-width: 1024px) {
    width: calc(100% - 80px);
  }

  h2,
  h3,
  h4 {
    font-family: ${props => props.theme.FONT.REG};
    font-weight: bold;
  }

  h2 {
    color: ${props => props.theme.COLOR_PALLETE.GREY};
    font-size: 20px;
  }

  h3 {
    color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
    font-size: 18px;
  }

  h4 {
    color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
    font-size: 14px;
  }
`;

const PageTitle = styled.h2`
  margin-bottom: 10px;
`;

const BreadCrumbs = styled.span`
  margin-bottom: 23px;
  display: block;
  color: ${props => props.theme.COLOR.HEADING};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 10px;
  font-weight: bold;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const MainSectionContainer = styled.div`
  border-radius: 4px;
  margin-right: 2.2%;
  flex-basis: 68.2%;
  background-color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.BOX_SHADOW}
`;

const SideBarContainer = styled.div`
  min-height: 500px;
  padding: 20px;
  border-radius: 4px;
  flex-basis: 29.56%;
  background: #fff;
  ${props => props.theme.SNIPPETS.BOX_SHADOW}

  p {
    ${props => props.theme.SNIPPETS.FONT_STYLE}
    font-size: 10px;
  }
`;

const LinkContainer = styled.span``;

const InteractivityBreadCrumb = styled.span``;

const StyledLink = styled(Link)`
  color: ${props => props.theme.COLOR.PRIMARY_LIGHT};
  text-transform: none;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export default Container(Interactivity);
