const dividerData = [
  {
    title: "",
    thumbnailLocation: "https://via.placeholder.com/123x84",
    fileLocation: "",
    enable: true
  },
  {
    title: "",
    thumbnailLocation: "https://via.placeholder.com/123x84",
    fileLocation: "",
    enable: true
  },
  {
    title: "",
    thumbnailLocation: "https://via.placeholder.com/123x84",
    fileLocation: "",
    enable: true
  },
  {
    title: "",
    thumbnailLocation: "https://via.placeholder.com/123x84",
    fileLocation: "",
    enable: true
  },
  {
    title: "",
    thumbnailLocation: "https://via.placeholder.com/123x84",
    fileLocation: "",
    enable: true
  },
  {
    title: "",
    thumbnailLocation: "https://via.placeholder.com/123x84",
    fileLocation: "",
    enable: true
  },
  {
    title: "",
    thumbnailLocation: "https://via.placeholder.com/123x84",
    fileLocation: "",
    enable: true
  },
  {
    title: "",
    thumbnailLocation: "https://via.placeholder.com/123x84",
    fileLocation: "",
    enable: true
  },
  {
    title: "",
    thumbnailLocation: "https://via.placeholder.com/123x84",
    fileLocation: "",
    enable: true
  },
  {
    title: "",
    thumbnailLocation: "https://via.placeholder.com/123x84",
    fileLocation: "",
    enable: true
  },
  {
    title: "",
    thumbnailLocation: "https://via.placeholder.com/123x84",
    fileLocation: "",
    enable: true
  },
  {
    title: "",
    thumbnailLocation: "https://via.placeholder.com/123x84",
    fileLocation: "",
    enable: false
  }
];

export default dividerData;
