import Template1 from "./template1.jpg";
import Template2 from "./template2.jpg";
import Template3 from "./template3.jpg";

let SummaryMakerImages = {
  Template1,
  Template2,
  Template3
};

export default SummaryMakerImages;
