import React from "react";
import { ThemeProvider } from "styled-components";

import { connect } from "react-redux";

import { get } from "lodash";
//configure app theme
import configureTheme from "../theme";

let defaultTheme = JSON.parse(JSON.stringify(configureTheme()));

const CustomThemeProvider = props => {
  let defaultColor = defaultTheme.COLOR.USER_PRIMARY;
  let theme = configureTheme();
  let themeColor = get(props, "clientConfig.color.primaryColor");
  let primaryColor = themeColor || defaultColor;

  if (!props.isClientConfigLoading && !primaryColor) {
    theme.COLOR.USER_PRIMARY = defaultColor;
  } else
    theme.COLOR.USER_PRIMARY = props.isClientConfigLoading
      ? null
      : primaryColor;

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default connect(state => {
  const { CLIENT_CONFIG_SUCCESS, CLIENT_CONFIG_LOADING } = state;

  return {
    ...CLIENT_CONFIG_SUCCESS,
    ...CLIENT_CONFIG_LOADING
  };
}, {})(CustomThemeProvider);
