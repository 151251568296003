import React, { Component } from "react";
import { getSelectedTemplate } from "./services";
import { connect } from "react-redux";
import { tableMetaData } from "./data";
import { get, map, fill, each } from "lodash";
import { ShareColored, Delete, Duplicate } from "assets/icons";
import styled from "styled-components";

const mapDispatchToProps = {
  getSelectedTemplate
};

const Container = Main =>
  connect(
    null,
    mapDispatchToProps
  )(
    class Page extends Component {
      state = {
        tableColumnsKeys: [],
        tableColumns: [],
        columnWidth: []
      };

      // called on click of templates
      templateClickHandler = selectedTemplate => {
        this.props.getSelectedTemplate(selectedTemplate);
      };

      componentDidMount() {
        this._extractMetadata();
        this._extractColumns();
        this._calculateColumnsWidth();
      }

      // extract metadata of the selected template for getting columns info
      _extractMetadata = () => {
        let { tableColumnsKeys } = this.state;

        tableColumnsKeys = map(
          get(tableMetaData[0], `fields`),
          eachMetadata => {
            return get(eachMetadata, `key`);
          }
        );
        tableColumnsKeys.push(""); // pushing empty string for action column to be displayed

        this.setState({
          tableColumnsKeys
        });
      };

      _extractColumns = () => {
        let { tableColumns } = this.state;
        let columnNames = {};

        // formatting columns name as col1 : columnName
        each(tableMetaData[0].fields, (eachMetadata, index) => {
          columnNames[`col${index + 1}`] = get(eachMetadata, `label`);
        });

        // pushing last action column
        columnNames[`col${tableMetaData[0].fields.length + 1}`] = "Actions";
        tableColumns = [
          {
            ...columnNames
          }
        ];

        this.setState({
          tableColumns
        });
      };

      _calculateColumnsWidth = () => {
        let { columnWidth } = this.state;
        // "+ 1" as actions column width also has to be included
        let eachColumnWidth =
          900 / (get(tableMetaData[0], `fields.length`) + 1);

        // each column width will be of same size, therefore populating same width length many times in the column width array
        columnWidth = fill(
          Array(tableMetaData[0].fields.length + 1),
          eachColumnWidth
        );

        this.setState({
          columnWidth
        });
      };

      showIcon = () => {
        let { actionFields } = tableMetaData[0];

        let Icon = {
          canDelete: <Delete />,
          canClone: <Duplicate />,
          canShare: <ShareColored />
        };

        return (
          <>
            {map(actionFields, (eachActionField, index) => {
              return (
                <IconWrapper key={index}>{Icon[eachActionField]}</IconWrapper>
              );
            })}
          </>
        );
      };

      renderHead = () => {
        return (
          <div className="heading">
            <HeadingName>Documents</HeadingName>
          </div>
        );
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props
        };

        return <Main {...stateMethodProps} />;
      }
    }
  );

const IconWrapper = styled.span`
  display: inline-block;
  svg {
    cursor: pointer;
  }
`;

const HeadingName = styled.span``;

export default Container;
