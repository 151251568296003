import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";

//import Document actions
import { onGetDocumentListSuccess, isDocumentListLoading } from "./actions";

/**
 * Get Document List
 * @param {STRING} userId
 */
const getDocumentList = userID => async dispatch => {
  const URL = `/marketing-materials/${userID}/documents`;

  // start loading
  dispatch(isDocumentListLoading({ isDocumentDataLoading: true }));
  const response = await FetchUtils.getData(URL);

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onGetDocumentListSuccess({
        documentDataList: response.data
      })
    );
    // stop loading
    dispatch(isDocumentListLoading({ isDocumentDataLoading: false }));
  } else {
    // stop loading
    dispatch(isDocumentListLoading({ isDocumentDataLoading: false }));
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Clone Document
 * @param {STRING} userId
 * @param {STRING} DocumentId
 */
const cloneDocument = (userID, documentID) => async dispatch => {
  const URL = `/marketing-materials/${userID}/documents/${documentID}`;
  // start loading
  dispatch(
    isDocumentListLoading({
      isDocumentDataLoading: true
    })
  );

  const response = await FetchUtils.postData(
    URL,
    null,
    "get clone Document data"
  );

  if (response.success && response.data) {
    // stop loading
    dispatch(
      isDocumentListLoading({
        isDocumentDataLoading: false
      })
    );

    ToastUtils.handleToast({
      operation: "success",
      message: "Document has been cloned successfully."
    });
  } else {
    // stop loading
    dispatch(
      isDocumentListLoading({
        isDocumentDataLoading: false
      })
    );
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

/**
 * delete Document
 * @param {STRING} userId
 * @param {STRING} DocumentId
 */
const deleteDocument = async (userId, documentId) => {
  let URL = `/marketing-materials/${userId}/documents/${documentId}`;

  const deleteResponse = await FetchUtils.deleteData(URL, "delete document");

  if (deleteResponse.success) {
    ToastUtils.handleToast({
      operation: "success",
      message: "Document has been deleted successfully."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(deleteResponse, "data.message")
    });
  }
};

export { getDocumentList, cloneDocument, deleteDocument };
