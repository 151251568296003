import React from "react";
import { EditWithNoShadow } from "assets/icons";
import { MdDone, MdClear } from "react-icons/md";
import styled, { withTheme } from "styled-components";

import { get } from "lodash";
import Container from "./container";

const EditableInput = props => {
  let {
    isLogoTitleEditable,
    logoTitleRef,
    editLogoTitleHandler,
    item,
    onResetHandler,
    onSaveHandler,
    titleError,
    handleTitleValidation
  } = props;

  return (
    <CustomerLogoName>
      <LogoTitle
        defaultValue={get(item, `title`)}
        title={get(item, `title`)}
        ref={logoTitleRef}
        onChange={handleTitleValidation}
        disabled={!isLogoTitleEditable}
      />
      <ErrorMessage>{titleError}</ErrorMessage>

      {!isLogoTitleEditable ? (
        <EditIconWrapper title="Edit" onClick={editLogoTitleHandler}>
          <EditWithNoShadow />
        </EditIconWrapper>
      ) : (
        <SaveClearIconWrapper>
          <SaveIconWrapper title="Save" onClick={onSaveHandler}>
            <MdDone size={16} color={props.theme.COLOR.BORDER_GREY} />
          </SaveIconWrapper>
          <ResetIconWrapper title="Reset" onClick={onResetHandler}>
            <MdClear size={16} color={props.theme.COLOR.BORDER_GREY} />
          </ResetIconWrapper>
        </SaveClearIconWrapper>
      )}
    </CustomerLogoName>
  );
};

const CustomerLogoName = styled.div`
  position: relative;
`;

const SaveClearIconWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 17%;
  transform: translateY(-50%);
`;

const SaveIconWrapper = styled.a`
  cursor: pointer;
  margin-right: 2px;
`;

const ResetIconWrapper = styled.a`
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
  position: absolute;
`;

const EditIconWrapper = styled.a`
  position: absolute;
  right: 18%;
  top: 3px;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const LogoTitle = styled.input`
  width: 88%;
  box-sizing: border-box;
  padding: 3px 50px 0 0;
  display: block;
  color: grey;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: ${props => `${props.theme.FONT.REG}`};
  opacity: 0.7;
  color: ${props => props.theme.COLOR.MAIN};
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::-ms-clear {
    display: none;
  }
`;

export default withTheme(Container(EditableInput));
