import React from "react";
import { Route } from "react-router-dom";

// routes
import { SummaryMakerAdmin } from "tools/summaryMaker/pages";
import { Dashboard } from "tools/summaryMaker/pages";

const SummaryMakerRoutes = (auth, checkAuthSession) => {
  const routes = (
    <>
      <Route
        path="/summarymaker/admin"
        render={props =>
          checkAuthSession(<SummaryMakerAdmin auth={auth} {...props} />)
        }
      />

      <Route
        path="/summarymaker/rates"
        render={props => checkAuthSession(<Dashboard auth={auth} {...props} />)}
      />
    </>
  );

  return routes;
};

export default SummaryMakerRoutes;
