import Single from "./single.svg";
import RowDouble from "./double.svg";
import WideSingle from "./single2.svg";
import ColDouble from "./double2.svg";
import ColFirstTri from "./tri2.svg";
import RowFirstTri from "./tri.svg";
import Quad from "./quad.svg";
import NoBack from "./noBack.svg";
import NoBackDouble from "./noBack2.svg";
import TemplateSingle from "./popupSingle.png";
import TemplateRowDouble from "./popupDouble.png";
import TemplateWideSingle from "./popupSingle2.png";
import TemplateColDouble from "./popupDouble2.png";
import TemplateColFirstTri from "./popupTri2.png";
import TemplateRowFirstTri from "./popupTri.png";
import TemplateQuad from "./popupQuad.png";

import FullPage from "./fullPage.jpg";
import HalfPage from "./halfPage.jpg";
import Quadrant from "./quadrant.jpg";

let MarketingMaterialImages = {
  Single,
  RowDouble,
  WideSingle,
  ColDouble,
  ColFirstTri,
  RowFirstTri,
  Quad,
  NoBack,
  NoBackDouble,
  TemplateSingle,
  TemplateRowDouble,
  TemplateWideSingle,
  TemplateColDouble,
  TemplateColFirstTri,
  TemplateRowFirstTri,
  TemplateQuad,
  FullPage,
  HalfPage,
  Quadrant
};

export default MarketingMaterialImages;
