import React, { Component } from "react";
import _, { get, uniqBy, find } from "lodash";
import handleBodyScroll from "utils/handleBodyScroll";
import { connect } from "react-redux";
import { menuOptions, toolOptionsList } from "./constants";
import { actions, mapStateToProps } from "./mapStateToProps";

const container = Main =>
  connect(
    mapStateToProps,
    actions
  )(
    class Container extends Component {
      constructor(props) {
        super(props);
        this.state = {
          isPopupOpen: false,
          isOpen: false,
          isHeaderSticky: false,
          isDropDownOpen: false,
          selectedDropDown: {},
          isLoading: true,
          toolOptions: toolOptionsList,
          notiCount: 0,
          showAll: false
        };
        this.domElem = React.createRef();
      }
      announcementLimit = 5;
      static defaultProps = {
        menuOptions
      };

      componentDidMount() {
        this.props.userProfileMeta && this.setToolOptions();
        window.addEventListener("scroll", this.checkHeader);

        // fetch announcement list only when user has access for ["PG-Users", "PG-System-Admin"]
        this.fetchAnnouncementFlag() && this.fetchAnnouncementList();
      }

      // flag for fetchAnnouncementList
      fetchAnnouncementFlag = () => {
        let pgRoles = ["PG-Users", "PG-System-Admin"];

        let permittedRoles = pgRoles.filter(
          role =>
            this.props.userProfileMeta &&
            this.props.userProfileMeta.roles.indexOf(role) !== -1
        );

        return permittedRoles.length;
      };

      fetchAnnouncementList = async (announcementLimit, pageNumber) => {
        let {
          userProfileMeta: { _id: userId }
        } = this.props || {};

        if (pageNumber && announcementLimit) {
          await this.props.getAnnouncementList(
            announcementLimit,
            pageNumber,
            userId
          );
        } else {
          await this.props.getAnnouncementList(5, 1, userId);
        }

        this.setState({
          notiCount: get(this.props.announcementList, "data.unreadCount") || 0
        });
      };

      updateAnnouncementHandler = async (
        announcementId,
        announcementLimit = 5,
        pageNumber = 1
      ) => {
        let {
          userProfileMeta: { _id: userId }
        } = this.props || {};

        await this.props.updateAnnouncement(userId, announcementId);
        this.fetchAnnouncementList(announcementLimit, pageNumber);
      };

      /**
       * Check current Active user roles and set the tool options
       *
       */
      setToolOptions = () => {
        let renewalRoles = ["RG-Sales", "RG-Admin", "RG-Underwriter"];
        let pgRoles = ["PG-Users", "PG-System-Admin"];
        let mgRoles = ["MG-Users", "MG-Admin"];
        let smRoles = ["Summary Maker Admin", "Summary Maker"];

        let { userProfileMeta: profile } = this.props;
        let toolOptions = [];
        let renewalToolAccess = false,
          pgToolAccess = false,
          mgToolAccess = false,
          smToolAccess = false;

        if (profile && Object.keys(profile).length) {
          let { roles: userRoles } = profile;
          for (let index = userRoles.length; index--; ) {
            // set renewal tool permission
            if (
              renewalRoles.indexOf(userRoles[index]) > -1 &&
              !renewalToolAccess
            ) {
              toolOptions.push(toolOptionsList[0]);
              renewalToolAccess = true;
            }

            // set pg tool permission
            if (pgRoles.indexOf(userRoles[index]) > -1 && !pgToolAccess) {
              let pgToolOptions = toolOptionsList[1];
              pgToolOptions.default = !renewalToolAccess;
              toolOptions.push(pgToolOptions);
              pgToolAccess = true;
            }

            // set mg tool permission
            if (mgRoles.indexOf(userRoles[index]) > -1 && !mgToolAccess) {
              let mgToolOptions = toolOptionsList[2];
              mgToolOptions.default = !renewalToolAccess && !pgToolAccess;
              toolOptions.push(mgToolOptions);
              mgToolAccess = true;
            }

            if (smRoles.indexOf(userRoles[index]) > -1 && !smToolAccess) {
              let smToolOptions = toolOptionsList[3];
              smToolOptions.default =
                !renewalToolAccess && !pgToolAccess && !mgToolAccess;
              toolOptions.push(smToolOptions);
              smToolAccess = true;
            }
          }
        }

        this.updateToolOptions(
          toolOptions,
          renewalToolAccess,
          pgToolAccess,
          mgToolAccess,
          smToolAccess
        );
      };

      // update tool options array based on the tool last logged in
      updateToolOptions = (
        toolOptions,
        renewalToolAccess,
        pgToolAccess,
        mgToolAccess,
        smToolAccess
      ) => {
        let { userProfileMeta: profile } = this.props;
        let lastLoggedInTool = get(profile, `previousTool`);

        // prepend last logged in tool at the beginning of toolOptions
        if (lastLoggedInTool === "rg" && renewalToolAccess) {
          toolOptions.unshift(toolOptionsList[0]);
        } else if (lastLoggedInTool === "pg" && pgToolAccess) {
          toolOptions.unshift(toolOptionsList[1]);
        } else if (lastLoggedInTool === "mg" && mgToolAccess) {
          toolOptions.unshift(toolOptionsList[2]);
        } else if (lastLoggedInTool === "sm" && smToolAccess) {
          toolOptions.unshift(toolOptionsList[3]);
        }

        // remove duplicate tool object
        toolOptions = uniqBy(toolOptions, "value");
        toolOptions[0].default = true;

        this.setState(
          {
            toolOptions
          },
          () => {
            this.redirectToLastTool();
            this._checkPermissions();
          }
        );
      };

      redirectToLastTool = () => {
        let isLastToolSet = localStorage.getItem("isLastToolSet");

        // this redirection should happen only once after login
        if (isLastToolSet) {
          let { toolOptions } = this.state;
          this.props.history.push({
            pathname: "/home",
            state: {
              selectedTool: toolOptions[0]
            }
          });
        }
        // once redirection happens remove isLastToolSet
        localStorage.removeItem("isLastToolSet");
      };

      componentDidUpdate(prevProps) {
        if (this.props.userProfileMeta !== prevProps.userProfileMeta) {
          this.setToolOptions();
          this.fetchAnnouncementFlag() && this.fetchAnnouncementList();
        }

        let updatedUnreadCount =
          this.props.announcementList &&
          this.props.announcementList.data &&
          this.props.announcementList.data.unreadCount;

        let prevUnreadCount =
          prevProps.announcementList &&
          prevProps.announcementList.data &&
          prevProps.announcementList.data.unreadCount;

        if (updatedUnreadCount !== prevUnreadCount) {
          this.setState({
            notiCount: updatedUnreadCount
          });
        }

        if (this.props.clientConfig !== prevProps.clientConfig) {
          this.setDocumentTitle();
        }

        if (this.props.location.pathname !== prevProps.location.pathname) {
          this._checkPermissions();
        }
      }

      componentWillUnmount() {
        window.removeEventListener("scroll", this.checkHeader);
      }

      /**
       * Function to select active menu
       *
       * @param {*} activePath
       */
      _activeLink = (activePath, defaultDropDown) => {
        let activeModule = _.get(defaultDropDown, "value");
        (this.props.menuOptions[activeModule] || []).forEach(menuItem => {
          let path = menuItem.link && menuItem.link.slice(0).split("?")[0];
          if (path === activePath) {
            this.setState({
              isActiveMenu: menuItem.value
            });
          }
        });
      };

      /**
       * function to check if the page is available to the user
       * @param {Array} menuAccess Array of roles which can access the given page/route
       */
      _checkUserAcess = menuAccess => {
        let { userProfileMeta: profile } = this.props;
        return (
          profile &&
          !!Object.keys(profile).length &&
          _.intersection(menuAccess, profile.roles).length
        );
      };

      /**
       * function to handle hamburger click
       * @param {String} action open/close to open and close menu
       */
      handleMenuClick = ({ action }) => {
        const { isDropDownOpen } = this.state;

        if (isDropDownOpen) {
          this.setState({
            isDropDownOpen: false
          });
        } else {
          if (action === "open") {
            this.setState({ isOpen: true });
            this.scrollBarWidth = handleBodyScroll({});
            handleBodyScroll({ action: "open" });
          } else {
            this.setState({ isOpen: false });
            this.scrollBarWidth = handleBodyScroll({});
            handleBodyScroll({ action: "close" });
          }
        }
      };

      checkHeader = _.throttle(() => {
        // Run JavaScript stuff here
        let scrollPosition = Math.round(window.scrollY);
        if (scrollPosition > 100) {
          this.setState({
            isHeaderSticky: true
          });
        }
        // If not, remove "sticky" class from header
        else {
          this.setState({
            isHeaderSticky: false
          });
        }
      }, 300);

      // Set the page/document title
      setDocumentTitle = (title = "") => {
        document.title = title || get(this.props, "clientConfig.siteName", "");
      };

      /**
       * function to handle menu item click
       * @param {Object} event event from on click
       * @param {String} value value of the clicked menu event
       *
       */
      handleMenuItemClick = ({ event, value }) => {
        const { isDropDownOpen } = this.state;

        if (isDropDownOpen) {
          this.setState({
            isDropDownOpen: false
          });
        } else {
          this.setState({
            isActiveMenu: value
          });
        }
        this.handleMenuClick({ action: "close" });
      };

      handleSelectClick = ({ e, value }) => {
        if (value !== false) {
          e.stopPropagation();
        }
        this.setState(prev => {
          return {
            isDropDownOpen: value === false ? value : !prev.isDropDownOpen
          };
        });
      };

      onProfileClick = ({ isOpen }) => {
        this.setState({
          isProfileOpen: isOpen
        });
      };

      onBellIconClick = () => {
        this.setState({
          showAll: !this.state.showAll
        });
      };

      showAllAnnouncement = (e, origin = null, id) => {
        const POPUP_ANNOUNCEMENT_COUNT = 5;

        this.setState(
          {
            showAll: !this.state.showAll,
            selectedId: id
          },
          () => {
            this.fetchAnnouncementList(POPUP_ANNOUNCEMENT_COUNT);

            !this.state.showAll && this.props.clearAnnouncementList();
          }
        );
        if (origin !== "announcementPopup") {
          this.setState({
            selectedId: ""
          });
        }

        if (e.target !== e.currentTarget && origin !== "announcementPopup")
          return;
      };

      getLineHeight = reference => {
        const lineHeight = window.getComputedStyle(reference)["line-height"];
        if (lineHeight === "normal") {
          return (
            2 * parseFloat(window.getComputedStyle(reference)["font-size"])
          );
        } else {
          return parseFloat(lineHeight);
        }
      };

      limitCharacter = (reference, description, lines = 3) => {
        if (!reference) return;

        const truncateText = description || reference.innerHTML;
        const StrippedString = truncateText.replace(/(<([^>]+)>)/gi, "");
        reference.innerHTML = StrippedString;
        const truncateTextParts = truncateText.split(" ");
        const lineHeight = this.getLineHeight(reference);
        while (lines * lineHeight < reference.clientHeight) {
          truncateTextParts.pop();
          reference.innerHTML = truncateTextParts.join(" ") + "...";
        }
      };

      showAllContent = (reference, description) => {
        const truncateText = description || reference.innerHTML;
        if (reference) {
          let content1 = truncateText.replace(/(<p><\/p>)+/g, "</br>");
          reference.innerHTML = content1;
        }
      };
      /**
       *Callback function for dropdown option  click
       * @param event event object of the option
       * @param selectedTool The selected option data
       */
      handleOptionClick = ({ event, selectedTool }) => {
        event.preventDefault();
        this.setDocumentTitle(selectedTool.name);

        // Redirect to default page on change of tool
        if (selectedTool.name !== _.get(this, "state.selectedDropDown.name")) {
          this.props.history.push({
            pathname: "/home",
            state: {
              selectedTool
            }
          });
        }

        this.setState({
          selectedDropDown: selectedTool
        });
      };

      /**
       * Function to check if user has tool access
       *  @param {Array} access Array of accessible tools
       */
      _checkPermissions = () => {
        const { pathname } = this.props.location;
        let { toolOptions } = this.state;
        let tool = this.props.location.search.slice(0).split("=")[1];
        let selectedDropDown = toolOptions[0] || {};

        if (pathname && pathname.indexOf("presentation") > -1) {
          selectedDropDown = find(toolOptions, { value: "presentation" });
        } else if (pathname && pathname.indexOf("marketing-materials") > -1) {
          selectedDropDown = find(toolOptions, {
            value: "placemat"
          });
        } else if (pathname && pathname.indexOf("renewal") > -1) {
          selectedDropDown = find(toolOptions, {
            value: "renewal"
          });
        } else if (pathname && pathname.indexOf("summarymaker") > -1) {
          selectedDropDown = find(toolOptions, {
            value: "summary"
          });
        }

        // this is used when url has a query parameter like for user pages
        if (tool === "rg") {
          selectedDropDown = find(toolOptions, {
            value: "renewal"
          });
        } else if (tool === "pg") {
          selectedDropDown = find(toolOptions, { value: "presentation" });
        } else if (tool === "mg") {
          selectedDropDown = find(toolOptions, {
            value: "placemat"
          });
        } else if (tool === "sm") {
          selectedDropDown = find(toolOptions, {
            value: "summary"
          });
        }

        this.setState(
          {
            selectedDropDown
          },
          () => {
            this.setDocumentTitle(selectedDropDown.name);
            this._activeLink(pathname, selectedDropDown);
          }
        );
      };

      render() {
        let MainProps = {
          ...this,
          ...this.props,
          ...this.state,
          scrollBarWidth: this.scrollBarWidth || 0,
          handleMenuClick: this.handleMenuClick,
          handleMenuItemClick: this.handleMenuItemClick,
          handleSelectClick: this.handleSelectClick,
          onProfileClick: this.onProfileClick,
          handleOptionClick: this.handleOptionClick,
          _checkUserAcess: this._checkUserAcess
        };

        return <Main {...MainProps} />;
      }
    }
  );

export default container;
