//import network services
import * as services from "./services";

const mapStateToProps = state => {
  const {
    SUCCESS_THEME_LIST,
    LOADING_THEME_LIST
  } = state;
  return {
    ...SUCCESS_THEME_LIST,
    ...LOADING_THEME_LIST
  };
};

const actions = {
  ...services
};

export {
  mapStateToProps,
  actions
};
