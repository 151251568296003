import React from "react";
import styled from "styled-components";
import hexToRgba from "utils/hexToRgba";
import { Close } from "assets/icons";

const getColor = props => {
  if (props.isSelected) {
    return hexToRgba(props.theme.COLOR.USER_PRIMARY, "0.7");
  } else {
    return props.theme.COLOR_PALLETE.LIGHT_GREY;
  }
};

const Placeholder = props => {
  let {
    selectedThumbnail,
    selectedThumbnailId,
    layoutType,
    imageMatrix,
    onSelect,
    selectedPlaceholder,
    id,
    placeholderData,
    index,
    deleteSelectedModule
  } = props;

  let isSelected = !!(selectedPlaceholder && id === selectedPlaceholder);

  let placeholderText = `Select a ${placeholderData.layoutId.toLowerCase()} from left panel to add it here.`;

  if (selectedThumbnail) {
    return (
      <ImageWrapper
        isSelected={isSelected}
        layoutType={layoutType}
        onClick={e => {
          onSelect({
            event: e,
            index,
            id,
            layoutType,
            imageMatrix,
            ...placeholderData,
            selectedThumbnailId
          });
        }}
      >
        <Thumbnail src={selectedThumbnail} />
        <CloseIconWrapper>
          <CloseIcon
            onClick={e => {
              deleteSelectedModule({
                event: e,
                index,
                id,
                layoutType,
                ...placeholderData
              });
            }}
          />
        </CloseIconWrapper>
      </ImageWrapper>
    );
  } else {
    return (
      <BorderWrapper
        layoutType={layoutType}
        onClick={e => {
          onSelect({
            event: e,
            index,
            id,
            layoutType,
            imageMatrix,
            ...placeholderData,
            selectedThumbnailId: ""
          });
        }}
        isSelected={isSelected}
      >
        <TextWrapper show={isSelected}>
          <Text>{placeholderText}</Text>
        </TextWrapper>
      </BorderWrapper>
    );
  }
};

const BorderWrapper = styled.div`
  margin: 0 auto;
  border-radius: 4px;
  border: 2px dashed ${props => getColor(props)};
  background-color: ${props =>
    props.isSelected
      ? hexToRgba(props.theme.COLOR.USER_PRIMARY, "0.12")
      : props.theme.COLOR.CONTAINER};
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  position: relative;
`;

const TextWrapper = styled.div`
  width: 100%;
  padding: 0 4px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  display: ${props => (props.show ? `block` : `none`)};
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 1)};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Text = styled.span``;

const ImageWrapper = styled.div`
  border-radius: 4px;
  border: 2px dashed ${props => getColor(props)};
  position: relative;
  cursor: pointer;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: -14px;
  right: -13px;
  background-color: ${props => props.theme.COLOR.WHITE};
  width: 34px;
  border-radius: 50%;
  height: 34px;
  cursor: pointer;
`;

const CloseIcon = styled(Close)`
  transform: translate(-50%, -50%);
  margin-left: 50%;
  margin-top: 50%;
  width: 26px;
  height: 26px;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

export default Placeholder;
