import React, { Component } from "react";
import ValidationUtils from "utils/ValidationUtils";

const EMPTY_FEILD_ERROR_MESSAGE = "This field is required.";
const SPECIAL_CHAR_ERROR_MESSAGE = "Please do not enter the special character.";
const WHITE_SPACE_ERROR_MESSAGE = "Please enter a valid input.";

const Container = Main =>
  class CategoryTitleContainer extends Component {
    constructor(props) {
      super(props);
      this.InputRef = React.createRef();
      this.state = {
        categoryInput: false,
        isEdited: false
      };
    }

    checkValidation = value => {
      if (ValidationUtils.checkIfEmptyField(value)) {
        return EMPTY_FEILD_ERROR_MESSAGE;
      } else if (ValidationUtils.checkIfWhiteSpace(value)) {
        return WHITE_SPACE_ERROR_MESSAGE;
      } else if (ValidationUtils.checkIfspecialChar(value)) {
        return SPECIAL_CHAR_ERROR_MESSAGE;
      } else {
        return null;
      }
    };

    setCategoryInput = () => {
      this.setState({
        categoryInput: this.props.categoryIdToEdit === this.props._id
      });
    };

    checkIfEdited = e => {
      this.setState({
        isEdited: true
      });
      // set error value
      this.props.handleStateChange({
        key: "inputError",
        value: this.checkValidation(e.target.value)
      });

      // set flag if field is edited and set title
      this.props.handleStateChange({
        key: "categoryTitle",
        value: e.target.value
      });

      this.props.handleStateChange({ key: "isEditedFlag", value: true });
    };

    componentDidUpdate(prevProps) {
      if (this.props.categoryIdToEdit !== prevProps.categoryIdToEdit) {
        this.setCategoryInput();
      }
    }

    saveCategoryDetails = async (params = {}, id) => {
      let inputError = this.checkValidation(params.title);

      // set error value
      this.props.handleStateChange({
        key: "inputError",
        value: inputError
      });

      if (inputError) {
        return;
      }

      if (
        this.props.isEditedFlag &&
        (await this.props.onSave({ ...params }, id))
      ) {
        this.setState({
          categoryInput: false,
          isEdited: false
        });

        // set error value
        this.props.handleStateChange({
          key: "inputError",
          value: ""
        });

        // reset new scroll index and edited flag and category id
        this.props.handleStateChange({ key: "newFocusIndex", value: 0 });
        this.props.handleStateChange({ key: "isEditedFlag", value: false });
        this.props.handleStateChange({ key: "categoryIdToEdit", value: null });
      }
    };
    render() {
      const $this = this;
      const props = {
        ...$this,
        ...$this.state,
        ...$this.props
      };

      return <Main {...props} />;
    }
  };

export default Container;
