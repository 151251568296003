import React from "react";
import { Route } from "react-router-dom";
import profileContextWrapper from "utils/profileContextWrapper";

// App Components
import Users from "pages/users";

/**
 * Users Routes list
 * @param {Object} auth
 * @param {Function} checkAuthSession
 */
const userRoutes = (auth, checkAuthSession) => {
  const routes = (
    <>
      <Route
        exact
        path="/admin/users"
        render={props =>
          checkAuthSession(profileContextWrapper(Users, auth, props))
        }
      />
    </>
  );

  return routes;
};

export default userRoutes;
