import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";

/**
 * Get Content Repo List for presentation setup
 */
const setUserLoginCount = async () => {
  const URL = "/login";

  const response = await FetchUtils.postData(URL);

  if (!response.success) {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

export { setUserLoginCount };
