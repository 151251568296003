import React from "react";
import { Route } from "react-router-dom";
import { CategoryList, CategoryCreate } from "tools/marketingMaterial/pages";
import { Dashboard, Modules } from "tools/marketingMaterial/pages";
import featureFlags from "utils/featureFlags";

/**
 * Presentation Route list
 * @param {Object} auth
 * @param {Function} checkAuthSession
 */
const MarketingMaterialRoutesComponent = (auth, checkAuthSession) => {
  const routes = (
    <>
      {
        featureFlags.marketingMaterials.categoryDashboard &&
          <Route
          exact
          path="/marketing-materials/admin/categories"
          render={props =>
            checkAuthSession(<CategoryList auth={auth} {...props} />)
          }
        />
      }
      <Route
        exact
        path="/marketing-materials/admin/categories/create"
        render={props =>
          checkAuthSession(<CategoryCreate auth={auth} {...props} />)
        }
      />
      <Route
        exact
        path="/marketing-materials/admin/dashboard"
        render={props => checkAuthSession(<Dashboard auth={auth} {...props} />)}
      />
      <Route
        exact
        path="/marketing-materials/admin/modules"
        render={props => checkAuthSession(<Modules auth={auth} {...props} />)}
      />
      <Route
        path="/marketing-materials/admin/categories/edit/:id"
        render={props =>
          checkAuthSession(<CategoryCreate auth={auth} {...props} />)
        }
      />
    </>
  );

  return routes;
};

export default MarketingMaterialRoutesComponent;
