import React from "react";
import styled from "styled-components";
import StyledDropzone from "components/chooseFile";
import hexToRgba from "utils/hexToRgba";
import { get } from "lodash";

const Sidebar = props => {
  let { themeNameRef, themeNameError } = props;

  return (
    <>
      <HeadingContainer>
        <Title>Upload New Theme</Title>
      </HeadingContainer>
      <InputContainer>
        <TextLabel htmlFor="input-text">
          Enter Theme Name<sup>*</sup>
        </TextLabel>
        <TextInput id="input-text" type="text" ref={themeNameRef} />
        {themeNameError && <ErrorMessage>{themeNameError}</ErrorMessage>}
      </InputContainer>
      <UploadContainer>
        <TextLabel>
          Upload New Theme<sup>*</sup>
        </TextLabel>
        <StyledDropzone
          fileName={get(themeNameRef, `current.value`)}
          {...props}
        />
      </UploadContainer>
    </>
  );
};

const HeadingContainer = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  padding-bottom: 3px;
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  color: ${props => props.theme.COLOR.MAIN};
  font-weight: bold;
  border-bottom: 3px solid ${props =>
    hexToRgba(props.theme.COLOR.USER_PRIMARY, ".63")};
`;

const InputContainer = styled.div`
  margin-bottom: 30px;
  position: relative;
`;

const TextLabel = styled.label`
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.LIGHT_GREYISH};
  display: block;
`;

const TextInput = styled.input`
  ${props => props.theme.SNIPPETS.SHARED_INPUT_STYLE};
  text-transform: none;
  padding: 6px 8px;
`;

const UploadContainer = styled.div`
  margin-bottom: 20px;

  button {
    min-width: 90px;
    padding: 4px 0 6px;
  }

  .filename {
    word-break: break-all;
  }
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
  position: absolute;
`;

export default Sidebar;
