import * as types from "./types";

export const onGetClientConfig = (
  payload = null,
  type = types.CLIENT_CONFIG_SUCCESS
) => {
  return {
    payload,
    type
  };
};

export const isUserLoginLoading = (
  payload = {},
  type = types.CLIENT_CONFIG_LOADING
) => {
  return {
    type,
    payload
  };
};
