const companyDataValue = {
  companyName: {
    type: "text",
    label: "Company Name",
    error: "",
    value: "",
    id: "companyName",
    width: "half-width"
  },
  addressOne: {
    type: "text",
    label: "Address 1",
    error: "",
    value: "",
    id: "addr1",
    width: "full-width"
  },
  addressTwo: {
    type: "text",
    label: "Address 2",
    error: "",
    value: "",
    id: "addr2",
    width: "full-width"
  },
  city: {
    type: "text",
    label: "City",
    error: "",
    value: "",
    id: "city",
    width: "half-width"
  },
  state: {
    type: "text",
    label: "State/Prov",
    error: "",
    value: "",
    id: "state",
    width: "half-width"
  },
  postalCode: {
    type: "number",
    label: "Postal Code",
    error: "",
    value: "",
    id: "postal",
    width: "half-width"
  },
  country: {
    type: "text",
    label: "Country",
    error: "",
    value: "",
    id: "country",
    width: "half-width"
  }
};

const primaryDataValue = {
  givenName: {
    type: "text",
    label: "First Name",
    error: "",
    value: "",
    id: "firstName",
    width: "half-width",
    isReadOnly: false
  },
  familyName: {
    type: "text",
    label: "Last Name",
    error: "",
    value: "",
    id: "lastName",
    width: "half-width",
    isReadOnly: false
  },
  email: {
    type: "email",
    label: "Email",
    error: "",
    value: "",
    id: "email",
    width: "half-width",
    isReadOnly: false
  },
  phone: {
    type: "text",
    label: "Phone",
    error: "",
    value: "",
    id: "phone",
    width: "half-width"
  },
  supportEmail: {
    type: "email",
    label: "Support Email",
    error: "",
    value: "",
    id: "support",
    width: "half-width"
  }
};

export { companyDataValue, primaryDataValue };
