import React from "react";
import styled from "styled-components";

// sortable tree
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";
import CategoryTitleComponent from "./components/categoryTitle";

import RightArrow from "assets/icons/right_arrow.svg";
import DownArrow from "assets/icons/dropdown.svg";

import Container from "./container";

const SortableTreeContainer = props => {
  const { imageFolderData, handleTreeChange } = props;

  let treeData = imageFolderData;
  let maxDepth = 3;

  return (
    <FolderListContainer>
      <ContainerSortable className="sort-container">
        <SortableTree
          className={"sortable-wrapper"}
          treeData={treeData}
          onChange={handleTreeChange}
          theme={FileExplorerTheme}
          rowHeight={37}
          innerStyle={{ padding: "0 20px" }}
          maxDepth={maxDepth}
          canDrag={false}
          generateNodeProps={rowInfo => {
            let { node } = rowInfo;
            let { level } = node;

            return {
              className: `level${level}`,
              title: (
                <CategoryTitleComponent
                  {...node}
                  rowInfo={rowInfo}
                  {...props}
                />
              )
            };
          }}
          getNodeKey={({ node }) => node._id}
        />
      </ContainerSortable>
    </FolderListContainer>
  );
};

const ContainerSortable = styled.div`
  width: 100%;
  height: 290px;
  position: absolute;
  overflow-y: hidden;

  &::after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    height: 10px;
    bottom: -10px;
    box-shadow: -1px -7px 7px ${props => props.theme.COLOR.WHITE};
  }

  .rstcustom__expandButton {
    &::after {
      border: none;
      width: 16px;
      height: 16px;
      top: 10px;
      background: url(${RightArrow}) no-repeat;
      background-size: contain;
      transform: none;
    }

    &:focus::after {
      filter: none;
    }
  }

  .rstcustom__collapseButton {
    &::after {
      border: none;
      width: 16px;
      height: 16px;
      top: 16px;
      left: 18px;
      background: url(${DownArrow}) no-repeat;
      background-size: contain;
    }

    &:focus::after {
      filter: none;
    }
  }

  .rstcustom__node {
    color: ${props => props.theme.COLOR.MAIN};
    ${props => props.theme.SNIPPETS.FONT_STYLE};
    font-size: 14px;
    font-weight: bold;

    & > div {
      position: relative;
    }
  }

  .rstcustom__rowWrapper {
    & > div {
      width: 90%;
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .rst__virtualScrollOverride {
    overflow-x: hidden !important;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 4px;
      outline: 1px solid slategrey;
    }
  }
  .ReactVirtualized__Grid__innerScrollContainer {
    margin-top: 10px;
  }

  .rstcustom__rowToolbar {
    display: none;
    position: absolute;
    right: -20px;
  }

  .rstcustom__rowContents {
    width: 100%;
    position: static;
    &:hover {
      ${props =>
        !props.isEditedFlag && `.rstcustom__rowToolbar{ display: block }`}
    }
  }

  .rstcustom__row {
    width: 100%;
    position: static;
  }

  .rstcustom__rowLabel {
    width: 100%;
  }

  .rstcustom__lineBlock {
    width: 15px !important;
  }

  .level0 {
    .cat-title-input {
      width: 77%;
    }

    .rstcustom__rowLabel {
      padding-left: 10px;
    }
  }

  .level1 {
    .cat-title-input {
      width: 77%;
    }
    .rstcustom__rowLabel {
      padding-left: 20px;
    }
  }

  .level2 {
    .rstcustom__rowLabel {
      padding-left: 25px;
    }
  }
`;

const FolderListContainer = styled.div`
  .elem-selected {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

export default Container(SortableTreeContainer);
