import React from "react";
import styled from "styled-components";

// sortable tree
import SortableTree, { walk } from "react-sortable-tree";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";
import CategoryTitleComponent from "./components/categoryTitle";

import RightArrow from "assets/icons/right_arrow.svg";
import DownArrow from "assets/icons/dropdown.svg";
import { Folder, FolderOpen } from "assets/icons";

const SortableTreeContainer = props => {
  const {
    imageFolder,
    handleTreeOnChange,
    generateNodeProps,
    categoryIdToEdit,
    isEditedFlag,
    onMoveNode,
    selectedTab,
    imageCategory,
    selectedParentELem,
    newFocusIndex,
    imageFolderDetails,
    selectFolderHandler
  } = props;

  let treeData = selectedTab === "folder" ? imageFolder : imageCategory;
  let maxDepth = selectedTab === "folder" ? 3 : 2;

  //keep expanded parent when selected to hide/unhide/delete/edit
  walk({
    treeData,
    getNodeKey: ({ node }) => node._id,
    callback: rowInfo => {
      let { node } = rowInfo;
      if (selectedParentELem.indexOf(node._id) > -1) node.expanded = true;
    },
    ignoreCollapsed: false
  });

  let reactVitualizedProp = newFocusIndex
    ? { scrollToIndex: newFocusIndex }
    : {};

  return (
    <FolderListContainer>
      {selectedTab === "folder" && (
        <AllButton
          onClick={() => {
            selectFolderHandler({
              _id: "",
              title: "All"
            });
          }}
          className={
            (imageFolderDetails || {})._id === "" ? "elem-selected" : null
          }
        >
          {(imageFolderDetails || {})._id === "" ? (
            <FolderOpen className="svg-icon" />
          ) : (
            <Folder className="svg-icon" />
          )}
          All
        </AllButton>
      )}

      <ContainerSortable
        className="sort-container"
        categoryIdToEdit={categoryIdToEdit}
        selectedTab={selectedTab}
        applyTopSpace={selectedTab === "folder"}
      >
        <SortableTree
          className={"sortable-wrapper"}
          treeData={treeData}
          onChange={handleTreeOnChange}
          theme={FileExplorerTheme}
          rowHeight={39}
          innerStyle={{ padding: "0 20px" }}
          canDrag={!isEditedFlag}
          maxDepth={maxDepth}
          generateNodeProps={rowInfo => {
            let { node } = rowInfo;
            let { _id, level } = node;

            return {
              className: `level${level}`,
              title: (
                <CategoryTitleComponent
                  {...node}
                  rowInfo={rowInfo}
                  categoryInput={categoryIdToEdit === _id}
                  isEditedFlag={isEditedFlag}
                  {...props}
                />
              ),
              buttons: (generateNodeProps && generateNodeProps(rowInfo)) || []
            };
          }}
          onMoveNode={onMoveNode}
          getNodeKey={({ node }) => node._id}
          onVisibilityToggle={({ node, expanded }) => {
            // check elements for multiple items toggled together
            let selectedParentIsArray = Array.isArray(selectedParentELem);
            let nodeIndex = selectedParentIsArray
              ? selectedParentELem.indexOf(node._id)
              : -1;

            if (expanded && selectedParentIsArray && nodeIndex === -1) {
              selectedParentELem.push(node._id);
            } else if (nodeIndex > -1 && selectedParentIsArray) {
              selectedParentELem.splice(nodeIndex, 1);
            }
          }}
          reactVirtualizedListProps={reactVitualizedProp}
        />
      </ContainerSortable>
    </FolderListContainer>
  );
};

const ContainerSortable = styled.div`
  width: 100%;
  height: ${props => (props.selectedTab === "folder" ? "434px" : "483px")};
  position: absolute;
  overflow-y: hidden;

  .rstcustom__expandButton {
    &::after {
      border: none;
      width: 16px;
      height: 16px;
      top: 10px;
      background: url(${RightArrow}) no-repeat;
      background-size: contain;
      transform: none;
      left: 11px;
    }

    &:focus::after {
      filter: none;
    }
  }

  .rstcustom__collapseButton {
    &::after {
      border: none;
      width: 16px;
      height: 16px;
      top: 16px;
      left: 18px;
      background: url(${DownArrow}) no-repeat;
      background-size: contain;
    }

    &:focus::after {
      filter: none;
    }
  }

  .rstcustom__node {
    color: ${props => props.theme.COLOR.MAIN};
    ${props => props.theme.SNIPPETS.FONT_STYLE};
    font-size: 14px;
    font-weight: bold;

    & > div {
      position: relative;
    }
  }

  .rstcustom__rowWrapper {
    & > div {
      width: 100%;
      height: 100%;
      text-overflow: ellipsis;
    }
  }

  .rst__virtualScrollOverride {
    overflow-x: hidden !important;
  }
  .ReactVirtualized__Grid__innerScrollContainer {
    ${props => !props.applyTopSpace && "margin-top: 10px;"}
  }

  .rstcustom__rowToolbar {
    display: none;
    position: absolute;
    right: -20px;
  }

  .rstcustom__rowContents {
    width: 100%;
  }

  .rstcustom__node {
    &:hover {
      ${props =>
        !props.categoryIdToEdit && `.rstcustom__rowToolbar{ display: block }`}
    }
  }

  .rstcustom__row {
    width: 100%;
  }

  .rstcustom__rowLabel {
    width: 100%;
  }

  .rstcustom__lineBlock {
    width: 15px !important;
  }

  .level0 {
    width: calc(100% - 15px);
    .cat-title-input {
      width: 89%;
    }

    .rstcustom__rowLabel {
      padding-left: 10px;
    }
  }

  .level1 {
    width: calc(100% - 30px);
    .cat-title-input {
      width: 78%;
    }
    .rstcustom__rowLabel {
      padding-left: 20px;
    }
  }

  .level2 {
    width: calc(100% - 45px);
    .rstcustom__rowLabel {
      padding-left: 25px;
    }
  }

  .rstcustom__rowLandingPad::before {
    border: 2px dashed ${props => props.theme.COLOR.WHITE};
  }

  /* hide blue line */
  .rstcustom__highlightLineVertical::before {
    width: 0;
    border: none !important;
  }

  .rstcustom__highlightLineVertical::after,
  .rstcustom__highlightBottomLeftCorner::after {
    border: none !important;
  }

  .rstcustom__highlightTopLeftCorner::before {
    border: none !important;
  }

  .rstcustom__highlightBottomLeftCorner::before {
    border: none !important;
    width: 0;
  }
  /* hide blue line end */
`;

const AllButton = styled.button`
  padding-left: 33px;
  border: none;
  margin-top: 22px;
  display: block;
  margin-bottom: 10px;

  background: none;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  cursor: pointer;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 12px;
  font-weight: bold;
  outline: none;

  .svg-icon {
    width: 14px;
    height: 12px;
    position: relative;
    top: 2px;
    left: -9px;
  }
`;

const FolderListContainer = styled.div`
  .elem-selected {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

export default SortableTreeContainer;
