import React from "react";
import Container from "./container";
import styled from "styled-components";
import Button, { SecondaryButton } from "components/button";
import { default as ReactSelect } from "react-select";
import hexToRgba from "utils/hexToRgba";
import CategoryLevelManager from "./components/FilterList/sortable";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import FullPageLoader from "components/FullPageLoader";
const Filters = props => {
  const Message =
    "Are you sure you want to do this? All information on left will be lost.";
  const DropdownInfoText =
    "Select an existing filter set from other repositories.";

  const SelectHeadingText = "Select Existing Filters";
  const RightSideFilterHeading = "List of filters";
  const EmptyContentRepoMessage =
    "The selected Content Repo does not have any filters associated with it.";
  return (
    <FilterContainer>
      {(props.filtersListLoading || props.isLoading) && (
        <FullPageLoader layerIndex="21" />
      )}
      <FilterTop>
        <FilterInfoContainer>
          <FilterHeading>Filters</FilterHeading>
        </FilterInfoContainer>
        <Button
          onClick={() => {
            props.onAddNewClick();
          }}
          text="Add New"
          width="120px"
          buttonClass="add-new"
        />
      </FilterTop>
      <FiltersContainerWrapper>
        <FilterLeftContainer>
          {Object.keys(props.treeData).length ? (
            <CategoryLevelManager {...props} />
          ) : null}
        </FilterLeftContainer>
        <FilterRightContainer
          className={props.addNew && "add-new"}
          selectedValue={props.selectedValue}
        >
          {!props.addNew && (
            <>
              <SelectHeading>{SelectHeadingText}</SelectHeading>
              <DropdownContainer>
                <DropdownInfo>{DropdownInfoText}</DropdownInfo>
                <ReactSelect
                  options={
                    props.contentRepoList &&
                    props.contentRepoList.map(({ name, _id }) => {
                      return {
                        value: name,
                        label: name,
                        _id
                      };
                    })
                  }
                  isClearable
                  placeholder="Content Repo"
                  onChange={selectedOption =>
                    props.onExistingFilterChange(selectedOption)
                  }
                  className="custom-select"
                  classNamePrefix="custom-select"
                />
              </DropdownContainer>
              {props.selectedValue && props.selectedValue.length ? (
                <FiltersList>
                  <FilterListHeading>
                    {RightSideFilterHeading}
                  </FilterListHeading>
                  <FiltersListLeftSide className="parent">
                    {props.selectedValue.map((item, index) => {
                      return (
                        <FilterListParent className="parentlist" key={index}>
                          {item.title}
                          {item.children.map((subitem, i) => {
                            return (
                              <FilterListChild key={i} className="childlist">
                                <FilterListGrandChild>
                                  --{subitem.title}
                                </FilterListGrandChild>
                              </FilterListChild>
                            );
                          })}
                        </FilterListParent>
                      );
                    })}
                  </FiltersListLeftSide>
                </FiltersList>
              ) : props.selectedRepo && props.selectedRepo ? (
                <EmptyContentRepo>{EmptyContentRepoMessage}</EmptyContentRepo>
              ) : null}
              <Button
                text="Select Filter"
                width="100%"
                buttonClass="select-filter"
                onClick={() => {
                  props.treeData.length
                    ? DeleteConfirmationAlert({
                        message: Message,
                        onYesClick: () => {
                          props.updateExisting();
                        }
                      })
                    : props.updateExisting();
                }}
                isDisabled={!props.selectedValue.length}
              />
            </>
          )}
          {props.addNew && (
            <FormContainerMain>
              <Title>Add Filter</Title>
              <FormContainer>
                <DropdownContainer>
                  <ReactSelect
                    options={
                      props.filtersList &&
                      props.filtersList.map(({ title, _id }) => {
                        return {
                          value: title,
                          label: title,
                          _id
                        };
                      })
                    }
                    value={props.filterSelected}
                    placeholder="Select Parent"
                    onChange={selectedOption =>
                      props.onDropdownChange(selectedOption)
                    }
                    isClearable
                    className="custom-select"
                    classNamePrefix="custom-select"
                  />
                </DropdownContainer>
                <InputContainer>
                  <Label>Filter Name*</Label>
                  <InputBox
                    value={props.createFilterForm.name.value}
                    onChange={e => props.checkFilterInput(e.target.value)}
                  />
                  {props.createFilterForm.name.errorMessage && (
                    <Error>{props.createFilterForm.name.errorMessage}</Error>
                  )}
                </InputContainer>
                <CtaContainer>
                  <Button
                    text="Save"
                    width="calc(50% - 2px)"
                    float="none"
                    buttonClass="save"
                    onClick={() => props.onSaveFilter()}
                  />
                  <SecondaryButton
                    text="Cancel"
                    width="calc(50% - 2px)"
                    float="none"
                    buttonClass="cancel"
                    onClick={() => props.onCancelClick()}
                  />
                </CtaContainer>
              </FormContainer>
            </FormContainerMain>
          )}
        </FilterRightContainer>
      </FiltersContainerWrapper>
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  width: ${props => props.theme.WRAPPER.WIDTH};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  margin: 106px auto 30px;
  padding: 40px 0;

  @media (max-width: 1024px) {
    width: calc(100% - 80px);
  }
`;

const Error = styled.span`
  min-width: 191px;
  position: absolute;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.ERROR, "1")};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 10px;
  font-weight: normal;
  bottom: -12px;
  left: 0;
  pointer-events: none;
`;

const InputContainer = styled.div`
  text-align: left;
  position: relative;
`;

const FormContainerMain = styled.div``;

const FormContainer = styled.div``;

const Title = styled.h4`
  padding-bottom: 4px;
  border-bottom: 3px solid
    ${props => hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.63)};
  margin-bottom: 23px;
  display: inline-block;
  color: ${props => props.theme.COLOR.MAIN};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-weight: bold;
`;

const CtaContainer = styled.div`
  margin-top: 24px;
  button {
    display: inline-block;
    vertical-align: top;
  }
  .save {
    height: 40px;
    margin-right: 4px;
  }

  .cancel {
    height: 40px;
    border-radius: 3px;
    font-size: 14px;
  }
`;

const Label = styled.label`
  margin-bottom: 7px;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, "0.64")};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  font-weight: bold;
  transition: 0.3s ease all;
  display: inline-block;
`;

const InputBox = styled.input`
  width: 100%;
  height: 30px;
  padding: 5px 10px;
  font-size: 14px;
  font-family: ${props => props.theme.FONT.REG};
  background-color: ${props => hexToRgba(props.theme.COLOR.WHITE, "0.18")};
  border: 1px solid
    ${props => hexToRgba(props.theme.COLOR_PALLETE.LIGHT_GREY, "0.4")};
  border-radius: 4px;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`;

const FiltersContainerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const FilterLeftContainer = styled.div`
  height: 563px;
  padding: 28px 3%;
  flex-basis: calc(68.5% - 12px);
  background: ${props => props.theme.COLOR.WHITE};
  box-shadow: 4px 8px 15px 0
    ${props => hexToRgba(props.theme.COLOR.DEAD_BLACK, 0.14)};
`;

const FilterRightContainer = styled.div`
  min-height: 563px;
  padding: 28px 1.2%;
  flex-basis: calc(31.33% - 12px);
  background: ${props => props.theme.COLOR.WHITE};
  box-shadow: 4px 8px 15px 0
    ${props => hexToRgba(props.theme.COLOR.DEAD_BLACK, 0.14)};
  position: relative;
  text-align: center;

  &.add-new {
    min-height: auto;
  }

  .select-filter {
    width: 92%;
    height: auto;
    padding: 11px 0;
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    opacity:${props =>
      props.selectedValue && props.selectedValue.length ? "1" : "0.5"}
    pointer-events:${props =>
      props.selectedValue && props.selectedValue.length ? "auto" : "none"}
  }
`;

const FilterTop = styled.div`
  margin-bottom: 28px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .add-new {
    height: auto;
    padding: 6px 0;
    float: initial;
  }
`;

const FilterInfoContainer = styled.div`
  flex-basis: 52.1%;
`;

const FilterHeading = styled.span`
  margin-bottom: 11px;
  color: ${props => props.theme.COLOR.HEADING};
  display: inline-block;
  font-weight: bold;
  font-size: 20px;
`;

const SelectHeading = styled.span`
  padding-bottom: 4px;
  border-bottom: 3px solid
    ${props => hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.63)};
  margin-bottom: 24px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
`;

const DropdownContainer = styled.div`
  text-align: left;

  .custom-select {
    margin-bottom: 19px;
  }

  /*Override custom select list*/
  .custom-select__control {
    /*When select list is focuses*/
    &--is-focused {
      border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      box-shadow: 0 0 0 1px ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }

    /*When select list is in active state*/
    &--menu-is-open {
      border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      box-shadow: 0 0 0 1px ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }

  /*Overriding option styling*/
  .custom-select__menu {
    > div > div {
      background-color: ${props => props.theme.COLOR.WHITE};

      &:hover {
        background-color: ${props =>
          hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.2)};
      }
    }

    .custom-select__option--is-selected {
      background-color: ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        background-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }
`;

const DropdownInfo = styled.p`
  margin-bottom: 6px;
  display: block;
  font-size: 10px;
  line-height: 1.5;
  color: ${props => props.theme.COLOR.HEADING};
`;

const FilterListHeading = styled.p`
  font-weight: 800;
`;

const FiltersList = styled.div`
  text-align: initial;
  padding-top: 45px;
  > ul {
    margin-bottom: 80px;
  }
  
`;
const FiltersListLeftSide = styled.ul``;

const FilterListParent = styled.li`
  margin-top: 15px;
`;

const FilterListChild = styled.ul``;

const FilterListGrandChild = styled.li`
  margin-top: 5px;
`;

const EmptyContentRepo = styled.p`
  text-align: center;
`;

export default Container(Filters);
