import { SummaryMakerImages } from "assets/images";

let data = [
  {
    _id: "5e5baafe33f3910d4c9360c3",
    templateName: "Open Enrollment - FI",
    imageSrc: SummaryMakerImages.Template1,
    description: "Open Enrollment - FI"
  },
  {
    _id: "5e5baafe33f3910d4c9360c6",
    templateName: "Open Enrollment - ASO",
    imageSrc: SummaryMakerImages.Template2,
    description: "Open Enrollment - ASO"
  },
  {
    _id: "5e5baafe33f3910d4c9360c7",
    templateName: "Open Enrollment - FI",
    imageSrc: SummaryMakerImages.Template3,
    description: "Open Enrollment - FI"
  }
];

let tableMetaData = [
  {
    templateName: "Flier",
    fields: [
      {
        label: "Name",
        key: "name",
        order: 1,
        isRequired: true
      },
      {
        label: "Customer Number",
        key: "customerNumber",
        order: 2,
        isRequired: true
      },
      {
        label: "Policy Number",
        key: "policyNumber",
        order: 3,
        isRequired: true
      },
      {
        label: "Renewal Date",
        key: "renewalDate",
        order: 5,
        isRequired: true
      }
    ],
    actionFields: ["canDelete", "canShare", "canClone"],
    searchFields: ["name", "customerNumber"]
  }
];

let documentList = [
  {
    name: "Document 1",
    customerNumber: "12345",
    policyNumber: "12345",
    renewalDate: "2020-03-13T14:05:02.559Z"
  },
  {
    name: "Document 2",
    customerNumber: "12345",
    policyNumber: "12345",
    renewalDate: "2020-03-13T14:05:02.559Z"
  },
  {
    name: "Document 3",
    customerNumber: "12345",
    policyNumber: "12345",
    renewalDate: "2020-03-13T14:05:02.559Z"
  },
  {
    name: "Lorem ipsum",
    customerNumber: "12345",
    policyNumber: "12345",
    renewalDate: "2020-03-13T14:05:02.559Z"
  },
  {
    name: "zzzz",
    customerNumber: "12345",
    policyNumber: "12345",
    renewalDate: "2020-03-13T14:05:02.559Z"
  }
];

export default data;
export { tableMetaData, documentList };
