import React, { Component } from "react";
import ValidationUtils from "utils/ValidationUtils";

const EMPTY_FEILD_ERROR_MESSAGE = "This field is required.";
const SPECIAL_CHAR_ERROR_MESSAGE = "Please do not enter the special character.";
const WHITE_SPACE_ERROR_MESSAGE = "Please enter a valid input.";

const Container = Main =>
  class CategoryTitleContainer extends Component {
    constructor(props) {
      super(props);
      this.InputRef = React.createRef();
      this.state = {
        categoryInput: false,
        isEdited: false,
        inputError: ""
      };
    }

    checkValidation = value => {
      if (ValidationUtils.checkIfEmptyField(value)) {
        return EMPTY_FEILD_ERROR_MESSAGE;
      } else if (ValidationUtils.checkIfWhiteSpace(value)) {
        return WHITE_SPACE_ERROR_MESSAGE;
      } else if (ValidationUtils.checkIfspecialChar(value)) {
        return SPECIAL_CHAR_ERROR_MESSAGE;
      } else {
        return null;
      }
    };

    setCategoryInput = () => {
      this.setState({
        categoryInput: this.props.categoryIdToEdit === this.props._id,
        inputError: ""
      });
    };

    checkIfEdited = e => {
      this.setState({
        isEdited: true,
        inputError: this.checkValidation(e.target.value)
      });
    };

    componentDidUpdate(prevProps) {
      if (this.props.categoryIdToEdit !== prevProps.categoryIdToEdit) {
        this.setCategoryInput();
      }
    }

    saveCategoryDetails = (params = {}) => {
      if (this.state.inputError) {
        return;
      }

      this.state.isEdited && this.props.onSave({ ...params }, "save");
      this.setState({
        categoryInput: false,
        isEdited: false,
        inputError: ""
      });
      this.props.setResetCategory(null);
    };
    render() {
      const $this = this;
      const props = {
        ...$this,
        ...$this.state,
        ...$this.props
      };

      return <Main {...props} />;
    }
  };

export default Container;
