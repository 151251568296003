import React, { Component } from "react";

const Container = Main =>
  class GearModal extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isOverlayOpen: false
      };
    }

    /**
     * Function to handle Modal state
     */
    onGearClick = () => {
      let { onGearClickHandler } = this.props;
      this.setState({
        isOverlayOpen: !this.state.isOverlayOpen
      });

      //callback function
      onGearClickHandler && onGearClickHandler();
    };

    /**
     * Function to Save the Changes
     */
    saveChangesHandler = () => {
      let { saveChanges } = this.props;
      this.setState({
        isOverlayOpen: !this.state.isOverlayOpen
      });
      //callback function
      saveChanges && saveChanges();
    };

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      };
      return <Main {...stateMethodProps} />;
    }
  };

export default Container;
