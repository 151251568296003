import React from "react";
import hexToRgba from "utils/hexToRgba";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";

import "react-datepicker/dist/react-datepicker.css";

import { Attach, Dropdown as DropdownIcon } from "assets/icons";
import Checkbox from "components/checkbox";

const InputField = props => {
  let {
    type,
    id,
    handleChange,
    placeholder,
    name,
    error,
    value,
    width,
    isFixedTitle,
    limit,
    option,
    fileName,
    filePreview
  } = props;
  let $isError = !!error;
  let fileTypeReference = {};

  return (
    <FormGroup width={width} ischeckbox={type === "checkbox"}>
      {type !== "checkbox" && (
        <Label isFixedTitle={isFixedTitle}>{placeholder}</Label>
      )}
      {type === "textarea" && (
        <TextBox>
          <InputArea
            className="form-control"
            type={type}
            id={id}
            name={name}
            value={value}
            autoComplete="anyrandomstring"
            maxLength={limit || 250}
            onChange={e => handleChange(id, e.target.value)}
          />
          <DisplayLimit>Max {limit} characters allowed.</DisplayLimit>
        </TextBox>
      )}

      {type === "file" && (
        <UploadSlideWrapper>
          <UploadedSlideFileName
            onClick={() => {
              fileTypeReference[id].click();
            }}
          >
            {fileName}
          </UploadedSlideFileName>
          <UploadFileInputWrapper>
            <UploadFileInput
              type={type}
              title="Attach"
              accept="image/x-png,image/jpeg,.ppt, .pptx"
              onChange={e => {
                props.onLayoutUpload(e, id);
              }}
              onClick={e => {
                //need to be done to accept/trigger on change
                e.target.value = null;
              }}
              ref={ref => (fileTypeReference[id] = ref)}
            />
            <UploadButton>
              <AttachIcon />
            </UploadButton>
          </UploadFileInputWrapper>
          <DisplayLimit>Only .jpg, .png or .pptx formats allowed</DisplayLimit>
          {filePreview && (
            <ImagePreview>
              <img src={filePreview} alt="Preview layout" />
            </ImagePreview>
          )}
        </UploadSlideWrapper>
      )}

      {type === "dropdown" && (
        <>
          <ParentSlideComponent>
            <AngleDown>
              <DropdownIcon />
            </AngleDown>
            <DropDown
              autoFocus={true}
              className="category-select"
              onChange={e => handleChange(id, e.target.value)}
              value={value}
            >
              {_.map(option, (item, index) => {
                let itemKey =
                  item instanceof Object ? _.get(item, "_id") : item;
                let itemValue =
                  item instanceof Object ? _.get(item, "title") : item;
                //split the value at double hyphen in order to get the proper name of the sub categories
                let actualValue = itemValue.split("--");

                return (
                  <DropDownOption
                    value={
                      actualValue.length > 1 ? actualValue[1] : actualValue[0]
                    }
                    className="select-options"
                    key={itemKey + index}
                    disabled={index === 0}
                  >
                    {itemValue}
                  </DropDownOption>
                );
              })}
            </DropDown>
          </ParentSlideComponent>
        </>
      )}

      {type === "checkbox" && (
        <Checkbox
          label={placeholder}
          id={id}
          handleChange={e => handleChange(id, e.target.checked)}
          checked={value}
        />
      )}

      {type === "input" && (
        <InputBox
          className="form-control"
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={e => handleChange(e.target.id, e.target.value)}
          isError={$isError}
          autoComplete="anyrandomstring"
          required
        />
      )}

      {$isError && <Error>{error}</Error>}
    </FormGroup>
  );
};

InputField.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.number,
  handleChange: PropTypes.func,
  handleFocus: PropTypes.func,
  isFixedTitle: PropTypes.bool,
  hint: PropTypes.string
};

InputField.defaultProps = {
  type: "text",
  isDisabled: false,
  placeholder: "Placeholder",
  name: "Name",
  error: "",
  isFixedTitle: true,
  hint: ""
};

const TextBox = styled.div``;

const InputBox = styled.input`
  width: 100%;
  height: 30px;
  padding: 5px 10px;
  font-size: 14px;
  font-family: ${props => props.theme.FONT.REG};
  background-color: ${props => hexToRgba(props.theme.COLOR.WHITE, "0.18")};
  border: 1px solid
    ${props => hexToRgba(props.theme.COLOR_PALLETE.LIGHT_GREY, "0.4")};
  border-radius: 4px;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`;

const InputArea = styled.textarea`
  width: 100%;
  height: 120px;
  padding: 5px 10px;
  font-size: 14px;
  font-family: ${props => props.theme.FONT.REG};
  background-color: ${props => hexToRgba(props.theme.COLOR.WHITE, "0.18")};
  border: 1px solid
    ${props =>
      props.isError
        ? props.theme.COLOR_PALLETE.RED
        : hexToRgba(props.theme.COLOR_PALLETE.LIGHT_GREY, "0.4")};
  border-radius: 4px;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  box-sizing: border-box;
  resize: none;

  &:focus {
    outline: none;
  }
`;

const FormGroup = styled.div`
  padding-top: ${props => (props.ischeckbox ? "0px" : "18px")};
  max-width: ${props => (props.width ? `${props.width}px` : "auto")};
  width: 100%;
  border-radius: 4px;
  position: relative;
`;

const Error = styled.span`
  min-width: 191px;
  position: absolute;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.ERROR, "1")};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 10px;
  font-weight: normal;
  bottom: -12px;
  left: 0;
  pointer-events: none;
`;

const Label = styled.label`
  position: absolute;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, "0.64")};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  font-weight: bold;
  top: 22px;
  left: 10px;
  transition: 0.3s ease all;

  ${props =>
    props.isFixedTitle
      ? `
      top: 0px;
      left: 0px;  
      font-weight: bold;
    `
      : `${InputBox}:focus ~ &,
    ${InputBox}:not(:focus):valid ~ & {
      transform: translateY(-22px);
      font-weight: bold;
      left: 0;
    }}`}
`;

//Uploat file styling

const UploadSlideWrapper = styled.div`
  cursor: pointer;
`;

const UploadedSlideFileName = styled.div`
  box-sizing: border-box;
  width: calc(100% - 30px);
  height: 30px;
  padding: 7px 10px 5px;
  border: 1px solid
    ${props => hexToRgba(props.theme.COLOR_PALLETE.LIGHT_GREY, "0.4")};
  border-radius: 4px;
  display: inline-block;
  vertical-align: top;
  background-color: ${props => hexToRgba(props.theme.COLOR.WHITE, "0.18")};
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.8)};
  font-family: ${props => props.theme.FONT.LATO};
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:focus {
    outline: none;
  }
`;

const UploadFileInput = styled.input`
  width: 30px;
  height: 30px;
  padding-left: 30px;
  vertical-align: top;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  text-indent: -9999px;
`;

const UploadFileInputWrapper = styled.div`
  width: 30px;
  display: inline-block;
  vertical-align: top;
  position: relative;
`;

const UploadButton = styled.span`
  width: 30px;
  height: 30px;
  display: inline-block;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: ${props => props.theme.COLOR.USER_PRIMARY};
  text-align: center;
`;

const AttachIcon = styled(Attach)`
  width: 13px;
  height: 13px;
  margin-top: 8px;
  cursor: pointer;
`;

const DisplayLimit = styled.span`
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};
`;

//Dropdown
const AngleDown = styled.label`
  position: absolute;
  padding-top: 5px;
  right: 0;
  width: 31px;
  height: 20px;
  top: 0;
  z-index: -1;
  height: 30px;
  background: ${props => props.theme.COLOR_PALLETE.SOLITUDE};
`;

const ParentSlideComponent = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 4px;
  background: ${props => props.theme.COLOR_PALLETE.SOLITUDE};
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
`;

const DropDown = styled.select`
  width: 100%;
  height: 30px;
  padding: 0 9px;
  border: none;
  border-radius: 4px;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};
  font-family: ${props => props.theme.FONT.REG};
  background: transparent;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  line-height: 30px;
  cursor: pointer;
  outline: none;
  padding-right: 40px;
`;

const ImagePreview = styled.figure`
  margin-top: 5px;
  cursor: auto;
  img {
    width: 100%;
  }
`;

const DropDownOption = styled.option``;

export default InputField;
