import React from "react";
import styled, { css } from "styled-components";

//generate admin module repo header

const sortableTreeHeader = () => {
  return (
    <CreateContentTabs>
      <TableColumns>
        <CategoryName>Themes Name</CategoryName>
        <CreatedDate>Modified Date</CreatedDate>
        <DefaultThemeHeading>Default Theme</DefaultThemeHeading>
      </TableColumns>
    </CreateContentTabs>
  );
};
export default sortableTreeHeader;

// Admin Module Header Styled components
const LabelSharedCSS = css`
  padding: 0;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  opacity: 0.64;
  outline: none;
  border: none;
  background: transparent;
  display: inline-block;
  vertical-align: top;
  text-align: left;
`;

const CategoryName = styled.button`
  ${LabelSharedCSS}
  float: left;
  margin: 0 36px 0 32px;
`;

const CreatedDate = styled.button`
  ${LabelSharedCSS}
`;

const DefaultThemeHeading = styled.button`
  ${LabelSharedCSS};
  margin-left: 25px;
`;

const CreateContentTabs = styled.div`
  max-height: 64px;
  border-radius: 3px;
  margin-bottom: 11px;
  background: ${props => props.theme.COLOR.WHITE};
  position: relative;
  z-index: 1;
  padding: 17px 32px 17px 40px;
  ${props => props.theme.SNIPPETS.BOX_SHADOW}

  &:after {
    content: " ";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
`;

const TableColumns = styled.div`
  margin-left: 111px;
`;
