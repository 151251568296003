import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import hexToRgba from "utils/hexToRgba";
import { get, map } from "lodash";

// components
import { default as ReactSelect } from "react-select";
import Checkbox from "components/checkbox";
import FullPageLoader from "components/FullPageLoader";

const categoryCreateForm = props => {
  let {
    categoryDetails,
    handleInputChange,
    displayAll,
    categoriesDocumentData,
    categoryDataDocumentLoading,
    categoryDataLoading,
    documentCheckboxHandler,
    categoriesOptions,
    isCategoryCreateLoading,
    categoryListLoading,
    documentTypes,
    documentLayoutsError,
    showNote,
    match,
    disabledCategoryNote
  } = props;

  return (
    <>
      {(categoryDataDocumentLoading ||
        categoryDataLoading ||
        isCategoryCreateLoading ||
        categoryListLoading) && <FullPageLoader layerIndex={2} />}
      <FormContainer>
        {map(
          categoryDetails,
          ({ title, type, key, value, error, limit }, index) => {
            let titleValue = title;

            // to make title subcategory when category is selected
            if (
              (key === "enable" || key === "name") &&
              categoryDetails[0].value &&
              categoryDetails[0].value !== "[Top Level Category]"
            ) {
              titleValue =
                type === "text"
                  ? `Subcategory Name`
                  : `Make subcategory visible`;
            }
            const fieldData = (
              <Fragment key={index}>
                {type === "dropDown" && (
                  <LabelGroup>
                    <ReactSelect
                      placeHolder={titleValue}
                      onChange={selected =>
                        handleInputChange({
                          parentProp: "categoryDetails",
                          key,
                          value: selected.value
                        })
                      }
                      options={[
                        {
                          value: "[Top Level Category]",
                          label: "[Top Level Category]"
                        },
                        ...map(categoriesOptions, item => {
                          let { name } = item || {};
                          return {
                            value: name,
                            label: name
                          };
                        })
                      ]}
                      isOptionDisabled={option =>
                        option.value === get(categoryDetails[1], `value`) &&
                        !!match.params.id
                      }
                      value={{
                        value: value || "[Top Level Category]",
                        label: value || "[Top Level Category]"
                      }}
                      className="category-select"
                      classNamePrefix="category-select"
                    />
                    {showNote && <Note>{disabledCategoryNote}</Note>}
                  </LabelGroup>
                )}

                {type === "text" && (
                  <LabelGroup>
                    <Label>{titleValue}</Label>
                    <LabelInput
                      type={"text"}
                      onInput={e =>
                        handleInputChange({
                          parentProp: "categoryDetails",
                          key,
                          value: e.target.value
                        })
                      }
                      defaultValue={value}
                    />
                    {error && <ErrorMessag>{error}</ErrorMessag>}
                  </LabelGroup>
                )}
                {type === "textarea" && (
                  <LabelGroup>
                    <Label>{titleValue}</Label>
                    <LabelTextarea
                      onChange={e =>
                        handleInputChange({
                          parentProp: "categoryDetails",
                          key,
                          value: e.target.value
                        })
                      }
                      value={value}
                      maxLength={limit}
                    />
                    <DisplayLimit
                      className={
                        error === `character limit reached` && `error-message`
                      }
                    >
                      Max {limit} characters allowed.
                    </DisplayLimit>
                    {error && error !== "character limit reached" && (
                      <ErrorMessag>{error}</ErrorMessag>
                    )}
                  </LabelGroup>
                )}
                {type === "checkbox" && (
                  <LabelGroup className="make-category-visible">
                    <Checkbox
                      label={titleValue}
                      id={Math.random()}
                      handleChange={e =>
                        handleInputChange({
                          parentProp: "categoryDetails",
                          key,
                          value: e.target.checked
                        })
                      }
                      checked={value}
                    />
                  </LabelGroup>
                )}
              </Fragment>
            );
            return fieldData;
          }
        )}

        <LabelGroup>
          <DisplayTitle>Display</DisplayTitle>
          <DisplaySubTitle>
            (Choose which templates can see this category)
          </DisplaySubTitle>
          <Checkbox
            label={"All (If modules matching the template size exist)"}
            id={Math.random()}
            defaultValue={displayAll}
            handleChange={e =>
              handleInputChange({
                key: "displayAll",
                value: e.target.checked
              })
            }
            checked={displayAll}
          />

          {!documentTypes.length && documentLayoutsError && (
            <ErrorMessag>Please select at least one-page layout.</ErrorMessag>
          )}
        </LabelGroup>
        <LabelGroup>
          {categoriesDocumentData &&
            Array.isArray(categoriesDocumentData) &&
            categoriesDocumentData.map(documentType => {
              return (
                <PageLayoutContainer key={documentType._id}>
                  {
                    <Checkbox
                      label={documentType.title}
                      id={documentType._id}
                      handleChange={e =>
                        documentCheckboxHandler({
                          page: documentType,
                          value: e.target.checked
                        })
                      }
                      checked={
                        props.documentTypes.indexOf(documentType._id) > -1
                      }
                    />
                  }
                </PageLayoutContainer>
              );
            })}
        </LabelGroup>
      </FormContainer>
    </>
  );
};

const FormContainer = styled.div`
  width: 71.2%;
  padding: 40px 30px;
  border-radius: 4px;
  margin: 34px 0 40px;
  background: ${props => props.theme.COLOR.WHITE};
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  font-family: ${props => props.theme.FONT.REG};

  .make-category-visible {
    label {
      color: ${props => props.theme.COLOR.LIGHT_GREYISH};
      font-weight: bold;
    }
  }
`;

const sharedInputStyling = css`
  box-sizing: border-box;
  width: 100%;
  padding: 7px 8px;
  display: block;
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.4);
  background: ${props => props.theme.COLOR.INPUT};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  font-size: 12px;
  outline: none;
`;

const LabelGroup = styled.div`
  margin-bottom: 16px;
  position: relative;
  outline: none;

  .category-select {
    .css-yk16xz-control {
      ${sharedInputStyling}
      padding: 0;
      display: flex;
    }

    .css-1pahdxg-control {
      ${sharedInputStyling}
      padding: 0;
      display: flex;
    }

    .css-1uccc91-singleValue {
      ${sharedInputStyling}
      padding: 0;
      border: none;
      background: transparent;
    }
  }

  /*Override custom select list*/
  .category-select__control {
    /*When select list is focuses*/
    &--is-focused {
      border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      box-shadow: 0 0 0 1px ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }

    /*When select list is in active state*/
    &--menu-is-open {
      border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      box-shadow: 0 0 0 1px ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }

  /*Overriding option styling*/
  .category-select__menu {
    > div > div {
      background-color: ${props => props.theme.COLOR.WHITE};

      &:hover {
        background-color: ${props =>
          hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.2)};
      }
    }

    .category-select__option--is-selected {
      background-color: ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        background-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }
`;

const Label = styled.label`
  margin-bottom: 3px;
  display: inline-block;
  color: ${props => props.theme.COLOR.LIGHT_GREYISH};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  font-weight: bold;
`;

const LabelInput = styled.input`
  ${sharedInputStyling}
  height: 30px;

  &:focus {
    border: 2px solid ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const LabelTextarea = styled.textarea`
  height: 80px;
  ${sharedInputStyling}
  resize: none;
  &:focus {
    border: 2px solid ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const DisplayLimit = styled.span`
  color: ${props => props.theme.COLOR.LIGHT_GREYISH};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 10px;

  &.error-message {
    color: ${props => hexToRgba(props.theme.COLOR_PALLETE.ERROR, "1")};
  }
`;

const ErrorMessag = styled.span`
  position: absolute;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.ERROR, "1")};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 10px;
  font-weight: normal;
  bottom: -12px;
  left: 0;
  pointer-events: none;
`;

const DisplayTitle = styled.h3`
  margin-top: 30px;
  color: ${props => props.theme.COLOR.HEADING};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 20px;
  font-weight: bold;
`;

const DisplaySubTitle = styled.span`
  margin-top: 5px;
  margin-bottom: 10px;
  display: inline-block;
  color: ${props => props.theme.COLOR.LIGHT_GREYISH};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  font-weight: bold;
`;

const PageLayoutContainer = styled.div`
  width: calc(33.33% - 4px);
  margin-top: 21px;
  display: inline-block;
  vertical-align: top;
`;

const Note = styled.span`
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.ERROR, "1")};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 10px;
  font-weight: normal;
`;

export default categoryCreateForm;
