import React from "react";
import { default as ReactSelect } from "react-select";

const MultiSelect = props => {
  let { allowSelectAll = false, options, allOption, handleChange, id } = props;
  return (
    <ReactSelect
      {...props}
      className="custom-select"
      classNamePrefix="custom-select"
      options={[...(allowSelectAll ? [allOption] : []), ...options]}
      onChange={(selected, event) => {
        if (allowSelectAll && selected !== null && selected.length > 0) {
          if (
            selected[selected.length - 1].value === allOption.value &&
            event.action !== "remove-value"
          ) {
            //if all is selected
            return handleChange([allOption, ...options], id);
          }
          let result = [];
          if (selected.length === options.length) {
            if (selected.includes(allOption)) {
              result = selected.filter(
                option => option.value !== allOption.value
              );
            } else if (event.action === "select-option") {
              result = [allOption, ...options];
            }
            return handleChange(result, id);
          }
        }
        return handleChange(selected, id);
      }}
    />
  );
};

MultiSelect.defaultProps = {
  allOption: {
    label: "All",
    value: "*"
  }
};
export default MultiSelect;
