import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { each, includes, get, assign, isEmpty } from "lodash";
import {
  addNodeUnderParent,
  getVisibleNodeCount,
  removeNodeAtPath,
  walk
} from "react-sortable-tree";
import ToastUtils from "utils/handleToast";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import uniqId from "uniqid";

// actions
import { mapStateToProps, actions } from "./mapStateToProps";

//icons
import { Delete, SquareEdit } from "assets/icons";

//Pooling instance
import imagePolling from "utils/PollingUtils";
import excelPolling from "utils/PollingUtils";

import { uploadFileToAws, AWSFileUploadStatus } from "services/awsUpload";

//search
import { search } from "utils/searchFuntionality";
import handleBodyScroll from "utils/handleBodyScroll";
import { downloadFile } from "utils/download";

// Icon style
const StyledIcon = styled.span`
  color: #000000;
  cursor: pointer;
  margin-left: ${props => (props.required ? "29px" : 0)};
`;

const EditIcon = styled(SquareEdit)`
  width: 17px;
  height: 15px;
  margin-right: 10px;
  transform: translateY(1px);
`;

const DeleteIcon = styled(Delete)`
  width: 16px;
  height: 17px;
  margin-right: 10px;
`;

const EMPTY_FIELD_ERROR_MESSAGE = "This field is required.";
const DELETE_IMAGE =
  "Do you also want to delete the images present in this folder?";

const container = Main =>
  connect(
    mapStateToProps,
    actions
  )(
    class Container extends Component {
      constructor(props) {
        super(props);
        this.state = {
          selectedTab: "folder",
          imageFolder: [],
          imageCategory: [],
          categoryIdToEdit: null,
          isEditedFlag: false,
          inputError: "",
          categoryTitle: "",
          imageFolderDetails: {
            _id: "",
            title: "All"
          },
          newFocusIndex: 0,
          imageList: [],

          uploadFileDetails: {
            uploadedFile: false,
            uploadInputDetails: [
              {
                id: "folder",
                type: "dropdown",
                label: "Folder",
                value: "",
                options: [],
                error: null,
                hideDropdown: false
              },
              {
                id: "file",
                type: "file",
                label: "Upload Image",
                value: "",
                error: null
              }
            ],
            fileMetaData: {},
            uploadedFileStatus: [],
            imageId: "",
            isEdited: false
          },
          isPollingActive: false,
          isUploadProcessActive: false,
          isDownloadActive: false,
          showSidebarModal: false,
          activeIngestId: null,
          searchKeyWord: null,
          showPreviewOverlay: false,
          imageIdList: [],
          activeIndex: 0,
          userDetail: {},
          isBulkUploadPending: false,
          isNewImageUploaded: false
        };

        this.selectedParentELem = [];
        this.sideBarRef = React.createRef();
      }

      componentDidMount() {
        // get image folder/category list
        this.fetchImageFolderCategoryList();

        //get All images
        this._fetchSpecificImageList(false);
        this.setUserProfile();
      }

      componentDidUpdate(prevProps) {
        // fetch and store user detail to fetch user id and get presentation required detail.
        if (this.props.userProfileMeta !== prevProps.userProfileMeta) {
          this.setUserProfile();
        }
      }

      fetchImageFolderCategoryList = async () => {
        let { selectedTab, imageFolderDetails } = this.state;

        await this.props.fetchImageFolderCategoryList(selectedTab);

        let treeData =
          (selectedTab === "folder"
            ? this.props.imageFolderListData
            : this.props.imageCategoryListData) || [];

        treeData = this.updateLevel({ treeData });

        walk({
          treeData,
          getNodeKey: ({ node }) => node._id,
          callback: rowInfo => {
            let {
              node: { _id, children, level, title },
              treeIndex
            } = rowInfo;

            if ((imageFolderDetails || {})._id === _id) {
              imageFolderDetails = {
                _id,
                children,
                level,
                title,
                treeIndex,
                rowInfo
              };
            }
          },
          ignoreCollapsed: false
        });

        const key = selectedTab === "folder" ? "imageFolder" : "imageCategory";

        this.setState({
          [key]: treeData,
          imageFolderDetails
        });
      };

      // update level;
      updateLevel = ({ key, treeData }) => {
        // update node level
        let updateLevelRecursiveFunc = (treeSet, level = 0) => {
          each(treeSet, slide => {
            let { children, attribute } = slide;
            slide.level = level;
            slide.children = children && children.length ? children : attribute;

            // delete attribute key after assigning it to children
            attribute && delete slide.attribute;

            if (Array.isArray(children) && children.length) {
              updateLevelRecursiveFunc(children, slide.level + 1);
            }
          });
        };

        updateLevelRecursiveFunc(treeData);

        return treeData;
      };

      /**
       * Handle slide selector tab selection
       * @param {String} selectedTab value of the selected tab
       *
       */
      handleSlideSelectorTab = selectedTab => {
        if (!this.state.isEditedFlag) {
          let imageFolderDefaultValue =
            selectedTab.value === "folder"
              ? {
                  _id: "",
                  title: "All"
                }
              : {};

          this.setState(
            {
              selectedTab: selectedTab.value,
              imageFolderDetails: imageFolderDefaultValue
            },
            () => {
              this.fetchImageFolderCategoryList();
              if (selectedTab.value === "folder") {
                this._fetchSpecificImageList(false);
              } else {
                this.handleStateChange({ key: "imageList", value: [] });
              }
            }
          );
        } else {
          ToastUtils.handleToast({
            operation: "error",
            message: "Please save your changes."
          });
        }
      };

      // treedata change
      handleTreeOnChange = treeData => {
        let key =
          this.state.selectedTab === "folder" ? "imageFolder" : "imageCategory";
        this.setState({
          [key]: treeData
        });
      };

      generateNodeProps = rowInfo => {
        let { node } = rowInfo;
        let { _id, level } = node;
        let { isEditedFlag } = this.state;

        return [
          <StyledIcon
            title="Edit"
            onClick={() => {
              if (!isEditedFlag) {
                this.setState({
                  categoryIdToEdit: _id
                });
              } else {
              }
            }}
          >
            <EditIcon size={15} />
          </StyledIcon>,
          <StyledIcon title="Delete">
            <DeleteIcon
              size={15}
              onClick={() => {
                this.handleDelete(_id, level);
              }}
            />
          </StyledIcon>
        ];
      };

      // handle state change
      handleStateChange = ({ key, value }) => {
        this.setState({
          [key]: value
        });
      };

      // move node
      onMoveNode = ({ treeData, nextParentNode }) => {
        const key =
          this.state.selectedTab === "folder" ? "imageFolder" : "imageCategory";

        treeData = this.updateLevel({ treeData });

        const reorderData = nextParentNode ? nextParentNode : treeData;

        this.reorderImageCategoryFolder(reorderData);

        this.setState({
          [key]: treeData,
          updatedTreeData: [...treeData]
        });
      };

      // reorder image folders
      reorderImageCategoryFolder = async reorderData => {
        let payload = {
            nextParent: null,
            children: []
          },
          children = reorderData;
        if (!Array.isArray(reorderData)) {
          payload.nextParent = reorderData._id;
          children = reorderData.children;
        }

        each(children, child => {
          let { _id, title } = child;
          payload.children.push({ _id, title });
        });

        const response = await this.props.imageFolderCategoryReorder(
          payload,
          this.state.selectedTab
        );

        if (response && response.success)
          await this.fetchImageFolderCategoryList();
      };

      // add new node under specific parent
      addNewNode = () => {
        const { imageFolderDetails, selectedTab } = this.state;

        let key = selectedTab === "folder" ? "imageFolder" : "imageCategory";

        let treeData = this.state[key];

        let level = 0,
          treeIndex = undefined,
          newFocusIndex = getVisibleNodeCount({ treeData }),
          parentId = null;

        // to check wether parent is selected if yes the add the node under that parent
        if (Object.keys(imageFolderDetails).length && imageFolderDetails._id) {
          let {
            children,
            level: parentLevel,
            _id,
            treeIndex: parentIndex
          } = imageFolderDetails;
          level = parentLevel + 1;
          // create index to set focus on newly added node
          let childrenLength =
            Array.isArray(children) &&
            children.length &&
            getVisibleNodeCount({ treeData: children });

          treeIndex = parentIndex;
          newFocusIndex = parentIndex + childrenLength;
          parentId = _id;
        }

        // generate unique id
        let id = uniqId();

        this.setState(
          prevState => ({
            [key]: addNodeUnderParent({
              treeData: prevState[key],
              parentKey: treeIndex,
              expandParent: true,
              newNode: {
                _id: id,
                title: "",
                parent: parentId,
                level,
                children: [],
                isNewlyAdded: true
              },
              getNodeKey: ({ treeIndex }) => treeIndex
            }).treeData,
            newFocusIndex
          }),
          () => {
            // set the node id
            this.handleStateChange({ key: "categoryIdToEdit", value: id });
            this.handleStateChange({ key: "isEditedFlag", value: true });
          }
        );
      };

      /**
       * Remove node from data at specified path
       * @param {Object} rowInfo row information
       */
      removeNodeFromTree = rowInfo => {
        let { path } = rowInfo;
        let { selectedTab } = this.state;
        let key = selectedTab === "folder" ? "imageFolder" : "imageCategory";

        this.setState({
          [key]: removeNodeAtPath({
            treeData: this.state[key],
            path: path, // You can use path from here
            getNodeKey: ({ node: TreeNode }) => {
              return TreeNode._id;
            },
            ignoreCollapsed: false
          })
        });
      };

      // create/update image folder/category
      onSave = async (payload, id) => {
        const { selectedTab } = this.state;

        const message = id
          ? `Image ${selectedTab} updated successfully.`
          : `Image ${selectedTab} created successfully.`;
        const key =
          selectedTab === "folder" ? "imageFolderCRUD" : "imageCategoryCRUD";

        const response = id
          ? await this.props[key]({ payload, message, id })
          : await this.props[key]({ payload, message });

        if (response && response.success) {
          await this.fetchImageFolderCategoryList();
        }

        return (response || {}).success;
      };

      // delete image folder/category
      handleDelete = (id, level) => {
        const { selectedTab } = this.state;
        const key =
          selectedTab === "folder" ? "imageFolderCRUD" : "imageCategoryCRUD";
        const keyToSelect =
          selectedTab === "folder" ? "imageFolder" : "imageCategory";
        const listItems = this.state[keyToSelect];
        if (selectedTab === "folder") {
          DeleteConfirmationAlert({
            message: DELETE_IMAGE,
            note: `Clicking on "Yes" will delete the folder and remove images from existing presentation.<br>
            Clicking on "No" will delete the folder and keep the images in existing presentation.`,
            onYesClick: async () => {
              const response = await this.props[key]({
                message: `The image ${selectedTab} and the images within it 
                has been deleted successfully.`,
                id,
                deleteFolder: true,
                deleteImages: true
              });

              if (response && response.success) {
                await this.fetchImageFolderCategoryList();
                this.fetchAllImages(id, listItems);
              }
            },
            onNoClick: async () => {
              const response = await this.props[key]({
                message: `The image ${selectedTab} has been deleted successfully.`,
                id,
                deleteFolder: true
              });
              if (response && response.success) {
                await this.fetchImageFolderCategoryList();
                this.fetchAllImages(id, listItems);
              }
            }
          });
        } else {
          const deleteNote =
            level === 0
              ? "Are you sure you want to delete this category?"
              : "Are you sure you want to delete this attribute?";

          const successMessage =
            level === 0
              ? "The image category has been deleted successfully."
              : "The image attribute has been deleted successfully.";

          DeleteConfirmationAlert({
            message: deleteNote,
            onYesClick: async () => {
              const response = await this.props[key]({
                message: successMessage,
                id,
                deleteImages: true
              });

              if (response && response.success) {
                await this.fetchImageFolderCategoryList();
                this.fetchAllImages(id, listItems);
              }
            }
          });
        }
      };

      // fetch all image when delted category is the selected category
      fetchAllImages = (id, listItems) => {
        let { imageFolderDetails, selectedTab } = this.state;

        if (
          imageFolderDetails._id === id ||
          this.checkIfChildIsActive(id, listItems)
        ) {
          if (selectedTab === "category") {
            this.handleStateChange({
              key: "imageFolderDetails",
              value: {}
            });
          } else {
            this._fetchSpecificImageList(false);
            this.handleStateChange({
              key: "imageFolderDetails",
              value: {
                _id: "",
                title: "All"
              }
            });
          }
        }
      };

      // check if selected folder/categories parent is deleted
      checkIfChildIsActive = (id, listItems) => {
        let flag = false;
        const { imageFolderDetails } = this.state;

        const recursiveFunc = (listItem, flag) => {
          each(listItem, item => {
            let { children, _id } = item;

            if (_id === imageFolderDetails._id) flag = true;

            if (Array.isArray(children) && children.length)
              recursiveFunc(children, flag);
          });

          return flag;
        };

        walk({
          treeData: listItems,
          getNodeKey: ({ node }) => node._id,
          callback: rowInfo => {
            let {
              node: { _id, children }
            } = rowInfo;

            if (id === _id) flag = recursiveFunc(children, flag);
          },
          ignoreCollapsed: false
        });

        return flag;
      };

      /**
       * Handle the Sidebar overlay status
       */
      handleOverlayStatus = async id => {
        let { uploadFileDetails, isUploadProcessActive } = this.state;

        if (isUploadProcessActive) return;

        if (!this.state.showSidebarModal) {
          //fetch image folder list
          await this.props.fetchImageFolderCategoryList();

          await this._checkUploadCompletionStatus();

          let imageFolder =
            JSON.parse(JSON.stringify(this.props.imageFolderListData)) || [];

          let dropDownOptionsNew = this._modifyOptionList(imageFolder);

          //add Choose folder at first index of the array
          dropDownOptionsNew.unshift({
            _id: 1,
            title: "Choose Folder"
          });

          uploadFileDetails.uploadInputDetails[0].options = dropDownOptionsNew;
          handleBodyScroll({ action: "open" });
        } else {
          this._resetPollingFlowDetails();

          this._checkIfSearching();

          handleBodyScroll({ action: "close" });
        }
        uploadFileDetails.uploadedFile = false;
        if (!id) {
          uploadFileDetails.imageId = "";
          uploadFileDetails.uploadInputDetails[0].hideDropdown = false;
        }

        //reset the state values
        uploadFileDetails.uploadedFile = false;
        this.emptyFileDetails();
        uploadFileDetails.uploadInputDetails[0].value = "";
        uploadFileDetails.uploadInputDetails[0].error = null;
        uploadFileDetails.isEdited = false;

        this.setState(prevState => {
          return {
            showSidebarModal: !prevState.showSidebarModal,
            uploadFileDetails
          };
        });

        ToastUtils.handleToast({
          operation: "dismiss"
        });
      };

      /**
       * Function to modify the option list as per the hierarchy
       * @param {Array} imageFolder-  Array of objects of the folder data
       */
      _modifyOptionList = imageFolder => {
        let dropDownOption = [];

        let createDropdownData = imageFolder => {
          imageFolder.forEach(data => {
            data.title = `${Array(data.level + 1).join("--")}` + data.title;

            dropDownOption = [...dropDownOption, data];

            if (Array.isArray(data.children) && data.children.length) {
              return createDropdownData(data.children);
            }
          });

          return dropDownOption;
        };

        return createDropdownData(imageFolder);
      };

      /**
       * Handle input changes
       * @param {Strign} value - The Id of the folder selected
       */
      _handleInputChange = value => {
        let { uploadFileDetails } = this.state;

        uploadFileDetails.uploadInputDetails[0].value = value || "";
        uploadFileDetails.uploadInputDetails[0].error = null;
        uploadFileDetails.isEdited = true;
        this.setState({
          uploadFileDetails
        });
      };

      /**
       * Handle when new file is uploaded
       * @param {Object} file - Object of the file uploaded with the file properties
       */
      handleFileChange = file => {
        let { uploadFileDetails } = this.state;

        let fileName = file.name;

        uploadFileDetails.uploadInputDetails[1].value = fileName;
        uploadFileDetails.uploadInputDetails[1].error = null;

        //TODO: This will be required during API INTEGRATION
        if (/\.(jpe?g|png|pptx|ppt|zip)$/i.test(fileName)) {
          const fileNameExtension = fileName.split(".").reverse()[0];
          let fileType = null;
          fileType = includes(["zip"], fileNameExtension) ? "zip" : "image";

          let fileToBeSent = assign(file, {
            preview: URL.createObjectURL(file)
          });

          const metaData = {
            fileType: fileType,
            fileName: fileName,
            fileToBeSent: fileToBeSent //required for polling
          };

          uploadFileDetails.fileMetaData = metaData;
          uploadFileDetails.isEdited = true;
        }

        this.setState({
          uploadFileDetails
        });
      };

      /**
       * Fucntion to save the the image Upload
       */
      onSavehandler = async () => {
        let { uploadFileDetails } = this.state;
        (uploadFileDetails.uploadInputDetails || []).forEach(item => {
          if (item.id === "folder" && !item.hideDropdown) {
            item.error = !item.value ? EMPTY_FIELD_ERROR_MESSAGE : null;
          } else if (item.id === "file") {
            item.error = item.value ? null : EMPTY_FIELD_ERROR_MESSAGE;
          }
        });

        let errorCheck = uploadFileDetails.uploadInputDetails.filter(
          item => item.error
        );

        if (errorCheck.length) {
          this.setState({
            uploadFileDetails
          });

          ToastUtils.handleToast({
            operation: "error",
            message: "Please fill all the fields."
          });

          return;
        }

        //get folder ID and the file details
        let folderId = get(uploadFileDetails, "uploadInputDetails[0].value");

        let metData = uploadFileDetails.fileMetaData || {};
        let response;

        if (uploadFileDetails.imageId) {
          let body = {
            fileName: metData.fileName || ""
          };

          response =
            this.props.updateImageDataAttributes &&
            (await this.props.updateImageDataAttributes(
              body,
              uploadFileDetails.imageId,
              true
            ));
        } else {
          let body = {
            folderParent: folderId,
            fileName: metData.fileName || "",
            fileType: metData.fileType || ""
          };
          response = await this.props.imageUpload(body);
        }

        if (response && response.success) {
          uploadFileDetails.isEdited = false;

          this.setState({
            imageUpdateLoading: true,
            uploadFileDetails
          });

          let data = response.data;

          this._uploadLayoutFileToAWS(
            metData.fileToBeSent || {},
            data.presignedUrl || "",
            data.ingestId || "",
            imagePolling,
            this.emptyFileDetails
          );
        }
      };

      /**
       * To upload file to AWS
       * @param {Object} fileToUpload - Object of the file to be uploaded
       * @param {String} presignedUrl - Presigned URL of the string
       * @param {String} ingestId - the Ingest ID of the file
       * @param {Object} PollingInstance - The Instance of the Polling,
       * @param {Function} cb - Callback function
       */
      _uploadLayoutFileToAWS = async (
        fileToUpload,
        presignedUrl,
        ingestId,
        PollingInstance,
        cb
      ) => {
        const LayoutResponse = await uploadFileToAws(
          presignedUrl,
          fileToUpload
        );

        if (LayoutResponse && LayoutResponse.success) {
          PollingInstance.startPolling({
            pollingAction: () => {
              this._checkImageFilePollingStatus(
                ingestId,
                PollingInstance,
                cb,
                false
              );
            },

            timeoutDuration: 600000,
            timeoutCallback: () => {
              PollingInstance.stopPolling();

              ToastUtils.handleToast({
                operation: "error",
                message:
                  "Request timed out. Please verify the image details and try again.",
                autoclose: false
              });

              this._checkImageFilePollingStatus(
                ingestId,
                PollingInstance,
                cb,
                true
              );
            }
          });
        }
      };

      /**
       * Check polling status for image/zip file uploaded to AWS
       * @param {String} ingestId - the Ingest ID of the file
       * @param {Object} PollingInstance - The Instance of the Polling,
       * @param {Function} cb - Callback function
       * */
      _checkImageFilePollingStatus = async (
        ingestId,
        PollingInstance,
        cb,
        stopPolling
      ) => {
        let pollingResponseStatus = await AWSFileUploadStatus(ingestId);
        if (pollingResponseStatus && pollingResponseStatus.success) {
          const responseSuccessFailedCallback = (message, pollingCompleted) => {
            PollingInstance.stopPolling();

            //Function to show error message
            const ModuleMessage = () => {
              return message;
            };
            cb && cb();

            ToastUtils.handleToast({
              operation: pollingCompleted ? "success" : "error",
              message: ModuleMessage(),
              autoClose: 3000
            });

            this.setState({
              imageUpdateLoading: false
            });
          };

          if (pollingResponseStatus.data.status === "Completed") {
            let { uploadFileDetails } = JSON.parse(JSON.stringify(this.state));
            uploadFileDetails.uploadInputDetails[0].value = "";
            uploadFileDetails.uploadInputDetails[0].error = null;

            if (pollingResponseStatus.data.fileType === "zip") {
              uploadFileDetails.uploadedFile = true;
              uploadFileDetails.uploadedFileStatus = get(
                pollingResponseStatus,
                "data.fileStatus"
              );

              this.setState({
                uploadFileDetails,
                imageUpdateLoading: false,
                isPollingActive: !stopPolling,
                isUploadProcessActive: true,
                ...(stopPolling && {
                  activeIngestId: pollingResponseStatus.data.ingestId
                })
              });
              let totalAsset = get(pollingResponseStatus, "data.totalAssets");

              if (
                totalAsset ===
                get(pollingResponseStatus, "data.fileStatus").length
              ) {
                let checkIfCompleted = get(
                  pollingResponseStatus,
                  "data.fileStatus"
                ).filter(item => item.status !== "Completed");

                if (!checkIfCompleted.length) {
                  responseSuccessFailedCallback(
                    "Image has been uploaded successfully.",
                    true
                  );
                  uploadFileDetails.uploadedFile = true;

                  uploadFileDetails.uploadedFileStatus = get(
                    pollingResponseStatus,
                    "data.fileStatus"
                  );

                  this.setState({
                    uploadFileDetails,
                    imageUpdateLoading: false,
                    isPollingActive: false,
                    activeIngestId: pollingResponseStatus.data.ingestId
                  });
                }
              }
            } else {
              responseSuccessFailedCallback(
                "Image has been uploaded successfully.",
                true
              );

              let imageId = get(
                pollingResponseStatus,
                "data.fileStatus[0].assetId"
              );
              this.setState({
                showPreviewOverlay: true,
                isNewImageUploaded: true
              });

              handleBodyScroll({ action: "open" });

              this.fetchDetailsForOverlay(imageId);
              this.setState({
                uploadFileDetails,
                showSidebarModal: false
              });
            }
          } else if (pollingResponseStatus.data.status === "Failed") {
            responseSuccessFailedCallback(
              get(pollingResponseStatus, "data.errorMessage")
            );
          } else {
            //TODO: This logic has to be checked when zip upload integration is done.
            let { uploadFileDetails } = this.state;

            if (
              get(pollingResponseStatus, "data.fileType") === "zip" &&
              Array.isArray(get(pollingResponseStatus, "data.fileStatus")) &&
              get(pollingResponseStatus, "data.fileStatus").length
            ) {
              uploadFileDetails.uploadedFile = true;

              uploadFileDetails.uploadedFileStatus =
                pollingResponseStatus.data.fileStatus;

              this.setState({
                uploadFileDetails,
                imageUpdateLoading: false,
                isPollingActive: true,
                isUploadProcessActive: true
              });
            }
          }
        } else {
          cb && cb();
          PollingInstance.stopPolling();
          ToastUtils.handleToast({
            operation: "error",
            message: get(pollingResponseStatus, "data.message"),
            autoclose: 3000
          });
        }
      };

      /**
       * Reset the file details
       */
      emptyFileDetails = () => {
        let { uploadFileDetails } = this.state;

        uploadFileDetails.uploadInputDetails[1].value = "";
        uploadFileDetails.uploadInputDetails[1].error = null;
        uploadFileDetails.fileMetaData = {};
        uploadFileDetails.uploadedFileStatus = [];
        this.setState({
          uploadFileDetails
        });
      };

      /**
       * Function to handle the selected folder list
       * @param {Object} value - Object of the node details.
       */
      selectFolderHandler = async value => {
        let { _id } = value;
        let { imageFolderDetails, isEditedFlag, selectedTab } = this.state;

        if (isEditedFlag) {
          ToastUtils.handleToast({
            operation: "error",
            message: "Please save your changes."
          });
        } else if (imageFolderDetails._id === _id) {
          if (selectedTab === "folder") {
            this.handleStateChange({
              key: "imageFolderDetails",
              value: {
                _id: "",
                title: "All"
              }
            });
            await this._fetchSpecificImageList(false);

            this._checkIfSearching();
          } else {
            this.handleStateChange({
              key: "imageFolderDetails",
              value: {}
            });
          }
        } else if ((imageFolderDetails || {})._id !== _id && !isEditedFlag) {
          await this._fetchSpecificImageList(_id);

          //check if the search is on
          this._checkIfSearching();

          this.handleStateChange({
            key: "imageFolderDetails",
            value
          });
          // set category id to null it is not edited
          this.handleStateChange({ key: "categoryIdToEdit", value: null });
        }
      };

      /**
       * Function to fetch the specific folder/category image list.
       * @param {String} ID - Id of the folder/category selected
       */
      _fetchSpecificImageList = async id => {
        let { selectedTab } = this.state;

        this.props.fetchImageList &&
          (await this.props.fetchImageList(id ? id : null, selectedTab));

        let { imageIdList } = this.state;

        imageIdList = [];

        imageIdList = (this.props.specificFolderImageList || []).map(item => {
          return item._id;
        });

        this.setState({
          imageList: this.props.specificFolderImageList || [],
          imageIdList
        });
      };

      onSearch = e => {
        let { imageIdList, imageFolderDetails, searchKeyWord } = this.state;
        //fields in which search should be done
        const searchFields = ["title", "metaData"];
        if (e.target.value && e.target.value.trim().length > 2) {
          let searchData = search({
            data: JSON.parse(
              JSON.stringify(this.props.specificFolderImageList)
            ),
            searchFields,
            searchKeyWord: e.target.value
          });

          if (searchData) {
            imageIdList = searchData.map(item => {
              return item._id;
            });
            this.setState({
              imageList: searchData,
              searchKeyWord: e.target.value,
              imageIdList
            });
          } else {
            this.setState({
              imageList: this.props.specificFolderImageList,
              searchKeyWord: null
            });
          }
        } else {
          //fetch updated list if searchkeyword was earlier present and the value is less than two words now
          searchKeyWord && this._fetchSpecificImageList(imageFolderDetails._id);

          this.setState({
            imageList: this.props.specificFolderImageList,
            searchKeyWord: null
          });
        }
      };

      /**
       * Function to handle the update of the overlay when image is clicked
       * @param {String} id - The id of an image which is clicked
       */
      editImageDetailsHandler = id => {
        this.setState({
          showPreviewOverlay: true
        });

        let { imageIdList = [], activeIndex } = this.state;

        if (imageIdList.indexOf(id) > -1) {
          activeIndex = imageIdList.indexOf(id);
        }

        this.setState({
          activeIndex
        });

        handleBodyScroll({ action: "open" });

        this.fetchDetailsForOverlay(id);
      };

      /**
       * Function to fetch the details for when overlay is active
       * @param {String} Id - The id of an image which is clicked
       */
      fetchDetailsForOverlay = async (id = "") => {
        //fetch category list
        await this.props.fetchImageFolderCategoryList("category", true);

        const treeData = this.props.imageCategoryListData;

        this.setState({
          imageCategory: this.updateLevel({ treeData })
        });

        //fetch specific image data
        await this.props.fetchSpecificImageData(id);
      };

      /**
       * Reset the state data
       */
      updateMainStateData = async fetchList => {
        let { imageFolderDetails } = this.state;
        if (fetchList) {
          await this._fetchSpecificImageList(imageFolderDetails._id || "");
        }

        //check if the search is on
        this._checkIfSearching();

        this.setState({
          showPreviewOverlay: false,
          isNewImageUploaded: false
        });
        handleBodyScroll({ action: "close" });
      };

      /**
       * To handle condition of reupload
       * @param {String} id- id of the image to be reuploaded
       */
      handleImageReupload = id => {
        this.setState({
          showPreviewOverlay: false
        });

        handleBodyScroll({ action: "close" });

        this.handleOverlayStatus(id);
        let { uploadFileDetails } = this.state;
        uploadFileDetails.uploadInputDetails[0].hideDropdown = true;
        uploadFileDetails.imageId = id;

        this.setState({
          uploadFileDetails
        });
      };

      /**
       * Handle the image slider condition
       * @param {String} direction - Direction of the slider will be moving
       */
      handleImageSlider = direction => {
        let { activeIndex, imageIdList } = this.state;

        let currentActiveIndex, currentActiveId;

        if (direction === "next") {
          currentActiveIndex = activeIndex + 1;

          currentActiveId = imageIdList[currentActiveIndex];
        } else if (direction === "previous") {
          currentActiveIndex = activeIndex - 1;
          currentActiveId = imageIdList[currentActiveIndex];
        }

        this.fetchDetailsForOverlay(currentActiveId);
        this.setState({
          activeIndex: currentActiveIndex
        });
      };

      /**
       * Function when user clicks the contniue cta after seeing the bulk upload file status
       */
      onContinueHandler = () => {
        this.setState({
          isUploadProcessActive: false,
          isDownloadActive: true
        });
      };

      /**
       * Function to download the excel file
       */
      onExcelDownload = async isSkip => {
        let { activeIngestId } = this.state;
        await this.props.downloadExcel(activeIngestId, isSkip);
        if (isSkip) {
          this.setState({
            showSidebarModal: false
          });

          handleBodyScroll({ action: "close" });

          this._resetPollingFlowDetails();

          let { imageFolderDetails } = this.state;

          this._fetchSpecificImageList(imageFolderDetails._id || "");
        } else {
          downloadFile(this.props.excelUrl);
        }
      };

      /**
       * Function to upload the excel file
       * @param {Object} uploadedFile - the file object that is uploaded
       */
      onExcelUpload = async uploadedFile => {
        let { activeIngestId } = this.state;
        let { file } = uploadedFile;
        let body = {
          fileName: file.name || "",
          ingestId: activeIngestId || ""
        };

        let response = await this.props.uploadExcel(body);
        if (response.success && response.data) {
          let fileToBeSent = assign(file, {
            preview: URL.createObjectURL(file)
          });
          let { ingestId, presignedUrl } = response.data;

          this.setState({
            imageUpdateLoading: true
          });

          this._uploadExcelFileToAWS(
            fileToBeSent,
            presignedUrl,
            ingestId,
            excelPolling,
            () => this._resetPollingFlowDetails(false)
          );
        }
      };

      /**
       * To upload file to AWS
       * @param {Object} fileToUpload - Object of the file to be uploaded
       * @param {String} presignedUrl - Presigned URL of the string
       * @param {String} ingestId - the Ingest ID of the file
       * @param {Object} PollingInstance - The Instance of the Polling,
       * @param {Function} cb - Callback function
       */
      _uploadExcelFileToAWS = async (
        fileToUpload,
        presignedUrl,
        ingestId,
        PollingInstance,
        cb
      ) => {
        const LayoutResponse = await uploadFileToAws(
          presignedUrl,
          fileToUpload
        );

        if (LayoutResponse && LayoutResponse.success) {
          PollingInstance.startPolling({
            pollingAction: () => {
              this._checkExcelPollingStatus(ingestId, PollingInstance, cb);
            },
            timeoutDuration: 600000,
            timeoutCallback: () => {
              ToastUtils.handleToast({
                operation: "error",
                message:
                  "Request timed out. Please verify the module details and try again.",
                autoclose: false
              });
            }
          });
        }
      };

      /**
       * Check polling status for the excel file uploaded to AWS
       * @param {String} ingestId - the Ingest ID of the file
       * @param {Object} PollingInstance - The Instance of the Polling,
       * @param {Function} cb - Callback function
       * */
      _checkExcelPollingStatus = async (ingestId, PollingInstance, cb) => {
        let pollingResponseStatus = await AWSFileUploadStatus(ingestId);
        if (pollingResponseStatus && pollingResponseStatus.success) {
          const responseSuccessFailedCallback = (message, pollingCompleted) => {
            PollingInstance.stopPolling();

            //Function to show error message
            const ModuleMessage = () => {
              if (pollingCompleted) {
                return message;
              }

              let getError = get(pollingResponseStatus, "data.errorLog");
              if (Array.isArray(getError) && getError.length > 1) {
                return `
                  <ul style="list-style: disc; font-weight: 900; margin-left: 15px;">
                  ${Array.isArray(getError) &&
                    getError
                      .map(item => {
                        return `<li>${item.error} on cell ${item.location}.</li>`;
                      })
                      .join("")}
                  </ul>`;
              } else if (getError.length) {
                return `${getError[0].error} on cell ${getError[0].location}.`;
              } else if (get(pollingResponseStatus, "data.message")) {
                return get(pollingResponseStatus, "data.message");
              }
            };
            cb && cb();

            ToastUtils.handleToast({
              operation: pollingCompleted ? "success" : "error",
              message: ModuleMessage(),
              autoClose: pollingCompleted ? "3000" : false
            });

            this.setState({
              imageUpdateLoading: false
            });

            let { imageFolderDetails } = this.state;

            this._fetchSpecificImageList(imageFolderDetails._id || "");
          };

          if (pollingResponseStatus.data.status === "Completed") {
            responseSuccessFailedCallback(
              "Excel file has been uploaded successfully.",
              true
            );

            this.setState({
              showSidebarModal: false
            });

            handleBodyScroll({ action: "close" });
          } else if (pollingResponseStatus.data.status === "Failed") {
            responseSuccessFailedCallback(
              get(pollingResponseStatus, "data.errorMessage")
            );
            this.setState({
              isDownloadActive: true
            });
          }
        } else {
          cb && cb();
          PollingInstance.stopPolling();
          ToastUtils.handleToast({
            operation: "error",
            message: get(pollingResponseStatus, "data.message"),
            autoclose: 3000
          });
        }
      };

      /**
       * To reset the polling flow details
       */
      _resetPollingFlowDetails = (resetIngestId = true) => {
        this.setState({
          isPollingActive: false,
          isUploadProcessActive: false,
          isDownloadActive: false,
          isBulkUploadPending: false,
          ...(resetIngestId ? { activeIngestId: null } : {})
        });
      };

      /**
       * Skip to download screen condition handled.
       */
      skipToDownload = () => {
        const { uploadFileDetails } = this.state;
        uploadFileDetails.isEdited = false;
        this.setState({
          isDownloadActive: true,
          uploadFileDetails
        });
      };

      /**
       * Fetch grid images
       */
      _updateGridImagesList = async () => {
        let { imageFolderDetails } = this.state;

        await this._fetchSpecificImageList(imageFolderDetails._id || "");

        //check if the search is on
        this._checkIfSearching();
      };

      /**
       * Function to check if the search is active
       */
      _checkIfSearching = () => {
        let { searchKeyWord, imageIdList } = this.state;

        if (searchKeyWord) {
          const searchFields = ["title", "metaData"];

          let searchData = search({
            data: JSON.parse(
              JSON.stringify(this.props.specificFolderImageList)
            ),
            searchFields,
            searchKeyWord: searchKeyWord
          });

          if (searchData) {
            imageIdList = searchData.map(item => {
              return item._id;
            });
            this.setState({
              imageList: searchData,
              imageIdList
            });
          } else {
            this.setState({
              imageList: this.props.specificFolderImageList,
              searchKeyWord: null
            });
          }
        }
      };

      /**
       * Get User Profile based on API respone and store in local state
       */
      setUserProfile = () => {
        const { userProfileMeta } = this.props;
        userProfileMeta &&
          this.setState(
            {
              userDetail: userProfileMeta
            },
            () => this._checkUploadCompletionStatus()
          );
      };

      /**
       * Check if zip upload is pending
       */
      _checkUploadCompletionStatus = async () => {
        let { userDetail, activeIngestId } = this.state;

        let response = await this.props.checkIncompleteUploadStatus(
          userDetail._id
        );

        if (response.success && response.data && !isEmpty(response.data)) {
          activeIngestId = get(response, "data.ingestId");
          this.setState({
            activeIngestId,
            isBulkUploadPending: true
          });
        }
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props,
          handleInputChange: this._handleInputChange,
          handleFileChange: this.handleFileChange,
          onSavehandler: this.onSavehandler
        };
        return <Main {...stateMethodProps} />;
      }
    }
  );

export default container;
