import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Delete, SquareEdit } from 'assets/icons'
import { connect } from 'react-redux'
import { map } from 'lodash'

import {
  getCategoriesList,
  deleteCategory,
  reorderedCategoryList
} from './services'
import DeleteConfirmationAlert from 'components/DeleteConfirmationAlert'
import { actions } from '../../modules/mapStateToProps'

//styles
const SlideCount = styled.span`
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  position: absolute;
  top: 50%;
  right: 176px;
  transform: translateY(-50%);
  color: #363636;
`

/** Icons Style */
const StyledIcon = styled.span`
  width: ${props => (props.isdeletemisssing ? '15px' : 'auto')};
  display: inline-block;
  margin-right: ${props => (props.isdeletemisssing ? '65px' : '0')};
  opacity: 1;
  color: #000000;
  cursor: pointer;
  margin-left: ${props => (props.required ? '29px' : 0)};
`

const DeleteIconWrapper = styled.span`
  opacity: ${props => (props.enableLevel ? 1 : 0.5)};
  color: #000000;
  cursor: ${props => (props.enableLevel ? 'pointer' : 'auto')};
  pointer-events: ${props => (props.enableLevel ? 'auto' : 'none')};
  margin-left: 0;
`

const EditIcon = styled(SquareEdit)`
  width: 17px;
  height: 17px;
  transform: translateY(1px);
`

const DeleteIcon = styled(Delete)`
  width: 16px;
  height: 19px;
  margin: 0 33px 0 14px;
`

/**
 * Map the state to props.
 */

const mapStateToProps = state => {
  const {
    CATEGORY_DATA_SUCCESS,
    LOADING_CATEGORY_DATA,
    MODULE_LIST_SUCCESS,
    LOADING_MODULE_LIST
  } = state

  return {
    ...CATEGORY_DATA_SUCCESS,
    ...LOADING_CATEGORY_DATA,
    ...MODULE_LIST_SUCCESS,
    ...LOADING_MODULE_LIST
  }
}

const Container = Main =>
  connect(mapStateToProps, {
    getCategoriesList,
    deleteCategory,
    reorderedCategoryList,
    getModuleList: actions.getModuleList
  })(
    class Categories extends Component {
      constructor (props) {
        super(props)
        this.state = { gData: [] }
      }

      componentDidMount () {
        this._getUpdatedCategoriesList()
      }

      /**
       * Function to fetch the category list
       */
      _getUpdatedCategoriesList = async () => {
        //get entire category list
        await this.props.getModuleList()
        let gData = this.props.moduleList

        //add level to the category data receive by using the recurssive function
        let updateLevel = (treeSet, level = 0) => {
          map(treeSet, elements => {
            elements.level = level
            if (Array.isArray(elements.children) && elements.children.length) {
              elements.moduleCategory =
                JSON.parse(
                  JSON.stringify(
                    elements.children.filter(elem => elem.label === 'module')
                  )
                ) || []
              elements.children = elements.children.filter(
                elem => elem.label !== 'module'
              )

              //calculate sub categories with modules
              let totalModulesInCategory = elements.children.reduce(
                (accumulator, subCategory) => {
                  if (
                    subCategory.label === 'subcategory' &&
                    Array.isArray(subCategory.children)
                  )
                    accumulator.push(
                      ...(subCategory.children.filter(
                        (modules = {}) => modules.label === 'module'
                      ) || [])
                    )
                  return accumulator
                },
                []
              ) || []

              elements.totalModulesInCategory =
                elements.moduleCategory.length + totalModulesInCategory.length

              return updateLevel(elements.children, elements.level + 1)
            }
          })
        }

        updateLevel(gData)

        this.setState({
          gData
        })
      }

      // generate actions for repo manager
      generateButtonNodeList = ({ node }) => {
        let { enable, createdAt, _id, totalModulesInCategory } = node
        createdAt = new moment(createdAt).format('MM/DD/YYYY HH:mm')

        return [
          <SlideCount
            style={{
              opacity: enable ? 1 : 0.5
            }}
            className='created-date'
          >
            {createdAt || null}
          </SlideCount>,
          <StyledIcon
            title='Edit'
            isdeletemisssing={
              totalModulesInCategory
                ? 'yes'
                : null
            }
            onClick={() => this.editClickHandler(_id)}
          >
            <EditIcon size={15} />
          </StyledIcon>,
          totalModulesInCategory ? null : (
            <DeleteIconWrapper
              enableLevel={enable}
              title={enable ? 'Delete' : ''}
              onClick={() => this.deleteClickHandler(_id)}
            >
              <DeleteIcon size={15} />
            </DeleteIconWrapper>
          )
        ]
      }

      /**
       * Function to handle edit category click
       * @param {String} Id of the cateogry to be edited.
       */
      editClickHandler = id => {
        this.props.history.push(
          `/marketing-materials/admin/categories/edit/${id}`
        )
      }

      // redirect to create category page on new button click
      onNewButtonClick = () => {
        this.props.history.push(`/marketing-materials/admin/categories/create`)
      }

      /**
       * Function to handle edit delete click
       * @param {String} Id of the cateogry to be deleted.
       */
      deleteClickHandler = id => {
        DeleteConfirmationAlert({
          onYesClick: async () => {
            await this.props.deleteCategory(id)
            // fetch all the categories after delete
            this._getUpdatedCategoriesList()
          }
        })
      }

      render () {
        const $this = this
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props
        }

        return <Main {...stateMethodProps} />
      }
    }
  )

export default Container
