import React from "react";
import styled from "styled-components";
import TabHeader from "components/tabHeader";
import Container from "./container";
import BulkUpload from "./components/bulkUpload";
import Repositories from "./components/repos";
import UserDetails from "./components/userDetails";
import presentation from "utils/featureFlags";
import { includes } from "lodash";
import Button from "components/button";
import { FaPlus } from "react-icons/fa";

const UserTabs = props => {
  // Is presentation tool access
  let isPresentation = includes(props.accessibleTools, "PG");

  let TabList = [
    { title: "New Users", id: "users", value: "users" },
    ...(isPresentation
      ? [{ title: "Repositories", id: "repos", value: "repos" }]
      : []),
    ...(presentation.showUserBulkUpload
      ? [{ title: "Bulk Upload", id: "upload", value: "upload" }]
      : [])
  ];

  let { selectedTabValue, setTab, showUserFormFlag, newUserHandler } = props;

  return (
    <>
      <NewButton>
        <Button
          icon={<AddIcon />}
          text="New"
          width="121px"
          marginLeft="14px"
          role={props.role}
          onClick={newUserHandler}
        />
      </NewButton>
      <UserDetailsWrapper>
        {showUserFormFlag && (
          <>
            <TabHeaderWrapper>
              <TabHeader
                data={TabList}
                manageStates={setTab}
                active={selectedTabValue}
                width={TabList.length <= 2 ? "50%" : "calc(100% -  186px)"}
                padding="15px 5px 8px"
              />
            </TabHeaderWrapper>
            <TabContent>
              {selectedTabValue === "users" ? (
                <UserDetails {...props} />
              ) : selectedTabValue === "repos" ? (
                <Repositories {...props} />
              ) : (
                <BulkUpload {...props} />
              )}
            </TabContent>
          </>
        )}
      </UserDetailsWrapper>
    </>
  );
};

const UserDetailsWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 279px;
  border-radius: 4px;
  box-shadow: 4px 8px 20px 0 rgba(0, 0, 0, 0.14);
  background-color: ${props => props.theme.COLOR.WHITE};
  position: absolute;
  min-height: 490px;
  right: 26px;
  top: 186px;
  margin-bottom: 50px;
`;

const TabContent = styled.div`
  padding: 30px 12px 12px;
`;

const TabHeaderWrapper = styled.div`
  ul {
    background-color: ${props => props.theme.COLOR_PALLETE.SOLITUDE};
  }
`;

const NewButton = styled.div`
  display: inline-block;
  position: absolute !important;
  top: 39px;
  right: 26px;
`;

const AddIcon = styled(FaPlus)`
  font-weight: 100;
  transform: translate(-10px, 2px);
`;

export default Container(UserTabs);
