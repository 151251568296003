import React from "react";
import styled from "styled-components";

//components
import ThemeDetails from "./components/themeDetails";
import ThemePreviewImage from "./components/themePreviewImage";

const ThemeMainContent = props => {
  return (
    <MainContentContainer>
      <ThemeDetails {...props} />
      <ThemePreviewImage {...props} />
    </MainContentContainer>
  );
};

const MainContentContainer = styled.div`
  margin-bottom: 30px;
  padding-left: 26px;
`;

export default ThemeMainContent;
