import React from "react";
import styled from "styled-components";

import hexToRgba from "utils/hexToRgba";
import DraggableSort from "components/draggableSort";
import ShadowScrollbars from "components/custom-scrollbars/ShadowScrollbars";
import Container from "./container";
import { Delete } from "assets/icons";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";

import SearchBlock from "./components/searchBlock";

const SlidesWithGroup = props => {
  let {
    searchList,
    selectedGroup,
    searchValue,
    isSearching,
    handleChange,
    contentSlideUpdate,
    groupId,
    errorEmptyList,
    categoryDetail
  } = props;
  let categoryName = Object.keys(categoryDetail || {}).length
    ? `of ${categoryDetail.title}`
    : "";
  return (
    <>
      {selectedGroup && (
        <SearchBlock
          searchList={searchList}
          searchValue={searchValue}
          isSearching={isSearching}
          handleChange={handleChange}
          contentSlideUpdate={contentSlideUpdate}
          groupId={groupId}
          errorEmptyList={errorEmptyList}
        />
      )}
      <Title>Group contents {categoryName}</Title>

      {props.slideList && props.slideList.length ? (
        <DragList>
          <ShadowScrollbars autoHeight autoHeightMax="233px" thumbMinSize={10}>
            <DraggableSort
              onListUpdate={props.onListUpdate}
              axis="y"
              listData={props.slideList}
              handlesGroup={false}
              allowExternalDrop={false}
              renderChild={(data, index) => {
                let {
                  contentRepository,
                  contentSlideCategory,
                  _id,
                  title
                } = data;

                return (
                  <Wrapper key={index}>
                    <Row key={index}>
                      <DragHover>
                        <BorderWrapper>
                          <Text title={title}>{title}</Text>
                          <DeleteIconWrap title="Remove Slide">
                            <Delete
                              onClick={() => {
                                DeleteConfirmationAlert({
                                  onYesClick: () => {
                                    contentSlideUpdate(
                                      contentRepository,
                                      contentSlideCategory,
                                      _id
                                    );
                                  }
                                });
                              }}
                            />
                          </DeleteIconWrap>
                        </BorderWrapper>
                      </DragHover>
                    </Row>
                  </Wrapper>
                );
              }}
            />
          </ShadowScrollbars>
        </DragList>
      ) : null}
    </>
  );
};

export default Container(SlidesWithGroup);

const Title = styled.h4`
  margin-bottom: 14px;
  font-family: ${props => props.theme.FONT.REG};
  font-size: 12px;
  font-weight: bold;
  color: ${props => hexToRgba(props.theme.COLOR.HEADING, 0.7)};
`;

const DragList = styled.div`
  width: 100%;
  position: relative;

  .sort-item {
    width: 97%;
  }

  &::after {
    content: "";
    width: 97%;
    height: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const Row = styled.div`
  width: 100%;
  position: relative;
  padding: 8px 0 9px 9px;
  margin-left: 1%;
  box-sizing: border-box;
  background-color: ${props =>
    hexToRgba(props.theme.COLOR_PALLETE.COOL_BLUE, 0.09)};
  border-radius: 2px;
  position: relative;
`;

const DragHover = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 32%;
  margin: 0 0 10px;
  border: 2px solid transparent;
  &.drop-before {
    ${DragHover} {
      ${props =>
        `&:before {
          content: " ";
          position: absolute;
          top: -8px;
          right: 0;
          left : 0;
          border: 1px solid ${hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.74)};
      }`}
    }
  }

  &.drop-after {
    ${DragHover} {
      ${props =>
        `&:before {
        content: " ";
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
        border: 1px solid ${hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.74)};
      }`}
    }
  }

  &.selected {
    ${Row} {
      background-color: ${props =>
        hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.3)};
      ${props => props.theme.SNIPPETS.BOX_SHADOW};
    }
  }
`;

const BorderWrapper = styled.div`
  position: relative;
`;

const Text = styled.span`
  display: block;
  padding-right: 20px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 12px;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DeleteIconWrap = styled.span`
  position: absolute;
  top: -1px;
  right: 6px;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;
  }
`;
