import React from "react";
import styled from "styled-components";

import InputField from "../inputField";

const Form = props => {
  return props.moduleData.map((item, index) => {
    let {
      label,
      type,
      maxChar,
      options,
      value,
      id,
      error,
      fileName,
      hideField,
      filePreview
    } = item;
    return item.type === "dropdown"
      ? !props.isEditModuleActive && (
          <SelectContainer key={index}>
            <InputField
              placeholder={label}
              type={type}
              limit={maxChar}
              option={options || null}
              value={value}
              id={id}
              handleChange={props.handleInputChange}
              error={error || null}
              {...props}
            />
          </SelectContainer>
        )
      : !hideField && (
          <InputContainer key={index}>
            <InputField
              placeholder={label}
              type={type}
              limit={maxChar}
              id={id}
              handleChange={props.handleInputChange}
              error={error || null}
              value={value}
              index={index}
              fileName={fileName}
              filePreview={filePreview}
              {...props}
            />
          </InputContainer>
        );
  });
};

export default Form;

const InputContainer = styled.div`
  margin-bottom: 18px;
`;

const SelectContainer = styled.div`
  margin-bottom: 18px;

  > div {
    display: inline-block;
    vertical-align: bottom;
  }

  .add-new-category {
    height: 30px;
    display: inline-block;
    margin-left: 10px;
    font-size: 12px;
  }
`;
