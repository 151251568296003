import React from "react";
import styled from "styled-components";
import SortableTree, { walk } from "react-sortable-tree";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import { get } from "lodash";
import hexToRgba from "utils/hexToRgba";

import Container from "./container";

const ModuleList = props => {
  let {
    handleTreeOnChange,
    checkIfElementCanBeDropped,
    onDragStateChanged,
    toggleExpand,
    toggleNodeExpansion,
    moduleClickHandler,
    onMoveNode,
    onVisibilityToggle,
    treeData
  } = props;
  
  let height =
    props.windowNode.innerHeight - 200 >= 900
      ? props.windowNode.innerHeight - 200
      : 821;

  //keep expanded parent when selected to hide/unhide/delete/edit
  walk({
    treeData: treeData,
    getNodeKey: ({ node }) => node._id,
    callback: rowInfo => {
      let { node } = rowInfo;
      if (props.selectedParent.indexOf(node._id) > -1) node.expanded = true;
    },
    ignoreCollapsed: false
  });

  return (
    <>
      <CtaContainer>
        {treeData.length?
        (<Expand onClick={() => toggleNodeExpansion(!toggleExpand)}>
          {!toggleExpand ? "Expand All" : "Collapse All"}
        </Expand>):null}
      </CtaContainer>
      <ContainerSortable className="sort-container" innerHeight={height}>
        <SortableTree
          treeData={treeData}
          onChange={handleTreeOnChange}
          maxDepth={3}
          canDrag={ !( get(treeData, ["length"]) <=1 && !get(treeData[0],["children","length"])) }
          onMoveNode={onMoveNode}
          getNodeKey={({ node }) => node._id}
          canDrop={checkIfElementCanBeDropped}
          onDragStateChanged={onDragStateChanged}
          onVisibilityToggle={onVisibilityToggle}
          generateNodeProps={rowInfo => {
            const { node } = rowInfo;
            return {
              className: `level${node.level}`,

              title: (
                <CategoryTitle
                  name={node.name}
                  enable={node.enable}
                  isModule={node.label === "module"}
                  onClick={() => moduleClickHandler(node.label, node._id)}
                  title={node.name}
                >
                  {node.name}
                </CategoryTitle>
              ),
              buttons:
                (props.generateButtonNodeList &&
                  props.generateButtonNodeList(rowInfo)) ||
                []
            };
          }}
        />
      </ContainerSortable>
    </>
  );
};

export default Container(ModuleList);

const CtaContainer = styled.div`
  padding: 20px;

  text-align: right;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);

  button {
    width: 92px;
    height: 20px;
    border: 1px solid ${props => props.theme.COLOR.MAIN};
    border-radius: 4px;
    outline: none;
    display: inline-block;
    background: transparent;
    color: ${props => hexToRgba(props.theme.COLOR.HEADING, 0.7)};
    font-family: ${props => props.theme.FONT.REG};
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
  }
`;

const Expand = styled.button`
  margin-right: 10px;
`;

const ContainerSortable = styled.div`
  width: 100%;
  height: ${props => props.innerHeight}px;
  position: relative;
  right: -9px;

  .rst__highlightLineVertical::before {
    width: 1px;
    height: 100%;
    border: none !important;
    margin-left: 0;
    background-color: #000;
  }
  .rst__highlightLineVertical::after,
  .rst__highlightBottomLeftCorner::after {
    border: none !important;
  }

  .rst__lineHalfHorizontalRight::before {
     height:${props => get(props.treeData, ["length"]) <=1 ? get(props.treeData[0],["children","length"]) ? "1px !important": "0 !important" : "1px !important"};
    }
    
  .rst__highlightTopLeftCorner::before {
    width: 50%;
    border-top: solid 1px #000;
    border-left: solid 1px #000;
    margin-top: 0;
  }
  .rst__highlightBottomLeftCorner::before {
    border-bottom: none;
    border-left: solid 1px #000;
    height: calc(100% + 10px);
    right: 12px;
    width: calc(50% - 12px);
  }

  .rst__expandButton,
  .rst__collapseButton {
    z-index: 10;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: hidden !important;
  }

  .rst__rowTitle {
    color: ${props => props.theme.COLOR.MAIN};
    font-size: 12px;
    opacity: 1;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rst__rowLabel {
    max-width: 488px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1024px) {
      max-width: 185px;
    }
  }

  .rst__nodeContent {
    width: 90%;
  }

  .rst__rowContents {
    flex-basis: 90%;
  }

  .rst__virtualScrollOverride {
    overflow-x: hidden !important;
  }
  .rst__tree {
    padding-top: 10px;
  }

  .rst__moveHandle {
    min-width: 44px;
  }

  .rst__node:last-of-type {
    margin-bottom: 40px;
  }

  .rst__rowLabel {
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .level1 {
    margin-right: 45px;
    &::before {
      margin-right: 45px;
    }
  }

  .level2 {
    margin-right: 90px;
    &::before {
      margin-right: 90px;
    }

    .rst__rowLabel {
      width: 35%;
    }
  }

  .level3 {
    margin-right: 134px;
    &::before {
      margin-right: 134px;
    }
  }

  .rst__node:last-of-type {
    margin-bottom: 40px;
  }

  .rst__toolbarButton {
    margin: 0 7px 0;
  }
`;

const CategoryTitle = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props =>
    props.isModule
      ? props.theme.COLOR.DEFAULT_USER_PRIMARY
      : props.theme.COLOR.HEADING};
  opacity: ${props => (props.enable ? "1" : ".5")};
  text-decoration: ${props => (props.isModule ? "underline" : "none")};
  cursor: ${props => (props.isModule ? "pointer" : "auto")};
`;
