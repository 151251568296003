import React, { useState } from "react";
import styled from "styled-components";

//commponents
import Container from "./container";
import Button from "components/button";
import MainContainer from "./components/mainContainer";
import FullPageLoader from "components/FullPageLoader";
import { AngleDown } from "assets/icons";
import hexToRgba from "utils/hexToRgba";

const Modules = props => {
  let { moduleListLoading, moduleDetailsLoading } = props;
  let [dropDownActive, setDropdownActive] = useState(false);

  return (
    <ModuleContainer>
      {(moduleListLoading || moduleDetailsLoading) && (
        <FullPageLoader layerIndex={10} />
      )}
      <ModuleHeader className="clearfix">
        <Title>Modules</Title>
        <ButtonWrapper>
          <Button
            float="none"
            text="Add"
            width="160px"
            title="Click to add new modules"
            onClick={props.showModuleHandler}
          />
          <DropDownContainer
            onClick={() => setDropdownActive(true)}
            title="Click to add new category/subcategory"
          >
            <DropDownIcon />
          </DropDownContainer>
          {dropDownActive && (
            <PopupWrapper
              onClick={() => setDropdownActive(false)}
              isActive={true}
            />
          )}
          {dropDownActive && (
            <AddCategoryModuleContainer isActive={true}>
              <AddSlide
                onClick={() =>
                  props.history.push(
                    "/marketing-materials/admin/categories/create"
                  )
                }
              >
                Add Category
              </AddSlide>
            </AddCategoryModuleContainer>
          )}
        </ButtonWrapper>
      </ModuleHeader>
      <MainContainerWrapper>
        <MainContainer {...props} />
      </MainContainerWrapper>
    </ModuleContainer>
  );
};

//add button for category and modules
const ButtonWrapper = styled.div`
  float: right;
  position: relative;
  border-radius: 3px;
  text-align: center;
`;

const AddCategoryModuleContainer = styled.div`
  padding: 15px;
  opacity: ${props => (props.isActive ? "1" : "0")};
  visibility: ${props => (props.isActive ? "visible" : "hidden")};
  width: 200px;
  background-color: ${props => props.theme.COLOR.WHITE};
  position: absolute;
  right: 0;
  top: 56px;
  z-index: 30;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  text-align: left;
  &:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    top: -10px;
    right: 10px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgb(255, 255, 255);
  }
`;

const AddSlide = styled.button`
  ${props => props.theme.SNIPPETS.SHARED_BUTTON_CSS};
  height: 30px;
  margin-bottom: ${props => (props.divider ? "20px" : 0)};
  color: ${props => props.theme.COLOR.WHITE};
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  opacity: ${props => (props.isLoading ? "0.8" : "1")};
  cursor: ${props => (props.isLoading ? "initial" : "pointer")};
  position: relative;
  a {
    color: ${props => props.theme.COLOR.WHITE};
    text-decoration: none;
    font-size: inherit;
  }
  width: 100%;
`;

const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  background-color: ${props => hexToRgba(props.theme.COLOR.BLACK, 0.36)};
  display: ${props => (props.isActive ? "block" : "none")};
`;

const DropDownContainer = styled.button`
  width: 43px;
  display: inline-block;
  padding: 0;
  border: none;
  outline: none;
  color: ${props => props.theme.COLOR.WHITE};
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  font-size: 24px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  height: 46px;
  filter: grayscale(35%);
  vertical-align: top;
  cursor: pointer;
  transform: translateX(-1px);
`;

const DropDownIcon = styled(AngleDown)`
  transform: translateY(-3px);
`;

const ModuleContainer = styled.div`
  width: 100%;
  padding: 0 42px;
  margin: 106px auto 30px;
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  background-color: ${props => props.theme.WRAPPER.COLOR};	
`;

const ModuleHeader = styled.div`
  padding: 32px 0 31px;
`;

const Title = styled.h2`
  margin-top: 5px;
  display: inline-block;
  color: ${props => props.theme.COLOR.MAIN};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 30px;
  font-weight: bold;
`;

const MainContainerWrapper = styled.div``;

export default Container(Modules);
