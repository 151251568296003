import React from "react";
import styled from "styled-components";
import { get } from "lodash";
import { SlidePreviewLeftArrow, SlidePreviewRightArrow } from "assets/icons";

const PreviewImage = props => {
  let {
    imageData,
    slideNo,
    onSliderNavigation,
    isSlidePreviewToggleOpen,
    layoutHeight,
    internalSliderTitle
  } = props;

  return (
    <SliderContentWrapper>
      {imageData && imageData.length ? (
        <>
          <SliderImage
            src={get(props, ["imageData", slideNo, "url"])}
            isSlidePreviewToggleOpen={isSlidePreviewToggleOpen}
            layoutHeight={layoutHeight}
          />
          {imageData.length > 1 && (
            <PreviousNextArrowIcons
              isSlidePreviewToggleOpen={isSlidePreviewToggleOpen}
            >
              <PreviousCta
                disable={slideNo === 0}
                className="prev-cta"
                onClick={() => {
                  onSliderNavigation(slideNo, "previous");
                }}
                title={`Previous ${internalSliderTitle}`}
              >
                <SlidePreviewLeftArrow />
              </PreviousCta>
              {get(props, ["imageData", slideNo, "imageType"]) && (
                <SlideInfo>
                  {get(props, ["imageData", slideNo, "imageType"])}
                </SlideInfo>
              )}
              <NextCta
                disable={
                  slideNo && slideNo === get(props, `imageData.length`) - 1
                }
                className="next-cta"
                onClick={() => {
                  onSliderNavigation(slideNo, "next");
                }}
                title={`Next ${internalSliderTitle}`}
              >
                <SlidePreviewRightArrow />
              </NextCta>
            </PreviousNextArrowIcons>
          )}
        </>
      ) : null}
    </SliderContentWrapper>
  );
};

const SliderContentWrapper = styled.div`
  box-sizing: border-box;
  width: 83%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  -webkit-transition: width 0.5s ease-in;
  transition: width 0.5s ease-in;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const SliderImage = styled.img`
  max-width: 100%;
  width: auto;
  height: ${props => (props.isSlidePreviewToggleOpen ? "auto" : `80%`)};
  max-height: ${props =>
    props.layoutHeight ? `calc(${props.layoutHeight} - 20px)` : `480px`};
  align-self: center;
`;

const PreviousCta = styled.button`
  opacity: ${props => (props.disable ? 0.5 : 1)};
  cursor: ${props => (props.disable ? "default" : "pointer")};
`;

const NextCta = styled.button`
  opacity: ${props => (props.disable ? 0.5 : 1)};
  cursor: ${props => (props.disable ? "default" : "pointer")};
`;

const PreviousNextArrowIcons = styled.div`
  position: absolute;
  bottom: 0;
  margin-top: 5px;
  align-self: center;
  .prev-cta,
  .next-cta {
    margin-left: 5px;
    color: ${props => props.theme.COLOR.SECONDARY};
    text-transform: uppercase;
    background-color: ${props => props.theme.COLOR.WHITE};
    ${props => props.theme.SNIPPETS.BOX_SHADOW_DARK};
    border-radius: 50%;
    border: none;
    outline: none;
    width: 31px;
    height: 31px;
  }

  .prev-cta {
    padding: 6px 6px 2px 4px;
  }
  .next-cta {
    padding: 6px 4px 2px 6px;
  }
`;

const SlideInfo = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  display: inline-block;
  margin: 0 10px 0 14px;
`;

export default PreviewImage;
