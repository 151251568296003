import { selectedTemplateHandler } from "./action";

const getSelectedTemplate = selectedTemplate => dispatch => {
  dispatch(
    selectedTemplateHandler({
      selectedTemplate
    })
  );
};

export { getSelectedTemplate };
