import React from "react";
import container from "./container";
import styled from "styled-components";
import Button, { SecondaryButton } from "components/button";

import InputField from "../inputField";

const getData = (data, inputChange, parentRef, handleKeyPress) => {
  let accountData = Object.keys(data || {}).map((input, index) => {
    let accountDataValue = data[input];
    return (
      <InputContainer className={accountDataValue.width} key={index}>
        <InputField
          placeholder={accountDataValue.label}
          name={accountDataValue.label}
          value={accountDataValue.value || ""}
          error={accountDataValue.error}
          type={accountDataValue.type}
          handleChange={inputChange}
          id={accountDataValue.id}
          dataValue={parentRef}
          readOnly={accountDataValue.isReadOnly}
          handleKeyPress={handleKeyPress}
        />
      </InputContainer>
    );
  });
  return accountData;
};

const AccountForm = props => {
  let {
    handleInputChange,
    companyData,
    primaryContact,
    saveButtonHandler,
    cancelButtonHandler,
    handleKeyPress,
    isLeadSystemAdmin
  } = props;

  let companyDataValue = getData(
      companyData,
      handleInputChange,
      "companyData",
      handleKeyPress
    ),
    primarContactData = getData(
      primaryContact,
      handleInputChange,
      "primaryContact"
    );

  return (
    <Wrapper>
      <Float>
        <HeadingContainer>
          <Heading>Account</Heading>
          <SubHeading>Company</SubHeading>
        </HeadingContainer>
        <FloatRight>
          <ButtonWrapper>
            <SecondaryButton
              text="Cancel"
              onClick={cancelButtonHandler}
              width="80px"
              buttonClass="account-cta"
            />
          </ButtonWrapper>
          <Button
            text="Save"
            onClick={saveButtonHandler}
            width="80px"
            buttonClass="account-cta"
          />
        </FloatRight>
      </Float>
      <CompanyWrapper>{companyDataValue}</CompanyWrapper>
      <PrimaryWrapper>
        <SubHeading>
          Primary contact
          {isLeadSystemAdmin && (
            <InfoText>
              Changing First Name, Last Name, Email or Phone assigns a new
              Primary Contact.
            </InfoText>
          )}
        </SubHeading>
        {primarContactData}
      </PrimaryWrapper>
    </Wrapper>
  );
};

export default container(AccountForm);

const InputContainer = styled.div``;

const Wrapper = styled.div`
  width: 100%;
  padding: 27px 40px 48px;
  border-radius: 4px;
  background-color: #fff;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  box-sizing: border-box;

  .half-width {
    width: calc(50% - 16px);
    display: inline-block;
    box-sizing: border-box;
  }
`;

const Heading = styled.h2`
  display: block;
  margin-bottom: 7px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  text-align: left;
`;

const SubHeading = styled.span`
  display: block;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-weight: bold;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  text-align: left;
`;

const InfoText = styled.span`
  display: block;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 12px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  text-align: left;
`;

const CompanyWrapper = styled.div`
  margin-top: 9px;

  .half-width {
    &:nth-of-type(2n) {
      margin-right: 32px;
    }
  }
  & > div {
    margin-bottom: 15px;

    &:nth-of-type(3n) {
      margin-top: 18px;
    }
  }
`;

const PrimaryWrapper = styled.div`
  margin-top: 15px;

  ${SubHeading} {
    margin-bottom: 7px;
  }

  .half-width {
    &:nth-of-type(odd) {
      margin-right: 32px;
    }
  }
  & > div {
    margin-bottom: 15px;
  }
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 15px;
`;

const Float = styled.div`
  width: 100%;

  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const FloatRight = styled.div`
  float: right;

  .account-cta {
    height: 30px;
    padding: 1px 6px 2px;
    font-size: 14px;
  }
`;
const HeadingContainer = styled.div`
  float: left;
`;
