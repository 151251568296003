import React from "react";
import styled from "styled-components";
import { SquareCross } from "assets/icons";

const SelectedModules = props => {
  let { selectedModules, removefromSelectedModules } = props;

  return (
    <SelectedModulesHolder>
      {selectedModules.length > 0 && (
        <>
          <Heading>Selected</Heading>
          {selectedModules.map((item, i) => {
            return (
              <SelectedModule key={i}>
                <ModuleText>{item.title}</ModuleText>
                <RemoveIconWrapper title="Remove">
                  <RemoveIcon
                    onClick={e =>
                      removefromSelectedModules(selectedModules, "id", item.id)
                    }
                  />
                </RemoveIconWrapper>
              </SelectedModule>
            );
          })}
        </>
      )}
    </SelectedModulesHolder>
  );
};

const SelectedModulesHolder = styled.div`
  width: 94.4%;
`;

const RemoveIconWrapper = styled.span``;

const Heading = styled.h3`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.COLOR.HEADING};
`;

const SelectedModule = styled.div`
  border-radius: 2px;
  background-color: rgba(65, 171, 193, 0.09);
  padding: 10px 10px 9px;
  margin-top: 10px;
`;

const ModuleText = styled.span`
  opacity: 0.9;
  font-size: 12px;
  line-height: 15px;
  color: ${props => props.theme.COLOR.HEADING};
  text-transform: capitalize;
`;

const RemoveIcon = styled(SquareCross)`
  width: 12px;
  height: 12px;
  float: right;
  cursor: pointer;

  path {
    fill: ${props => props.theme.COLOR_PALLETE.GREY};
  }

  rect {
    stroke: ${props => props.theme.COLOR_PALLETE.GREY};
  }
`;

export default SelectedModules;
