import React from 'react'
import styled from 'styled-components'
import { FaPlus } from 'react-icons/fa'

// components
import Button from 'components/button'

const header = props => {
  const {
    titleText,
    _new,
    _submit,
    onSubmitClick,
    onNewButtonClick,
    _back
  } = props

  return (
    <HeaderConatiner>
      <Title>{titleText}</Title>
      {_new && (
        <NewButton
          onClick={() => onNewButtonClick()}
          icon={<AddIcon />}
          text='New'
          width='121px'
          marginLeft='14px'
        />
      )}
      {_submit && (
        <SubmitButton
          onClick={() => onSubmitClick()}
          text='Submit'
          width='121px'
          marginLeft='14px'
        />
      )}
      {_back && (
        <SubmitButton
          onClick={() => props.history.goBack()}
          text='Back'
          width='121px'
          buttonClass='back'
        />
      )}
    </HeaderConatiner>
  )
}

const HeaderConatiner = styled.div`
  padding: 40px 0 34px;
  .back {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
    background: #fff;
    border: 1px solid ${props => props.theme.COLOR.USER_PRIMARY};
    margin-right: 14px;
  }
`

const Title = styled.h2`
  float: left;
  color: ${props => props.theme.COLOR.MAIN};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 30px;
  font-weight: bold;
`

const NewButton = styled(Button)`
  float: right;
`

const SubmitButton = styled(Button)``

const AddIcon = styled(FaPlus)`
  font-weight: 100;
  transform: translate(-10px, 2px);
`
export default header
