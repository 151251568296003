import React from "react";
import styled from "styled-components";
import { get, round, map } from "lodash";
import moment from "moment";

const getValuesOfField = (label, details) => {
  switch (label) {
    case "Title":
      return details["title"];
    case "Presenter Name":
      return details["presenterName"];
    case "Customer Name":
      return details["customerName"];
    case "Presentation Date":
      return moment(details["presentationDate"])
        .utc()
        .format("LL");
    default:
      return null;
  }
};

const SliderContent = props => {
  let { presentationData, dynamicFields } = props;
  const slideData = map(dynamicFields, (slideDetails, index) => {
    if (!getValuesOfField(slideDetails["label"], presentationData)) return null;
    return (
      <SliderContentWrapper
        label={slideDetails["label"]}
        width={round(slideDetails.width, 2)}
        height={round(slideDetails.height, 2)}
        top={round(slideDetails.y, 2)}
        left={round(slideDetails.x, 2)}
        key={index}
      >
        <DynamicText
          colorValue={get(slideDetails, `font.color`)}
          fontWeightValue={get(slideDetails, `font.style.bold`)}
          fontSizeValue={Number(get(slideDetails, `font.size`)) / 2}
          fontStyleValue={get(slideDetails, `font.style.italic`)}
        >
          {getValuesOfField(slideDetails["label"], presentationData)}
        </DynamicText>
      </SliderContentWrapper>
    );
  });
  return slideData;
};

const SliderContentWrapper = styled.div`
  width: ${props => props.width}%;
  height: ${props => (props.label === "Title" ? "auto" : props.height)}%;
  position: absolute;
  font-size: ${props => props.height}px;
  /* handling top/left value due to which the image may move outside of the thumbnail
    TODO handle this without this condition
   */
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  overflow: hidden;
`;

const DynamicText = styled.span`
  width: 93%;
  height: 100%;
  padding: 0;
  border: none;
  font-size: ${props => props.fontSizeValue || "16"}px;
  font-weight: ${props => props.fontWeightValue && "bold"};
  font-style: ${props => props.fontStyleValue && "italic"};
  line-height: 1.1;
  font-family: Arial, sans-serif;
  color: rgba(${props => props.colorValue});
  overflow-wrap: break-word;

  &:focus {
    outline: transparent;
  }

  @media all and (max-width: 1024px) {
    /* Covert the sizes as responsive for smaller screen */
    font-size: ${props => props.fontSizeValue * 0.089 || "16"}vw;
  }
`;

export default SliderContent;
