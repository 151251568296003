import React, { Component } from "react";

const Container = Main =>
  class Presentation extends Component {
    state = {
      pageNumber: 1,
      selectedId: ""
    };
      
    announcementLimit = 5;

    fetchAnnouncementList = async (limit = 0) => {
      let { pageNumber } = this.state;
      let {
        userProfileMeta: { _id: userId }
      } = this.props || {};
     await this.props.getAnnouncementList(limit, pageNumber, userId);
    };

    manageAnnouncementsHandler = () => {
      this.props.history.push({
        pathname: `/presentation/admin/setup`,
        state: {
          activeTab: "announcement"
        }
      });
      this.props.onBellIconClick && this.props.onBellIconClick();
    };

    paginationHandler = direction => {
      this.setState(
        {
          pageNumber:
            direction === "right"
              ? this.state.pageNumber + 1
              : this.state.pageNumber - 1,
         
        },
        () => {
          this.fetchAnnouncementList(this.announcementLimit);
        }
      );
    };

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      };
      return <Main {...stateMethodProps} />;
    }
  };

export default Container;
