import React from "react";
import styled from "styled-components";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import { Link } from "react-router-dom";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import { Prompt } from "react-router";

//Components
import Container from "./container";
import StepDetails from "components/buildProcess/stepDetails";
import Sidebar from "./components/sidebar";
import CategoryTitleComponent from "./components/categoryTitle";
import SortableTreeHeader from "./components/sortableTreeHeader";
import PreviewModal from "components/previewModal";
import ThemePreview from "./components/themePreview";
import FullPageLoader from "components/FullPageLoader";

/**
 * Main library component
 * @param {*} props
 */
const NOTE_TEXT =
  "Any grayed-out themes have been disabled, but not deleted from existing presentations.";
const Theme = props => {
  props.validFileStatus &&
    ToastUtils.handleToast({
      operation: "error",
      message: props.validFileStatus
    });
  let {
    handleTreeOnChange,
    showThemePreview,
    handleTitleClick,
    handleModal,
    isThemeLoading,
    match,
    saveEditedThemeDetails,
    selectedContentRepo,
    disableUploadButton
  } = props;

  const contentRepoName = get(selectedContentRepo, `name`, "Content Repo Name");

  const breadCrumbs = () => {
    return (
      <LinkContainer>
        <StyledLink to={`/presentation/list`}>Home/</StyledLink>
        <StyledLink to={`/presentation/admin/content-repo`}>
          Content Repo/
        </StyledLink>
        <StyledLink
          to={`/presentation/admin/content-repo/${match.params.contentRepoId}/content-slides`}
        >
          {contentRepoName}/
        </StyledLink>
        <ThemeBreadCrumb>Themes</ThemeBreadCrumb>
      </LinkContainer>
    );
  };

  return (
    <ThemeContainer>
      {disableUploadButton && (
        <Prompt
          when={disableUploadButton}
          message="You haven't saved your progress. Hitting refresh or back will lose your work."
        />
      )}
      {isThemeLoading && <FullPageLoader layerIndex={10} />}
      <ThemeHeader>
        <StepDetails
          title={contentRepoName}
          description={breadCrumbs()}
          className={"step-details"}
          notDangerously={true}
        />
        <NoteContainer>
          <Note>NOTE: </Note>
          <NoteText>{NOTE_TEXT}</NoteText>
        </NoteContainer>
      </ThemeHeader>
      <ContentContainer>
        <MainSectionContainer>
          <SortableTreeHeader {...props} />
          <ContainerSortable className="sort-container">
            {!Array.isArray(props.treeData) || !props.treeData.length ? (
              <NoDataAvailableMessage>
                No data available.
              </NoDataAvailableMessage>
            ) : (
              <SortableTree
                treeData={props.treeData}
                onChange={handleTreeOnChange}
                maxDepth={1}
                rowHeight={80}
                generateNodeProps={rowInfo => {
                  const { node } = rowInfo;
                  return {
                    className: `drag-drop ${!rowInfo.node.enable &&
                      "disabled-row"}`,
                    title: (
                      <CategoryTitleComponent
                        {...node}
                        handleTitleClick={handleTitleClick}
                        saveEditedThemeDetails={saveEditedThemeDetails}
                      />
                    ),
                    buttons:
                      (props.generateButtonNodeList &&
                        props.generateButtonNodeList(node)) ||
                      []
                  };
                }}
              />
            )}
          </ContainerSortable>
        </MainSectionContainer>
        <SideBarContainer>
          <Sidebar uploadFileType={"ppt"} {...props} />
        </SideBarContainer>
      </ContentContainer>
      {showThemePreview && (
        <PreviewModal closeModal={handleModal} width="85%">
          <ThemePreview {...props} />
        </PreviewModal>
      )}
    </ThemeContainer>
  );
};

const ThemeContainer = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  width: ${props => props.theme.WRAPPER.WIDTH};
  margin: 106px auto 30px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};

  @media (max-width: 1024px) {
    width: calc(100% - 80px);
  }

  .step-details {
    border-bottom: none;
  }

  .disabled-row {
    .rst__rowContents {
      background-color: ${props => props.theme.COLOR.LIGHT_GREY};
    }
  }

  h2 {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const ThemeHeader = styled.div`
  .next-button {
    width: 160px;
  }

  .step-details {
    padding: 40px 0 30px;
    border-bottom: none;

    p {
      padding-top: 17px;
      display: block;
      text-transform: capitalize;
      font-family: ${props => props.theme.FONT.REG};
      font-weight: bold;
      font-size: 10px;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const MainSectionContainer = styled.div`
  border-radius: 3px;
  flex-basis: 68%;
  margin-right: 3%;
  ${props => props.theme.SNIPPETS.BOX_SHADOW_PRESENTATION};
  background-color: ${props => props.theme.COLOR.WHITE};
`;

const NoteContainer = styled.div`
  padding: 0 0 22px 0;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 12px;
  line-height: 1.5;
`;

const Note = styled.span`
  font-weight: bold;
`;

const NoteText = styled.span``;

const SideBarContainer = styled.div`
  width: 29.87%;
  padding: 22px 1.8% 0;
  border-radius: 3px;
  background-color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.BOX_SHADOW_PRESENTATION};
`;

const ContainerSortable = styled.div`
  width: 100%;
  height: 500px;
  position: relative;

  .rst__nodeContent {
    width: 91%;
  }

  .rst__rowContents {
    box-shadow: none;
    border: solid #dedede 1px;
  }

  .rst__rowToolbar {
    padding-right: 10px;

    .rst__toolbarButton {
      &:first-of-type {
        margin-right: 14px;
      }
    }
  }

  .rst__moveHandle,
  .rst__loadingHandle {
    margin-right: -2px;
    border: none;
    box-shadow: none;
  }

  .rst__lineHalfHorizontalRight::before,
  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after {
    background-color: #dedede;
  }
`;

const NoDataAvailableMessage = styled.span`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 800;
  padding: 30px;
  font-family: ${props => props.theme.FONT.REG};
  background: rgba(255, 255, 255, 0.2);
  font-size: 19px;
  opacity: 0.5;
`;

const LinkContainer = styled.span``;

const ThemeBreadCrumb = styled.span``;

const StyledLink = styled(Link)`
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  text-decoration: none;
  text-transform: none;
`;

export default Container(Theme);
