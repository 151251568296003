import React, { Component } from "react";

const Container = Main =>
  class ContentSlideGroup extends Component {
    constructor(props) {
      super(props);

      this.state = {
        groupOption: []
      };
    }

    componentDidMount() {
      //to fetch the groups of the slides
      this.props._fetchSlideGroup();
    }

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      };

      return <Main {...stateMethodProps} />;
    }
  };
export default Container;
