import React, { Component } from "react";
import styled from "styled-components";
import SliderDataComponent from "./components/sliderContent";
import SliderCoverDetails from "./components/sliderCoverDetails";
import CobrandLogoDetails from "./components/coBrandLogoDetails";
import { map, get } from "lodash";

export class SlideComponent extends Component {
  render() {
    const {
      slideImageEditable,
      previewData,
      slideImageError,
      draggableContainer,
      draggableElement,
      activeSlideDetail,
      selectedThemeLayout,
      selectedThemeDetails,
      getThumbnailBasedOnSlideType,
      checkIfAddedToPresentation,
      setDynamicFieldChanges,
      showDynamicImageOverlay,
      editIconsHandler
    } = this.props;

    // File url based on theme
    let thumbnailLocation =
      get(activeSlideDetail, "thumbnailLocation.url") ||
      (getThumbnailBasedOnSlideType &&
        getThumbnailBasedOnSlideType(
          selectedThemeLayout,
          activeSlideDetail,
          "thumbnail"
        ));
    let isCover = get(activeSlideDetail, "isCover");
    let isAddedToPresentation = checkIfAddedToPresentation();
    let hasDynamicData =
      get(activeSlideDetail, "containsDynamicText") ||
      get(activeSlideDetail, `containsDynamicImagery`);
    let displayDynamicImage = get(activeSlideDetail, "displayDynamicImage");

    return (
      <PreviewContainer>
        <PreviewSlideContainer>
          <DraggableContainer
            slideImageEditable={slideImageEditable}
            coOrdinate={previewData.boundaryBoxCoOrdinate}
            className="draggable-container"
            slideImageError={slideImageError}
            ref={draggableContainer}
          >
            <ThumbnailWrapper>
              {showDynamicImageOverlay && (
                <ThumbnailOverlay onClick={editIconsHandler}></ThumbnailOverlay>
              )}
              <ThumbnailLocation src={thumbnailLocation} />
              <PreviewImageContainer>
                <DraggableElement
                  className="draggable-element"
                  ref={draggableElement}
                >
                  {isCover && selectedThemeDetails && (
                    <SliderCoverDetails
                      {...this.props}
                      dynamicFields={selectedThemeDetails.themeData}
                    />
                  )}
                  {(displayDynamicImage || isAddedToPresentation) &&
                    hasDynamicData &&
                    map(get(activeSlideDetail, "slideData"), (slide, index) => {
                      return (
                        <SliderDataComponent
                          slideDetails={slide}
                          key={activeSlideDetail._id + index}
                          placeholderPosition={index}
                          inputType={slide.inputType}
                          setDynamicFieldChanges={setDynamicFieldChanges}
                          activeSlideDetail={activeSlideDetail}
                          {...this.props}
                        />
                      );
                    })}

                  <CobrandLogoDetails {...this.props} />
                </DraggableElement>
              </PreviewImageContainer>
            </ThumbnailWrapper>
          </DraggableContainer>
        </PreviewSlideContainer>
      </PreviewContainer>
    );
  }
}

const PreviewContainer = styled.div`
  height: 100%;
`;

const PreviewSlideContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const DraggableContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
`;

const ThumbnailOverlay = styled.span`
  opacity: 0.8;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.COLOR.WHITE};
  z-index: 1;
`;

const ThumbnailLocation = styled.img`
  width: 100%;
  height: 100%;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  opacity: ${props => props.showDynamicImageOverlay && "0.5"};
`;

const PreviewImageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const DraggableElement = styled.div``;
