import FetchUtils from "utils/FetchUtils.js";
import ToastUtils from "utils/handleToast";

// import report actions
import { filtersListSuccess, filtersListLoading } from "./action";

/**
 * Fetch all the filters
 */
const getFilterList = (contentRepoId, flag) => async dispatch => {
  // start loading
  dispatch(
    filtersListLoading({
      filtersListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/content-filters?checkIfFilterIsAssociated=true`,
    response = await FetchUtils.getData(URL, "Fetch all the Filters ==>");

  // stop loading
  dispatch(
    filtersListLoading({
      filtersListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch reports data on success
    dispatch(
      filtersListSuccess({
        filtersList: response.data
      })
    );
  } else {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Reorder fiters list
 */
const reorderFilterList = (contentRepoId, body) => async dispatch => {
  // start loading
  dispatch(
    filtersListLoading({
      filtersListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/content-filters/reorder`,
    response = await FetchUtils.patchData(URL, body, "Reorder filters ==>");

  // stop loading
  dispatch(
    filtersListLoading({
      filtersListLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message:
        (response.data && response.data.message) || "List updated successfully"
    });
  } else {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Create filter
 */
const createFilter = (contentRepoId, body) => async dispatch => {
  // start loading
  dispatch(
    filtersListLoading({
      filtersListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/content-filters`,
    response = await FetchUtils.postData(URL, body, "Create filter ==>");

  // stop loading
  dispatch(
    filtersListLoading({
      filtersListLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message:
        (response.data && response.data.message) ||
        "Filter created successfully"
    });
  } else {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Delete filter
 */
const deleteFilter = (contentRepoId, contentFilterId) => async dispatch => {
  // start loading
  dispatch(
    filtersListLoading({
      filtersListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/content-filters/${contentFilterId}`,
    response = await FetchUtils.deleteData(URL, "Delete filter ==>");

  // stop loading
  dispatch(
    filtersListLoading({
      filtersListLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message:
        (response.data && response.data.message) ||
        "Filter deleted successfully"
    });
  } else {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Edit filter
 */
const editFilter = (contentRepoId, contentFilterId, body) => async dispatch => {
  // start loading
  dispatch(
    filtersListLoading({
      filtersListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/content-filters/${contentFilterId}`,
    response = await FetchUtils.patchData(URL, body, "Update filter ==>");

  // stop loading
  dispatch(
    filtersListLoading({
      filtersListLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message:
        (response.data && response.data.message) ||
        "Filter updated successfully"
    });
  } else {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

const updateExistingFilter = (
  contentRepoId,
  contentFilterId
) => async dispatch => {
  // start loading
  dispatch(
    filtersListLoading({
      filtersListLoading: true
    })
  );

  const URL = `/content-repositories/${contentRepoId}/content-filters/content-repositories/${contentFilterId}`,
    response = await FetchUtils.postData(URL, "Update existing filter ==>");

  // stop loading
  dispatch(
    filtersListLoading({
      filtersListLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message:
        (response.data && response.data.message) ||
        "Filter updated successfully"
    });
  } else {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

export {
  getFilterList,
  reorderFilterList,
  createFilter,
  deleteFilter,
  editFilter,
  updateExistingFilter
};
