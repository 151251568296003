import React from "react";
import container from "./container";
import styled from "styled-components";
import Button from "../button";
import DraggableSort from "components/draggableSort";
import SlideList from "./components/slideList";

const SearchPriorities = props => {
  let {
    searchOptions,
    onListUpdate,
    onSaveSearchPrioritiesData,
    resetSearchPrioritiesData
  } = props;

  return (
    <Wrapper>
      <Float>
        <HeadingContainer>
          <Heading>Search Priorities</Heading>
          <SubHeading>
            Drag to re-arrange search priorities accordingly. This is the order
            in which the system will rank information found on each PowerPoint
            slide.
          </SubHeading>
        </HeadingContainer>
        <FloatRight>
          <ButtonWrapper>
            <Button
              text="Reset"
              width={80}
              isFixed={true}
              type="secondary"
              onClick={resetSearchPrioritiesData}
            />
          </ButtonWrapper>
          <Button
            text="Save"
            width={80}
            isFixed={true}
            type="primary"
            onClick={onSaveSearchPrioritiesData}
          />
        </FloatRight>
      </Float>
      <DragList>
        <DraggableSort
          onListUpdate={onListUpdate}
          axis="y"
          listData={searchOptions}
          handlesGroup={false}
          allowExternalDrop={false}
          renderChild={(data, index) => {
            return (
              <SlideList
                key={data._id}
                index={index}
                title={data.searchKey}
                data={data}
                {...data}
              />
            );
          }}
        />
      </DragList>
    </Wrapper>
  );
};

export default container(SearchPriorities);

const Wrapper = styled.div`
  width: 100%;
  padding: 28px 40px;
  border-radius: 4px;
  background-color: #fff;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  box-sizing: border-box;

  .search-tab {
    margin-bottom: 14px;
  }

  .rc-draggable-list {
    cursor: move;
  }
`;

const Heading = styled.h2`
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  text-align: left;
`;

const SubHeading = styled.span`
  margin-top: 2px;
  display: block;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  text-align: left;
`;

const DragList = styled.div``;

const Float = styled.div`
  width: 100%;
  margin-bottom: 30px;

  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const FloatRight = styled.div`
  float: right;
`;
const HeadingContainer = styled.div`
  width: 70%;
  float: left;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 15px;
`;
