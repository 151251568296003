import React from "react";
import styled from "styled-components";

// container
import Container from "./container";

// components
import BgWrapper from "components/bgWrapper";
import CategoryHeader from "../categoryHeader";
import CategoryCreateForm from "./components/categoryCreateForm";

const categoryCreatComponent = props => {
  return (
    <CategoriesContainer>
      <CategoryHeader {...props} titleText={"Categories"} _submit _back />
      <CategoryCreateForm {...props} />
    </CategoriesContainer>
  );
};

const CategoriesContainer = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  width: ${props => props.theme.WRAPPER.WIDTH};
  margin: 0 auto;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  @media (max-width: 1024px) {
    width: calc(100% - 80px);
  }
`;

export default Container(BgWrapper(categoryCreatComponent));
