import React, { Component } from "react";
import { Cart, Paint, TabLibrary, Sort, ConfigRate } from "assets/icons";
import { connect } from "react-redux";
import {
  get,
  map,
  each,
  flatten,
  intersection,
  keys,
  filter,
  includes,
  sortBy,
  mapKeys,
  toLower,
  find,
  differenceBy,
  isEmpty,
  uniqBy,
  uniq,
  has
} from "lodash";

import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
//utils
import ToastUtils from "utils/handleToast";
import PollingUtils from "utils/PollingUtils";
import handleBodyScroll from "utils/handleBodyScroll";
import ValidationUtils from "utils/ValidationUtils";
import featureFlags from "utils/featureFlags";

import { Overview } from "assets/images";

//reducer action and props
import { mapStateToProps, actions } from "./mapStateToProps";
import uniqId from "uniqid";
import { shareBuild } from "./services/saveBuildData";

let UI_STRINGS = {
  BUILD_ERROR: "Could not complete the build. Please try again.",
  BUILD_TIMEOUT: "Build is taking too long. PLease try again later.",
  UNSAVED_BUILD_ERROR: "Please save your presentation before proceeding.",
  ADD_GROUP_SLIDE_WARNING_MESSAGE:
    "Adding this slide will add all the slides belonging to this group. Do you still want to continue?",
  REMOVE_GROUP_SLIDE_WARNING_MESSAGE:
    "Removing this slide will remove all the slides belonging to this group. Do you still want to continue?",
  EMPTY_FIELD_ERROR_MESSAGE: "This field is required.",
  FIELD_ERROR_MESSAGE: "Please enter required fields.",
  VALID_INPUT_ERROR_MESSAGE: "Please enter all the required fields.",
  CONTENT_REPO_ERROR_MESSAGE: "Please select a content repo.",
  UNSAVED_PROMPT_ERROR:
    "You haven't saved your progress. Hitting refresh or back will lose your work.",
  SPECIAL_CHAR_ERROR_MESSAGE: "Please do not enter the special character.",
  WHITE_SPACE_ERROR_MESSAGE: "Please enter a valid input.",
  SAVED_PRESENTATION_SUCCES_MESSAGE:
    "Your presentation has been saved successfully.",
  DISABLED_CONTENT_REPO:
    "Please select a new content repository, the previous one has been deleted/disabled."
};

const Container = Main =>
  connect(
    mapStateToProps,
    actions
  )(
    class Presentation extends Component {
      state = {
        userDetail: null,
        stepItems: [
          {
            title: "Setup",
            src: ConfigRate
          },
          {
            title: "Theme",
            src: Paint
          },
          {
            title: "Library",
            src: TabLibrary
          },
          {
            title: "Sort",
            src: Sort
          },
          {
            title: "Build",
            src: Cart
          }
        ],
        activeStep: 0,
        includeCoverPage: true,
        selectedThemeLayout: 0,
        selectedCoverLayout: null,
        contentRepo: {},
        selectedFilters: [],
        selectedLogo: null,
        selectedLogoId: null,
        selectSlides: [],
        selectedSlidesData: [],
        selectedSlidesListDetail: [],
        overlayChangedDynamicImages: [],
        currentOverlayData: null,
        deckChildrenSlideId: [],
        deckChildrenData: [],
        isSlideDeck: false,
        slidePartOfDeck: null,
        cropData: {},
        isImageCropperOpen: false,
        selectedImageCategoryList: {},
        isSingleSlidePreview: true,
        librarySearchString: "",
        isShowPreview: false,
        buildSetupDetails: {
          presentationName: {
            value: "",
            error: ""
          },
          includePageNumber: {
            value: true,
            error: ""
          },
          customerName: {
            value: "",
            error: ""
          }
        },
        presentationId: "",
        showModal: false,
        maximumSlideCount: 60,
        contentSlideGroups: {},
        buildProgress: {
          status: "InProgress"
        },
        activeSlideDetail: {},
        isInputFocused: false,
        allowLogoUpload: !!this.props.match.params.id,
        isEdited: false,
        completedSteps: [],
        slideNameError: "",
        dividerSlideCount: 0,
        coverDetails: {},
        isPresentationNameFocused: false,
        downloadDeck: false,
        requiredSlides: [],
        overviewLimitExceeded: false,
        newlyAddedSlideFlag: true,
        coBrandLogoData: {},
        showEditIcons: true, // show edit icons for dynamic images
        showDynamicImageOverlay: false,
        slidesWithDeletedImages: [],
        isImageCategoryEdited: false
      };

      //  Disabled slides
      disabledSlide = [];

      componentDidMount() {
        // Add event listener to check back button
        window.addEventListener("beforeunload", this.checkBackButton);

        // set user detail profile if present and get presentation meta details
        this.setUserProfile();

        // to set focus on presentation name field once
        if (this.props.match.params.id) {
          this.setFocusValue({
            key: "isPresentationNameFocused",
            value: true
          });
          this.setFocusValue({
            key: "newlyAddedSlideFlag",
            value: false
          });
        }
      }

      componentWillUnmount() {
        PollingUtils.stopPolling();
        window.removeEventListener("beforeunload", this.checkBackButton);
      }

      checkBackButton = e => {
        if (
          (this.state.isEdited || this.props.isSlideDetailLoading) &&
          !this.state.downloadDeck
        ) {
          e.preventDefault();
          e.returnValue = UI_STRINGS.UNSAVED_PROMPT_ERROR;
        } else {
          return;
        }
      };

      //get presentation detail on edit
      getPresentationDetailOnLoad = async () => {
        let { id: presentationId } = this.props.match.params;
        const { _id: userId } = this.state.userDetail;
        const { contentRepoList } = this.props;

        if (presentationId) {
          await this.props.getPresentationDetail(userId, presentationId);

          let presentationData = this.props.presentationData || {};

          let { contentRepository } = presentationData;
          let { buildSetupDetails } = this.state;

          let title =
            (Array.isArray(contentRepoList) &&
              contentRepoList.filter(item => contentRepository === item._id)) ||
            [];

          let contentRepo = {
            _id: get(title[0], "_id") || null,
            title: get(title[0], "title") || "",
            overviewData: get(title[0], "overviewData") || {},
            maximumSlides: get(title[0], "maximumSlides") || 60
          };

          ["presentationName", "customerName", "includePageNumber"].forEach(
            item => {
              buildSetupDetails[item] = {
                value: presentationData[item],
                error: ""
              };
            }
          );

          if (!title.length) {
            // when only one content repo is assigned to a user
            if (
              Array.isArray(contentRepoList) &&
              contentRepoList.length === 1
            ) {
              title = contentRepoList;

              contentRepo = {
                _id: get(title[0], "_id") || null,
                title: get(title[0], "title") || "",
                overviewData: get(title[0], "overviewData") || {},
                maximumSlides: get(title[0], "maximumSlides") || 60
              };

              this.setState(
                {
                  contentRepo,
                  presentationId,
                  includeCoverPage: get(presentationData, `includeCover`)
                },
                () => {
                  this.onContentRepoDropdownChanged();
                }
              );
            } else {
              ToastUtils.handleToast({
                operation: "error",
                message: UI_STRINGS.DISABLED_CONTENT_REPO
              });

              this.setState(
                {
                  presentationId,
                  buildSetupDetails: {},
                  includeCoverPage: get(presentationData, `includeCover`)
                },
                () => {
                  this.setState({
                    buildSetupDetails
                  });
                }
              );
            }

            return;
          }

          let slides = get(presentationData, `slides`);

          // extract slides which were selected and filter out undefined element in array
          let selectSlides = map(slides, eachSlide => {
            let Id = get(eachSlide, `slideId._id`);
            if (Id && !this._checkIfContentSlideDisabled(eachSlide)) return Id;
          }).filter(ele => !!ele);

          // check if slideid is present
          let checkIfSlideDataPresent = filter(slides, eachSlide => {
            this._handleDisabledSlides(eachSlide);
            return (
              get(eachSlide, `slideId`) &&
              !this._checkIfContentSlideDisabled(eachSlide)
            );
          });

          // Handle disabled slide toast
          if (this.disabledSlide.length) {
            this.handleToastError(
              "warning",
              this._getDisabledSlideErrorMessage(this.disabledSlide)
            );
          }

          // extract slide list detail
          let selectedSlidesListDetail = map(
            checkIfSlideDataPresent,
            eachSlide => {
              return {
                type: get(eachSlide, `type`),
                changedTitle: eachSlide.slideTitle,
                ...get(eachSlide, `slideId`),
                ...(get(eachSlide, "slideData") && {
                  slideData: get(eachSlide, "slideData")
                }),
                ...(get(eachSlide, `slideData`) &&
                  get(eachSlide, `slideData.length`) && {
                    containsEditedSlideData: true // set flag so that slideData for the slide can be called to get "all" the dynamic images as slideData in presentationData contains only the selected image
                  })
              };
            }
          );

          await this.checkIfLastSavedImageWasDeleted(selectedSlidesListDetail);

          // extract customer Logo url
          let selectedLogo = get(presentationData, `customerLogo.location.url`);
          let selectedLogoId = get(presentationData, `customerLogo._id`);

          this.setState(
            {
              presentationId,
              buildSetupDetails,
              contentRepo,
              selectSlides,
              selectedSlidesListDetail,
              selectedLogo,
              selectedLogoId,
              croppedImage: selectedLogo,
              includeCoverPage: get(presentationData, `includeCover`)
            },
            () => {
              this.onContentRepoDropdownChanged(true);
              // Set completed steps
              this.setCompletedSteps(presentationData);
            }
          );
        }
      };

      // check if the last saved slides have had images which are now completely deleted and show a toast if images were deleted
      checkIfLastSavedImageWasDeleted = selectedSlidesListDetail => {
        let slidesWithDeletedImages = [];
        each(selectedSlidesListDetail, eachSlide => {
          if (get(eachSlide, `slideData.length`)) {
            each(eachSlide.slideData, (eachSlideData, index) => {
              if (get(eachSlideData, `image.imageId.deleted`)) {
                slidesWithDeletedImages.push(get(eachSlide, `changedTitle`));
              }
            });
          }
        });

        // if slide contains more than one placeholder the slideName would be repeated, therefore remove the duplicates from the array
        slidesWithDeletedImages = uniq(slidesWithDeletedImages);

        this.setState({
          slidesWithDeletedImages
        });

        if (!slidesWithDeletedImages.length) return;

        ToastUtils.handleToast({
          operation: "error",
          message: `The last selected image for the Slide(s) [${slidesWithDeletedImages}] has been deleted by the administrator. Please select another image(s).`
        });
      };

      componentDidUpdate(prevProps) {
        // fetch and store user detail to fetch user id and get presentation required detail.
        if (this.props.userProfileMeta !== prevProps.userProfileMeta) {
          this.setUserProfile();
        }
      }

      /**
       *This function checks if the used presentation was either deleted/disabled
       *
       * This is done by checking a flag named enabled in slidesId key of presentation details response
       */
      _checkIfContentSlideDisabled = ({ slideId = {}, ...slideData }) => {
        if (slideData.type !== "contentSlide") return false;
        const fieldstoBeChecked = [
          "slideListByBlanks",
          "slideListByCovers",
          "slideListByDividers",
          "slideListByThemes"
        ];

        // if any one of the above fields is null (not an array) it means that the slide was deleted permanently by the admin
        const isFieldNull = fieldstoBeChecked.some(eachField => {
          return has(slideId, eachField) && !Array.isArray(slideId[eachField]);
        });

        return !!!slideId.enable || isFieldNull;
      };

      /**
       *Function to check if slide is disabled and push it an static class variable disableSlide and also set edited true to show save button
       *
       * @param {*} [slide={}]
       */
      _handleDisabledSlides = (slide = {}) => {
        if (this._checkIfContentSlideDisabled(slide)) {
          this.disabledSlide.push(slide);
          this.onStepEdit();
        }
      };

      /**
       * Get User Profile based on API respone and store in local state
       *
       */
      setUserProfile = () => {
        const { userProfileMeta } = this.props;
        userProfileMeta &&
          this.setState(
            {
              userDetail: userProfileMeta
            },
            () => this.getPresentationSetupMetaDataList()
          );
      };

      //hits all the API requests required for presentation
      getPresentationSetupMetaDataList = async () => {
        let { showImageCategories, showCustomerLogo } = get(
          featureFlags,
          `presentation`
        );

        if (this.state.userDetail) {
          await this.props.getContentRepoList(this.state.userDetail._id);
          showCustomerLogo &&
            this.props.getCustomerLogoList(this.state.userDetail._id);
          await this.getPresentationDetailOnLoad();
          showImageCategories && this.fetchImageCategoryList();
        }
      };

      //fetch library filters list and keep default check all stored in filters
      fetchLibraryFiltersList = async (contentRepo = {}) => {
        contentRepo._id &&
          (await this.props.getLibraryFiltersList(contentRepo._id));

        // extract filter list
        // const { libraryFiltersList } = this.props;
        let selectedFilters = [];
        // TODO: Uncomment for default filters
        // each(libraryFiltersList, ({ children }, index) => {
        //   let filtersList = map(children, ({ _id }) => {
        //     return _id;
        //   });
        //   filtersList.push(`F-${index}`);
        //   selectedFilters.push(...filtersList);
        // });

        // this.setState({
        //   selectedFilters
        // });

        return selectedFilters;
      };

      /**
       * Upload customer logo
       *
       * @param {Object} imageData Data to be sent
       * @param {Object} title Customer name
       * @param {boolean} saveToProfile states whether the uploaded image has to saved in profile
       */
      handleSelectedLogo = async (imageData, customerName, saveToProfile) => {
        // data to be sent in upload post
        let data = {
          imageData,
          saveToProfile,
          title: customerName
        };

        let response = await this.props.createNewCustomerLogo(
          this.state.userDetail._id,
          data
        );

        this.setState({
          selectedLogoId: get(response, `data._id`)
        });

        // fetch new list of logos if save to profile was checked
        if (saveToProfile) {
          this.props.getCustomerLogoList(this.state.userDetail._id);
        }

        this.onStepEdit();
      };

      /**
       *
       * @param selectedLogo logo which was selected from the bottom or uploaded
       * @param {boolean} isImageSourceUrl states the selectedLogo is image url or data uri
       */
      selectedLogoHandler = selectedLogo => {
        if (!selectedLogo) {
          this.setState({
            selectedLogo: null,
            selectedLogoId: null
          });
          this.state.selectedLogo && this.onStepEdit();
          return;
        }
        this.setState({
          selectedLogo: selectedLogo.url || selectedLogo,
          croppedImage: selectedLogo.url || selectedLogo, // storing same data as selectedLogo so that croppedImage could be used for preview in the placeholder and selectedLogo as the imageSrc
          selectedLogoId: selectedLogo._id
        });
        this.onStepEdit();
      };

      /** Fetch themes and covers data from service
       * Hit Services with content repo id
       * This services are dependent on content repo selected in dropdown
       *
       */
      onContentRepoDropdownChanged = flag => {
        let { contentRepo: contentRepoId, newlyAddedSlideFlag } = this.state;
        //reset library search
        this.fetchLibraryBySearch();

        if (typeof flag === "string") newlyAddedSlideFlag = true;

        this.setState({
          librarySearchString: "",
          selectedSlidesData: [],
          maximumSlideCount: contentRepoId.maximumSlides || 60,
          newlyAddedSlideFlag
        });

        //exit if contentRepoId Detail is not present
        if (
          typeof contentRepoId === "object" &&
          !Object.keys(contentRepoId).length
        )
          return;

        //set content repo id and get lists required for presentation
        const selectedContentRepoId = contentRepoId._id;
        this.props.getDynamicCoverFieldsList(selectedContentRepoId);
        this.fetchDynamicCoverFields(selectedContentRepoId);
        this.fetchThemeList(selectedContentRepoId);
      };

      checkRequiredSlides = (item = {}) => {
        let { label, isRequired } = item;

        return label === "slide" && isRequired;
      };

      /**
       * maping edit presentation response with dynamic cover fields
       *
       * @param {*} selectedContentRepoId content repository id
       */

      fetchDynamicCoverFields = async selectedContentRepoId => {
        await this.props.getDynamicCoverFieldsList(selectedContentRepoId);
        let { dynamicCoverFieldsList, presentationData } = this.props;

        let { buildSetupDetails, contentRepo } = this.state;
        presentationData = presentationData || {};
        let { contentRepository } = presentationData;
        Array.isArray(dynamicCoverFieldsList) &&
          dynamicCoverFieldsList.forEach(({ label }) => {
            let key = label.replace(/\s/g, ""); // to create a key out of label
            key = key.charAt(0).toLowerCase() + key.slice(1); // first letter lowercase

            buildSetupDetails[key] = buildSetupDetails[key]
              ? buildSetupDetails[key]
              : {};

            let UTCDate = new Date();
            if (key === "presentationDate") {
              let date = new Date(Date.parse(presentationData[key]));
              UTCDate = new Date(
                date.getUTCMonth() +
                  1 +
                  "-" +
                  date.getUTCDate() +
                  "-" +
                  date.getUTCFullYear()
              );
            }

            let value =
              key === "presentationDate"
                ? presentationData[key]
                  ? UTCDate
                  : new Date()
                : presentationData[key];

            if (this.props.match.params.id) {
              buildSetupDetails[key].value =
                contentRepository !== selectedContentRepoId &&
                this.skipKeys.indexOf(key) < 0
                  ? key === "presentationDate"
                    ? new Date()
                    : ""
                  : value || "";
            } else {
              buildSetupDetails[key].value =
                key === "presentationDate"
                  ? new Date()
                  : buildSetupDetails[key].value || "";
            }
          });

        if (
          this.props.match.params.id &&
          get(presentationData, "includeOverview") !==
            !!get(contentRepo, `overviewData.enable`) &&
          contentRepository === selectedContentRepoId
        ) {
          !!!get(contentRepo, `overviewData.enable`) &&
            this.handleToastError(
              "warning",
              `The overview page (table of contents) option has been disabled by the Admin.`
            );
          this.onStepEdit();
        }

        // attach key for includeOverview
        buildSetupDetails["includeOverview"] = {
          value:
            presentationData["includeOverview"] ||
            !!get(contentRepo, `overviewData.enable`),
          error: ""
        };

        if (!!!get(contentRepo, `overviewData.enable`)) {
          buildSetupDetails["includeOverview"] = {
            value: false,
            error: ""
          };
        }

        this.setState(
          {
            buildSetupDetails: {}
          },
          () => {
            this.setState({
              buildSetupDetails
            });
          }
        );
      };

      /**
       * Fetch themes data from service
       */
      fetchThemeList = async contentRepoId => {
        await this.props.getThemeList(contentRepoId);

        // get master theme
        let activeTheme = filter(this.props.themeList, eachTheme => {
          return !!eachTheme.isMaster;
        });

        // set master theme as default theme
        let defaultTheme =
          (Array.isArray(this.props.themeList) &&
            activeTheme.length &&
            activeTheme[0]._id) ||
          null;

        // get theme from save presentation data
        let selectedTheme =
          (this.props.presentationData &&
            get(this.props.presentationData, "theme")) ||
          defaultTheme;

        // check if theme from the save presentation exist in the fetched theme list if yes then assign that theme else assign default theme
        if (
          !find(this.props.themeList, {
            _id: selectedTheme
          }) &&
          get(this.props, "presentationData.contentRepository") ===
            contentRepoId
        ) {
          this.handleToastError(
            "error",
            `The theme you originally used no longer exists. Please select a new theme.`
          );
          selectedTheme = defaultTheme;
          this.nextStepHandler(this.state.activeStep + 1, true);
        }

        // set theme from saved presentation data else use default theme
        let selectedThemeLayout = this.props.match.params.id
          ? (this.props.presentationData || {}).contentRepository !==
            contentRepoId
            ? defaultTheme
            : selectedTheme
          : defaultTheme;

        let selectedThemeDetails =
          this.getDetails(selectedThemeLayout, "theme") || activeTheme;

        selectedThemeLayout &&
          this.setState({ selectedThemeLayout, selectedThemeDetails }, () =>
            this.fetchCoverList(contentRepoId)
          );
      };

      /**
       * Fetch covers list based on selected theme from service
       */
      fetchCoverList = async () => {
        const { contentRepo: contentRepoId, selectedThemeLayout } = this.state;

        let { showPresentationFilter } = get(featureFlags, `presentation`);
        let { presentationData } = this.props;

        //exit if contentRepoId Detail is not present
        if (
          typeof contentRepoId === "object" &&
          !Object.keys(contentRepoId).length
        )
          return;

        if (
          Array.isArray(this.props.themeList) &&
          this.props.themeList.length
        ) {
          //set content repo id and get lists required for presentation
          const selectedContentRepoId = contentRepoId._id;
          await this.props.getCoverList(
            selectedContentRepoId,
            selectedThemeLayout
          );

          let defaultCover;
          let defaultCoverDetails = {};

          // Select the first cover by default in case no covers are selected
          if (
            Array.isArray(this.props.coverList) &&
            this.props.coverList.length
          ) {
            defaultCoverDetails = { ...this.props.coverList[0] };
            defaultCover = get(this.props.coverList[0], `_id`) || null;
          }

          let selectedCover = this.props.match.params.id
            ? (presentationData || {}).contentRepository !== contentRepoId ||
              presentationData.theme !== selectedThemeLayout
              ? defaultCover
              : get(this.props.presentationData, "coverSlide.cover")
            : defaultCover;

          let selectedCoverDetails =
            this.getDetails(selectedCover, "cover") || defaultCoverDetails;

          selectedCover &&
            this.setState({
              selectedCoverLayout: selectedCover,
              coverDetails: selectedCoverDetails
            });

          // Get selected filters before content slides for library page
          const selectedFilters =
            showPresentationFilter &&
            (await this.fetchLibraryFiltersList(contentRepoId));

          await this.props.getLibraryByTopic(
            selectedContentRepoId,
            selectedThemeLayout,
            {
              filters: (selectedFilters || []).filter(
                elem => !(elem.indexOf("F-") > -1)
              )
            }
          );

          // Get required slides data
          let {
            withRequiredSlides,
            withRequiredSlideId
          } = this.getContentSlideDetails();

          await this.props.getDividerListOfSelectedRepo(
            selectedContentRepoId,
            selectedThemeLayout
          );
          await this.props.getBlankSlideListOfSelectedRepo(
            selectedContentRepoId,
            selectedThemeLayout
          );
          await this.props.getContentSlideListOfSelectedRepo(
            selectedContentRepoId,
            selectedThemeLayout
          );

          let formattedList = this.formatSlidesForDragAndDrop(
            withRequiredSlides
          );

          // Handle overview slide limit flag
          let overviewLimitExceeded = !this.checkOverviewSlideLimit(
            formattedList
          );

          this.setState({
            overviewLimitExceeded,
            selectedSlidesListDetail: formattedList,
            selectSlides: withRequiredSlideId
          });
        }
      };

      // get content slide detail based on the selected id from the response while editing the presentation.
      getContentSlideDetails = () => {
        let { selectSlides, newlyAddedSlideFlag } = JSON.parse(
          JSON.stringify(this.state)
        );
        let newSelectedSlides = [...this.state.selectedSlidesListDetail];
        let currentReqSlides = [];

        /**
         * Loop through content slides
         *
         * @param {Array} contentSlides array of content slides
         * @param {Array} [selectedSlidesListDetail=[]] array of selected content slides
         * @param {Object} contentSlideGroups unique content slide group objects
         * @returns array of selected slide detail
         */
        let getSlides = (contentSlides, contentSlideGroups = {}) => {
          each(contentSlides, item => {
            let { children, _id, label, group } = item;

            if (group && group.title) {
              contentSlideGroups[group.title] = contentSlideGroups[group.title]
                ? contentSlideGroups[group.title]
                : [];

              if (
                contentSlideGroups[group.title] &&
                contentSlideGroups[group.title].filter(
                  ({ _id: id }) => item._id === id
                ).length === 0
              ) {
                contentSlideGroups[group.title].push(item);
              }
            }

            let slideIndex = selectSlides.indexOf(_id);
            if (
              label === "slide" &&
              slideIndex > -1 &&
              this.props.match.params.id
            ) {
              newSelectedSlides[slideIndex] = {
                ...newSelectedSlides[slideIndex],
                ...item
              };
            }

            if (this.checkRequiredSlides(item)) {
              currentReqSlides = [...currentReqSlides, item];
            }

            if (Array.isArray(children)) {
              return getSlides(children, contentSlideGroups);
            }
          });

          return contentSlideGroups;
        };

        let contentSlideGroups = getSlides(this.props.libraryByTopicList);

        // Get required slides which are not present in the ppt
        let notIncludedSlides = differenceBy(
          currentReqSlides,
          newSelectedSlides,
          "_id"
        );

        let withRequiredSlides = [...newSelectedSlides, ...notIncludedSlides];

        // Get array of required slide Id which are not added in the presentation
        let withRequiredSlideId = map(withRequiredSlides, "_id");

        if (currentReqSlides.length) newlyAddedSlideFlag = false;

        this.setState({
          selectedSlidesListDetail: withRequiredSlides,
          contentSlideGroups,
          requiredSlides: currentReqSlides,
          newlyAddedSlideFlag
        });

        return {
          withSlideDetails: newSelectedSlides,
          withRequiredSlides,
          withRequiredSlideId
        };
      };

      /**
       * Fetch Image category list based on selected content repo
       * @param {String} contentRepoId
       */
      fetchImageCategoryList = async contentRepoId => {
        //hit get dynamic cover fields service with actions

        await this.props.getImageCategoryListOfSelectedRepo(contentRepoId);
        let { id } = this.props.match.params;
        let { selectedImageCategoryList } = this.state;
        if (id) {
          let { imageCategoryList } = this.props;
          let { imageCategories } = this.props.presentationData || {};
          Array.isArray(imageCategoryList) &&
            Array.isArray(imageCategories) &&
            imageCategoryList.forEach(imageCat => {
              let { attribute, key } = imageCat;
              attribute.forEach(item => {
                let { _id, title } = item;
                if (imageCategories.indexOf(_id) > -1) {
                  selectedImageCategoryList[key] = selectedImageCategoryList[
                    key
                  ]
                    ? selectedImageCategoryList[key]
                    : [];
                  let obj = {
                    label: title,
                    title: title,
                    value: _id,
                    _id: _id
                  };
                  selectedImageCategoryList[key].push(obj);
                }
              });
            });
        }

        this.setState({
          selectedImageCategoryList
        });
      };

      /**
       *Check if slides are saved and set the step is edited
       *
       * @param {*} { slides }
       */
      handleUnsavedSlide = ({ slides }) => {
        if (slides.length !== this.state.selectedSlidesListDetail.length) {
          this.onStepEdit(true);
        }
      };

      modifyStep = activeStep => {
        this.setState({ activeStep });
      };

      /**
       * Handle filters with based on current selected tab active for view by search and topics
       * @param {Boolean} viewByTopic - True for view by topic/ False for view by search
       */
      onChangeHandleFiltersCheck = (viewByTopic, resetFilters) => {
        const { contentRepo: contentRepoId, selectedFilters } = this.state;

        //exit if contentRepoId Detail is not present
        if (
          typeof contentRepoId === "object" &&
          !Object.keys(contentRepoId).length
        )
          return;

        //set content repo id and get lists required for presentation
        const selectedContentRepoId = contentRepoId._id;

        // reset selected filters on clear all button
        if (resetFilters && selectedFilters.length) {
          this.setState({
            selectedFilters: []
          });
        } else if (resetFilters && !selectedFilters.length) {
          return;
        }

        //if view by search tab is active
        !viewByTopic && this.fetchLibraryBySearch();

        //if view by topic tab is active
        this.props.getLibraryByTopic(
          selectedContentRepoId,
          this.state.selectedThemeLayout,
          {
            filters: resetFilters
              ? []
              : selectedFilters.filter(elem => !(elem.indexOf("F-") > -1))
          }
        );
      };

      /**
       * Get Library Search Results based on search Text and filters selected from left panel
       *  @param {Array} filters
       *  @param {String} search
       *
       */
      onChangeHandleLibraryBySearch = ({ search: librarySearchString }) => {
        this.setState(
          {
            librarySearchString
          },
          () => this.fetchLibraryBySearch()
        );
      };

      fetchLibraryBySearch = () => {
        const {
          contentRepo: contentRepoId,
          selectedFilters,
          librarySearchString
        } = this.state;

        //check if length exists more than 3 and content repo is selected
        let checkStringLength =
          typeof librarySearchString === "string" &&
          (librarySearchString.length > 2 || librarySearchString.length === 0);

        //exit if contentRepoId Detail is not present
        if (
          typeof contentRepoId === "object" &&
          !Object.keys(contentRepoId).length
        ) {
          ToastUtils.handleToast({
            operation: "error",
            message:
              "Please select a content repository before searching slides."
          });
          return;
        }

        // get content slides list based on selected repo
        checkStringLength &&
          this.props.getLibraryBySearch(
            contentRepoId._id,
            this.state.selectedThemeLayout,
            {
              search: librarySearchString,
              filters: selectedFilters.filter(
                elem => !(elem.indexOf("F-") > -1)
              )
            }
          );
      };

      /**
       * handle Theme, Cover Layout and checkbox change
       * @param {String} key contains propertyName
       * @param {String} value contains changed value
       */
      handleStateChange = ({ key, value, cb }, flag) => {
        this.setState(
          {
            [key]: value
          },
          () => {
            !flag && this.onStepEdit();
            cb && cb();
          }
        );
      };

      /**
       * handle setup textbox datachange
       * @param {String} label key name
       * @param {String} value value of the text field
       * @param {String} error error when validation fails
       */

      handleSetupDataChange = (label, value = "", error = "") => {
        let { buildSetupDetails } = this.state;

        buildSetupDetails[label] = buildSetupDetails[label]
          ? buildSetupDetails[label]
          : {};

        // set isEdited flag on value change
        if (buildSetupDetails[label].value !== value) this.onStepEdit();

        buildSetupDetails[label].value = value;
        buildSetupDetails[label].error = error;

        this.setState({
          buildSetupDetails
        });
      };

      // empty data on contentrepo change
      skipKeys = ["presentationName", "includePageNumber", "customerName"];
      clearSavedData = () => {
        let { buildSetupDetails } = this.state;
        this.props.resetPresentationDetails({
          presentationName: buildSetupDetails["presentationName"].value,
          customerName: buildSetupDetails["customerName"].value
        });

        for (let item in buildSetupDetails) {
          if (this.skipKeys.indexOf(item) === -1) {
            buildSetupDetails[item].value = "";
          }
        }

        this.setState({
          selectSlides: [],
          buildSetupDetails,
          selectedSlidesListDetail: []
        });
      };

      // handler for setting the slides id which are selected
      handleSelectSlides = (
        selectSlides = [],
        selectedSlidesListDetail = []
      ) => {
        let overviewLimitExceeded = false;
        let { newlyAddedSlideFlag, selectSlides: selectedSlides } = this.state;
        let {
          presentationData: { slides }
        } = this.props;
        // to set isEdited true only if content slides exist
        let contentSlidesOnly = this.getContentSlides(selectedSlidesListDetail);
        if (contentSlidesOnly.length) {
          this.onStepEdit();
        } else {
          this.onStepEdit(false);
        }

        overviewLimitExceeded = !this.checkOverviewSlideLimit(
          selectedSlidesListDetail
        );

        // Check over slide limit
        if (Array.isArray(slides) && slides.length) newlyAddedSlideFlag = false;

        if (Array.isArray(selectedSlides) && !selectedSlides.length)
          newlyAddedSlideFlag = true;

        // Add new flag on newly added slides to show it highlighed
        let modifiedSlide = selectedSlidesListDetail;
        if (!newlyAddedSlideFlag) {
          modifiedSlide = this.modifyNewlyAddedSlide(selectedSlidesListDetail);
        }

        this.setState({
          selectSlides,
          selectedSlidesListDetail: modifiedSlide,
          newlyAddedSlideFlag,
          overviewLimitExceeded
        });
      };

      /**
       *Modify selectedSlidesListData to add newlyAdded falg
       *
       * @param {*} slideListThe list of data which needs to be modified
       * @returnsArray of new slides
       */
      modifyNewlyAddedSlide = (slideList = []) => {
        let {
          presentationData: { slides }
        } = this.props;

        let newSlides = slideList
          .filter(elem => elem)
          .map((ele = {}, index) => {
            let uniqueId = ele["refId"] || ele["_id"];

            if (
              find(slides, { slideId: { _id: uniqueId } }) ||
              find(slides, { slideId: uniqueId })
            ) {
              return ele;
            } else {
              return {
                ...ele,
                isNewlyAdded: true
              };
            }
          });

        return newSlides;
      };

      /**
       * Function to save any changes made in dynamic fields
       *
       * @param {*}  value value to be save index of the
       * @param {*} index Index of the position of the dynamic field in the dynamic field array
       * @param {*} activeSlideId
       */
      setDynamicFieldChanges = props => {
        let { value, position, activeSlideId, dynamicFieldType } = props;

        let slides = [...this.state.selectedSlidesListDetail];
        let { index: activeSlideIndex } = this.checkAndFindSlideDetail(
          activeSlideId,
          slides
        );

        // Check if the selected slide is found and add a value key to it and save the edited data in it
        if (
          activeSlideIndex > -1 &&
          slides[activeSlideIndex]["slideData"] &&
          slides[activeSlideIndex]["slideData"][position]
        ) {
          slides[activeSlideIndex]["slideData"][position][
            dynamicFieldType
          ] = value;
          this.onStepEdit();
        }

        this.setState({
          selectedSlidesListDetail: slides
        });
      };

      // set the dimensions of current edited image
      setDynamicImagesDimensions = props => {
        let { activeSlideId, imagePosition, position, dimensions } = props;
        let slides = [...this.state.selectedSlidesListDetail];
        let { index: activeSlideIndex } = this.checkAndFindSlideDetail(
          activeSlideId,
          slides
        );

        if (
          get(slides[activeSlideIndex], [
            "slideData",
            position,
            "images",
            imagePosition
          ])
        ) {
          //set the dimensions for the current edited image
          slides[activeSlideIndex]["slideData"][position]["images"][
            imagePosition
          ]["dimensions"] = dimensions;
        }

        this.setState({
          selectedSlidesListDetail: slides
        });
      };

      /**
       *Format dynamic image data for api
       *
       */
      formatSlideDataForApi = (slideData = []) => {
        // format the response as per the requirement of build api
        let placeholderDetails = map(slideData, (data = {}, index) => {
          let slideDataObj = {};
          let dimensions = {
            height: data.height,
            width: data.width,
            x: data.x,
            y: data.y
          };

          if (data.inputType === "image" && data.image) {
            slideDataObj = {
              ...data,
              image: {
                imageDimension: get(data, `image.imageDimension`),
                imageId: get(data, `image.imageId._id`)
              }
            };
          } else if (data.inputType === "image" && get(data, `images.length`)) {
            let imageDimensions = {
              x:
                get(data, `selectedImage.dimensions.x`) ||
                get(data, `images[0].dimensions.x`) ||
                get(data, `images[0].focalPoint.x`) ||
                0,
              y:
                get(data, `selectedImage.dimensions.y`) ||
                get(data, `images[0].dimensions.y`) ||
                get(data, `images[0].focalPoint.y`) ||
                0,
              scale:
                get(data, `selectedImage.dimensions.scale`) ||
                get(data, `images[0].dimensions.scale`) ||
                1,
              width:
                get(data, `selectedImage.dimensions.width`) ||
                get(data, `images[0].dimensions.containerWidth`) ||
                get(data, `widthInPixel`),
              height:
                get(data, `selectedImage.dimensions.height`) ||
                get(data, `images[0].dimensions.containerHeight`) ||
                get(data, `heightInPixel`)
            };

            slideDataObj = {
              ...dimensions,
              image: {
                imageId: !isEmpty(get(data, `selectedImage`))
                  ? get(data, `selectedImage._id`)
                  : get(data, `images[0]._id`),
                imageDimension: {
                  ...imageDimensions
                }
              },
              type: "image",
              inputType: "image",
              label: get(data, `label`),
              name: get(data, `name`)
            };
          } else if (data.inputType === "text") {
            slideDataObj = {
              ...dimensions,
              type: "text",
              name: data.name,
              inputType: data.inputType,
              font: data.font,
              ...(data.text && {
                text: data.text
              })
            };
          }

          return slideDataObj;
        });

        let slideDataWithDimensions = filter(
          placeholderDetails,
          eachSlideData => {
            return (
              typeof eachSlideData === "object" &&
              Object.keys(eachSlideData).length
            );
          }
        );

        return slideDataWithDimensions;
      };

      /**
       * Fetch selected details of cover/theme for a given id
       *
       */
      getDetails = (id, flag) => {
        let selectedDetail = [];
        if (flag === "cover") {
          let { coverList } = this.props;
          selectedDetail = filter(coverList, eachCover => eachCover._id === id);
        } else if (flag === "theme") {
          let { themeList } = this.props;
          selectedDetail = filter(themeList, eachTheme => eachTheme._id === id);
        }
        if (id && !selectedDetail.length) {
          this.handleToastError(
            "error",
            `Please select a different ${flag}, the previous one has been either deleted/disabled.`
          );
          return;
        }
        return selectedDetail.length ? selectedDetail[0] : null;
      };

      /**
       *Add cover/overview to set of slides
       *
       */
      addRequiredSlidesSlides = (slides = []) => {
        let {
          includeCoverPage,
          coverDetails,
          overviewLimitExceeded
        } = this.state;
        let addedSlides = [];
        // get overview
        let overview = !overviewLimitExceeded && this.getOverviewDetails();

        // Set isCover true flag
        if (includeCoverPage && coverDetails) {
          coverDetails["isCover"] = true;
          // Add cover if cover is not already present
          addedSlides = overview
            ? [coverDetails, ...slides, overview]
            : [coverDetails, ...slides];
        } else if (overview) {
          addedSlides = [...slides, overview];
        } else {
          addedSlides = [...slides];
        }
        return addedSlides;
      };

      // handler function to set the data to be sent while ppt build
      setSelectedSlidesData = singleSlideDetail => {
        let { selectedSlidesData, selectSlides } = this.state;
        // format each slide detail
        let formattedData = this.formatSlideDataForApi(singleSlideDetail);

        // content slide list
        let newContentSlides =
          selectedSlidesData && selectedSlidesData.length
            ? [...selectedSlidesData, formattedData]
            : [formattedData];

        // Reorder slide array wrt to selectSlides
        let sortedCollection = sortBy(newContentSlides, function (item) {
          return selectSlides.indexOf(item._id);
        });
        this.setState({
          selectedSlidesData: sortedCollection
        });
      };

      // on library page next button click
      librarySaveDataHandler = async cb => {
        await this.getUnseenOverlaySlideId("library");
      };

      getUnseenOverlaySlideId = async (origin = "library") => {
        let { selectedSlidesListDetail } = this.state;
        let slideType = origin === "library" ? "contentSlide" : "themeSlide";

        // slides which had dynamic images and were not opened by user
        let slidesWithDynamicImagery = filter(
          selectedSlidesListDetail,
          eachSlide => {
            return (
              eachSlide.containsDynamicImagery &&
              isEmpty(get(eachSlide, `slideData`))
            );
          }
        );

        // ids of the slides which had dynamic images but were not opened
        let slideOverlayNotSeen = map(slidesWithDynamicImagery, eachSlide => {
          return eachSlide._id;
        });

        let attributeList = this.getImageAttributeList();
        let postData = {
          slideIds: slideOverlayNotSeen,
          imageCategory: attributeList
        };

        if (slideOverlayNotSeen.length > 0) {
          await this.getMultipleSlidesData(postData, slideType);
        }
      };

      /**
       *Remove required slides after drag and drop
       *
       */
      removeRequiredSlide = slides => {
        let {
          includeCoverPage,
          buildSetupDetails,
          coverDetails,
          overviewLimitExceeded
        } = this.state;

        // Set isCover true flag
        if (includeCoverPage && coverDetails) {
          slides.shift();
        }

        // Remove last element
        get(buildSetupDetails, "includeOverview.value") &&
          !overviewLimitExceeded &&
          slides.pop();

        return slides;
      };

      /**
       * Callback funtion for any slide order manipulation or new slide addition
       *  The slideData is formatted for sorting in the sort component and returned with extra keys like group for sorting purposes
       *
       */
      onPresentationEdit = slideData => {
        let selectedSlidesListDetail = this.removeRequiredSlide(slideData);
        // check data exists
        let dataSlides = filter(selectedSlidesListDetail, eachSlide =>
          get(eachSlide, `_id`)
        );

        // extract slides which were selected
        let selectSlides = map(dataSlides, eachSlide => {
          return get(eachSlide, `_id`);
        });

        let onlyContentSlides = this.getContentSlides(dataSlides) || [];
        // Reset newly added slides
        this.resetNewlyAddedSlides(selectedSlidesListDetail);

        let overviewLimitExceeded = !this.checkOverviewSlideLimit(
          selectedSlidesListDetail
        );

        this.setState(
          {
            selectedSlidesListDetail: selectedSlidesListDetail,
            selectSlides,
            overviewLimitExceeded
          },
          this.updateCompletedStep(onlyContentSlides)
        );
      };

      /**
       * Update completed steps when selected slides length 0
       *
       * @param {Array} selectSlides selected conten slides
       * @param {Array} contentSlides selected conten slides, cover, overview
       */
      updateCompletedStep = selectSlides => {
        let { completedSteps } = this.state;
        if (selectSlides.length) {
          this.onStepEdit();
        } else {
          let newStepArray = [];
          each(completedSteps, item => {
            if ([2, 3].indexOf(item) < 0) {
              newStepArray.push(item);
            }
          });

          this.setState({
            completedSteps: newStepArray,
            isEdited: false
          });
        }
      };

      // get onli content slides
      getContentSlides = slides => {
        return filter(slides, eachSlide => {
          if (
            ["themeSlide", "dividerSlide", "blankSlide"].indexOf(
              get(eachSlide, `type`, "")
            ) < 0
          ) {
            return eachSlide;
          }
        });
      };

      /**
       *Get blank and divider slide count
       *
       */
      getBlankSlideCount = () => {
        let { selectedSlidesListDetail } = this.state;
        let dividerSlideCount = 0;
        each(selectedSlidesListDetail, (eachSlide = {}) => {
          if (["dividerSlide", "blankSlide"].indexOf(eachSlide.type) > -1) {
            dividerSlideCount++;
          }
        });
        return dividerSlideCount;
      };

      /**
       *Get blank and divider slide details
       *
       * @param {*} [data={}] Data provided in edit api for divider
       * @param {*} type type of slide
       * @param {*} count count of blank and dividers
       * @param {*} index index of the current divider slide
       * @returns
       */
      getBlankDividerDetails = (data = {}, type) => {
        let { _id, changedTitle, refId } = data;
        let { dividers, blankSlides } = this.props;

        let slideData = {};
        let newUniqueId = uniqId();
        if (
          _id &&
          dividers &&
          blankSlides &&
          ["dividerSlide", "blankSlide", "themeSlide"].indexOf(type) > -1
        ) {
          let themeSlide = [...dividers, ...blankSlides];
          let slide = filter(themeSlide, eachSlide => {
            let themeSlideUniqueId = eachSlide["refId"] || eachSlide["_id"];
            let selectedSlideId = refId || _id;
            return themeSlideUniqueId === selectedSlideId;
          });

          if (slide[0] && !refId) {
            // Handle unique id for sort
            slideData = {
              ...slide[0],
              refId: _id,
              _id: newUniqueId
            };
          } else {
            slideData = {
              ...data,
              prevId: _id
            };
          }
        }
        if (changedTitle) {
          slideData.changedTitle = changedTitle;
        }

        return slideData;
      };

      /**
       *Check if the passed group id is between the group/ first element/ last element of group.
       *
       * @param {*} groupId The group Id that the current element belongs to.
       * @param {number} [index=0] Index of the element whose group is being checked.
       * @param {*} list The list os slide.
       * @returns position flag one of(between,first,last)
       */
      _checkGroup(groupId, index = 0, list) {
        let previousGroupId = get(list[index - 1], "group._id");
        let nextGroupId = get(list[index + 1], "group._id");
        // Check if the current group Id matches previous and next element group Id
        if (groupId === previousGroupId && groupId === nextGroupId) {
          return "between";
          // Check if next and previous are both undefined to see if it is the only element in group
        } else if (
          (!previousGroupId && !nextGroupId) ||
          (groupId !== previousGroupId && groupId !== nextGroupId)
        ) {
          return "single";
          // Check if the previous group Id is undefined i.e. Does not belong to any group or does not match the current group Id.
        } else if (!previousGroupId || groupId !== previousGroupId) {
          return "first";
          // Check if the next group Id is undefined i.e. Does not belong to any group or does not match the current group Id.
        } else if (!nextGroupId || groupId !== nextGroupId) {
          return "last";
        }
      }

      /**
       *Create an array of id of the slide that belong to the same group.
       *
       * @param {*} list List of the slides
       * @param {*} groupStartindex Starting index of the group set.
       * @returns Array of id of the slides in same group
       */
      _createSet = (list, groupStartindex) => {
        let groupSet = [];
        for (let i = groupStartindex; i < list.length; i++) {
          let listId = get(list[i], "_id");
          let groupId = get(list[i], "group._id");
          groupSet = [...groupSet, { _id: listId }];
          if (
            this._checkGroup(groupId, i, list) === "last" ||
            this._checkGroup(groupId, i, list) === "single"
          ) {
            return groupSet;
          }
        }
      };

      /**
       *Format slide data for draggable component
       *
       */
      formatSlidesForDragAndDrop = list => {
        let groupSet = {},
          groupData = {};
        let {
          selectedCoverLayout,
          selectedThemeLayout,
          coverDetails
        } = this.state;
        let array = map(list, (data = {}, index) => {
          // check if divider or blank and add details
          if (
            ["dividerSlide", "blankSlide", "themeSlide"].indexOf(data.type) >
              -1 &&
            (!data.refId || selectedThemeLayout !== data.theme)
          ) {
            let { type } = data;
            let newData = this.getBlankDividerDetails(data, type);

            let thumbnailLocation =
              (this.getThumbnailBasedOnSlideType &&
                this.getThumbnailBasedOnSlideType(
                  selectedThemeLayout,
                  newData,
                  "thumbnail"
                )) ||
              get(newData, "thumbnailLocation");
            return {
              ...newData,
              thumbnailLocation,
              isDraggable: true
            };
          }

          let groupId = get(data, "group._id");

          // Check if cover or overview slide
          let checkIfCoverOrOverview =
            (selectedCoverLayout && coverDetails && data.isCover) ||
            data.isOverview;

          // Check if thumbnail location is directly present else get theme based thumbnail location
          let thumbnailLocation =
            (this.getThumbnailBasedOnSlideType &&
              this.getThumbnailBasedOnSlideType(
                selectedThemeLayout,
                data,
                "thumbnail"
              )) ||
            get(data, "thumbnailLocation");

          //  Check if ungrouped content slide.
          if (!groupId) {
            groupSet = [];
            return {
              ...data,
              thumbnailLocation,
              isDraggable: !!!checkIfCoverOrOverview
            };
          }

          // Check if element is of same group
          let groupFlag = this._checkGroup(groupId, index, list);

          // create a set of all slideId present in the group
          groupSet =
            groupFlag === "first" || groupFlag === "single"
              ? this._createSet(list, index)
              : groupSet;

          // Create group data consisting of group set, group Id
          groupData = {
            groupSet,
            groupId
          };

          // Allocate flag depending on group position
          if (groupFlag === "first") {
            groupData["isFirst"] = true;
          } else if (groupFlag === "last") {
            groupData["isLast"] = true;
          } else if (groupFlag === "single") {
            groupData["isSingle"] = true;
          }
          return {
            ...data,
            thumbnailLocation,
            isDraggable: true,
            isGrouped: true,
            group: {
              ...groupData
            }
          };
        });
        return array;
      };

      /**
       * Handle save on sort component
       *
       */
      sortSaveDataHandler = () => {
        let { selectedSlidesListDetail } = this.state;
        let postData = {};

        if (!selectedSlidesListDetail.length) {
          ToastUtils.handleToast({
            operation: "error",
            message: "You must select slides before you can continue."
          });
          return {};
        }
        this.getUnseenOverlaySlideId();

        let slideList = this.formatSlidesForApi(selectedSlidesListDetail) || [];

        let overviewLimitExceeded = !this.checkOverviewSlideLimit(
          selectedSlidesListDetail
        );

        this.setState({
          overviewLimitExceeded
        });

        postData = {
          ...(slideList.length && { slides: slideList }),
          ...(overviewLimitExceeded && { includeOverview: false })
        };
        return postData;
      };

      /**
       *Reset the newly added flags once the user comes on sort page
       *
       */
      resetNewlyAddedSlides = (slides = []) => {
        if (!slides.length) return;
        let newSlideList = map(slides, eachSlide => {
          if (eachSlide.isNewlyAdded) {
            eachSlide.isNewlyAdded = false;
            return eachSlide;
          } else {
            return eachSlide;
          }
        });
        this.setState({
          selectedSlidesListDetail: newSlideList
        });
      };

      /**
       *Format the slides data for api calls after sort
       *
       * @param {*} slides Slide list
       * @returns Formatted slide data
       */
      formatSlidesForApi = slides => {
        return map(slides, (eachSlide = {}, index) => {
          let {
            type,
            _id,
            slideData,
            refId,
            slideId,
            title,
            changedTitle
          } = eachSlide;

          // Check if type is not defined type is available only in blank and divider slides
          // slide data for content slide
          if (type === "contentSlide" || !type) {
            return {
              slideId: _id,
              type: "contentSlide",
              slideTitle: changedTitle || title,
              order: index,
              ...(slideData && {
                slideData: this.formatSlideDataForApi(slideData)
              })
            };
          } else {
            let uniqueId = slideId ? slideId._id : refId || _id;
            //Slide data for blank or divider slide
            return {
              slideId: uniqueId,
              type: "themeSlide",
              order: index,
              slideTitle: changedTitle || title,
              ...(slideData && {
                slideData: this.formatSlideDataForApi(slideData)
              })
            };
          }
        });
      };

      /**
       * Get the details of the selected slides
       *
       * @param {Object} postData Id's of slides whose details are to be fetched
       * @param {String} type type specifying slide Type(contentSlide or themeSlide)
       */
      getMultipleSlidesData = async (postData, type) => {
        const {
          contentRepo: { _id: contentRepoId }
        } = this.state;

        await this.props.getMultipleSlidesIdForMetadata(
          postData,
          contentRepoId,
          type
        );

        let { selectedSlidesListDetail = [] } = this.state;
        let { multipleSlideMetaData = [] } = this.props;
        let selectedSlidesLength = selectedSlidesListDetail.length;
        let unseenSlideLength = multipleSlideMetaData.length;

        for (let index = 0; index < selectedSlidesLength; index++) {
          for (let j = 0; j < unseenSlideLength; j++) {
            if (
              selectedSlidesListDetail[index]._id ===
              multipleSlideMetaData[j]._id
            ) {
              selectedSlidesListDetail[index]["slideData"] =
                multipleSlideMetaData[j]["slideData"];
            }
          }
        }

        this.setState({
          selectedSlidesListDetail
        });
      };

      // get image categories attribute ids
      getImageAttributeList = () => {
        let { selectedImageCategoryList } = this.state;

        let arrayOfCategories = Object.values(selectedImageCategoryList);

        let mergedCategories = flatten(arrayOfCategories);

        // get the selected image categories
        let attributeList = map(mergedCategories, image => {
          return get(image, `_id`); // attribute list id
        });
        attributeList = filter(attributeList, eachAttribute => eachAttribute);

        return attributeList;
      };

      // !Deprecated Currently unused
      // common function to get slide details
      getSingleSlideDetails = async (id, type = "content") => {
        let imageCategoriesAttributeIds = this.getImageAttributeList();

        let response = await this.props.getSlideDetails(
          id,
          this.state.selectedThemeLayout,
          imageCategoriesAttributeIds,
          type
        );
        return response;
      };

      // get new slideData whenever image categories are changed by attaching a key containsEditedSlideData
      getSlideDetailsOnImageCategoriesChange = () => {
        let { selectedSlidesListDetail } = this.state;

        let slidesWithSlideData = filter(
          selectedSlidesListDetail,
          eachSlide => {
            return get(eachSlide, `slideData.length`);
          }
        );

        each(slidesWithSlideData, eachSlide => {
          // attach a key containsEditedSlideData so that slide data api is called to get slide data as updated image categories
          eachSlide.containsEditedSlideData = true;
        });

        this.setState({
          selectedSlidesListDetail,
          isImageCategoryEdited: true
        });
      };

      /**
       *Handle preview navigation
       *
       * @param {*} slidePosition Position of the index in which slide is present
       *
       */
      sliderBottomNavigation = async slidePosition => {
        const { isSingleSlidePreview, activeSlideDetail } = this.state;

        if (slidePosition < 0) return null;

        // TODO: Handle get slide details
        // let response = await this.getSingleSlideDetails(id, type);
        // if (!response.success) return;

        // Check if the slider is single slide preview
        if (!isSingleSlidePreview) {
          this.onPreview(slidePosition);
        } else {
          // Checks If single slide belongs to a deck
          this.setSlideDeckPosition({
            id: activeSlideDetail._id,
            activeIndex: slidePosition
          });
        }

        this.setState({
          slideName: "",
          isInputFocused: false,
          showEditIcons: true,
          showDynamicImageOverlay: false
        });
      };

      /**
       *Create a array of Id of decks
       *
       * @param {*} slideIds
       */
      handleDeckSlidesIds = slideIds => {
        let { deckChildrenSlideId, deckChildrenData } = this.state;

        // extract the ids of all the slides in the deck
        let ids = map(slideIds, slideId => {
          return slideId._id;
        });
        deckChildrenData.push(slideIds);
        deckChildrenSlideId.push(ids);
        this.setState({
          deckChildrenSlideId, // array of arrays consisting of ids of slides in decks
          deckChildrenData
        });
      };

      deleteLogoHandler = async logoId => {
        await this.props.deleteLogo(this.state.userDetail._id, logoId);
        // fetch the logo list on delete
        this.props.getCustomerLogoList(this.state.userDetail._id);

        // logo selected and the logo deleted from the list is same empty the placeholder
        if (logoId === this.state.selectedLogoId) {
          this.setState({
            selectedLogo: null
          });
        }
      };

      logoEditHandler = async (dataUrl, cropData, saveToProfile) => {
        let { buildSetupDetails, userDetail } = this.state;

        let data = {
          imageData: dataUrl,
          saveToProfile,
          title: get(buildSetupDetails["customerName"], `value`)
        };

        await this.props.createNewCustomerLogo(get(userDetail, `_id`), data);

        this.setState({
          selectedLogoId: get(this.props, `customerLogoDetails._id`),
          cropData,
          croppedImage: dataUrl, // placeholder will now have the cropped image
          isImageCropperOpen: false
        });

        this.onStepEdit();
      };

      imageCropperHandler = () => {
        if (this.state.selectedLogo) {
          this.setState(state => {
            return {
              isImageCropperOpen: !state.isImageCropperOpen
            };
          });
        }
      };

      // step vlidation to check if content slides selected
      libraryScreenValidationHandler = () => {
        let { selectedSlidesListDetail } = this.state;
        let onlyContentSlides =
          this.getContentSlides(selectedSlidesListDetail) || [];
        if (!onlyContentSlides.length) {
          ToastUtils.handleToast({
            operation: "error",
            message: "You must select slides before you can continue."
          });
          return false;
        }
        return true;
      };

      /**
       *Update the slide details in selectSlide/ selectedSlideListDetail on theme change
       * @param {Boolean} flag to set details of dividers and blanks
       */
      updateDetailsBasedOnTheme = (flag = false) => {
        let { presentationData } = this.props;
        const { selectedSlidesListDetail, selectSlides } = this.state;

        // Check if the current step is theme selection and any changes have been made
        let slides = get(presentationData, `slides`);

        // update the divider and blank slide details
        let newSelectSlides = selectSlides || [];
        each(slides, eachSlide => {
          let id = get(eachSlide, `slideId`);
          if (
            flag &&
            id &&
            ["themeSlide", "dividerSlide", "blankSlide"].indexOf(
              get(eachSlide, `type`, "")
            ) > -1
          ) {
            newSelectSlides.push(id);
          } else if (!flag && id && newSelectSlides.indexOf(id) === -1) {
            newSelectSlides.push(id);
          }
        });

        // check if slideid is present
        let checkIfSlideDataPresent = filter(slides, eachSlide => {
          if (
            flag &&
            ["themeSlide", "dividerSlide", "blankSlide"].indexOf(
              get(eachSlide, `type`, "")
            ) > -1
          ) {
            return get(eachSlide, `slideId`);
          } else if (!flag) {
            return get(eachSlide, `slideId`);
          }
        });

        // extract slide list detail
        let newList = map(checkIfSlideDataPresent, (eachSlide, index) => {
          // Find the id of the slide present at current position of selectedSlideListDetail state
          let previousId =
            get(selectedSlidesListDetail[index], "refId") ||
            get(selectedSlidesListDetail[index], "_id");

          // If the id do not match considering it as divider/blank slide with changed theme
          if (previousId !== get(eachSlide, `slideId`)) {
            return {
              type: get(eachSlide, `type`),
              changedTitle: eachSlide.slideTitle,
              _id: get(eachSlide, `slideId`)
            };
          } else {
            // return the previous element if content slide
            return {
              ...selectedSlidesListDetail[index]
            };
          }
        });
        this.setState(
          {
            selectSlides: newSelectSlides,
            selectedSlidesListDetail: newList
          },
          () => {
            this.getContentSlideDetails();
          }
        );
      };

      /**
       * To have a common Next button handler for all the four process
       * @param {number} activeStep process number
       */
      nextStepHandler = async (stepToSetActive, skipData = false) => {
        const { activeStep, isEdited, selectedSlidesListDetail } = this.state;

        //go back if data is already saved..
        if (!isEdited && activeStep > stepToSetActive) {
          this.modifyStep(stepToSetActive);
          return;
        }

        if (!this.checkValidation(activeStep)) {
          return;
        }

        for (
          let activeIndex = activeStep;
          activeIndex <= 4 && stepToSetActive > 2;
          activeIndex++
        ) {
          if (!this.checkValidation(activeIndex)) return;
        }

        // check and save on all steps if step is skipped and create a general payload
        let cb = () => {
          let contentSlidesOnly = this.getContentSlides(
            selectedSlidesListDetail
          );

          if (contentSlidesOnly.length && activeStep === 1 && isEdited) {
            this.updateDetailsBasedOnTheme();
          } else if (activeStep === 1 && isEdited) {
            this.updateDetailsBasedOnTheme(true);
          }

          this.modifyStep(stepToSetActive);
          this.onStepCompleted(activeStep);
          (stepToSetActive === 2 || stepToSetActive === 3) &&
            this.handleUnsavedSlide(this.props.presentationData || {});
        };

        let savePptPayload = {};

        if (activeStep < stepToSetActive && isEdited) {
          for (let index = activeStep; index < stepToSetActive; index++) {
            savePptPayload = {
              ...savePptPayload,
              ...(await this.saveDataHandler(index + 1, skipData, cb))
            };
          }
        } else if (activeStep > stepToSetActive && isEdited) {
          savePptPayload = await this.saveDataHandler(
            activeStep + 1,
            skipData,
            cb
          );
        } else {
          this.modifyStep(stepToSetActive);
        }

        // save step with all payload
        Object.keys(savePptPayload).length &&
          this.savePresentationDetails(savePptPayload, cb);
      };

      /**
       * To have a common skip button handler for all the four process
       * @param {number} activeStep process number
       */
      skipBtnHandler = async () => {
        if (
          this.state.activeStep !== 1 &&
          !this.checkValidation(this.state.activeStep)
        )
          return;
        await this.onStepEdit();
        this.nextStepHandler(this.state.activeStep + 1);
      };

      /**
       * This is the common function to have the validation check for the process/
       * @param {number} activeStep process number
       */
      checkValidation = activeStep => {
        switch (activeStep) {
          case 0:
            return this.setupStepValidation();
          case 1:
            return true;
          case 2:
            return this.libraryScreenValidationHandler();
          case 3:
            return this.libraryScreenValidationHandler();
          case 4:
            return this.libraryScreenValidationHandler();
          default:
            return null;
        }
      };

      setUpKeys = ["presentationName", "customerName", "title"];
      setupStepValidation = () => {
        let { buildSetupDetails, contentRepo } = this.state;
        let counter = 0,
          errorMessage;

        let keys = Object.keys(buildSetupDetails) || [];

        for (let index = keys.length; index--; ) {
          if (
            (!buildSetupDetails[keys[index]].value ||
              buildSetupDetails[keys[index]].error) &&
            this.setUpKeys.indexOf(keys[index]) > -1
          ) {
            buildSetupDetails[keys[index]].error = !buildSetupDetails[
              keys[index]
            ].value
              ? UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE
              : buildSetupDetails[keys[index]].error;

            errorMessage =
              !buildSetupDetails[keys[index]].value ||
              buildSetupDetails[keys[index]].error
                ? UI_STRINGS.VALID_INPUT_ERROR_MESSAGE
                : "";
          } else if (buildSetupDetails[keys[index]].error) {
            counter++;
            errorMessage = UI_STRINGS.VALID_INPUT_ERROR_MESSAGE;
          }
        }

        // check if content repo selected
        if (
          Object.keys(contentRepo).indexOf("error") > -1 ||
          !Object.keys(contentRepo).length
        ) {
          contentRepo.error = UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
          counter++;
        }

        this.setState(
          {
            buildSetupDetails: {},
            contentRepo
          },
          () => {
            this.setState({
              buildSetupDetails
            });
          }
        );

        if (errorMessage || counter > 0) {
          errorMessage =
            counter > 0 ? UI_STRINGS.VALID_INPUT_ERROR_MESSAGE : errorMessage;
          ToastUtils.handleToast({
            operation: "error",
            message: errorMessage
          });
          return false;
        } else {
          return true;
        }
      };

      setupSaveDataHandler = () => {
        let setUpData = {};
        let {
          buildSetupDetails,
          contentRepo,
          selectedLogoId,
          presentationId,
          requiredSlides,
          selectedSlidesListDetail,
          selectedThemeLayout,
          selectedCoverLayout,
          includeCoverPage
        } = this.state;

        let { contentRepository, slides = [] } =
          this.props.presentationData || {};

        // Required slides on content repo change
        let formattedRequiredSlides = this.formatSlidesForApi(requiredSlides);

        if (presentationId && contentRepository !== contentRepo._id) {
          setUpData.slides = [...formattedRequiredSlides];

          // set theme and cover payload when content repo changes
          setUpData.theme = selectedThemeLayout;
          setUpData.coverSlide = {
            cover: selectedCoverLayout
          };
          setUpData.includeCover = includeCoverPage;
        }

        setUpData.contentRepository = contentRepo._id;
        let imageCategoriesAttributeIds = this.getImageAttributeList();
        if (imageCategoriesAttributeIds.length) {
          setUpData.imageCategories = imageCategoriesAttributeIds;
        }

        setUpData.customerLogo = selectedLogoId;

        // If disabled slides are present save slides after removing the disabled slide
        if (
          (slides || []).length &&
          selectedSlidesListDetail.length !== slides.length &&
          this.disabledSlide.length
        ) {
          // format slides for saving if slide was disabled
          let formatSlides = this.formatSlidesForApi(selectedSlidesListDetail);
          setUpData.slides = formatSlides;
        }

        Object.keys(buildSetupDetails).forEach(item => {
          if (item === "presentationDate" && buildSetupDetails[item].value) {
            let date = new Date(buildSetupDetails[item].value);

            setUpData[item] = new Date(
              `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
                -2
              )}-${("0" + date.getDate()).slice(-2)}`
            ).toISOString();
          } else if (
            buildSetupDetails[item].value ||
            buildSetupDetails[item].value === false
          ) {
            setUpData[item] =
              typeof buildSetupDetails[item].value === "string"
                ? buildSetupDetails[item].value.trim()
                : buildSetupDetails[item].value;
          }
        });

        return setUpData;
      };

      saveDataHandler = async (activeStep, skipData, cb) => {
        switch (activeStep - 1) {
          case 0:
            return this.setupSaveDataHandler();
          case 1:
            return this.themeSaveDataHanlder(skipData);
          case 2:
            await this.librarySaveDataHandler(cb);
            return this.sortSaveDataHandler();
          case 3:
            return this.sortSaveDataHandler();
          default:
            return {};
        }
      };

      savePresentationDetails = async (payload, cb) => {
        let { presentationId, userDetail } = this.state;
        if (userDetail) {
          let response = await this.props.createPresentation(
            userDetail._id,
            payload,
            presentationId
          );

          if (response.success) {
            //hit callback when presentation is saved
            cb && cb();
            this.disabledSlide = [];
            this.setCompletedSteps(this.props.presentationData);
            if (this.props.presentationData && !presentationId) {
              presentationId = this.props.presentationData._id;
              this.setState({
                presentationId
              });
            }
          } else {
            ToastUtils.handleToast({
              operation: "error",
              message: get(response, "data.message"),
              autoclose: false
            });
          }
          return true;
        }
        return false;
      };

      //This function is used to save the data of the theme.
      themeSaveDataHanlder = async skipData => {
        const {
          selectedThemeLayout,
          selectedCoverLayout,
          includeCoverPage
        } = this.state;

        let payload = {
          theme: selectedThemeLayout
        };

        //This executes if the user clicks next button and cover is included
        if (!skipData) {
          payload.coverSlide = {
            cover: selectedCoverLayout
          };
          payload.includeCover = includeCoverPage;
        } else {
          payload = await this.handleSkipCoverDetails();
          this.onStepEdit(false);
          this.modifyStep(2);
        }
        return payload;
      };

      handleSkipCoverDetails = async () => {
        let { selectedThemeLayout } = this.state;
        let payload = {};

        let activeTheme = filter(this.props.themeList, eachTheme => {
          return !!eachTheme.isMaster;
        });

        await this.handleStateChange({
          key: "selectedThemeLayout",
          value: get(activeTheme[0], `_id`)
        });

        await this.fetchCoverList();

        let selectedTheme = activeTheme.length ? selectedThemeLayout : null;
        let { coverList } = this.props;
        let selectedCover = coverList.length && get(coverList[0], `_id`, 0);

        payload = {
          theme: selectedTheme,
          coverSlide: {
            cover: selectedCover
          },
          includeCoverPage: true
        };

        this.setState({
          includeCoverPage: true
        });

        return payload;
      };

      //change the Selected cover layout based on the checkbox value
      coverListHandler = () => {
        this.setState({
          selectedCoverLayout: get(this.props.coverList[0], `_id`) || 0,
          coverDetails: get(this.props, "coverList[0]")
        });
      };

      /**
       * Get the build progres by looping object keys of the completed steps in response.
       *
       * @param {*} response Build response object
       * @returns
       */
      getCompletedPercentage = response => {
        let { buildProgress } = this.state;
        let progress = { ...buildProgress };

        let completedSteps = intersection(
          ["coverSlideBuild", "contentSlidebuild", "concatPpt"],
          keys(response)
        );
        // Check if internal steps consist of failed steps
        // TODO: Not checking currently for content slide
        let failedSteps = filter(completedSteps, key => {
          return (
            response[key]["status"] && response[key]["status"] === "Failed"
          );
        });

        if (response.status === "Failed" || failedSteps.length) {
          progress = {
            percentage: 100,
            status: "Failed"
          };
        } else if (response.status === "Initialized") {
          // Iterate percentage
          let percentage = buildProgress.percentage
            ? buildProgress.percentage + 10
            : 10;
          if (percentage < 100) {
            progress = {
              percentage,
              status: "InProgress"
            };
          }
        } else if (
          response.status === "Completed" &&
          completedSteps.length === 3
        ) {
          progress = {
            percentage: 100,
            status: "Completed"
          };
        }
        return progress;
      };

      // Share the downloadable link mail to the user on successful build
      _sharePresentationOnSuccess = async (buildDetails = {}) => {
        const { email } = this.state.userDetail;
        let { presentationId, type } = buildDetails;

        //body to post to an api endpoint
        const body = {
          type,
          emails: [email],
          presentationId,
          sendMailToSelf: false,
          isBuild: true
        };

        let response = await shareBuild(body);

        if (response.success && response.data) {
          ToastUtils.handleToast({
            operation: "success",
            message: "Please check your email for a download link."
          });
        } else {
          ToastUtils.handleToast({
            operation: "error",
            message: `Could not send the download link to your email. Please try again later.`
          });
        }
      };

      // Checks if the specified selected option has a successful build
      _checkIfShareAvailable = (presentationData = {}, selectedOption) => {
        let { email = {}, status } = presentationData;
        return status === "Completed" && !!email[selectedOption];
      };

      /**
       *The polling action for presentation build
       *
       * @param {*} id
       */
      pollingActionForBuild = async (id, buildDetails = {}) => {
        let { getPresentationBuildStatus } = this.props;
        let { selectedSlidesListDetail } = this.state;
        const { _id: userId } = this.state.userDetail;
        let { match } = this.props;
        // Presentation Id of the current presentation
        let presentationId =
          get(match, "params.id") || this.state.presentationId;

        let response = await getPresentationBuildStatus(id);

        let pollingResponseStatus = get(response, "data.status");
        let responseData = get(response, "data") || {};

        // Get progress data for show progress bar
        let progressData = this.getCompletedPercentage(responseData);
        this.setState({
          buildProgress: progressData
        });

        if (
          responseData &&
          pollingResponseStatus === "Completed" &&
          progressData.status !== "Failed"
        ) {
          // Get presentation data
          await this.props.getPresentationDetail(userId, presentationId);

          // Send email to the user if shareable link available in presentationData
          this._checkIfShareAvailable(
            this.props.presentationData,
            buildDetails.type
          ) && this._sharePresentationOnSuccess(buildDetails);

          // Reset newly added slides
          this.resetNewlyAddedSlides(selectedSlidesListDetail);

          this.setState({
            isBuilding: false,
            pptLocation: responseData,
            buildProgress: progressData
          });
          this.onStepCompleted(4);
          PollingUtils.stopPolling();
        } else if (
          pollingResponseStatus === "Failed" ||
          progressData.status === "Failed"
        ) {
          ToastUtils.handleToast({
            operation: "error",
            message: UI_STRINGS.BUILD_ERROR,
            autoclose: false
          });
          PollingUtils.stopPolling();
          this.setState({
            isBuilding: false,
            buildProgress: progressData
          });
        }
      };

      /**
       *Function for starting polling for build
       *
       * @param {*} buildId
       */
      startBuildPolling = (buildId, buildDetails = {}) => {
        let { selectedSlidesListDetail } = this.state;
        // Get timeout duration each slide takes approx 4s
        let timeoutDuration =
          selectedSlidesListDetail && selectedSlidesListDetail.length > 10
            ? selectedSlidesListDetail.length * 10000
            : 100000;

        PollingUtils.startPolling({
          pollingAction: () => {
            this.pollingActionForBuild(buildId, buildDetails);
          },
          timeoutCallback: () => {
            this.setState({
              isBuilding: false,
              buildProgress: {
                status: "Failed",
                percentage: 100
              }
            });
            ToastUtils.handleToast({
              operation: "error",
              message: UI_STRINGS.BUILD_ERROR,
              autoclose: false
            });
          },
          timeoutDuration
        });
      };

      /**
       *Start Build process
       *
       * @param {*} { buildOption } The type of build selected ppt/pdf/zip
       */
      onBuild = async ({ buildOption: contentType }) => {
        let { match, presentationData, triggerPresentationBuild } = this.props;
        const { _id: userId } = this.state.userDetail;
        // Presentation Id of the current presentation
        let presentationId =
          get(match, "params.id") || get(presentationData, "_id");

        if (presentationId) {
          this.setState({
            buildProgress: {
              status: "InProgress",
              percentage: 0
            },
            isBuilding: true
          });
          let response = await triggerPresentationBuild(
            userId,
            presentationId,
            contentType
          );
          let { buildId } = response.data;
          // on successfully triggering build
          if (buildId) {
            this.startBuildPolling(buildId, {
              presentationId,
              type: contentType
            });
          } else {
            this.setState({
              isBuilding: false
            });
            // Show error message popup
            ToastUtils.handleToast({
              operation: "error",
              message: get(response, "data.message", false)
            });
          }
        } else {
          this.setState({
            isBuilding: false
          });
          // Show error message popup
          ToastUtils.handleToast({
            operation: "error",
            message: UI_STRINGS.UNSAVED_BUILD_ERROR
          });
        }
      };
      /**
       *Check if the max overview slide limit is reached
       *
       * @param {*} totalSlides all slides with cover and overview
       * @returns
       */
      checkOverviewSlideLimit = totalSlides => {
        let { buildSetupDetails } = this.state;
        let dividerCount = 0,
          slideCount = 0;

        each(totalSlides, (eachSlide = {}) => {
          if (eachSlide.slideType === "Divider") {
            dividerCount++;
          } else {
            slideCount++;
          }
        });

        let isValid = dividerCount <= 100 && slideCount <= 150;
        // Do not show error message if is valid or the overview slide has been disabled
        if (isValid || !get(buildSetupDetails, "includeOverview.value")) {
          return true;
        } else {
          return false;
        }
      };

      /**
       * Function to insert group of slides
       * @param {Array} slidesArr Set of selected slides when group or deck is selected
       * @param {Boolean} flag To insert or remove value in to an array
       * @param {String} groupName To check if group is selected
       * @returns
       */
      handleSelectedSlides = (slidesArr, flag, groupName, slidesData = []) => {
        let { selectSlides, selectedSlidesListDetail, showModal } = this.state;

        let { maximumSlideCount } = this.state;
        let counter = 0;
        let previousSelectedList = [
          ...this.formatSlidesForDragAndDrop(selectedSlidesListDetail)
        ];

        // With cover and overview slides
        let withCoverOverview = this.addRequiredSlidesSlides(
          selectedSlidesListDetail
        );

        if (flag && groupName) {
          // group
          if (
            withCoverOverview.length < maximumSlideCount &&
            maximumSlideCount - slidesArr.length >= withCoverOverview.length
          ) {
            each(slidesArr, (item, index) => {
              // Check if required slide
              if (this.checkRequiredSlides(item)) return;

              if (!includes(selectSlides, item)) {
                selectSlides.push(item);
                selectedSlidesListDetail.push(slidesData[index]);
              }
            });
          } else {
            counter++;
          }
        } else if (flag) {
          // deck
          each(slidesArr, (item, index) => {
            if (withCoverOverview.length < maximumSlideCount) {
              if (!includes(withCoverOverview, item)) {
                selectSlides.push(item);
                selectedSlidesListDetail.push(slidesData[index]);
              }
            } else {
              counter++;
            }
          });
        } else {
          each(slidesArr, item => {
            // Check if required slide
            if (this.checkRequiredSlides(item)) return;

            selectSlides = selectSlides.filter(id => id !== item);

            // remove changedTitle property from slide which is getting removed
            each(selectedSlidesListDetail, eachSlide => {
              if (get(eachSlide, `changedTitle`)) {
                delete eachSlide.changedTitle;
              }
            });
            selectedSlidesListDetail = selectedSlidesListDetail.filter(
              ({ _id: slideId }) => slideId !== item
            );
          });
        }

        if (counter > 0) {
          this.handleToastError(
            "error",
            `You have reached a limit of ${maximumSlideCount} slides.`
          );
        }
        // Update slideId for preview
        showModal &&
          this.updatePreviewData(
            selectedSlidesListDetail,
            previousSelectedList
          );
        this.handleSelectSlides(selectSlides, selectedSlidesListDetail);
      };

      /**
       *Update selected slides data
       *
       * @param {*} selectSlides Pass an array of Id on the basis of which the Id needs to be filtered
       */
      updatePreviewData = (selectSlides = [], prevSelectSlides) => {
        let { isSingleSlidePreview } = this.state;

        // Check if deck slide overlay do not handle delete in such case
        if (isSingleSlidePreview) return null;

        // Required slides
        let withCoverOverview = this.addRequiredSlidesSlides(selectSlides);
        let formattedSlides = this.formatSlidesForDragAndDrop(
          withCoverOverview
        );
        // Required slide with ids
        let selectSlidesId = map(formattedSlides, "_id");

        let isRemovedIndex = withCoverOverview.length;
        for (var i = 0; i < selectSlides.length; i++) {
          if (selectSlides[i]._id !== prevSelectSlides[i]._id) {
            // Adding cover slide count
            isRemovedIndex = i + 1;
            break;
          }
        }

        // Check if empty selected slides then close the modal
        if (!selectSlides.length) {
          this.setState({
            showModal: false
          });
          // Check if the removed index is null then it states the removed element was the last element in the array
        } else if (!isRemovedIndex && isRemovedIndex !== 0) {
          this.sliderBottomNavigation(formattedSlides.length - 1, selectSlides);
        } else {
          this.sliderBottomNavigation(isRemovedIndex + 1, selectSlides);
        }
        this.setState({ slidePartOfDeck: selectSlidesId });
      };

      /** Toast Error handler */
      handleToastError = (type, message) => {
        ToastUtils.handleToast({
          operation: type,
          message: message
        });
      };

      /**
       * Checkbox handler
       * @param {Event} e
       * @param {String} groupName Group name of a selected group
       * @param {Array} children Set of slides
       */
      handleCheckBoxChange = (checked, id, groupName, children, slideData) => {
        let { groupId } = this.state;

        if (groupName) {
          // Check if required slides
          if (this.checkRequiredSlides(slideData)) return;

          groupId = [];
          let groupSlidesDetail = [];
          children &&
            each(children, child => {
              if (child.group && child.group.title === groupName) {
                groupId.push(child._id);
                groupSlidesDetail.push(child);
              }
            });

          this.handleSelectedSlides(
            groupId,
            checked,
            groupName,
            groupSlidesDetail
          );
        } else {
          this.handleSingleCheckbox(id, false, slideData);
        }
      };

      /**
       * Function to add single slide
       * @param {Number} id single slide id
       */
      handleSingleCheckbox = async (id, flag = false, slideData) => {
        let {
          maximumSlideCount,
          selectSlides,
          selectedSlidesListDetail,
          showModal
        } = this.state;

        let previousSelectedList = [...selectedSlidesListDetail];

        let withCoverOverview = this.addRequiredSlidesSlides(
          selectedSlidesListDetail
        );

        if (this.checkRequiredSlides(slideData)) return;

        if (!includes(selectSlides, id)) {
          if (withCoverOverview.length < maximumSlideCount) {
            selectSlides = [...selectSlides, id];
            selectedSlidesListDetail = [...selectedSlidesListDetail, slideData];
            if (flag)
              this.handleToastError(
                "success",
                "Slide has been added to the presentation."
              );
          } else {
            this.handleToastError(
              "error",
              `You have reached a limit of ${maximumSlideCount} slides.`
            );
          }
        } else {
          if (flag)
            this.handleToastError(
              "success",
              "Slide has been removed from the presentation."
            );
          selectSlides = filter(selectSlides, _id => _id !== id);

          // remove changedTitle property if the slide is removed
          each(selectedSlidesListDetail, eachSlide => {
            if (get(eachSlide, `changedTitle`) && eachSlide._id === id) {
              delete eachSlide.changedTitle;
            }
          });

          selectedSlidesListDetail = filter(
            selectedSlidesListDetail,
            (item = {}) => item._id !== id && item.refId !== id
          );
        }
        // Update slideId for preview
        showModal &&
          (await this.updatePreviewData(
            selectedSlidesListDetail,
            previousSelectedList
          ));
        this.handleSelectSlides(selectSlides, selectedSlidesListDetail);
      };

      /**
       * To remove slide from preview
       *
       * @param {Boolean} flag to add or remove group
       * @param {String} id to be added or removed
       */
      addRemoveSlideFromPreview = (flag, slideData = {}) => {
        let { contentSlideGroups, activeSlideDetail } = this.state;
        let { _id, refId } = activeSlideDetail || {};
        let grpName = "";

        // Do not allow add/remove operation on required slides
        if (this.checkRequiredSlides(slideData)) return;

        let slideId = _id || slideData._id;
        contentSlideGroups &&
          Object.keys(contentSlideGroups).forEach(item => {
            contentSlideGroups[item].forEach(elem => {
              if (slideId === elem._id) {
                grpName = item;
              }
            });
          });

        if (grpName) {
          let groupSlideIds =
            Array.isArray(contentSlideGroups[grpName]) &&
            contentSlideGroups[grpName].map(({ _id }) => _id);

          DeleteConfirmationAlert({
            message: flag
              ? UI_STRINGS.ADD_GROUP_SLIDE_WARNING_MESSAGE
              : UI_STRINGS.REMOVE_GROUP_SLIDE_WARNING_MESSAGE,
            onYesClick: () => {
              this.handleSelectedSlides(
                groupSlideIds,
                flag,
                grpName,
                contentSlideGroups[grpName]
              );
            }
          });
        } else {
          if (refId) {
            this.handleRemoveDividerBlank();
          } else {
            activeSlideDetail &&
              this.handleSingleCheckbox(slideId, false, slideData);
          }
        }
      };

      /**
       * Remove divider and blank slides from the presentation
       *
       */
      handleRemoveDividerBlank = async () => {
        let {
          selectedSlidesListDetail,
          activeSlideDetail,
          selectSlides,
          showModal
        } = JSON.parse(JSON.stringify(this.state));
        let prevSelectedSlidedListDetail = [
          ...this.formatSlidesForDragAndDrop(selectedSlidesListDetail)
        ];
        let { _id } = activeSlideDetail || {};

        selectedSlidesListDetail = filter(
          selectedSlidesListDetail,
          (item = {}) => item._id !== _id
        );

        // Update slideId for preview
        showModal &&
          (await this.updatePreviewData(
            selectedSlidesListDetail,
            prevSelectedSlidedListDetail
          ));
        let formatSlides = this.formatSlidesForDragAndDrop(
          selectedSlidesListDetail
        );
        this.handleSelectSlides(selectSlides, formatSlides);
      };

      /**
       * Check if the passed Id is added to presentation list and return its details
       * NOTE: Make sure to pass the refId for blank and dividers
       *
       * @param {*} id Id of the slide which needs to be checked
       * @param {*} slides Array of slides which needs to be checked
       * @returns If the passed Id is present in the presentation return its details else return null
       */
      checkAndFindSlideDetail = (id, slides) => {
        let { selectedSlidesListDetail } = this.state;
        let index = -1;
        // If slides is passed wil check in the slides deck or else will default to thhe presentation list
        let checkSlides = slides || selectedSlidesListDetail;
        let slideDetail = filter(
          checkSlides,
          (eachSlide = {}, eachSlideIndex) => {
            let originalId = eachSlide._id;
            if (originalId === id) {
              index = eachSlideIndex;
              return true;
            }
          }
        );
        if (slideDetail[0])
          return {
            details: slideDetail[0],
            index: index
          };
        return {};
      };

      clearCobrandLogoData = () => {
        this.setState({
          coBrandLogoData: {}
        });
      };

      // remove props added on single preview
      removeSinglePreviewProps = selectedSlidesListDetail => {
        return map(selectedSlidesListDetail, item => {
          item.displayDynamicImage && delete item.displayDynamicImage;
          item.hideEditIcon && delete item.hideEditIcon;

          return item;
        });
      };

      /**
       *Handle preview Modal pass id in sort screen to show the current slide with selected slides
       *
       */
      onPreview = async currentIndex => {
        let {
          selectedSlidesListDetail,
          activeStep,
          contentRepo: { _id: contentRepoId }
        } = this.state;

        this.clearCobrandLogoData();

        let currentOpenSlide = {},
          slideData = {};

        let filteredSlideData = this.removeSinglePreviewProps(
          selectedSlidesListDetail
        );

        let formattedData = this.formatSlidesForDragAndDrop(filteredSlideData);
        // Required slides
        let withRequiredSlides = this.addRequiredSlidesSlides(formattedData);
        let withSlideDetails = [...withRequiredSlides];

        // Required slide with ids
        let withRequiredSlidesId = map(withRequiredSlides, (eachSlide = {}) => {
          return eachSlide._id;
        });

        // Length of the presentation with cover and overview
        let pptLength = withRequiredSlides.length;

        // Check if index is not set then show first slide such case happens when universal preview is shown
        if (currentIndex !== 0 && !currentIndex && withRequiredSlides[0]) {
          currentOpenSlide = withRequiredSlides[0];
        } else if (
          currentIndex >= pptLength &&
          withRequiredSlides[pptLength - 1]
        ) {
          //Check if the slide length is more than last slide
          currentOpenSlide = withRequiredSlides[pptLength - 1];
        } else {
          currentOpenSlide = pptLength ? withRequiredSlides[currentIndex] : {};

          // Get dynamic content details if dynamicData is not present or if slideData contains only previous saved image ids
          if (
            ((!currentOpenSlide["slideData"] ||
              !currentOpenSlide["slideData"].length) &&
              (currentOpenSlide["containsDynamicText"] ||
                currentOpenSlide["containsDynamicImagery"])) ||
            currentOpenSlide["containsEditedSlideData"]
          ) {
            let type =
              currentOpenSlide["type"] === "contentSlide" ||
              currentOpenSlide["slideType"] === "Content"
                ? "contentSlide"
                : "themeSlide";
            // Handle Dynamic Data
            slideData = await this.getDynamicFieldsData(
              currentOpenSlide._id,
              contentRepoId,
              type
            );

            // Add dynamic slide data to the selected slide
            withSlideDetails = this.addDynamicDataToSlide(
              withRequiredSlides,
              currentIndex,
              slideData
            );

            // if the currently open slide contains dynamic image call stepEdit function so that the save button is seen so that we can save slideData for the slides containing dynamicImagery
            if (currentOpenSlide["containsDynamicImagery"]) this.onStepEdit();
          }
        }

        // if preview presentation is clicked just open the modal
        if (!this.state.selectSlides.length) {
          ToastUtils.handleToast({
            operation: "error",
            message: "Please select slides to preview."
          });
          return;
        }

        // Reset newly added slides when on sort screen
        activeStep === 3 && this.resetNewlyAddedSlides(formattedData);

        let coBrandLogoData = await this.getCobrandLogoDataBasedOnSlideType(
          currentOpenSlide
        );

        this.setState({
          slidePartOfDeck: withRequiredSlidesId,
          isSlideDeck: true,
          showModal: true,
          isSingleSlidePreview: false,
          activeSlideDetail: currentOpenSlide,
          selectedSlidesListDetail: this.removeRequiredSlide(withSlideDetails),
          coBrandLogoData,
          showEditIcons: true
        });
      };

      /**
       *Get the dynamic field details
       *
       * @param {*} id Id of the slide whose dynamic detail is required
       * @param {*} contentRepositoryId The content Repo Id to which the content slide belongs.
       * @param {*} type Type of slide themeSlide/contentSlide
       * @returns
       */
      getDynamicFieldsData = async (id, contentRepositoryId, type) => {
        let attributeList = this.getImageAttributeList();

        // resetting the value of associatedDeckIndex by setting it to null
        let response = await this.props.getSlideDetails(
          id,
          contentRepositoryId,
          type,
          attributeList
        );

        // exit if there is an error in response
        if (!response.success) return;

        return response["data"];
      };

      /**
       *Add the dynamic field data to the matching slide index
       *
       * @param {*} slides The array of slides in which the matching index slide is to be found
       * @param {*} index Index of the slide whose dynamic data is to be added
       * @param {*} [slideData={}] The dynamic data of the slide
       * @returns
       */
      addDynamicDataToSlide = (slides, index, slideData = {}) => {
        let { isImageCategoryEdited } = this.state;
        let newSlides = [...slides];
        let uniqueId = newSlides[index].refId || newSlides[index]._id;

        // Mutating and adding the dynamic data to selectedSlidesDetails and activeSlideDetails
        if (uniqueId === slideData._id && slideData["slideData"]) {
          // if image category is changed slideData should contain the new slide data
          if (isImageCategoryEdited) {
            newSlides[index]["slideData"] = slideData["slideData"];
          } else {
            //if in create mode, slidedata will be taken as is from api, else we will attach the last selected image from presentation data at the start of slideData, this is done because in case if the selected image gets deleted from its folder but was asked to exist in the existing ppts
            newSlides[index]["slideData"] = !isEmpty(
              newSlides[index]["slideData"]
            )
              ? // check if slidedata exists so tht the last saved image could be added
                this.getSlideDataFromPresentationData(
                  newSlides[index],
                  slideData["slideData"]
                )
              : slideData["slideData"];
          }
          newSlides[index]["containsEditedSlideData"] = false; // setting it to false so tht next time api is not called
        }
        return newSlides;
      };

      /**
       * Attach the last saved image to the start of slideData
       *
       * @param {Object} slide current slide
       * @param {Array} [slideData=[]] The dynamic data of the slide
       * @returns
       */
      getSlideDataFromPresentationData = (slide, slideData = []) => {
        let { presentationData: { slides = [] } = {} } = this.props;
        // get the slide details which was last saved from the presentation data
        let previousSavedSlide = filter(slides, eachSlide => {
          return (
            get(eachSlide, `slideId._id`) === get(slide, `_id`) ||
            get(eachSlide, `slideId`) === get(slide, `_id`) //"Get" gives _id inside slideId and "patch" gives _id as slideId
          );
        });

        if (!previousSavedSlide.length) return;

        let previousSavedSlideData = get(previousSavedSlide[0], `slideData`);

        // attach the last saved image to the start of the images array
        for (
          let slideDataIndex = 0;
          slideDataIndex < previousSavedSlideData.length;
          slideDataIndex++
        ) {
          let imageToBeInserted =
            get(previousSavedSlideData[slideDataIndex], `image.imageId`) || {};
          imageToBeInserted.dimensions = get(
            previousSavedSlideData[slideDataIndex],
            `image.imageDimension`
          );

          if (!imageToBeInserted.deleted) {
            slideData[slideDataIndex]["images"].unshift(imageToBeInserted);
          }

          // if the image is not deleted from folder, slideData will have 2 same images, therefore remove the duplicates
          let uniqueImages = uniqBy(slideData[slideDataIndex]["images"], "_id");
          slideData[slideDataIndex]["images"] = uniqueImages;
        }

        return slideData;
      };

      /**
       *Check if the selected slide is part of a deck
       *
       * @param {*} id Id of the selected slide
       */
      _checkSlideIsDeck = (id = "") => {
        // Return if id is not present
        if (!id) return;

        let { deckChildrenSlideId } = this.state;
        let flag = false;
        // checking if the current selected id is a part of the deck
        each(deckChildrenSlideId, slideId => {
          each(slideId, deckId => {
            // if current slide is a part of deck set the associated deck
            if (deckId === id) flag = true;
          });
        });
        return flag;
      };

      /**
       *Set slide deck set and details
       *
       * @param {*} id Id whose deck need to be found
       * @param {*} activeIndex If active index is present then set current deck details in activeSlideDetail
       *
       */
      setSlideDeckPosition = ({ id, activeIndex }) => {
        this.clearCobrandLogoData();
        let { deckChildrenSlideId, deckChildrenData } = this.state;

        // checking if the current selected id is a part of the deck
        each(deckChildrenSlideId, (slideId, associatedDeckIndex) => {
          each(slideId, async deckId => {
            // get the details of the id provided
            let activeSlideDetail =
              deckChildrenData.length &&
              deckChildrenData[associatedDeckIndex][activeIndex];
            // if current slide is a part of deck set the associated deck
            if (deckId === id) {
              // If active index is present get the deck details
              if (activeIndex > -1) {
                // get dynamic image data when single slide is previewed inside a deck
                activeSlideDetail = await this.fetchDynamicData(
                  activeSlideDetail
                );
                this.setState(
                  {
                    activeSlideDetail
                  },
                  async () => {
                    let coBrandLogoData = await this.getCobrandLogoDataBasedOnSlideType(
                      activeSlideDetail
                    );

                    this.setState({
                      coBrandLogoData
                    });
                  }
                );
              }
              this.setState({
                isSlideDeck: true, // determine if the current selected slide is a part of deck to show/hide bottom slider on overlay
                slidePartOfDeck: deckChildrenSlideId[associatedDeckIndex], // current selected slide part of which deck
                isSingleSlidePreview: true
              });
            }
          });
        });
      };

      /**
       * @param {Object} activeSlideDetail currently open slide
       */
      downloadDeckHandler = async (
        activeSlideDetail = {},
        download,
        themeid
      ) => {
        const {
          contentRepo: { _id: contentRepoId },
          selectedThemeLayout,
          selectedCoverLayout
        } = this.state;
        let categoryId = get(activeSlideDetail, `contentSlideCategory`);
        let { blankSlides, dividers } = this.props;

        let body = {
          themeId: selectedThemeLayout,
          coverId: selectedCoverLayout,
          dividerId: get(dividers[0], `_id`),
          ...(Object.keys(blankSlides[0]).length && {
            blankId: get(blankSlides[0], `_id`)
          })
        };

        await this.props.downloadDeck(contentRepoId, categoryId, body);
        let { downloadDeckDetails } = this.props || {};

        if (downloadDeckDetails && "url" in downloadDeckDetails) {
          this.downloadDeckUrlHandler(
            downloadDeckDetails["url"],
            activeSlideDetail.contentSlideCategory,
            download,
            themeid
          );
          return;
        }

        let ingestId = get(downloadDeckDetails, `ingestId`);

        PollingUtils.startPolling({
          pollingAction: () => {
            this.pollingActionForDownload(
              ingestId,
              contentRepoId,
              categoryId,
              body
            );
          },
          timeoutCallback: () => {
            this.props.stopDownloadDeckLoading();
            ToastUtils.handleToast({
              operation: "error",
              message:
                "Deck download is taking too long. Please try again later."
            });
          }
        });
      };

      /**
       * @param {String} ingestId ingest id to be used for polling
       * @param {String} contentRepoId currently selected content repo id
       * @param {String} categoryId category id which is the parent of the currently open slide
       * @param {Object} body body to be sent
       */
      pollingActionForDownload = async (
        ingestId,
        contentRepoId,
        categoryId,
        body
      ) => {
        let { activeSlideDetail, selectedThemeLayout } = this.state;
        await this.props.getFilePollingStatus(ingestId);

        let { filePollingStatus } = this.props || {};
        if (get(filePollingStatus, `data.status`) === "Completed") {
          PollingUtils.stopPolling();

          // call downloadDeck again to get url
          await this.props.downloadDeck(contentRepoId, categoryId, body);
          let { downloadDeckDetails } = this.props || {};
          if ("url" in downloadDeckDetails) {
            this.downloadDeckUrlHandler(
              downloadDeckDetails["url"],
              activeSlideDetail.contentSlideCategory,
              "contentSlideCategory",
              selectedThemeLayout
            );
          }
        } else if (
          get(filePollingStatus, `status` !== 200) ||
          get(filePollingStatus, `data.status`) === "Failed"
        ) {
          PollingUtils.stopPolling();
          ToastUtils.handleToast({
            operation: "error",
            message: get(filePollingStatus, `data.message`)
          });
        }
      };

      downloadDeckUrlHandler = async (
        urlToBeDownloaded,
        id,
        download,
        themeId
      ) => {
        await this.setState({
          downloadDeck: true
        });

        this.props.loggingDownloadInDatabase({
          id,
          type: download,
          themeId
        });
        document.location.href = urlToBeDownloaded;
        this.setState({
          downloadDeck: false
        });
      };

      /**
       * @param {Object} slideType defines the currently open slide
       * @returns coBrandLogoData which consists of the the dimensions and position of the cobrand data
       */
      getCoBrandLogoData = slideType => {
        let slideList = slideType || [];

        if (slideList.length && slideList[0].coBrandLogoData) {
          return slideList[0].coBrandLogoData;
        }
        return null;
      };

      /**
       * @param {Object} slide currently open slide whose slideLayout type has to be defined
       */
      getCobrandLogoDataBasedOnSlideType = slide => {
        let slideLayoutType =
          toLower(get(slide, "slideLayoutType")) ||
          toLower(get(slide, `slideType`));

        let {
          contentSlideData = {},
          dividers = {},
          blankSlides = {}
        } = this.props;

        switch (slideLayoutType) {
          case "content":
            return this.getCoBrandLogoData(contentSlideData);

          case "divider":
            return this.getCoBrandLogoData(dividers);

          case "blank":
            return this.getCoBrandLogoData(blankSlides);

          case "cover":
            let coverDetails = get(this.state, `coverDetails`, {});
            let selectedCoverDetail = [];
            selectedCoverDetail.push(coverDetails);
            return this.getCoBrandLogoData(selectedCoverDetail);

          default:
            return null;
        }
      };

      /**
       *Handle preview modal. This function is used only to open preview on click of content slides in library.
       *
       * @param {*} id Slide Id
       * @param {*} childList Array of group set to which the selected slide belongs
       * @returns
       */
      handleModal = async (id, childList = [], slideData) => {
        this.clearCobrandLogoData();
        let coBrandLogoData = await this.getCobrandLogoDataBasedOnSlideType(
          slideData
        );

        // get dynamic image data when single slide is previewed
        slideData = await this.fetchDynamicData(
          JSON.parse(JSON.stringify(slideData))
        );

        // Check if selected Id is part of a deck
        this.setSlideDeckPosition({ id });

        handleBodyScroll({ action: "open" });
        this.setState({
          showModal: true,
          slideName: "", // unset slideName for another slide editing
          activeSlideDetail: slideData,
          isSingleSlidePreview: true,
          isInputFocused: false,
          coBrandLogoData
        });
      };
      //end

      // fetch dynamic image data
      fetchDynamicData = async slideData => {
        const {
          contentRepo: { _id: contentRepoId },
          selectedSlidesListDetail
        } = this.state;

        // Get dynamic content details if dynamicData is not present or if slideData contains only previous saved image ids
        let existingSlideData = find(selectedSlidesListDetail, {
          _id: slideData._id
        });

        if (
          existingSlideData &&
          (existingSlideData || {}).slideData &&
          !existingSlideData["containsEditedSlideData"]
        ) {
          slideData = JSON.parse(JSON.stringify(existingSlideData));
        } else if (
          ((!slideData["slideData"] || !slideData["slideData"].length) &&
            (slideData["containsDynamicText"] ||
              slideData["containsDynamicImagery"])) ||
          slideData["containsEditedSlideData"]
        ) {
          let type =
            slideData["type"] === "contentSlide" ||
            slideData["slideType"] === "Content"
              ? "contentSlide"
              : "themeSlide";

          // Handle Dynamic Data
          let dynamicslideData = await this.getDynamicFieldsData(
            slideData._id,
            contentRepoId,
            type
          );

          slideData["slideData"] = this.addDynamicDataFromHandleModal(
            dynamicslideData,
            slideData
          );

          // if slide added to presentation
          if (existingSlideData) {
            let newSlideData = this.addDynamicDataFromHandleModal(
              dynamicslideData,
              existingSlideData
            );

            existingSlideData["slideData"] = newSlideData;
          }
        }

        slideData["displayDynamicImage"] = true;
        slideData["hideEditIcon"] = true;

        return slideData;
      };

      addDynamicDataFromHandleModal = (slideData, currentSlide) => {
        let { isImageCategoryEdited } = this.state;
        currentSlide["containsEditedSlideData"] = false;
        if (slideData["slideData"]) {
          if (isImageCategoryEdited) {
            return slideData["slideData"];
          } else {
            let newSlideData = !isEmpty(currentSlide["slideData"])
              ? this.getSlideDataFromPresentationData(
                  currentSlide,
                  slideData["slideData"]
                )
              : slideData["slideData"];

            return newSlideData;
          }
        }
      };

      closeModal = () => {
        handleBodyScroll({ action: "close" });
        this.setState({
          showModal: false,
          activeSlideDetail: {},
          slideName: "",
          isInputFocused: false,
          isSlideDeck: false,
          slidePartOfDeck: [],
          slideNameError: "",
          showEditIcons: false,
          showDynamicImageOverlay: false
        });
      };

      hideModal = e => {
        if (
          e.target.classList.contains("modal-preview-container") ||
          e.target.classList.contains("modal-preview-subcontainer")
        ) {
          handleBodyScroll({ action: this.state.showModal ? "close" : "open" });
          this.setState({
            showModal: !this.state.showModal,
            slideName: "",
            isInputFocused: false,
            isSlideDeck: false
          });
        }
      };
      /**
       * validate slidetitle
       *
       * @param {*} value input value to be validated
       * @returns appropriate error message
       */
      slideNameValidationHandler = value => {
        if (ValidationUtils.checkIfspecialChar(value)) {
          return UI_STRINGS.SPECIAL_CHAR_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfWhiteSpace(value)) {
          return UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
        } else {
          return null;
        }
      };

      setSlideTitle = (e, revertToOldName = false) => {
        let { activeSlideDetail, selectedSlidesListDetail } = this.state;

        let currentSlideWithTitle = filter(
          selectedSlidesListDetail,
          eachSlide => {
            return eachSlide._id === activeSlideDetail._id;
          }
        );

        let slideNameError = this.slideNameValidationHandler(
          get(e, "target.value")
        );

        this.setState({
          slideName: revertToOldName
            ? get(currentSlideWithTitle[0], `changedTitle`) ||
              get(currentSlideWithTitle[0], `title`)
            : e.target.value,
          slideNameError
        });
      };

      focusInputHandler = () => {
        this.setState({
          isInputFocused: true
        });
      };

      saveSlideTitle = () => {
        let { slideName, isInputFocused, slideNameError } = this.state;

        if ((!slideName && isInputFocused) || slideNameError) {
          ToastUtils.handleToast({
            operation: "error",
            message: "Please enter a valid slide title."
          });
          return false;
        }

        let { selectedSlidesListDetail, activeSlideDetail } = this.state;

        let newSelectedSlides = map(selectedSlidesListDetail, slideDetail => {
          if (get(slideDetail, `_id`) === get(activeSlideDetail, `_id`)) {
            slideDetail.changedTitle = slideName.trim();
            return slideDetail;
          } else {
            return slideDetail;
          }
        });

        this.setState({
          selectedSlidesListDetail: newSelectedSlides
        });
        this.onStepEdit();
        return true;
      };

      // Handle Edit click button
      onEdit = () => {
        this.modifyStep(0);
      };

      /**
       * Get themeList associated to slected slide based on its type
       *
       * @param {String} themeId selected theme id
       * @param {Object} slide selected slide
       * @returns thumbnail/file url
       */
      getThumbnailBasedOnSlideType = (themeId, slide, type) => {
        let slideType = toLower(get(slide, "slideLayoutType"));
        switch (slideType) {
          case "content":
            return this.getThemeBasedUrl(
              themeId,
              get(slide, "slideListByThemes"),
              type
            );

          case "divider":
            return this.getThemeBasedUrl(
              themeId,
              get(slide, "slideListByDividers"),
              type
            );

          case "blank":
            return this.getThemeBasedUrl(
              themeId,
              get(slide, "slideListByBlanks"),
              type
            );

          case "cover":
            return this.getThemeBasedUrl(
              themeId,
              get(slide, "slideListByCovers"),
              type
            );

          default:
            return null;
        }
      };

      /**
       *Get thumbnail/file location based on the selected theme
       *
       * @param {*} type thumbnail/ file
       * @param {*} themeId Currently selected theme ID
       * @param {*} themedSlides Slides based on available themes
       */
      getThemeBasedUrl = (themeId, themedSlides, type = "thumbnail") => {
        let selectedThemeSlide = filter(themedSlides, slide => {
          // Check if the themeId matches selected theme and return the slide
          return get(slide, "themeId") === themeId;
        });
        if (selectedThemeSlide.length && type === "thumbnail") {
          return get(selectedThemeSlide[0], "thumbnailLocation.url");
        } else if (selectedThemeSlide.length && type === "file") {
          return get(selectedThemeSlide[0], "fileLocation.url");
        }
        return null;
      };

      /**
       *Check if the currently active slide in preview is added to presentation
       *
       */
      checkIfAddedToPresentation = () => {
        let { activeSlideDetail, selectSlides } = this.state;

        let id = get(activeSlideDetail, "_id");
        // Check if id is present
        let { details } = this.checkAndFindSlideDetail(id);

        let isAdded = details && Object.keys(details).length;

        return (
          !!isAdded || selectSlides.indexOf(get(activeSlideDetail, `_id`)) > -1
        );
      };

      /**
       * Check the disabled slides and create a html structure for error message
       *
       */
      _getDisabledSlideErrorMessage = (slide = {}) => {
        return `
          <span style="font-weight: 900;">The following slides have been removed from the system:</span>
          <ul style="list-style: disc; font-weight: 900; margin-left: 15px;">
            ${map(slide, ({ slideId }) => {
              if (slideId.title) return `<li>${slideId.title}</li>`;
            }).join("")}
          </ul>`;
      };

      // TODO: Overview slide details get from backend
      getOverviewDetails = () => {
        let { buildSetupDetails } = this.state;
        if (get(buildSetupDetails, "includeOverview.value")) {
          return {
            _id: "overviewSlide",
            thumbnailLocation: { url: Overview },
            isOverview: true,
            title: "Overview",
            isDraggable: false
          };
        }
        return null;
      };

      // handle presentation edit
      onSave = async () => {
        let { activeStep, isEdited } = this.state;
        let savePptPayload = {};
        if (!this.checkValidation(activeStep)) {
          return;
        }
        // Active step save data
        savePptPayload = { ...(await this.saveDataHandler(activeStep + 1)) };

        let cb = () => {
          this.onStepCompleted(activeStep);
          ToastUtils.handleToast({
            operation: "success",
            message: UI_STRINGS.SAVED_PRESENTATION_SUCCES_MESSAGE
          });
        };

        // save current step with payload
        isEdited &&
          Object.keys(savePptPayload).length &&
          this.savePresentationDetails(savePptPayload, cb);
      };

      /**
       * Callback on completed step.
       *
       * Call to set the completed steps variable to show tick on completion of step
       */
      onStepCompleted = completedStep => {
        let { completedSteps } = this.state;

        if (!includes(completedSteps, completedStep)) {
          this.setState({
            completedSteps: [...completedSteps, completedStep],
            isEdited: false
          });
        }
      };

      /**
       * Call on any input field edited in a step
       *
       */
      onStepEdit = (flag = true) => {
        let { completedSteps, activeStep } = this.state;
        let newStepArray = filter(completedSteps, ele => {
          return ele !== activeStep;
        });
        this.setState({
          completedSteps: newStepArray,
          isEdited: flag
        });
      };

      // Set completed steps to show completed Steps
      setCompletedSteps = (response = {}) => {
        let completedSteps = [];
        const { selectedSlidesListDetail } = this.state;
        let contentSlidesOnly = this.getContentSlides(selectedSlidesListDetail);

        mapKeys(response, (value, key) => {
          if (key === "presentationName" && !this.disabledSlide.length) {
            completedSteps.push(0);
          } else if (key === "theme" && value) {
            completedSteps.push(1);
          } else if (
            contentSlidesOnly.length &&
            key === "slides" &&
            value.length
          ) {
            completedSteps.push(2);
            completedSteps.push(3);
          } else if (key === "status" && value === "Completed") {
            completedSteps.push(4);
          }
        });

        this.setState({
          completedSteps
        });
      };

      // set focus on presentation name field
      setFocusValue = ({ key, value }) => {
        this.setState({
          [key]: value
        });
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props
        };
        return <Main {...stateMethodProps} />;
      }
    }
  );

export default Container;
