import React, { Component } from "react";
import { get, round } from "lodash";
import { connect } from "react-redux";
import ToastUtils from "utils/handleToast";

//import services
import { createAndEditContentRepo } from "../dashboard/services";
import { getContentRepoName } from "../themes/services";

const mapStateToProps = state => {
  const {
    SUCCESS_THEME_LIST,
    LOADING_THEME_LIST,
    LOADING_CONTENT_REPO_LIST
  } = state;
  return {
    ...SUCCESS_THEME_LIST,
    ...LOADING_THEME_LIST,
    ...LOADING_CONTENT_REPO_LIST
  };
};

const Container = Main =>
  connect(mapStateToProps, {
    createAndEditContentRepo,
    getContentRepoName
  })(
    class InteractivityContainer extends Component {
      state = {
        config: {},
        activeAlignment: 0,
        disableCobrand: false,
        isEdited: false
      };

      setConfigDetails = selectedContentRepo => {
        let config = {
          logos: {
            title: "Logos",
            checkbox: [
              {
                label: "Allow a dynamically created overview page",
                defaultChecked: !!get(
                  selectedContentRepo,
                  `overviewData.enable`
                ),
                id: "overview",
                type: "logos"
              },
              {
                label: "Link logo to Overview page",
                defaultChecked: !!get(
                  selectedContentRepo,
                  `overviewData.linkLogo`
                ),
                id: "logo-overview",
                type: "logos"
              }
            ]
          },
          cobrand: {
            title: "Co-Brand Logo",
            checkbox: [
              {
                label: "Allow co-branded logo option",
                defaultChecked: !!get(
                  selectedContentRepo,
                  "coBrandLogoData.enable"
                ),
                id: "cobrand",
                value: false,
                type: "cobrand"
              }
            ],
            instruction: `Enter the two coordinates for the top left (or right) location of the co-brand logo`,
            radios: [
              {
                id: "align-left",
                name: "align",
                label: "Align Left",
                defaultChecked:
                  get(selectedContentRepo, `coBrandLogoData.alignment`) ===
                  "Left",
                values: {
                  min: {
                    "x-coordinate": {
                      value:
                        get(
                          selectedContentRepo,
                          `coBrandLogoData.alignment`
                        ) === "Left"
                          ? round(
                              get(
                                selectedContentRepo,
                                `coBrandLogoData.location.x`
                              ),
                              2
                            )
                          : 0,
                      error: ""
                    },
                    "y-coordinate": {
                      value:
                        get(
                          selectedContentRepo,
                          `coBrandLogoData.alignment`
                        ) === "Left"
                          ? round(
                              get(
                                selectedContentRepo,
                                `coBrandLogoData.location.y`
                              ),
                              2
                            )
                          : 0,
                      error: ""
                    }
                  },
                  max: {
                    "max-hieght": {
                      value:
                        get(
                          selectedContentRepo,
                          `coBrandLogoData.alignment`
                        ) === "Left"
                          ? get(
                              selectedContentRepo,
                              `coBrandLogoData.location.height`
                            )
                          : 0,
                      error: ""
                    },
                    "max-width": {
                      value:
                        get(
                          selectedContentRepo,
                          `coBrandLogoData.alignment`
                        ) === "Left"
                          ? get(
                              selectedContentRepo,
                              `coBrandLogoData.location.width`
                            )
                          : 0,
                      error: ""
                    }
                  }
                }
              },
              {
                id: "align-right",
                name: "align",
                label: "Align Right",
                defaultChecked:
                  get(selectedContentRepo, `coBrandLogoData.alignment`) ===
                  "Right",
                values: {
                  min: {
                    "x-coordinate": {
                      value:
                        get(
                          selectedContentRepo,
                          `coBrandLogoData.alignment`
                        ) === "Right"
                          ? round(
                              get(
                                selectedContentRepo,
                                `coBrandLogoData.location.x`
                              ),
                              2
                            )
                          : 0,
                      error: ""
                    },
                    "y-coordinate": {
                      value:
                        get(
                          selectedContentRepo,
                          `coBrandLogoData.alignment`
                        ) === "Right"
                          ? round(
                              get(
                                selectedContentRepo,
                                `coBrandLogoData.location.y`
                              ),
                              2
                            )
                          : 0,
                      error: ""
                    }
                  },
                  max: {
                    "max-hieght": {
                      value:
                        get(
                          selectedContentRepo,
                          `coBrandLogoData.alignment`
                        ) === "Right"
                          ? get(
                              selectedContentRepo,
                              `coBrandLogoData.location.height`
                            )
                          : 0,
                      error: ""
                    },
                    "max-width": {
                      value:
                        get(
                          selectedContentRepo,
                          `coBrandLogoData.alignment`
                        ) === "Right"
                          ? get(
                              selectedContentRepo,
                              `coBrandLogoData.location.width`
                            )
                          : 0,
                      error: ""
                    }
                  }
                }
              }
            ],
            coordinates: {
              min: [
                {
                  label: "X-Axis",
                  type: "text",
                  name: "x-coordinate",
                  id: "brand-coordinate-top-x"
                },
                {
                  label: "Y-Axis",
                  type: "text",
                  name: "y-coordinate",
                  id: "brand-coordinate-top-y"
                }
              ],
              max: [
                {
                  label: "Maximum Width",
                  type: "text",
                  name: "max-width",
                  id: "left-max-width",
                  unit: "px"
                },
                {
                  label: "Maximum Height",
                  type: "text",
                  name: "max-hieght",
                  id: "left-max-hieght",
                  unit: "px"
                }
              ]
            }
          }
        };

        this.setState({
          config,
          disableCobrand: !get(selectedContentRepo, `coBrandLogoData.enable`),
          activeAlignment:
            get(selectedContentRepo, `coBrandLogoData.alignment`) === "Left"
              ? 0
              : 1
        });
      };

      componentDidMount() {
        this.getRepoDetails();
      }

      getRepoDetails = async () => {
        const { contentRepoId } = this.props.match.params;
        await this.props.getContentRepoName(contentRepoId);

        this.setConfigDetails(this.props.selectedContentRepo);
      };

      /**
       * manage logos status
       * @param {string} type - "logo/cobrand"
       * @param {string} key - "logo/cobrand"
       * @param {boolean} value - "logo/cobrand"
       */

      setLogoOptions = ({ type, index, defaultChecked }) => {
        let { config } = JSON.parse(JSON.stringify(this.state));
        config[type]["checkbox"][index]["defaultChecked"] = !defaultChecked;
        this.setState({
          config,
          disableCobrand: type === "cobrand" && !defaultChecked === false,
          isEdited: true
        });
      };

      // Validate category
      checkValidInput = value => {
        if (!value) {
          return {
            msg: "This field is required.",
            status: false
          };
        } else if (isNaN(value)) {
          return {
            msg: "Please enter only numbers.",
            status: false
          };
        } else {
          return {
            msg: "",
            status: true
          };
        }
      };

      onChangeInput = ({ e, id, name, min, max }) => {
        const { config, activeAlignment } = JSON.parse(
          JSON.stringify(this.state)
        );
        let value = e.target.value;
        let error = this.checkValidInput(e.target.value);

        if (min) {
          if (error.status === true)
            config.cobrand.radios[activeAlignment].values.min[name] = {
              value,
              error: ""
            };
          else {
            config.cobrand.radios[activeAlignment].values.min[name] = {
              value: 0,
              error: error.msg
            };
          }
        } else if (max) {
          if (error.status === true)
            config.cobrand.radios[activeAlignment].values.max[name] = {
              value,
              error: ""
            };
          else {
            config.cobrand.radios[activeAlignment].values.max[name] = {
              value: 0,
              error: error.msg
            };
          }
        }

        this.setState({
          config,
          isEdited: true
        });
      };

      //get alignment config
      getAlignmentConfig = () => {
        const { activeAlignment, config } = this.state;

        let x = get(
          config,
          `cobrand.radios[${activeAlignment}].values.min[${"x-coordinate"}]`
        );
        let y = get(
          config,
          `cobrand.radios[${activeAlignment}].values.min[${"y-coordinate"}]`
        );

        let width = get(
          config,
          `cobrand.radios[${activeAlignment}].values.max[${"max-width"}]`
        );

        let height = get(
          config,
          `cobrand.radios[${activeAlignment}].values.max[${"max-hieght"}]`
        );

        return {
          x,
          y,
          width,
          height
        };
      };

      saveContentRepoInteractityMetaDetails = async type => {
        ToastUtils.handleToast({
          operation: "dismiss"
        });

        const { config, activeAlignment, isEdited } = this.state;

        if (!isEdited) return;

        const { contentRepoId } = this.props.match.params;

        let payload = {
          overviewData: {
            linkLogo: get(config, "logos.checkbox[1].defaultChecked"),
            enable: get(config, "logos.checkbox[0].defaultChecked")
          },
          coBrandLogoData: {
            enable: get(config, "cobrand.checkbox[0].defaultChecked"),
            alignment: activeAlignment === 0 ? "Left" : "Right"
          }
        };

        const { x, y, height, width } = this.getAlignmentConfig();

        if (payload.coBrandLogoData.enable) {
          payload.coBrandLogoData.location = {
            x: x.value || 0,
            y: y.value || 0,
            width: width.value || 0,
            height: height.value || 0
          };
        }

        if (
          payload.coBrandLogoData.enable &&
          [x, y, width, height].filter((elem = {}) => elem.error).length
        ) {
          ToastUtils.handleToast({
            operation: "error",
            message: "Please fill the required field."
          });
          return;
        }

        contentRepoId &&
          (await this.props.createAndEditContentRepo(
            payload,
            contentRepoId,
            "Content Repo Interactivity settings updated successfully."
          ));

        this.setState({
          isEdited: false
        });
        this.getRepoDetails();
      };

      //swap alignments display for cobrand logos
      setAlignmentDetails = (e, activeAlignment) => {
        const { x, y, height, width } = this.getAlignmentConfig();
        ToastUtils.handleToast({
          operation: "dismiss"
        });

        if ([x, y, width, height].filter((elem = {}) => elem.error).length) {
          e.preventDefault();
          e.stopPropagation();
          e.target.checked = false;

          ToastUtils.handleToast({
            operation: "error",
            message: "Please fill the required field."
          });
          return false;
        }

        this.setState({
          activeAlignment,
          isEdited: true
        });
      };

      render() {
        const $this = this;

        /**Merge State and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.props,
          ...$this.state
        };
        return <Main {...stateMethodProps} />;
      }
    }
  );

export default Container;
