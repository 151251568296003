import React from "react";
import styled from "styled-components";
import { MdDone, MdClear } from "react-icons/md";
import { get } from "lodash";
import { Folder, FolderOpen } from "assets/icons";

import Container from "./container";

const CategoryTitleComponent = ({
  _id,
  title,
  categoryInput,
  checkIfEdited,
  InputRef,
  inputError,
  handleStateChange,
  children,
  imageFolderDetails,
  level,
  rowInfo,
  removeNodeFromTree,
  isNewlyAdded,
  isEditedFlag,
  saveCategoryDetails,
  categoryIdToEdit,
  parent,
  selectFolderHandler
}) => {
  let isChildrenExist = Array.isArray(children) && children.length;

  // class to highlight selected folder
  let selectedClass =
    (imageFolderDetails || {})._id === _id ? "elem-selected" : "";

  // parent id
  let parentID =
    Object.keys(imageFolderDetails || {}).length &&
    (imageFolderDetails || {})._id
      ? (imageFolderDetails || {})._id
      : null;
  // parent level
  let parentLevel = Object.keys(imageFolderDetails || {}).length
    ? (imageFolderDetails || {}).level
    : 0;

  let { treeIndex } = rowInfo;

  return (
    <>
      {categoryInput ? (
        <CategoryWrapper className="cat-title-input">
          <span>
            <input
              onChange={checkIfEdited}
              ref={InputRef}
              autoFocus
              type="text"
              defaultValue={title}
            />
            {inputError && <ErrorMessage>{inputError}</ErrorMessage>}
          </span>
          <button
            className="save-button"
            onClick={() => {
              if (InputRef && get(InputRef, "current.value")) {
                let payload = {
                  title: get(InputRef, "current.value"),
                  parent: parentID,
                  parentLevel
                };
                if (!isNewlyAdded && categoryIdToEdit) {
                  saveCategoryDetails(
                    {
                      title: get(InputRef, "current.value"),
                      parent
                    },
                    _id
                  );
                } else {
                  saveCategoryDetails(payload);
                }
              }
            }}
            title="save"
          >
            <SaveIcon />
          </button>
          <button
            className="close-button"
            title="Close"
            onClick={() => {
              handleStateChange({ key: "categoryIdToEdit", value: null });
              handleStateChange({ key: "isEditedFlag", value: false });
              handleStateChange({ key: "inputError", value: false });
              isNewlyAdded && removeNodeFromTree(rowInfo);
            }}
          >
            <ClearIcon />
          </button>
        </CategoryWrapper>
      ) : (
        <TitleComp
          key={_id}
          title={title}
          tabIndex={0}
          className={selectedClass}
          onClick={() => {
            selectFolderHandler({
              _id,
              children,
              level,
              treeIndex,
              title,
              rowInfo
            });
          }}
        >
          {!isChildrenExist ? (
            (imageFolderDetails || {})._id === _id ? (
              <FolderOpen className="svg-icon" />
            ) : (
              <Folder className="svg-icon" />
            )
          ) : (
            ""
          )}
          <TiltleHolder>{title}</TiltleHolder>
        </TitleComp>
      )}
    </>
  );
};

const CategoryWrapper = styled.div`
  width: 78%;
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);

  span {
    width: 100%;
    display: inline-block !important;
  }

  & > span {
    width: 88%;
  }

  input {
    width: 100%;
    padding: 5px 5px 0;
    border: none;
    border-bottom: 2px solid ${props => props.theme.COLOR.USER_PRIMARY};
    outline: none;
    color: ${props => props.theme.COLOR.HEX_GRAY};
    background: transparent;
    transform: translateY(-3px);
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
  }

  .save-button {
    right: -11px;
  }

  .close-button {
    right: -33px;
  }
`;

const SaveIcon = styled(MdDone)`
  font-size: 16px;
  cursor: pointer;
`;

const ClearIcon = styled(MdClear)`
  font-size: 16px;
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  height: 23px;
  position: absolute;
  left: 0;
  bottom: -21px;
  color: ${props => props.theme.COLOR.ERROR};
  font-size: 10px;
  font-weight: normal;
  white-space: normal;
`;

const TitleComp = styled.span`
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  font-size: 12px;
  outline: none;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
  .svg-icon {
    width: 14px;
    height: 12px;
    position: absolute;
    top: -1px;
    left: -21px;
  }

  &.elem-selected {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const TiltleHolder = styled.span`
  width: 78%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
`;
export default Container(CategoryTitleComponent);
