import React from "react";
import { map } from "lodash";
import FilterList from "./filter";

/**
 * Helper function to fetch filter type values
 * @param {Filter types and Filter data array} filterParams
 */

export const FilterAll = props => {
  const { filter, selectedFilters = [] } = props;
  return map(filter, ({ children, ...elem }, index) => {
    // check children length and add options as all at top
    let childrensExists = Array.isArray(children) && children.length;
    if (childrensExists) {
      children = JSON.parse(JSON.stringify(children));

      let childrenList = children.filter(
        (elem = {}) => selectedFilters.findIndex(item => item === elem._id) > -1
      );
      if (
        children.length === childrenList.length &&
        selectedFilters.indexOf(`F-${index}`) < 0
      )
        selectedFilters.push(`F-${index}`);
      children.unshift({ _id: `F-${index}`, title: "All" });
    }

    return (
      <FilterList
        key={index + elem._id}
        {...props}
        {...elem}
        children={children}
      />
    );
  });
};
