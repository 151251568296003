import React from "react";
import styled from "styled-components";

const ThemeSlideDetails = ({ data }) => {
  return data.map((item, index) => {
    let { title, value, subTitle, hasMultipleData } = item;

    return (
      <MetaContentField key={index}>
        <MetaContentFieldTitle> {title} </MetaContentFieldTitle>

        {hasMultipleData && <MetaSubtitle>{subTitle}</MetaSubtitle>}

        <MetaContentFieldValue>
          {hasMultipleData ? (
            <>
              {value.map((item, index) => {
                return (
                  <PrimaryCoverValue key={index}>{item}</PrimaryCoverValue>
                );
              })}
            </>
          ) : (
            <DataValue>{value}</DataValue>
          )}
        </MetaContentFieldValue>
      </MetaContentField>
    );
  });
};

const MetaContentField = styled.div`
  margin-bottom: 15px;
`;
const MetaContentFieldTitle = styled.span`
  margin-bottom: 4px;
  font-weight: bold;
  opacity: 0.7;
`;

const MetaContentFieldValue = styled.span`
  line-height: 1.5;
`;

const MetaSubtitle = styled.span`
  margin: -4px 0 4px;
  font-weight: bold;
  opacity: 0.7;
`;

const PrimaryCoverValue = styled.span`
  display: block;
`;

const DataValue = styled.span``;

export default ThemeSlideDetails;
