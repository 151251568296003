import React from "react";
import { get, round, toLower } from "lodash";
import styled from "styled-components";

/**
 * calculates the alignment of the coBrand logo
 * @param {String} alignment alignment of the coBrandLogo
 * @param {String} direction states whether alignment is in x-axis or y-axis
 * @returns {String} styling to be applied
 */
const getAlignment = (alignment, direction = "yAlignment") => {
  switch (alignment) {
    case "top":
    case "left":
      return "flex-start";

    case "bottom":
    case "right":
      return "flex-end";

    case "center":
      return "center";

    default:
      return direction === "xAlignment" && !alignment ? "flex-end" : "center";
  }
};

const SliderContent = props => {
  let customerLogo = get(props, `presentationData.customerLogo.location.url`);
  let { coBrandLogoData = {} } = props;

  return (
    <>
      {coBrandLogoData && (
        <CobrandLogoWrapper
          width={round(get(coBrandLogoData, `width`, 0), 2)}
          height={round(get(coBrandLogoData, `height`, 0), 2)}
          top={round(get(coBrandLogoData, `y`, 0), 2)}
          left={round(get(coBrandLogoData, `x`, 0), 2)}
          coBrandLogoData={coBrandLogoData}
        >
          <CobrandLogoImage src={customerLogo} />
        </CobrandLogoWrapper>
      )}
    </>
  );
};

const CobrandLogoWrapper = styled.div`
  width: ${props => props.width}%;
  height: ${props => props.height}%;
  position: absolute;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  display: flex;
  align-items: ${props =>
    getAlignment(
      toLower(get(props, `coBrandLogoData.yAlignment`)),
      "yAlignment"
    )};
  justify-content: ${props =>
    getAlignment(
      toLower(get(props, `coBrandLogoData.xAlignment`)),
      "xAlignment"
    )};
  flex-wrap: wrap;
`;

const CobrandLogoImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
`;

export default SliderContent;
