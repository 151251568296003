import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";

//import System Configuration actions
import {
  onSystemConfigurationDataSuccess,
  isSystemConfigurationDataLoading
} from "./actions";

/**
 * Get System Configuration Data List
 */
const getSystemConfiguration = () => async dispatch => {
  const URL = `/system-configuration`;

  // start loading
  dispatch(
    isSystemConfigurationDataLoading({ isSystemConfigurationDataLoading: true })
  );
  const response = await FetchUtils.getData(URL);
  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onSystemConfigurationDataSuccess({
        systemConfigurationData: response.data
      })
    );
    // stop loading
    dispatch(
      isSystemConfigurationDataLoading({
        isSystemConfigurationDataLoading: false
      })
    );
  } else {
    // stop loading
    dispatch(
      isSystemConfigurationDataLoading({
        isSystemConfigurationDataLoading: false
      })
    );
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const saveSystemConfiguration = (body, id) => async dispatch => {
  const URL = `/system-configuration/${id}`;

  // start loading
  dispatch(
    isSystemConfigurationDataLoading({ isSystemConfigurationDataLoading: true })
  );
  const response = await FetchUtils.patchData(
    URL,
    body,
    "Save System Configuration Data ==>"
  );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onSystemConfigurationDataSuccess({
        systemConfigurationData: response.data
      })
    );
    // stop loading
    dispatch(
      isSystemConfigurationDataLoading({
        isSystemConfigurationDataLoading: false
      })
    );

    ToastUtils.handleToast({
      operation: "success",
      message: "Changes has been saved successfully"
    });
  } else {
    // stop loading
    dispatch(
      isSystemConfigurationDataLoading({
        isSystemConfigurationDataLoading: false
      })
    );
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

export { getSystemConfiguration, saveSystemConfiguration };
