import * as types from "./types";

/**
 *
 * Store/Reset/Remove data dynamically based on .
 * @param {Object} data - API response on success/loading
 * @param {String} type - type for sucess/error/loading
 */

export const contentSlideListSuccess = (
  payload = {},
  type = types.CONTENT_SLIDE_SUCCESS
) => {
  return {
    type,
    payload
  };
};

export const contentRepoSlideUpdateSuccess = (
  payload = {},
  type = types.CONTENT_REPO_SLIDE__SUCCESS
) => {
  return {
    type,
    payload
  };
};

export const contentRepoSlideLoading = (
  payload = {},
  type = types.LOADING_CONTENT_REPO_SLIDE
) => {
  return {
    type,
    payload
  };
};

export const isSlideUploading = (
  payload = {},
  type = types.UPLOADING_SLIDE
) => {
  return {
    type,
    payload
  };
};

export const getThemeData = (payload = {}, type = types.GET_THEME_DATA) => {
  return {
    type,
    payload
  };
};

export const filtersListSuccess = (
  payload = {},
  type = types.FILTERS_LIST_SUCCESS
) => {
  return {
    type,
    payload
  };
};

export const filtersListLoading = (
  payload = {},
  type = types.FILTERS_LIST_LOADING
) => {
  return {
    type,
    payload
  };
};

export const saveContentCategoryLoading = (
  payload = {},
  type = types.CONTENT_FILTER_LOADING
) => {
  return {
    type,
    payload
  };
};

export const contentFilterUpdateSuccess = (
  payload = {},
  type = types.CONTENT_FILTER_UPDATE_SUCCESS
) => {
  return {
    type,
    payload
  };
};
