import React, { Component } from "react";

const container = Main =>
  class Container extends Component {
    constructor(props) {
      super(props);
      this.state = {
        imageFolderData: []
      };
    }

    componentDidMount() {
      this.setState({
        imageFolderData: this.props.imageFolder || []
      });
    }

    componentDidUpdate(prevProps) {
      if (prevProps.imageFolder !== this.props.imageFolder) {
        this.setState({
          imageFolderData: this.props.imageFolder || []
        });
      }
    }

    // treedata change
    handleTreeChange = treeData => {
      this.setState({
        imageFolderData: treeData
      });
    };

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      };
      return <Main {...stateMethodProps} />;
    }
  };

export default container;
