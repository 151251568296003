import React from "react";
import Styled from "styled-components";

import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";

import CustomTable from "components/customTable";
import Loader from "components/loader";
import bgWrapper from "components/bgWrapper";
import RepoDetail from "./components/detail";
import Container from "./container";

import FullPageLoader from "components/FullPageLoader";

const Dashboard = props => {
  return (
    <>
      {props.isLoading && <FullPageLoader layerIndex={21} />}
      <LandingPageWrapper className="clearfix">
        <LandingPageLeftPanel>
          {props.isRenderTable && !props.hideTableOnResize ? (
            <CustomTable
              data={props.contentRepoList}
              tableHeader={props.landingPageColumn}
              columnWidth={props.landingPageColumnWidth}
              searchFields={props.landingPageSearchFields}
              searchFieldName="name"
              tableColumnHeader={props.landingPageColumnHeader}
              heading="Content Repository"
              renderHead={props.renderHead}
              isContentRepo={true}
              showIcon={props.showIcon}
              expanderWidth="18"
              _newButton
              defaultSorted={[
                {
                  id: "name",
                  desc: false
                }
              ]}
              isShowSearchBox={true}
              renderTableCell={props.renderTableCell}
            />
          ) : !props.isLoading ? (
            <Loader />
          ) : null}
        </LandingPageLeftPanel>
        <LandingPageRightPanel>
          <AddButtonWrapper className="clearfix">
            <AddButton title="Add Repo" onClick={props.onClickAddRepo}>
              Add Repo
            </AddButton>
          </AddButtonWrapper>
          <RepoDetailWrapper>
            <RepoDetail {...props} />
          </RepoDetailWrapper>
        </LandingPageRightPanel>
      </LandingPageWrapper>
    </>
  );
};

const LandingPageWrapper = Styled.div`
  padding: 0 40px;
  .ReactTable .rt-th {
    white-space: nowrap;
    padding: 22px 0 19px !important;
  }
  .sales-radio-btn {
    display: none;
  }
  .table-search {
    margin-top: 10px;
  }
`;

const LandingPageLeftPanel = Styled.div`
  width: 68.8%;
  display: inline-block;
  @media (min-width: 1164px) {
    width: 820px;
  }
  @media (min-width: 1039px) and (max-width: 1164px) {
    width: 752px;
  }
`;

const LandingPageRightPanel = Styled.div`
  width: calc(31% - 9px);
  float: right;
  @media (min-width: 1164px) {
    width: calc(100% - 850px);
  }
  @media (min-width: 1039px) and (max-width: 1164px) {
    width: calc(100% - 770px);
  }
`;

const AddButton = Styled.button`
  ${props => props.theme.SNIPPETS.SHARED_BUTTON_STYLE}
  float: right;
  background: ${props => props.theme.COLOR.USER_PRIMARY};
  margin-left: 20px;
  width: 160px;
  height: 46px;
  &:hover {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
    background: ${props => props.theme.COLOR.WHITE};
    border: 1px solid ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const AddButtonWrapper = Styled.div`
  margin: 40px 0 54px;
  height: 46px;
`;

const RepoDetailWrapper = Styled.div`
  margin-top: 125px;
`;

export default Container(bgWrapper(Dashboard));
