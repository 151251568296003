import React, { Component } from "react";
import get from "lodash/get";
import ValidationUtils from "utils/ValidationUtils";
import { connect } from "react-redux";
import {
  updateCustomerLogoTitle,
  getCustomerLogoList
} from "tools/presentation/pages/build/services/setupCustomerLogoList";

const UI_STRINGS = {
  EMPTY_FIELD_ERROR_MESSAGE: "This field is required.",
  SPECIAL_CHAR_ERROR_MESSAGE: "Please do not enter the special character.",
  WHITE_SPACE_ERROR_MESSAGE: "Please enter a valid input."
};

const mapDispatchToProps = {
  updateCustomerLogoTitle,
  getCustomerLogoList
};

const Container = Main =>
  connect(
    null,
    mapDispatchToProps
  )(
    class EditableInput extends Component {
      state = {
        isLogoTitleEditable: false,
        titleError: ""
      };

      logoTitleRef = React.createRef();

      componentDidUpdate(prevProps) {
        if (get(prevProps, `item.title`) !== get(this.props, `item.title`)) {
          this.logoTitleRef.current.value = get(this.props, `item.title`);
        }
      }

      editLogoTitleHandler = () => {
        let { isAnyLogoEdited } = this.props;
        // check if any other logo is being edited currently
        if (isAnyLogoEdited) return;

        // set isAnyLogoEdited so that any other logo can't be edited
        this.props.setLogoEditedFlag(true);
        this.setState(
          {
            isLogoTitleEditable: !this.state.isLogoTitleEditable
          },
          () => {
            get(this.logoTitleRef, `current`) &&
              this.logoTitleRef.current.focus();
          }
        );
      };

      // validation handler
      handleTitleValidation = () => {
        let titleValue = get(this.logoTitleRef, `current.value`);
        let { titleError } = this.state;

        if (ValidationUtils.checkIfEmptyField(titleValue)) {
          titleError = UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfspecialChar(titleValue)) {
          titleError = UI_STRINGS.SPECIAL_CHAR_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfWhiteSpace(titleValue)) {
          titleError = UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
        } else {
          titleError = null;
        }

        this.setState({
          titleError
        });
      };

      onSaveHandler = async () => {
        let { titleError } = this.state;
        let { item, userDetail } = this.props;
        this.props.setLogoEditedFlag(false);
        // do not save if there is an error or logo id or customer id is not present
        if (titleError || !get(item, `_id`) || !get(userDetail, `_id`)) return;

        // if title is not changed, do not saved
        if (get(item, `title`) === get(this.logoTitleRef, `current.value`)) {
          this.setState({
            isLogoTitleEditable: false
          });
          return;
        }

        await this.props.updateCustomerLogoTitle(
          get(userDetail, `_id`),
          get(item, `_id`),
          { title: this.logoTitleRef.current.value }
        );
        await this.props.getCustomerLogoList(get(userDetail, `_id`));

        this.setState({
          isLogoTitleEditable: false
        });
      };

      onResetHandler = () => {
        let { item } = this.props;

        // reset title
        this.logoTitleRef.current.value = get(item, `title`);
        this.props.setLogoEditedFlag(false);
        this.setState({
          isLogoTitleEditable: false,
          titleError: ""
        });
      };

      render() {
        const $this = this;

        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props
        };
        return <Main {...stateMethodProps} />;
      }
    }
  );

export default Container;
