import React from 'react'
import styled from 'styled-components'

//Components
import Logos from './components/logos'
import CobrandLogos from './components/logos'

const MainSection = ({ config, ...props }) => {
  return (
    <>
      <SectionTitle>Logos</SectionTitle>
      <Logos {...config.logos} {...props} />
      <CobrandLogos {...config.cobrand} {...props} />
    </>
  )
}

const SectionTitle = styled.h4`
  padding: 25px 30px 27px;
  border-radius: 3px;
  ${props => props.theme.SNIPPETS.BOX_SHADOW_PRESENTATION}
`

export default MainSection
