//import network services
import {
  getAnnouncementData,
  fetchSingleAnnouncement,
  saveAnnouncementData,
  deleteAnnouncementData
} from "./services/announcement";

import {
  getSystemConfiguration,
  saveSystemConfiguration
} from "./services/systemConfiguration";

import {
  getSearchPriorities,
  saveSearchPrioritiesData
} from "./services/searchPriorities";

import { getClientConfig } from "services/clientConfig";

const mapStateToProps = state => {
  const {
    LOADING_ANNOUNCEMENT_DATA,
    SUCCESS_ANNOUNCEMENT_DATA,
    SUCCESS_SYSTEM_CONFIGURATION_DATA,
    LOADING_SYSTEM_CONFIGURATION_DATA,
    SUCCESS_SEARCH_PRIORITIES,
    LOADING_SEARCH_PRIORITIES,
    CLIENT_CONFIG_SUCCESS,
    CLIENT_CONFIG_LOADING
  } = state;

  return {
    ...LOADING_ANNOUNCEMENT_DATA,
    ...SUCCESS_ANNOUNCEMENT_DATA,
    ...SUCCESS_SYSTEM_CONFIGURATION_DATA,
    ...LOADING_SYSTEM_CONFIGURATION_DATA,
    ...SUCCESS_SEARCH_PRIORITIES,
    ...LOADING_SEARCH_PRIORITIES,
    ...CLIENT_CONFIG_SUCCESS,
    ...CLIENT_CONFIG_LOADING
  };
};

const actions = {
  getAnnouncementData,
  fetchSingleAnnouncement,
  saveAnnouncementData,
  deleteAnnouncementData,
  getSystemConfiguration,
  saveSystemConfiguration,
  getSearchPriorities,
  saveSearchPrioritiesData,
  getClientConfig
};

export { mapStateToProps, actions };
