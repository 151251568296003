import React, { Component, Fragment } from "react";
import Checkbox from "../Checkbox";
import { each, map } from "lodash";
class CollapsibleList extends Component {
  state = {
    isOpen: false
  };

  handleCheckbox = (item, list) => e => {
    let { checked } = e.target;
    let { selectedImageData, onClick } = this.props;

    (selectedImageData || {}).folderParent &&
      onClick({ ...item, checked }, list);
  };

  render() {
    let { list, selectedCategory, selectedImageData } = this.props;
    let flag = true;
    let Option = map(list, item => {
      if (item.title === "All") {
        each(list, elem => {
          if (elem.title !== "All" && selectedCategory.indexOf(elem._id) < 0)
            flag = false;
        });
      }
      return (
        <Checkbox
          key={item.key}
          label={item.title}
          handleChange={this.handleCheckbox(item, list)}
          isChecked={
            item.title === "All"
              ? flag
              : selectedCategory.indexOf(item._id) > -1
          }
          selectedImageData={selectedImageData}
        />
      );
    });

    return <Fragment>{Option}</Fragment>;
  }
}

export default CollapsibleList;
