import React from "react";
import BgWrapper from "components/bgWrapper";
import CustomTable from "components/customTable";
import Container from "./container";
import styled from "styled-components";
import PresentationShare from "components/presentationShareForm";
import Loader from "components/loader";
import PresentationDownload from "components/presentationDownloadForm";

const Presentation = props => {
  return (
    <>
      {props.isShowOverlay && <PresentationShare {...props} showRadioBtn />}
      {props.isPopShow && <PresentationDownload {...props} />}
      {props.isRenderTable ? (
        <PageWrapper>
          <CustomTable
            data={props.presentationData || []}
            tableHeader={props.state.cols}
            columnWidth={props.columnWidth}
            tableColumnHeader={props.tableColumnHeader}
            heading="Presentation History"
            renderHead={props.renderHead}
            expanderWidth="38"
            defaultSorted={[
              {
                id: "createdAt",
                desc: true
              }
            ]}
            _newButton
            _searchBox
            role="admin" // eslint-disable-line jsx-a11y/aria-role
            showIcon={props.showIcon}
          />
        </PageWrapper>
      ) : (
        <Loader />
      )}
    </>
  );
};

const PageWrapper = styled.div`
  position: relative;

  .ReactTable .rt-tbody .rt-td:last-child {
    position: relative;
    overflow: visible;
    bottom: 4px;
  }

  .ReactTable .rt-tbody .rt-tr-group {
    padding: 15.5px 0px 2.5px;
  }

  .ReactTable {
    margin-top: 20px;
  }

  .ReactTable .rt-th {
    padding: 22px 0 19px !important;
  }

  .active {
    display: block;
  }
`;

export default Container(BgWrapper(Presentation));
