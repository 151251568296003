import React, { Component } from "react";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import styled from "styled-components";
import { map, includes } from "lodash";
import CategoryTitleComponent from "./categoryTitle";

export default class Tree extends Component {
  constructor(props) {
    super(props);
    //ref
    this.selectedParent = [];
  }

  // if snapshot is not null update container height
  componentDidUpdate(prevProps) {
    if (prevProps.treeData !== this.props.treeData) {
      this.props.setCategoryContainerHeight();
      this.props.nodeMoveToggle(true);
    }
  }

  render() {
    let checkFilterLength =
      this.props.treeData.length === 1 &&
      !this.props.treeData[0].children.length
        ? "no-drag-drop"
        : null;

    //keep expanded parent when selected to hide/unhide/delete/edit
    let treeData = map(this.props.treeData, elem => {
      elem.expanded = false;

      if (
        Array.isArray(this.selectedParent) &&
        includes(this.selectedParent, elem._id)
      ) {
        elem.expanded = true;
      }

      return elem;
    });

    return (
      <CategoryLevelContainer
        ref={this.props.categoryLevelContainerRef}
        innerHeight={this.props.categoryContainerHeight}
      >
        <Container className="sort-container" style={{}}>
          <SortableTree
            className={`sortable-wrapper ${checkFilterLength}`}
            treeData={treeData}
            onChange={this.props.handleTreeOnChange}
            maxDepth={2}
            onDragStateChanged={this.props.onDragStateChanged}
            canDrop={true}
            slideRegionSize={200}
            onMoveNode={this.props.onMoveNode}
            onVisibilityToggle={({ node, expanded }) => {
              // check elements for multiple items toggled together
              let selectedParentIsArray = Array.isArray(this.selectedParent);
              let nodeIndex = selectedParentIsArray
                ? this.selectedParent.indexOf(node._id)
                : -1;

              if (expanded && selectedParentIsArray && nodeIndex === -1) {
                this.selectedParent.push(node._id);
              } else if (nodeIndex > -1 && selectedParentIsArray) {
                this.selectedParent.splice(nodeIndex, 1);
              }
            }}
            generateNodeProps={rowInfo => {
              const {
                node,
                node: { parent }
              } = rowInfo;

              return {
                style: {
                  marginRight: parent ? `44px` : 0
                },
                title: (
                  <CategoryTitleComponent
                    {...node}
                    setResetCategory={this.props.setResetCategory}
                    categoryIdToEdit={this.props.categoryIdToEdit}
                    showEditDeleteRevert={this.props.showEditDeleteRevert}
                    onSave={this.props.onSave}
                    parent={parent ? parent : null}
                    updateContentFilter={this.props.updateContentFilter}
                  />
                ),
                buttons:
                  (this.props.showEditDelete !== node._id &&
                    this.props.generateButtonNodeList &&
                    this.props.generateButtonNodeList(rowInfo)) ||
                  []
              };
            }}
          />
        </Container>
      </CategoryLevelContainer>
    );
  }
}

const CategoryLevelContainer = styled.div`
  width: 100%;
  display: inline-block;
  border-radius: 3px;
  font-family: ${props => props.theme.FONT.REG};
  height: 563px;
  position: relative;
  overflow-y: auto;

  .no-drag-drop .rst__lineHalfHorizontalRight {
    display: none;
  }

  .rst__rowLabel {
    max-width: 488px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1024px) {
      max-width: 260px;
    }
  }

  .rst__rowSearchMatch,
  .rst__rowSearchFocus {
    outline: solid 2px transparent;
  }

  .search-active {
    outline: solid 2px ${props => props.theme.COLOR.DODGER_BLUE};
  }

  .rst__nodeContent {
    width: 90%;
  }

  .rst__virtualScrollOverride {
    overflow-x: hidden !important;
  }

  .rst__highlightLineVertical::before {
    width: 1px;
    height: 100%;
    border: none !important;
    margin-left: 0;
    background-color: #000;
  }
  .rst__highlightLineVertical::after,
  .rst__highlightBottomLeftCorner::after {
    border: none !important;
  }
  .rst__highlightTopLeftCorner::before {
    width: 50%;
    border-top: solid 1px #000;
    border-left: solid 1px #000;
    margin-top: 0;
  }
  .rst__highlightBottomLeftCorner::before {
    border-bottom: none;
    border-left: solid 1px #000;
    height: calc(100% + 10px);
    right: 12px;
    width: calc(50% - 12px);
  }
  .rst__expandButton,
  .rst__collapseButton {
    z-index: 10;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 563px;
  padding-bottom: 30px;
`;
