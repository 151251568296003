//import network services
import {
  getContentRepoList,
  createAndEditContentRepo,
  cloneContentRepo,
  deleteContentRepo
} from "./services";

const mapStateToProps = state => {
  const { SUCCESS_CONTENT_REPO_LIST, LOADING_CONTENT_REPO_LIST } = state;
  return {
    ...SUCCESS_CONTENT_REPO_LIST,
    ...LOADING_CONTENT_REPO_LIST
  };
};

const actions = {
  getContentRepoList,
  createAndEditContentRepo,
  cloneContentRepo,
  deleteContentRepo
};

export { mapStateToProps, actions };
