let featureFlags = {
  accessibleTools: ["renewal", "presentation"],
  renewal: {
    allowCobrandLogoUpload: false
  },
  presentation: {
    hideInteractivity: false,
    showBreadCrumbCheckBox: false,
    showPresentationFilter: true,
    showFilter: true,
    showAdminLayouts: false,
    showImageCategories: true,
    showCustomerLogo: true,
    slideDataAvailable: false,
    hideSecondaryColor: true
  },
  marketingMaterials: {
    allowClientLogoUpload: false,
    showDocumentTitle: false,
    showDownloadDeck: false,
    showContentMasterSideBar: false,
    showUserBulkUpload: false
  }
};

export default featureFlags;
