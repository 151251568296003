import React, { Component } from "react";
import { get, find } from "lodash";

const Container = Main =>
  class Presentation extends Component {
    state = {
      showPreview: false,
      overlayCoverImage: null,
      doesCobrandLogoExist: false
    };

    componentDidMount() {
      let {
        contentRepo: { _id: contentRepoId }
      } = this.props;

      // selected theme _id from presentation response
      let selectedTheme =
        this.props.presentationData &&
        get(this.props.presentationData, "theme");

      // save theme data if previously selected theme is been disabled by admin
      if (
        !find(this.props.themeList, {
          _id: selectedTheme
        }) &&
        get(this.props, "presentationData.contentRepository") === contentRepoId
      ) {
        this.props.onStepEdit();
      }

      this.checkIfCobrandLogoExits();
    }

    checkIfCobrandLogoExits = () => {
      let themeList = this.props.themeList || [];

      for (let index = 0; index < themeList.length; index++) {
        if (themeList[index].isCobrandLogo) {
          this.setState({
            doesCobrandLogoExist: true
          });
          return;
        }
      }
    };

    handleCoverPreview = (imgSrc = "") => {
      this.setState({
        showPreview: !this.state.showPreview,
        overlayCoverImage: imgSrc
      });
    };

    render() {
      const $this = this;

      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      };
      return <Main {...stateMethodProps} />;
    }
  };
export default Container;
