import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";
import clientConfigDetails from "config";

// import actions
import { onGetClientConfig, isUserLoginLoading } from "./action";

// Get client based data
let clientConfig = clientConfigDetails[process.env.REACT_APP_CLIENT] || {};

const getClientConfig = (isLandingPage = false) => async dispatch => {
  if (
    process.env.REACT_APP_CLIENT &&
    get(clientConfig, "getClientConfig", false)
  ) {
    //dispatch datalist on success
    dispatch(
      onGetClientConfig({
        clientConfig: clientConfig || {}
      })
    );
    return;
  }

  const URL = `/info${isLandingPage ? "?isLandingPage=true" : ""}`;

  // start loading
  dispatch(isUserLoginLoading({ isClientConfigLoading: true }));

  const response = await FetchUtils.getData(URL, "get client detail");

  //set client config details untill it is configured from Technical Admin/Syystem Admin
  let clientConfigDetails = {};
  if (clientConfig) {
    clientConfigDetails = {
      ...(clientConfig || {}),
      ...get(response, "data", {})
    };
  }
  // stop loading
  dispatch(isUserLoginLoading({ isClientConfigLoading: false }));

  if (response.success && response.data) {
    //dispatch datalist on success
    dispatch(
      onGetClientConfig({
        clientConfig: clientConfigDetails
      })
    );
  } else {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
    return response;
  }
};

export { getClientConfig };
