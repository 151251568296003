import React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";

import hexToRgba from "utils/hexToRgba";
import {
  Attach,
  Dropdown as DropdownIcon,
  Preview,
  PlusIcon
} from "assets/icons";
import { FaFileImage } from "react-icons/fa";
import { default as ReactSelect } from "react-select";
/* Manage Label Input on edit */
export const EnableLabelNameOnEdit = ({ title, handleInputChange }) => {
  return (
    <LabelNameInputContainer>
      <LabelNameInput
        autoFocus={true}
        value={title}
        onChange={e =>
          handleInputChange({
            slideValue: e.target.value,
            slideLabel: "name"
          })
        }
      />
    </LabelNameInputContainer>
  );
};

const LabelNameInputContainer = styled.div`
  margin-top: 3px;
  position: relative;
`;

const LabelNameInput = styled.input`
  width: 100%;
  height: 30px;
  padding: 2px 10px;
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.4);
  color: ${props => props.theme.COLOR.MAIN};
  font-family: ${props => props.theme.FONT.LATO};
  transition: 1s border-bottom ease;
  font-size: 12px;
  font-weight: 700;
  outline: none;
`;

//END

/**
 * Parent Slide Container Box
 */
export const SlideDropDown = ({
  parentSlide,
  elemId,
  option,
  selectedValue,
  onDropDownChange
}) => {
  return (
    <ParentSlideComponent parentSlide={parentSlide}>
      <AngleDown htmlFor={elemId}>
        <DropdownIcon />
      </AngleDown>
      <DropDown
        autoFocus={true}
        id={`${elemId}`}
        onChange={onDropDownChange}
        className="category-select"
      >
        {_.map(option, (item, key) => {
          let itemKey = item instanceof Object ? _.get(item, "_id") : item;
          let itemValue = item instanceof Object ? _.get(item, "title") : item;

          return (
            <DropDownOption
              value={key === 0 ? "" : itemKey}
              className="select-options"
              key={itemKey + key}
              selected={itemKey === selectedValue}
            >
              {itemValue}
            </DropDownOption>
          );
        })}
      </DropDown>
    </ParentSlideComponent>
  );
};

SlideDropDown.defaultProps = {
  parentSlide: false,
  elemId: "parent",
  option: [{ value: "Select Parent", _id: 0 }]
};

const AngleDown = styled.label`
  position: absolute;
  padding-top: 5px;
  right: 0;
  width: 31px;
  height: 20px;
  top: 0;
  z-index: -1;
  height: 30px;
  background: ${props => props.theme.COLOR_PALLETE.SOLITUDE};
`;

const ParentSlideComponent = styled.div`
  margin-top: ${props => (props.parentSlide && "8px") || "7px"};
  position: relative;
  z-index: 10;
  overflow: hidden;
  border-radius: 4px;
  background: ${props => props.theme.COLOR_PALLETE.SOLITUDE};
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
`;

const DropDown = styled.select`
  width: 100%;
  height: 30px;
  padding: 0 9px;
  border: none;
  border-radius: 4px;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};
  font-family: ${props => props.theme.FONT.REG};
  background: transparent;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  line-height: 30px;
  cursor: pointer;
  outline: none;
  padding-right: 40px;
`;

const DropDownOption = styled.option``;

//END

/**
 * Slide Description Container
 */

export const DescriptionContainer = ({ limit, value, handleInputChange }) => {
  return (
    <DescriptionWrapper>
      <DescriptionInput
        maxLength={limit}
        onChange={e =>
          handleInputChange({
            slideValue: e.target.value,
            slideLabel: "description"
          })
        }
        value={value}
      />
      <DisplayLimit
        className={value && value.length > limit ? "error-red" : ""}
      >
        Max {limit} characters
      </DisplayLimit>
    </DescriptionWrapper>
  );
};

const DescriptionWrapper = styled.div`
  margin-top: 7px;
`;

const DescriptionInput = styled.textarea`
  width: 100%;
  padding: 2px 10px;
  border: none;
  outline: none;
  resize: none;
  height: 80px;
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.4);
  font-family: ${props => props.theme.FONT.LATO};
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.8)};
`;

const DisplayLimit = styled.span`
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};

  &.error-red {
    color: ${props => props.theme.COLOR_PALLETE.ERROR};
  }
`;

//END

/**
 * Upload Slide Container
 *
 */
export const UploadSlideContainer = ({
  sizeInMb,
  onLayoutUpload,
  layoutLabel,
  slideFormValidations,
  handleUploadClick,
  removeFile
}) => {
  let inputRef = React.createRef();
  let isUploaded = !!_.get(slideFormValidations, "upload.value.name");
  return (
    <UploadSlideWrapper>
      <InlineWrapper>
        <UploadedSlideFileName
          onClick={e => {
            handleUploadClick && handleUploadClick(e, inputRef);
          }}
          isUploaded={isUploaded}
        >
          {slideFormValidations.upload.value.name}
        </UploadedSlideFileName>
        <UploadFileInputWrapper>
          <UploadFileInput
            id={layoutLabel}
            type={"file"}
            title="Attach"
            data-name={layoutLabel}
            onChange={e => {
              onLayoutUpload(e, e.target.getAttribute("id"));
            }}
            accept=".ppt, .pptx"
            ref={inputRef}
          />
          <UploadButton>
            <AttachIcon />
          </UploadButton>
        </UploadFileInputWrapper>
        {isUploaded && (
          <PlusIconWrap title="Remove" onClick={() => removeFile(inputRef)}>
            <PlusIcon />
          </PlusIconWrap>
        )}
      </InlineWrapper>
      <DisplayLimit>Size: {sizeInMb}</DisplayLimit>
    </UploadSlideWrapper>
  );
};

const InlineWrapper = styled.div``;

UploadSlideContainer.defaultProps = {
  sizeInMb: "20 mb"
};

const UploadSlideWrapper = styled.div`
  padding-top: 7px;
  cursor: pointer;
`;

const UploadedSlideFileName = styled.div`
  width: ${props =>
    props.isUploaded ? `calc(100% - 66px)` : `calc(100% - 35px)`};
  height: 30px;
  padding: 2px 10px;
  outline: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.4);
  display: inline-block;
  vertical-align: top;
  font-family: ${props => props.theme.FONT.LATO};
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.8)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UploadFileInput = styled.input`
  width: 30px;
  height: 30px;
  padding-left: 30px;
  vertical-align: top;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

const UploadFileInputWrapper = styled.div`
  width: 30px;
  display: inline-block;
  vertical-align: top;
  position: relative;
`;

const UploadButton = styled.span`
  width: 30px;
  height: 30px;
  padding: 7px 0;
  display: inline-block;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: ${props => props.theme.COLOR.USER_PRIMARY};
  text-align: center;
`;

const AttachIcon = styled(Attach)`
  width: 13px;
  height: 13px;
  cursor: pointer;
`;

const PlusIconWrap = styled.span`
  width: 30px;
  height: 30px;
  padding: 11px 0 3px 5px;
  border-radius: 4px;
  margin-left: 6px;
  text-align: center;
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  cursor: pointer;

  svg {
    transform: rotate(-42deg) translate(-4px, 1px);
    width: 24px;
    height: 24px;
    path {
      fill: ${props => props.theme.COLOR.WHITE};
    }
  }
`;

// END
const MultiSelect = props => {
  return (
    <ReactSelect
      className="custom-select"
      classNamePrefix="custom-select"
      {...props}
    />
  );
};

export const FiltersContainer = props => {
  let {
    filterContent,
    handleFilterChange,
    selectedOption,
    onMenuOpen,
    onMenuClose
  } = props;

  return (
    <FilterContentList>
      {Array.isArray(filterContent) &&
        filterContent.map(({ _id, label, options }) => {
          return (
            <LabelGroup key={_id}>
              {label && <Label>{label}</Label>}
              <MultiSelect
                placeholder={"Select an option"}
                options={options}
                value={selectedOption[_id] || null}
                onChange={selected => handleFilterChange(selected, _id)}
                isClearable
                isMulti
                closeMenuOnSelect={false}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
              />
            </LabelGroup>
          );
        })}
    </FilterContentList>
  );
};

const LabelGroup = styled.div`
  margin-bottom: 10px;
`;

const LabelCSS = css`
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.64)};
  margin-bottom: 4px;
  display: inline-block;
`;

const Label = styled.span`
  ${LabelCSS}
`;

const FilterContentList = styled.ul`
  margin-top: 4px;
`;

// END
export const ListedTheme = ({ themeListData = [], handleFile }) => {
  let themeItems = _.map(
    themeListData,
    ({ themeId, slideThemeName, fileLocation, thumbnailLocation }, index) => (
      <ListItem key={_.get(themeId, "_id") + index}>
        <>
          <StyledImageIcon />
          <ItemTitle
            title={slideThemeName}
            onClick={() => handleFile(fileLocation, "download")}
          >
            {slideThemeName}
          </ItemTitle>
        </>
        <PreviewIconWrapper
          onClick={() => handleFile(thumbnailLocation, "view")}
        >
          <Preview />
        </PreviewIconWrapper>
      </ListItem>
    )
  );

  return <ThemeList>{themeItems}</ThemeList>;
};

const StyledImageIcon = styled(FaFileImage)`
  padding-right: 5px;
  transform: translateY(2px);
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};
`;

const ListItem = styled.div`
  margin-top: 12px;
  padding: 0 35px 0 5px;
  position: relative;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PreviewIconWrapper = styled.span`
  position: absolute;
  opacity: 0.8;
  top: 2px;
  right: 5px;
  cursor: pointer;
`;

const ItemTitle = styled.span`
  width: calc(100% - 26px);
  vertical-align: bottom;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => hexToRgba(props.theme.COLOR.USER_PRIMARY, 1)};
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ThemeList = styled.ul`
  margin-top: 4px;
  padding-bottom: 10px;
`;
