import React, { PureComponent } from "react";

const Container = Main =>
  class SideBar extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {};
    }

    componentDidUpdate(prevProps) {
      if (
        prevProps.showModuleDetails !== this.props.showModuleDetails &&
        this.props.showModuleDetails
      ) {
        this.props.fetchCategorList();
      }
    }

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      };

      return <Main {...stateMethodProps} />;
    }
  };

export default Container;
