import React, { Component } from "react";
import styled from "styled-components";
import { each, capitalize, includes, assign, isEmpty, get } from "lodash";

import { connect } from "react-redux";
import { mapStateToProps, actions } from "./mapStateToProps";
import { changeNodeAtPath, toggleExpandedForAll } from "react-sortable-tree";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import ValidationUtils from "utils/ValidationUtils";
import ToastUtils from "utils/handleToast";
import moment from "moment";

// polling utils to check if file is uploaded
import fullPagePolling from "utils/PollingUtils";
import halfPagePolling from "utils/PollingUtils";
import quadrantPolling from "utils/PollingUtils";

import { uploadFileToAws, AWSFileUploadStatus } from "services/awsUpload";

import { Delete, EditWithNoShadow, Show, Hidden } from "assets/icons";

const UI_STRINGS = {
  DELETE_LEVEL: "Do you really want to delete?"
};

const EMPTY_FIELD_ERROR_MESSAGE = "This field is required.";
const SPECIAL_CHAR_ERROR_MESSAGE = "Please do not enter the special character.";
const WHITE_SPACE_ERROR_MESSAGE = "Please enter a valid input.";

/** Icons Style */
const SlideCount = styled.span`
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  position: absolute;
  top: 50%;
  right: 176px;
  transform: translateY(-50%);
  color: #363636;
`;

const StyledIcon = styled.span`
  opacity: ${props => (props.enableLevel ? 1 : 0.5)};
  color: #000;
  cursor: pointer;
`;

const EnableIcon = styled.span`
  color: #000;
  cursor: pointer;
`;

const EditIcon = styled(EditWithNoShadow)`
  width: 17px;
  height: 17px;
`;

const DeleteIcon = styled(Delete)`
  width: 17px;
  height: 17px;
`;

const ShowIcon = styled(Show)`
  height: 17px;
`;

const SizeContainer = styled.div`
  margin-right: 30px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR.MAIN};
  font-size: 12px;
  font-weight: bold;
`;
const Size = styled.span`
  font-weight: normal;
  margin-left: 6px;
  text-transform: capitalize;
`;

const Container = Main =>
  connect(
    mapStateToProps,
    actions
  )(
    class Modules extends Component {
      constructor(props) {
        super(props);
        this.state = {
          treeData: [],
          moduleData: [
            {
              id: "name",
              type: "input",
              label: "Name*",
              value: "",
              error: null,
              isEdit: false
            },
            {
              id: "author",
              type: "input",
              label: "Author*",
              value: "",
              error: null,
              isEdit: false
            },
            {
              id: "desc",
              type: "textarea",
              label: "Description",
              value: "",
              error: null,
              maxChar: 250,
              isEdit: false
            },
            {
              id: "category",
              type: "dropdown",
              label: "Category*",
              value: "Choose category",
              selectedParentId: 1,
              options: [],
              error: null,
              isEdit: false
            },
            {
              id: "visible",
              type: "checkbox",
              label: "Make module visible",
              value: true,
              error: null,
              isEdit: false
            },
            {
              id: "fullpage",
              type: "file",
              label: "Full Page (17x11)",
              value: null,
              fileName: "",
              filePreview: "",
              error: null,
              hideField: false,
              isEdit: false
            },
            {
              id: "halfpage",
              type: "file",
              label: "Half Page (8.5x11)",
              value: null,
              fileName: "",
              filePreview: "",
              error: null,
              isEdit: false
            },
            {
              id: "quadrant",
              type: "file",
              label: "Quadrant",
              value: null,
              fileName: "",
              filePreview: "",
              error: null,
              isEdit: false
            }
          ],
          showModuleDetails: false,
          windowNode: {},
          toggleExpand: false,
          showWarningNote: false,
          moduleDetails: {
            fullPage: {},
            halfPage: {},
            quadrant: {}
          },
          moduleFileDetails: {
            fullPage: {},
            halfPage: {},
            quadrant: {}
          },
          isEditModuleActive: false
        };

        this.moduleUploadDetails = {
          fullpage: {
            success: true,
            message: ""
          },
          halfpage: {
            success: true,
            message: ""
          },
          quadrant: {
            success: true,
            message: ""
          }
        };

        this.moduleDetailsOnSaved = {};

        this.selectedParent = [];
        this.allParentList = [];
        this.disabledParentNote = `The selected parent is in disabled state. Creating a module will enable the selected parent. Please uncheck "Make module visible" in case you wish to keep the parent disabled.`;
      }

      componentDidMount() {
        this.setWindowNode();
        window.addEventListener("resize", this.setWindowNode);
        this._fetchModuleList();
      }

      // handle when node is moved for category and modules
      onMoveNode = async ({ treeData, nextParentNode, node, ...props }) => {
        //Get data body as per the API
        let reorderedData = [];
        if (
          nextParentNode &&
          Array.isArray(nextParentNode.children) &&
          nextParentNode.children.length
        ) {
          reorderedData =
            (Array.isArray(nextParentNode.children) &&
              nextParentNode.children.map(
                ({ _id, label, parent, category }) => {
                  return {
                    id: _id,
                    label: label === "module" ? "module" : "subcategory",
                    parent: label === "module" ? category : parent
                  };
                }
              )) ||
            [];
        } else {
          reorderedData = treeData.map(
            ({ _id, name, label, parent }, index) => {
              return {
                id: _id,
                label: "category",
                parent
              };
            }
          );
        }

        const payload = {
          children: reorderedData
        };

        let nextParentExists = reorderedData.filter(
          ({ parent }) => (nextParentNode && nextParentNode._id) !== parent
        );

        nextParentExists =
          !!!nextParentExists.length && !nextParentNode
            ? reorderedData.filter(({ parent }) => null !== parent)
            : nextParentExists;

        payload.children = payload.children.map(({ id, label }) => ({
          id,
          label
        }));

        //get next parent
        if (nextParentExists.length === 1) {
          (nextParentNode || {}).label !== "subcategory" &&
            this.updateLevel(treeData || []);
          payload.nextParent = (nextParentNode || {})._id || null;
        }

        //set nextParent for visibilty if it is hidden while reordering
        let nextParentId = (nextParentNode || {})._id;
        if (nextParentId && this.selectedParent.indexOf(nextParentId) < 0)
          this.selectedParent.push(nextParentId);

          if(props.prevTreeIndex!==props.nextTreeIndex){
            this.setState(
              {
                treeData
              },
              async () => {
                await this.props.reorderedCategoryList(payload);
                this._fetchModuleList();
              }
            );
          }
      };

      /**
       * Function to set window object for new values on resize
       */
      setWindowNode = () => {
        this.setState({
          windowNode: window
        });
      };

      // update node level to maintain levels width
      updateLevel = treeSet => {
        let recursiveFunc = (treeSet, level = 0) => {
          each(treeSet, category => {
            //set level for indentation
            category.level = level;
            if (Array.isArray(category.children) && category.children.length) {
              //check if modules exists in root level to last to display delete button
              category.moduleCategory =
                JSON.parse(
                  JSON.stringify(
                    category.children.filter(elem => elem.label === "module")
                  )
                ) || [];

              //calculate sub categories with modules
              let totalModulesInCategory =
                category.children.reduce((accumulator, subCategory) => {
                  if (
                    subCategory.label === "subcategory" &&
                    Array.isArray(subCategory.children)
                  )
                    accumulator.push(
                      ...(subCategory.children.filter(
                        (modules = {}) => modules.label === "module"
                      ) || [])
                    );
                  return accumulator;
                }, []) || [];

              category.totalModulesInCategory =
                category.moduleCategory.length + totalModulesInCategory.length;

              //set ids for expand and collapse actions
              this.allParentList.push(category._id);
              recursiveFunc(category.children, category.level + 1);
            }
          });
          return treeSet;
        };

        this.setState({
          treeData: recursiveFunc(treeSet)
        });
      };

      /**
       * Function to fetch module list
       */
      _fetchModuleList = async () => {
        await this.props.getModuleList();

        let treeData = JSON.parse(JSON.stringify(this.props.moduleList || []));
        this.updateLevel(treeData || []);

        this.setState({
          treeData
        });
      };

      /**
       * Function to trigger when drag start changes
       * @param {Boolean} whether it element is dragging or not
       */
      onDragStateChanged = ({ isDragging }) => {
        if (isDragging) {
          window.addEventListener("dragover", this.scrollWindow);
        } else {
          window.removeEventListener("dragover", this.scrollWindow);
        }
      };

      /**
       * Function to scroll window when dragged element reaches window height
       */
      scrollWindow = e => {
        const { windowNode } = this.state;
        let screenYValue = e.screenY;

        if (windowNode && screenYValue > windowNode.innerHeight) {
          windowNode.scrollTo({
            top: screenYValue,
            behavior: "smooth"
          });
        } else if (screenYValue <= 200) {
          windowNode.scrollTo({
            top: 180,
            behavior: "smooth"
          });
        }
      };

      /**
       * Function to generate actions for repo manager
       * @param {Object} the Object node of the element
       */
      generateButtonNodeList = rowInfo => {
        let {
          node,
          node: { enable, label, _id, createdAt, totalModulesInCategory }
        } = rowInfo;

        createdAt = new moment(createdAt).format("MM/DD/YYYY HH:mm");

        let message = `${capitalize(node.label)} has been successfully ${
          !enable ? "enabled" : "disabled"
        }.`;

        return [
          <SlideCount
            style={{
              opacity: enable ? 1 : 0.5
            }}
            className="created-date"
          >
            {createdAt || null}
          </SlideCount>,
          node.sizes && (
            <SizeContainer>
              Sizes:
              {node.sizes.map((item, index) => (
                <Size key={index}>{item}</Size>
              ))}
            </SizeContainer>
          ),
          (label === "module" || !totalModulesInCategory) && (
            <StyledIcon
              children={node.children}
              enableLevel={enable}
              title={enable ? "Delete" : ""}
            >
              <DeleteIcon
                size={15}
                onClick={() =>
                  this.deleteModuleCategoryHandler(_id, label, rowInfo)
                }
              />
            </StyledIcon>
          ),
          <StyledIcon
            enableLevel={enable}
            title={enable ? "Edit" : ""}
            onClick={e => this.editHandler(e, _id, label)}
          >
            <EditIcon size={15} />
          </StyledIcon>,
          <EnableIcon
            enableLevel={enable}
            title={enable ? "Enabled" : "Disabled"}
            label={label}
            onClick={() =>
              this.editedDetailsHandler(
                _id,
                { enable: !enable },
                message,
                label,
                rowInfo
              )
            }
          >
            {enable ? <ShowIcon size={15} /> : <Hidden size={15} />}
          </EnableIcon>
        ];
      };

      editedDetailsHandler = async (levelId, body, message, label, rowInfo) => {
        let response = await this.props.updateModule(
          levelId,
          body,
          message,
          label
        );

        if (response && response.success) {
          this._fetchModuleList();
        }
      };

      deleteModuleCategoryHandler = async (levelId, label, rowInfo) => {
        DeleteConfirmationAlert({
          message: UI_STRINGS.DELETE_LEVEL,
          onYesClick: async () => {
            await this.props.deleteModuleCategory(levelId, label);
            this._fetchModuleList();

            this._hideModuleDetails();
          },
          onNoClick: () => {}
        });
      };

      checkIfElementCanBeDropped = ({ node, nextParent = {}, prevParent }) => {
        // Return the data describing the dragged item
        const { label: DraggedItemLabel, children = [] } = node;

        if (
          (nextParent === null &&
            ((node || {}).label === "category" ||
              (node || {}).label === "subcategory")) ||
          //check for module
          (nextParent &&
            nextParent.label !== "module" &&
            ((nextParent && DraggedItemLabel === "module") ||
              (DraggedItemLabel !== "module" &&
                nextParent &&
                nextParent.label === "module") ||
              (!nextParent && DraggedItemLabel !== "module") ||
              (nextParent &&
                nextParent.label !== "subcategory" &&
                DraggedItemLabel === "subcategory") ||
              (nextParent &&
                nextParent.label === "category" &&
                DraggedItemLabel === "category" &&
                !(
                  children.filter(
                    (elem = {}) => elem.label === "subcategory"
                  ) || []
                ).length)))
        ) {
          return true;
        }

        return false;
      };

      /**
       * Function to show Module Details
       */
      _showModuleHandler = () => {
        this._resetDatahandler();
        this.setState({
          showModuleDetails: true,
          isEditModuleActive: false
        });
      };

      /**
       * Function to hide the module details
       */
      _hideModuleDetails = () => {
        this._resetDatahandler();
        this.setState({
          showModuleDetails: false,
          isEditModuleActive: false
        });
      };

      /**
       * Reset the state and field data
       */
      _resetDatahandler = () => {
        let { moduleData } = this.state;
        moduleData[5].hideField = false;
        moduleData.forEach(item => {
          item.error = null;
          item.isEdit = false;
          if (item.type === "input" || item.type === "textarea") {
            item.value = "";
          } else if (item.type === "checkbox") {
            item.value = true;
          } else if (item.type === "dropdown") {
            item.value = "Choose category";
          } else if (item.type === "file") {
            item.filePreview = "";
            item.fileName = "";
            item.error = null;
            item.value = null;
          }
        });

        this.moduleDetailsOnSaved = {};

        //empty file details
        this._emptyFileDetails(true);

        this.setState({
          moduleData
        });
      };

      editHandler = async (e, id, label) => {
        if (label === "module") {
          this._resetDatahandler();
          this._updateModuleDetails(id);
          window.scrollTo(0, 0);
        } else {
          this.props.history.push(
            `/marketing-materials/admin/categories/edit/${id}`
          );
        }
      };

      moduleClickHandler = (label, moduleId) => {
        if (label === "module") {
          this._resetDatahandler();
          this._updateModuleDetails(moduleId);
          this._scrollToBottom();
        }
      };

      _scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
      };

      _updateModuleDetails = async moduleId => {
        let specificModuleResponse = await this.props.fetchSpecificModule(
          moduleId
        );
        let specificModuleData = get(specificModuleResponse, "data");

        this.moduleDetailsOnSaved = specificModuleData || {};

        let {
          name,
          author,
          description,
          enable,
          category,
          moduleAssets
        } = specificModuleData;

        let { moduleData } = this.state;
        moduleData[0].value = name;
        moduleData[1].value = author;
        moduleData[2].value = description;
        moduleData[4].value = enable;

        !this.props.categoryListData && (await this._fetchCategorList());

        //empty the file details as they are optional and not all the value will be fetched
        this._emptyFileDetails(true);
        //reccursion across categogry list
        let checkCategoryList = optionList => {
          each(optionList, item => {
            if (item._id === category) {
              moduleData[3].value = item.name;
            }
            //check for children
            if (
              Array.isArray(item.subCategories) &&
              item.subCategories.length
            ) {
              checkCategoryList(item.subCategories);
            }
          });
        };

        checkCategoryList(this.props.categoryListData || []);

        this.checkIfParentIsDisable(moduleData[3].value);

        //update the file detais
        Array.isArray(moduleAssets) &&
          moduleAssets.forEach(item => {
            if (item.imageMatrix === "quadrant") {
              moduleData[7].filePreview = item.location;
              moduleData[7].fileName = item.fileName;
            } else if (item.imageMatrix === "halfpage") {
              moduleData[6].filePreview = item.location;
              moduleData[6].fileName = item.fileName;
            } else if (item.imageMatrix === "fullpage") {
              moduleData[5].filePreview = item.location;
              moduleData[5].fileName = item.fileName;
            }
          });

        this.setState({
          showModuleDetails: true,
          moduleData,
          isEditModuleActive: true
        });
      };

      /**
       * Change treeData at given path
       *
       * @param {Object} newNode changed data from response
       * @param {Object} rowInfo row information
       */
      changeNodeAtSelectedPath = (newNode, rowInfo) => {
        const { path } = rowInfo;

        this.setState(prevState => ({
          treeData: changeNodeAtPath({
            treeData: prevState.treeData,
            path, // You can use path from here
            newNode,
            getNodeKey: ({ node: TreeNode }) => {
              return TreeNode._id;
            }
          })
        }));
      };

      /**
       * Function to handle sortable tree on change
       * @param {Array} This will be array of objects which will have the data of the node
       */
      handleTreeOnChange = treeData => {
        this.setState({
          treeData
        });
      };

      /**
       * Function to handle the expanded and collapse state of the tree
       * @param {Boolean} Gives the boolean value whether or not state is to be expanded
       */
      toggleNodeExpansion = expanded => {
        this.selectedParent = expanded ? this.allParentList : [];
        this.setState(prevState => ({
          treeData: toggleExpandedForAll({
            treeData: prevState.treeData,
            expanded
          }),
          toggleExpand: !this.state.toggleExpand
        }));
      };

      //store parent on visibility toggle to retain on delete/reorder categories
      onVisibilityToggle = ({ node, expanded }) => {
        // check elements for multiple items toggled together
        let selectedParentIsArray = Array.isArray(this.selectedParent);
        let nodeIndex = selectedParentIsArray
          ? this.selectedParent.indexOf(node._id)
          : -1;

        if (expanded && selectedParentIsArray && nodeIndex === -1) {
          this.selectedParent.push(node._id);
        } else if (nodeIndex > -1 && selectedParentIsArray) {
          this.selectedParent.splice(nodeIndex, 1);
        }
      };

      /**
       * Fetch the category list
       */
      _fetchCategorList = async () => {
        await this.props.fetchCategoryList();
        let categoryData = this.props.categoryListData;
        let categoryOptions = [];

        //Update the Category and Sub category option
        let updateOptionList = (optionList, subCategoryNotation) => {
          each(optionList, item => {
            let itemName = subCategoryNotation
              ? `${subCategoryNotation}${item.name}`
              : item.name;
            categoryOptions.push({
              title: itemName,
              _id: item._id,
              enable: item.enable
            });

            if (
              Array.isArray(item.subCategories) &&
              item.subCategories.length
            ) {
              updateOptionList(item.subCategories, "--");
            }
          });
        };

        updateOptionList(categoryData);
        categoryOptions.unshift({
          title: "Choose category",
          _id: 1
        });

        //Update the option list
        let { moduleData } = this.state;
        moduleData[3].options = categoryOptions;
        this.setState({
          moduleData
        });
      };

      /**
       * Function to handle module dropdown change
       * @param {String} - id ID of the dropdown
       * @param {String} - selectedValue Value of the selected category
       */
      _handleInputChange = (id, value) => {
        let { moduleData } = this.state;
        moduleData.forEach(item => {
          //check if dropdown
          if (item.id === id && item.type === "dropdown") {
            //get enable/disable module value and then check for the conditionif the parent is enabled or disabled
            let enableValue = moduleData[4].value;
            item.error = null;
            this.checkIfParentIsDisable(value, enableValue);
            item.value = value;
            item.isEdit = true;
          }
          if (item.id === id && item.type === "checkbox") {
            item.value = value;
            item.isEdit = true;
          }
          if (
            item.id === id &&
            (item.type === "input" || item.type === "textarea")
          ) {
            item.isEdit = true;
            item.value = value;
            item.error =
              item.id !== "desc" ? this.checkValidation(value) : null;
          }
        });
        this.setState({
          moduleData
        });
      };

      /**
       * Function to hanlde the validation of the input field
       * @param {String} The value inputted by the user
       */
      checkValidation = value => {
        if (ValidationUtils.checkIfEmptyField(value)) {
          return EMPTY_FIELD_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfWhiteSpace(value)) {
          return WHITE_SPACE_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfspecialChar(value)) {
          return SPECIAL_CHAR_ERROR_MESSAGE;
        } else {
          return null;
        }
      };

      /**
       * Function to handle the file upload
       * @param {Object} - e : The event object
       * @param {String} - layoutName: The name of the layout on which the file is been uploaded
       */
      onLayoutUpload = (e, layoutName) => {
        let file = e.target && e.target.files[0];

        let fileName = e.target.files[0] && e.target.files[0].name;
        if (/\.(jpe?g|png|pptx|ppt)$/i.test(fileName)) {
          const fileNameExtension = fileName.split(".").reverse()[0];
          let fileType = null;
          fileType = includes(["ppt", "pptx"], fileNameExtension)
            ? "ppt"
            : "image";

          let fileToBeSent = assign(file, {
            preview: URL.createObjectURL(file)
          });

          const metaData = {
            fileType: fileType,
            fileName: fileName,
            resource: "module",
            imageMatrix: layoutName
          };

          let { moduleData, moduleDetails, moduleFileDetails } = this.state;

          //Update individual metaDataof the layout
          switch (layoutName) {
            case "fullpage":
              moduleDetails["fullPage"] = metaData;
              moduleFileDetails["fullPage"] = fileToBeSent;
              break;
            case "halfpage":
              moduleDetails["halfPage"] = metaData;
              moduleFileDetails["halfPage"] = fileToBeSent;

              break;
            case "quadrant":
              moduleDetails["quadrant"] = metaData;
              moduleFileDetails["quadrant"] = fileToBeSent;
              break;
            default:
              break;
          }

          let selectedLayout = moduleData.filter(
            item => item.id === layoutName
          );

          if (Array.isArray(selectedLayout) && selectedLayout.length) {
            selectedLayout[0].fileName = fileName;
            selectedLayout[0].isEdit = true;
            selectedLayout[0].filePreview = "";
          }

          this.setState({
            moduleData
          });
        } else if (fileName !== undefined) {
          ToastUtils.handleToast({
            operation: "error",
            message: "Please upload valid file format [.png, .jpg, .pptx, .ppt]."
          });
        }
      };

      /**
       * Save module details
       */
      _saveModuleDetailsHandler = async () => {
        let {
          moduleData,
          moduleDetails,
          moduleFileDetails,
          isEditModuleActive
        } = this.state;

        //check if anything is edited if not then no api call.
        //Update the error message if any
        moduleData.forEach(item => {
          if (item.type === "dropdown") {
            item.error =
              item.value === "Choose category"
                ? EMPTY_FIELD_ERROR_MESSAGE
                : null;
          } else if (item.type === "input") {
            item.error = this.checkValidation(item.value);
          }
        });

        let errorStatus = moduleData.filter(item => item.error);

        //If there are any errors return and show message accordingly
        if (errorStatus.length) {
          this.setState({
            moduleData
          });
          ToastUtils.handleToast({
            operation: "error",
            message: "Please fill all the fields.",
            autoClose: 3000
          });
          return;
        }

        let isEditted = moduleData.filter(item => item.isEdit);
        if (isEditted.length) {
          let modulesInfo = [];

          //check if any of the layout is selected/uploaded
          if (
            isEmpty(moduleDetails["fullPage"]) &&
            isEmpty(moduleDetails["halfPage"]) &&
            isEmpty(moduleDetails["quadrant"]) &&
            !isEditModuleActive
          ) {
            ToastUtils.handleToast({
              operation: "error",
              message: "Please upload at least one layout.",
              autoClose: 3000
            });
            return;
          }

          //update the details of the layout
          for (let item in moduleDetails) {
            if (
              !isEmpty(moduleDetails[item]) &&
              item === "fullPage" &&
              moduleData[5].isEdit
            ) {
              modulesInfo.push(moduleDetails[item]);
            }

            if (
              !isEmpty(moduleDetails[item]) &&
              item === "halfPage" &&
              moduleData[6].isEdit
            ) {
              modulesInfo.push(moduleDetails[item]);
            }
            if (
              !isEmpty(moduleDetails[item]) &&
              item === "quadrant" &&
              moduleData[7].isEdit
            ) {
              modulesInfo.push(moduleDetails[item]);
            }
          }

          //API body
          let body = {
            ...(moduleData[0].isEdit && {
              name: moduleData[0].value.trim() || ""
            }),
            ...(moduleData[1].isEdit && {
              author: moduleData[1].value.trim() || ""
            }),
            ...(moduleData[2].isEdit &&
              moduleData[2].value.length && {
                description: moduleData[2].value.trim() || ""
              }),
            ...(moduleData[3].isEdit && {
              category: moduleData[3].selectedParentId
            }),
            enable: moduleData[4].value || false,
            ...(modulesInfo.length && { modulesInfo })
          };

          //check if edit mode is active and get the module ID
          let moduleUpdateId = isEditModuleActive
            ? this.moduleDetailsOnSaved._id
            : "";

          let response = await this.props.createUpdateModule(
            body,
            moduleUpdateId
          );

          if (response && response.success) {
            this.setState({
              moduleListLoading: true
            });

            this.moduleDetailsOnSaved = get(response, "data") || {};

            let fullPageDetails = [];
            let halfPageDetails = [];
            let quadrantDetails = [];
            let data = response.data.signedUrls;

            //if there are any presigned URL the apply polling else simply reset the state and show toast mesages
            if (data) {
              //Loop through the data and upload the file to aws by the presigned URL and ingest ID
              Array.isArray(data) &&
                data.forEach(async item => {
                  if (item.layoutType === "fullpage") {
                    fullPageDetails.push(item);
                    await this._uploadLayoutFileToAWS(
                      moduleFileDetails["fullPage"],
                      item.presignedUrl,
                      item.ingestId,
                      fullPagePolling,
                      "fullpage",
                      data
                    );
                  } else if (item.layoutType === "halfpage") {
                    halfPageDetails.push(item);

                    await this._uploadLayoutFileToAWS(
                      moduleFileDetails["halfPage"],
                      item.presignedUrl,
                      item.ingestId,
                      halfPagePolling,
                      "halfpage",
                      data
                    );
                  } else if (item.layoutType === "quadrant") {
                    quadrantDetails.push(item);
                    await this._uploadLayoutFileToAWS(
                      moduleFileDetails["quadrant"],
                      item.presignedUrl,
                      item.ingestId,
                      quadrantPolling,
                      "quadrant",
                      data
                    );
                  }
                });

              //Polling logic
              let fullPageCallBack = null;
              let halfPageCallback = null;

              if (fullPageDetails.length) {
                fullPageCallBack = flag => {
                  //check for toast message flag. Check if default flag value is presend if layout length is one if not check if quadrant details is present or half page. Which in turn will help us to show the toast message
                  let flagValue = flag
                    ? flag
                    : quadrantDetails.length
                    ? quadrantDetails.length
                    : halfPageDetails.length;

                  this._pollingFunction(
                    fullPageDetails[0].ingestId,
                    fullPagePolling,
                    "fullpage",
                    data,
                    this._emptyFileDetails,
                    flagValue,
                    isEditModuleActive
                  );
                };

                if (data.length === 1) {
                  fullPageCallBack(true);
                }
              }
              if (halfPageDetails.length) {
                //check if there is only one layout if yes then call empty fields if not then check for fullpage layout length if not found call empty field function as backup
                let callbackFunctionValue =
                  data.length === 1
                    ? this._emptyFileDetails
                    : fullPageDetails.length
                    ? fullPageCallBack
                    : this._emptyFileDetails;

                halfPageCallback = flag => {
                  //check if default flag is present if not check if length of layout is one if not check for if quadrant is present and at the same time fullpage is not there if thats the cse show the popup message
                  let flagValue = flag
                    ? flag
                    : data.length === 1
                    ? true
                    : quadrantDetails.length && !fullPageDetails.length
                    ? true
                    : false;

                  this._pollingFunction(
                    halfPageDetails[0].ingestId,
                    halfPagePolling,
                    "halfpage",
                    data,
                    callbackFunctionValue,
                    flagValue,
                    isEditModuleActive
                  );
                };

                if (
                  data.length === 1 ||
                  (fullPageDetails.length && !quadrantDetails.length)
                ) {
                  halfPageCallback();
                }
              }

              if (quadrantDetails.length) {
                //check if there is only one layout if yes then call empty fields if not then check for halfpage layout length if not found then check for fullpage layout length  call empty field function as backup
                let quadrandCallbackFunctionValue =
                  data.length === 1
                    ? this._emptyFileDetails
                    : halfPageDetails.length
                    ? halfPageCallback
                    : fullPageDetails.length
                    ? fullPageCallBack
                    : this._emptyFileDetails;

                this._pollingFunction(
                  quadrantDetails[0].ingestId,
                  quadrantPolling,
                  "quadrant",
                  data,
                  quadrandCallbackFunctionValue,
                  data.length === 1,
                  isEditModuleActive
                );
              }
            } else {
              ToastUtils.handleToast({
                operation: "success",
                message: "Module has been updated successfully"
              });

              //reset the field data
              this.setState({
                moduleListLoading: false,
                isEditModuleActive: false
              });

              this._hideModuleDetails();
              this._fetchModuleList();
              this.scrollToTopOnSave();
            }
          }
        }
      };

      /**
       * To upload file to AWS
       * @param {Object} fileToUpload - Object of the file to be uploaded
       * @param {String} presignedUrl - Presigned URL of the string
       */
      _uploadLayoutFileToAWS = async (fileToUpload, presignedUrl) => {
        await uploadFileToAws(presignedUrl, fileToUpload);
      };

      /**
       * Function to undergo Polling
       * @param {String} ingestId - the Ingest ID of the file
       * @param {Object} PollingInstance - The Instance of the Polling,
       * @param {String} layoutType - Type of te layout whose polling is to be done
       * @param {Array} layoutDetails - The entire data of which the polling is to be done
       * @param {Function} cb - Callback function
       * @param {Boolean} message - whether or not to show the Error message/Toast message
       * @param {Boolean} editActive - whether or not the module is being edited
       */
      _pollingFunction = async (
        ingestId,
        PollingInstance,
        layoutType,
        layoutDetails,
        cb,
        message,
        editActive
      ) => {
        PollingInstance.startPolling({
          pollingAction: () => {
            this._checkLayoutModulePollingStatus(
              ingestId,
              PollingInstance,
              layoutType,
              layoutDetails,
              cb,
              message,
              editActive
            );
          },
          timeoutCallback: () => {
            ToastUtils.handleToast({
              operation: "error",
              message:
                "Request timed out. Please verify the module details and try again.",
              autoclose: false
            });
          }
        });
      };

      /**
       * Check polling status for single and stack layout uploaded files to AWS
       *  @param {String} ingestId - the Ingest ID of the file
       * @param {Object} PollingInstance - The Instance of the Polling,
       * @param {String} layoutType - Type of te layout whose polling is to be done
       * @param {Array} layoutDetails - The entire data of which the polling is to be done
       * @param {Function} cb - Callback function
       * @param {Boolean} message - whether or not to show the Error message/Toast message
       * */
      _checkLayoutModulePollingStatus = async (
        ingestId,
        PollingInstance,
        layoutType,
        layoutDetails,
        cb,
        message,
        editActive
      ) => {
        let pollingResponseStatus = await AWSFileUploadStatus(ingestId);
        if (pollingResponseStatus && pollingResponseStatus.success) {
          const responseSuccessFailedCallback = () => {
            PollingInstance.stopPolling();

            //Function to show error message
            const ModuleMessage = () => {
              let modulesUploadSuccess =
                this.moduleUploadDetails["fullpage"].success &&
                this.moduleUploadDetails["halfpage"].success &&
                this.moduleUploadDetails["quadrant"].success;

              if (modulesUploadSuccess) {
                return `Module has been ${
                  this.moduleDetailsOnSaved._id && editActive
                    ? `updated`
                    : `created`
                } successfully.`;
              } else {
                return `
                  <ul style="list-style: disc; font-weight: 900; margin-left: 15px;">
                    ${
                      this.moduleUploadDetails["fullpage"].message
                        ? `<li>${this.moduleUploadDetails["fullpage"].message}</li>`
                        : ``
                    }
                    ${
                      this.moduleUploadDetails["halfpage"].message
                        ? `<li>${this.moduleUploadDetails["halfpage"].message}</li>`
                        : ``
                    }

                    ${
                      this.moduleUploadDetails["quadrant"].message
                        ? `<li>${this.moduleUploadDetails["quadrant"].message}</li>`
                        : ``
                    }
                  </ul>
                `;
              }
            };
            cb && cb();

            //check for layout length and if the toast message is to be displayed
            if (layoutDetails.length && message) {
              //show error toast if any of the polling has failed
              let modulesUploadError =
                !this.moduleUploadDetails["fullpage"].success ||
                !this.moduleUploadDetails["halfpage"].success ||
                !this.moduleUploadDetails["quadrant"].success;

              ToastUtils.handleToast({
                operation: modulesUploadError ? `error` : `success`,
                message: ModuleMessage(),
                autoClose: modulesUploadError ? false : 3000
              });

              //empty the error message
              this.moduleUploadDetails["fullpage"].message = "";
              this.moduleUploadDetails["halfpage"].message = "";
              this.moduleUploadDetails["quadrant"].message = "";

              //check if all the layout has been uploaded
              let modulesUploadSuccess =
                this.moduleUploadDetails["fullpage"].success &&
                this.moduleUploadDetails["halfpage"].success &&
                this.moduleUploadDetails["quadrant"].success;

              if (modulesUploadSuccess) {
                this._hideModuleDetails();
                this.setState({
                  isEditModuleActive: false
                });
              } else {
                let { moduleData } = this.state;
                // reset is edit of input field
                moduleData.forEach(item => {
                  item.isEdit = false;
                });

                this.moduleUploadDetails["fullpage"].success = true;
                this.moduleUploadDetails["halfpage"].success = true;
                this.moduleUploadDetails["quadrant"].success = true;

                //fetch specific module
                this._updateModuleDetails(this.moduleDetailsOnSaved._id || "");

                //enable the edit mode
                this.setState({
                  isEditModuleActive: true,
                  moduleData
                });
              }

              this.setState({
                moduleListLoading: false
              });

              this._fetchModuleList();
              this.scrollToTopOnSave();
            }
          };

          let type = layoutType[0].toUpperCase() + layoutType.slice(1);

          if (pollingResponseStatus.data.status === "Completed") {
            this.moduleUploadDetails[layoutType] = {
              success: true,
              message: `${type} Layout - Successfully uploaded.`
            };

            responseSuccessFailedCallback();
          } else if (pollingResponseStatus.data.status === "Failed") {
            this.moduleUploadDetails[layoutType] = {
              success: false,
              message: get(pollingResponseStatus, "data.message")
                ? `${type} Layout - ${get(
                    pollingResponseStatus,
                    "data.message"
                  )}`
                : `The ${layoutType} was unable to upload. Please try again.`
            };

            responseSuccessFailedCallback();
          }
        } else {
          cb && cb();
          PollingInstance.stopPolling();
          this._fetchModuleList();
          this.scrollToTopOnSave();
          ToastUtils.handleToast({
            operation: "error",
            message: get(pollingResponseStatus, "data.message"),
            autoclose: 3000
          });
        }
      };

      //scroll  move to top on moduke save
      scrollToTopOnSave=()=> {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }

      /**
       * To check if the parent selected is enabled or note
       * @param {String} - Value : This will be the selected value of the parent
       */
      checkIfParentIsDisable = value => {
        let categoryData = this.props.categoryListData;

        //reccursion across categogry list
        let checkCategoryList = optionList => {
          each(optionList, item => {
            if (item.name === value) {
              let { moduleData, moduleDetails, moduleFileDetails } = this.state;

              moduleData[3].selectedParentId = item._id;

              //Check if the selected parent only has flier
              moduleData[5].hideField =
                Array.isArray(item.documentTypes) &&
                item.documentTypes.length === 1 &&
                item.documentTypes[0].title === "Flier";

              if (moduleData[5].hideField) {
                moduleData[5].value = null;
                moduleData[5].fileName = "";
                moduleDetails["fullPage"] = {};
                moduleFileDetails["fullPage"] = {};
              }

              this.setState({
                moduleData
              });
            }
            //check for children
            if (
              Array.isArray(item.subCategories) &&
              item.subCategories.length
            ) {
              checkCategoryList(item.subCategories);
            }
          });
        };

        checkCategoryList(categoryData);
      };

      /**
       * Reset the  file details
       */
      _emptyFileDetails = emptyAllFields => {
        let { moduleData, moduleDetails, moduleFileDetails } = this.state;

        for (let item in moduleFileDetails) {
          let itemId = item.toLowerCase();
          if (!this.moduleUploadDetails[itemId].success || emptyAllFields) {
            moduleFileDetails[item] = {};
            moduleDetails[item] = {};
          }
        }

        moduleData.forEach((item, index) => {
          if (
            (index === 5 || index === 6 || index === 7) &&
            (!this.moduleUploadDetails[item.id].success || emptyAllFields)
          ) {
            item.value = null;
            item.fileName = "";
            item.isEdit = false;
          }
        });

        this.setState({
          moduleData,
          moduleDetails,
          moduleFileDetails
        });
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.props,
          ...$this.state,
          addCategory: this._addNewCateogryHandler,
          showModuleHandler: this._showModuleHandler,
          hideModuleDetails: this._hideModuleDetails,
          handleDropDownChange: this._handleDropDownChange,
          fetchCategorList: this._fetchCategorList,
          handleInputChange: this._handleInputChange,
          onLayoutUpload: this.onLayoutUpload,
          saveModuleDetailsHandler: this._saveModuleDetailsHandler
        };
        return <Main {...stateMethodProps} />;
      }
    }
  );
export default Container;
