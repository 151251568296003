import React, { PureComponent } from "react";
import ToastUtils from "utils/handleToast";

const Container = Main =>
  class SortableTree extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        searchString: "",
        searchFocusIndex: -1,
        searchFoundCount: null,
        updatedTreeData: [],
        selectedParentDetails: {}
      };
      this.categoryLevelContainerRef = React.createRef();
    }

    componentDidMount() {
      document.addEventListener("mousedown", this.removeCategorySelection);
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.removeCategorySelection);
    }

    // remove category selection
    removeCategorySelection = e => {
      if (
        this.categoryLevelContainerRef.current &&
        !this.categoryLevelContainerRef.current.contains(e.target) &&
        !this.props.categoryIdToEdit
      ) {
        this.props.resetNewFocusIndex({
          key: "selectedParentDetails",
          value: {}
        });
      }
    };

    searchFinishCallback = matches => {
      // reset new scroll index and edited flag and category id
      this.props.setResetCategory(null, true);
      this.props.resetNewFocusIndex({ key: "newFocusIndex", value: 0 });
      this.props.resetNewFocusIndex({ key: "isEditedFlag", value: false });
      this.props.resetNewFocusIndex({ key: "categoryTitle", value: "" });

      if (
        typeof this.props.searchQueryString === "string" &&
        this.props.searchQueryString.length > 2
      ) {
        this.setState({
          searchFoundCount: matches.length,
          searchFocusIndex:
            matches.length > 0
              ? this.state.searchFocusIndex % matches.length
              : 0
        });

        if (matches.length === 0 && this.props.searchQueryString) {
          ToastUtils.handleToast({
            operation: "dismiss"
          });

          this.clearToast && clearTimeout(this.clearToast);

          this.clearToast = setTimeout(() => {
            ToastUtils.handleToast({
              operation: "error",
              message: "No data found.",
              autoclose: false
            });
          }, 100);
        }
      }
    };

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      };

      return <Main {...stateMethodProps} />;
    }
  };

export default Container;
