import React from "react";
import styled from "styled-components";
import { ActiveStar, InactiveStar } from "assets/icons";
import Moment from "react-moment";

import hexToRgba from "utils/hexToRgba";

const CategoryTitleComponent = props => {
  let {
    thumbnailLocation,
    title,
    updatedAt,
    handleTitleClick,
    isMaster,
    _id,
    saveEditedThemeDetails,
    enable
  } = props;

  return (
    <>
      <ImageContainer>
        <Image src={thumbnailLocation} alt="Theme slide" />
      </ImageContainer>
      <Title title={title} onClick={() => handleTitleClick(_id)}>
        {title}
      </Title>
      <DateContainer>
        <Moment format="MM/DD/YYYY">{updatedAt}</Moment>
      </DateContainer>

      <DefaultThemeContainer
        enabled={enable}
        onClick={() => {
          saveEditedThemeDetails(
            { isMaster: !isMaster },
            _id,
            "Master Theme has been successfully updated."
          );
        }}
      >
        {isMaster ? (
          <ActiveStarContainer title="Default Theme">
            <ActiveStar />
          </ActiveStarContainer>
        ) : (
          <InactiveStarContainer>
            <InactiveStar />
          </InactiveStarContainer>
        )}
      </DefaultThemeContainer>
    </>
  );
};

const ImageContainer = styled.div`
  font-size: 12px;
  white-space: normal;
  max-width: 58px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border: 0.5px solid ${props => props.theme.COLOR.BORDER_GREY};
`;

const Image = styled.img`
  width: 100%;
  display: block;
  max-height: 32px;
  max-width: 58px;
`;

const Title = styled.h3`
  width: 104px;
  margin: 0 4% 0 11%;
  display: inline-block;
  vertical-align: middle;
  color: ${props => hexToRgba(props.theme.COLOR.DEFAULT_USER_PRIMARY, ".9")};
  font-size: 12px;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DateContainer = styled.span`
  width: 100px;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  color: ${props => props.theme.COLOR.MAIN};
  font-family: ${props => props.theme.FONT.REG};
  font-weight: normal;
`;

const DefaultThemeContainer = styled.span`
  width: 23px;
  display: inline-block;
  margin-left: 10%;
  text-align: center;
  vertical-align: middle;
  cursor: ${props => (props.enabled ? "pointer" : "default")};
  pointer-events: ${props => !props.enabled && "none"};
`;

const InactiveStarContainer = styled.span``;

const ActiveStarContainer = styled.span``;

export default CategoryTitleComponent;
