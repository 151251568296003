import React from "react";
import styled from "styled-components";

import Button, { SecondaryButton } from "components/button";

import Container from "./container";
import Form from "./components/Form";

const Sidebar = props => {
  return (
    props.showModuleDetails && (
      <>
        <Title>Modules</Title>
        <FormContainer>
          <Form {...props} />
          <CtaContainer>
            <Button
              text="Save"
              width="calc(50% - 2px)"
              float="none"
              buttonClass="save"
              onClick={props.saveModuleDetailsHandler}
            />
            <SecondaryButton
              text="Cancel"
              width="calc(50% - 2px)"
              float="none"
              buttonClass="cancel"
              onClick={props.hideModuleDetails}
            />
          </CtaContainer>
        </FormContainer>
      </>
    )
  );
};

export default Container(Sidebar);

const FormContainer = styled.div``;
const Title = styled.h4`
  margin-bottom: 23px;
  color: ${props => props.theme.COLOR.MAIN};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-weight: bold;
`;

const CtaContainer = styled.div`
  margin-top: 24px;
  button {
    display: inline-block;
    vertical-align: top;
  }
  .save {
    height: 40px;
    margin-right: 4px;
  }

  .cancel {
    height: 40px;
    border-radius: 3px;
    font-size: 14px;
  }
`;
