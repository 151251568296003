import FetchUtils from "utils/FetchUtils.js";
import ToastUtils from "utils/handleToast";

// import report actions
import {
  presentationHistorySuccess,
  presentationHistoryLoading
} from "./action";

/**
 * Fetch all categories
 */
const getPresentationHistory = () => async dispatch => {
  // start loading
  dispatch(presentationHistoryLoading({ presentationHistoryLoading: true }));

  const URL = `/presentations`,
    response = await FetchUtils.getData(URL, "Fetch all pagelayout ==>");

  // stop loading
  dispatch(presentationHistoryLoading({ presentationHistoryLoading: false }));

  if (response.success && response.data) {
    //dispatch reports data on success
    dispatch(
      presentationHistorySuccess({
        presentationHistoryList: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

export { getPresentationHistory };
