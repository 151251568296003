import React, { PureComponent } from 'react'
import { each } from 'lodash'

const Container = Main =>
  class SortableTree extends PureComponent {
    constructor (props) {
      super(props)
      this.state = {
        treeData: [],
        windowNode: {}
      }
      this.categoryLevelContainerRef = React.createRef()
      this.selectedParent = []
    }

    componentDidMount () {
      this.setWindowNode()
      window.addEventListener('resize', this.setWindowNode)

      this.setState({
        treeData: this.props.gData
      })
    }

    // trigger when drag stat changes
    onDragStateChanged = ({ isDragging }) => {
      if (isDragging) {
        window.addEventListener('dragover', this.scrollWindow)
      } else {
        window.removeEventListener('dragover', this.scrollWindow)
      }
    }

    // scroll window when dragged element reaches window height
    scrollWindow = e => {
      const { windowNode } = this.state
      if (windowNode && e.screenY > windowNode.innerHeight) {
        windowNode.scrollTo({
          top: e.screenY,
          behavior: 'smooth'
        })
      }
    }

    // set window object for new values on resize
    setWindowNode = () => {
      this.setState({
        windowNode: window
      })
    }

    componentDidUpdate (prevProps) {
      if (this.props.gData !== prevProps.gData) {
        this.setState({
          treeData: this.props.gData
        })
      }
    }

    handleTreeOnChange = treeData => {
      this.setState({
        treeData
      })
    }

    //store parent on visibility toggle to retain on delete/reorder categories
    onVisibilityToggle = ({ node, expanded }) => {
      // check elements for multiple items toggled together
      let selectedParentIsArray = Array.isArray(this.selectedParent)
      let nodeIndex = selectedParentIsArray
        ? this.selectedParent.indexOf(node._id)
        : -1

      if (expanded && selectedParentIsArray && nodeIndex === -1) {
        this.selectedParent.push(node._id)
      } else if (nodeIndex > -1 && selectedParentIsArray) {
        this.selectedParent.splice(nodeIndex, 1)
      }
    }

    // handle when node is moved for category and modules
    onMoveNode = async ({ treeData, nextParentNode, ...props }) => {
      // update node level
      let updateLevel = (treeSet, level = 0) => {
        each(treeSet, category => {
          category.level = level
          if (Array.isArray(category.children) && category.children.length) {
            updateLevel(category.children, category.level + 1)
          }
        })
      }

      updateLevel(treeData)

      //Get data body as per the API
      let reorderedData = []
      if (
        nextParentNode &&
        Array.isArray(nextParentNode.children) &&
        nextParentNode.children.length
      ) {
        let subCategories =
          (Array.isArray(nextParentNode.children) &&
            nextParentNode.children.map(({ _id, label, parent }) => {
              return {
                id: _id,
                label: 'subcategory',
                parent
              }
            })) ||
          []

        let modules =
          (Array.isArray(nextParentNode.moduleCategory) &&
            nextParentNode.moduleCategory.map(({ _id, label, parent }) => ({
              id: _id,
              label,
              parent
            }))) ||
          []

        reorderedData = [...modules, ...subCategories]
      } else {
        reorderedData = treeData.map(({ _id, name, label }, index) => {
          return {
            id: _id,
            label: 'category'
          }
        })
      }

      const payload = {
        children: reorderedData
      }

      let nextParentExists = reorderedData.filter(
        ({ parent }) => (nextParentNode || {})._id !== parent
      )
      payload.children = payload.children.map(({ id, label }) => ({
        id,
        label
      }))

      //get next parent
      if (nextParentExists.length) {
        payload.nextParent = (nextParentNode || {})._id || null
      }

      //set nextParent for visibilty if it is hidden while reordering
      let nextParentId = (nextParentNode || {})._id
      if(nextParentId && this.selectedParent.indexOf(nextParentId) < 0)
        this.selectedParent.push(nextParentId)

      await this.props.reorderedCategoryList(payload)

      this.setState({
        treeData
      }, () => {
        this.props._getUpdatedCategoriesList();
      })
    }

    render () {
      const $this = this
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      }

      return <Main {...stateMethodProps} />
    }
  }

export default Container
