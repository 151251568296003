import FetchUtils from "utils/FetchUtils.js";
import ToastUtils from "utils/handleToast";
import { get, capitalize } from "lodash";

// import report actions
import { moduleListSuccess, moduleListLoading } from "./action";
import ConvertQueryParamsObjectToString from "utils/queryString";

/**
 * Fetch all Modules categories
 */
const getModuleList = () => async dispatch => {
  // start loading
  dispatch(
    moduleListLoading({
      moduleListLoading: true
    })
  );

  const URL = `/marketing-materials/admin/modules`,
    response = await FetchUtils.getData(
      URL,
      "Fetch all Modules categories ==>"
    );

  // stop loading
  dispatch(
    moduleListLoading({
      moduleListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch reports data on success
    dispatch(
      moduleListSuccess({
        moduleList: response.data
      })
    );
  } else {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: response.data && response.data.message
    });
  }

  //return reponse to check in component if required
  return response;
};

const updateModule = (levelId, body, message, label) => async dispatch => {
  const URL = `/marketing-materials/${
    label === "module" ? "modules" : "module-categories"
  }/${levelId}`;

  // start loading
  dispatch(
    moduleListLoading({
      moduleListLoading: true
    })
  );

  const response = await FetchUtils.patchData(
    URL,
    body,
    "patch modules and categories data"
  );

  dispatch(
    moduleListLoading({
      moduleListLoading: false
    })
  );

  if (response.success && response.data) {
    // stop loading
    ToastUtils.handleToast({
      operation: "success",
      message: message || "Module has been updated successfully."
    });
  } else {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const deleteModuleCategory = (levelId, label) => async dispatch => {
  const URL = `/marketing-materials/${
    label === "module" ? "modules" : "module-categories"
  }/${levelId}`;

  // start loading
  dispatch(
    moduleListLoading({
      moduleListLoading: true
    })
  );

  const response = await FetchUtils.deleteData(
    URL,
    "Delete module or category"
  );

  // stop loading
  dispatch(
    moduleListLoading({
      moduleListLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: `${capitalize(label)} has been successfully deleted.`
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const fetchCategoryList = () => async dispatch => {
  const params = ConvertQueryParamsObjectToString({
    field: "subcategories"
  });

  const URL = `/marketing-materials/module-categories${
    params ? `?${params}` : ""
  }`;

  // start loading
  dispatch(
    moduleListLoading({
      moduleListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL, "Fetch category list");
  // stop loading
  dispatch(
    moduleListLoading({
      moduleListLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch reports data on success
    dispatch(
      moduleListSuccess({
        categoryListData: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const createUpdateModule = (body, id) => async dispatch => {
  const URL = `/marketing-materials/modules/${id}`;
  // start loading
  dispatch(
    moduleListLoading({
      moduleDetailsLoading: true
    })
  );

  const response = id
    ? await FetchUtils.patchData(URL, body, "Update modules")
    : await FetchUtils.postData(URL, body, "Create modules");

  dispatch(
    moduleListLoading({
      moduleDetailsLoading: false
    })
  );

  if (!response.success) {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

const fetchSpecificModule = id => async dispatch => {
  const URL = `/marketing-materials/modules/${id}`;

  // start loading
  dispatch(
    moduleListLoading({
      moduleListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL, "Fetch specific modules");

  // stop loading
  dispatch(
    moduleListLoading({
      moduleListLoading: false
    })
  );

  if (!response.success) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

export {
  getModuleList,
  updateModule,
  deleteModuleCategory,
  fetchCategoryList,
  createUpdateModule,
  fetchSpecificModule
};
