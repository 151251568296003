import React from "react";
import styled from "styled-components";

import hexToRgba from "utils/hexToRgba";
import Search from "components/searchBox";
import ShadowScrollbars from "components/custom-scrollbars/ShadowScrollbars";

/**
 * for appending escape character if search string contains special characters
 * @param {String} str string to be search for special characters
 */
const escapeRegExp = str => {
  return str.replace(/([*+?^=!:${}()|\[\]\/\\])/g, "\\$1"); // eslint-disable-line no-useless-escape
};

const SearchBlock = props => {
  let {
    searchList,
    isSearching,
    handleChange,
    searchValue,
    contentSlideUpdate,
    groupId,
    errorEmptyList
  } = props;

  return (
    <SearchContainer>
      <Search
        placeholder="Search to add slides"
        handleChange={e => handleChange(e)}
      />
      {searchList && isSearching && (
        <SearchResult>
          {searchList.length ? (
            <SearchResultContainer>
              <ShadowScrollbars
                autoHeight
                autoHeightMax="160px"
                thumbMinSize={10}
              >
                {searchList.map((item, index) => {
                  let {
                    title,
                    contentRepository,
                    contentSlideCategory,
                    _id
                  } = item;

                  //To highlight the searched text
                  let searchDataValue = escapeRegExp(searchValue);
                  // split row value with separator as the regex of search value
                  let parts = title.split(
                    new RegExp(`(${searchDataValue})`, "gi")
                  );

                  let dataValue = (
                    <span>
                      {parts.map((part, i) => (
                        <span
                          key={i}
                          style={
                            part.toLowerCase() ===
                              searchDataValue.toLowerCase() &&
                            searchDataValue.length > 2
                              ? { backgroundColor: "#f3c200" }
                              : {}
                          }
                        >
                          {part}
                        </span>
                      ))}
                    </span>
                  );
                  return (
                    <List key={index}>
                      <ListSlideTitle title={title}>{dataValue}</ListSlideTitle>
                      <AddSlide
                        title="Add Slide"
                        onClick={() => {
                          contentSlideUpdate(
                            contentRepository,
                            contentSlideCategory,
                            _id,
                            groupId
                          );
                        }}
                      >
                        Add
                      </AddSlide>
                    </List>
                  );
                })}
              </ShadowScrollbars>
            </SearchResultContainer>
          ) : (
            <NoData>{errorEmptyList}</NoData>
          )}
        </SearchResult>
      )}
    </SearchContainer>
  );
};

export default SearchBlock;

const SearchContainer = styled.div`
  margin-top: 18px;

  &::after {
    content: "";
    width: 91px;
    height: 2px;
    margin: 21px auto 19px;
    display: block;
    background-color: ${props =>
      hexToRgba(props.theme.COLOR_PALLETE.LIGHT_GREY, 0.46)};
  }

  .search-wrapper {
    width: 100%;
    min-width: auto;
    float: none;

    input {
      width: calc(100% - 39px);
      min-width: auto;
      height: 30px;
      padding: 0;
      font-size: 12px;
      color: ${props => props.theme.COLOR.MAIN};
    }

    svg {
      width: 14px;
      height: 14px;
      top: 3px;
    }
  }
`;

const SearchResult = styled.div``;

const SearchResultContainer = styled.div`
  background-color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.BOX_SHADOW}
`;

const List = styled.div`
  position: relative;
  margin: 10px 0;
  padding: 0 0 0 8px;
`;

const ListSlideTitle = styled.h4`
  width: 83%;
  margin-right: 2px;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AddSlide = styled.button`
  margin-top: -2px;
  border: none;
  outline: none;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  background: none;
  color: ${props => props.theme.COLOR.USER_PRIMARY};
  font-size: 12px;
  text-decoration: underline;
`;

const NoData = styled.h4`
  margin: 10px 0 0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.COLOR.MAIN};
`;
