import React from "react";
import styled, { css } from "styled-components";
import Checkbox from "components/checkbox";
import { flatMap, each } from "lodash";
import hexToRgba from "utils/hexToRgba";

const ListItemContainer = props => {
  const {
    checkIfExist,
    handleChooseContentChecbox,
    modules,
    IsSubCatModule,
    documentTypes,
    id,
    highlightText
  } = props;

  let docTypes = {
    id: id,
    documents: [
      { title: "Placemat", active: false },
      { title: "Flier", active: false },
      { title: "Brochure", active: false }
    ]
  };

  each(documentTypes, documentType => {
    each(docTypes.documents, item => {
      if (item.title === documentType.title) {
        item.active = true;
      }
    });
  });

  return (
    <>
      {flatMap(modules, moduleItem => {
        const { title, id } = moduleItem;
        return (
          <ListItemWrapper key={id}>
            <ListItem>
              <CheckBoxWrapper IsSubCatModule={IsSubCatModule}>
                <Checkbox
                  label={title}
                  name={title}
                  id={id}
                  checked={checkIfExist(id)}
                  handleChange={e =>
                    handleChooseContentChecbox(moduleItem, docTypes, e)
                  }
                  chooseContent={true}
                  highlightText={highlightText}
                />
              </CheckBoxWrapper>
              <ItemDocuments>
                {flatMap(docTypes.documents, doc => {
                  return (
                    <ItemDocument active={doc.active} key={doc.title}>
                      {doc.active ? doc.title : ""}
                    </ItemDocument>
                  );
                })}
              </ItemDocuments>
            </ListItem>
          </ListItemWrapper>
        );
      })}
    </>
  );
};

const ListItem = styled.div`
  margin-bottom: 12px;
  position: relative;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const checkBoxStyledCSS = css`
  display: inline-block;
  vertical-align: top;
  width: calc(38.1% - 26px);
  margin-right: 11.8%;
  .checkbox-container {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  input {
    margin-right: 12px;
  }
  label {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};
  }
`;

const CheckBoxWrapper = styled.div`
  ${checkBoxStyledCSS}
  width: ${props =>
    props.IsSubCatModule ? "calc(38.1% - 52px)" : "calc(38.1% - 26px)"};
  margin-right: ${props =>
    props.IsSubCatModule ? "calc(11.8% + 17px)" : "11.8%"};

  label { text-transform: capitalize; }
`;

const ItemDocuments = styled.ul`
  display: inline-block;
  vertical-align: top;
  width: 50.1%;
`;

const ItemDocument = styled.li`
  display: inline-block;
  vertical-align: top;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.COLOR.WHITE};
  border-radius: 10px;
  height: 15px;
  text-align: center;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &:nth-of-type(1) {
    background-color: ${props => props.theme.COLOR_PALLETE.LIPSTICK};
    width: 82px;
  }

  &:nth-of-type(2) {
    background-color: ${props => props.theme.COLOR_PALLETE.APPLE_GREEN};
    width: 58px;
  }

  &:nth-of-type(3) {
    background-color: ${props => props.theme.COLOR_PALLETE.GOLDEN};
    width: 80px;
  }

  height: ${props => (!props.active ? "0px" : "15px")};
`;

const ListItemWrapper = styled.div``;

export default ListItemContainer;
