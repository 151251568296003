import React from "react";
import Container from "./container";
import BgWrapper from "components/bgWrapper";

// module manager
import RepoManager from "./components/repoManager";
import FullPageLoader from "components/FullPageLoader";
import get from "lodash/get";

const ContentRepo = props => {
  const {
    treeData,
    generateButtonNodeList,
    checkIfElementCanBeDropped,
    addNewSlide,
    windowNode,
    onDragStateChanged,
    match,
    updateSlideSettings,
    contentSlideListLoading,
    categoryIdToEdit,
    setResetCategory,
    selectedParentDetails,
    setParentDetails,
    onSave,
    addNewNode,
    removeNodeFromTree,
    handleTreeOnChange,
    toggleNodeExpansion,
    onMoveNode,
    toggleExpand,
    editInput,
    resetNewFocusIndex,
    newFocusIndex,
    isEditedFlag,
    categoryTitle,
    inputError,
    updateGroupOnContentSlideList,
    currentSelectedSlide,
    setFocusOnGroupDropdownChange,
    selectedParentELem,
    handleRepoSearch,
    searchQueryString,
    dropDownOptionsNew,
    editedSlide,
    isSlideUploading,
    fetchContentSlides,
    clearEditedSlide,
    isThemeFetching,
    fetchThemeData,
    clearThemeData,
    parentNode,
    selectedFilters,
    saveFilters,
    onChangeHandleFiltersCheck,
    selectedFilterCategory,
    filterOnDropChange,
    handleCheckBoxChange,
    displayFiltersList,
    resetRightPanelForm,
    resetFilters,
    activeTab,
    manageParentStates,
    isFilterTabEdited,
    selectedContentRepo
  } = props;

  // content repo config for admin module
  const contentRepoConfig = {
    header: {
      repoTitle: `Content Repo: ${get(selectedContentRepo, `name`) || ""}`,
      search: true,
      settings: true,
      bulkEdit: true,
      addActive: true,
      contentRepoId: match.params.contentRepoId,
      addNewSlide
    },
    levelManager: {
      moduleComponentHeader: true,
      expandCollapseAllFlag: true,
      modules: true,
      expandRepo: false
    }
  };

  const repoProps = {
    treeData,
    contentRepoConfig,
    generateButtonNodeList,
    checkIfElementCanBeDropped,
    windowNode,
    onDragStateChanged,
    updateSlideSettings,
    categoryIdToEdit,
    setResetCategory,
    selectedParentDetails,
    setParentDetails,
    onSave,
    addNewNode,
    removeNodeFromTree,
    handleTreeOnChange,
    onMoveNode,
    toggleNodeExpansion,
    toggleExpand,
    editInput,
    resetNewFocusIndex,
    newFocusIndex,
    contentRepoId: match.params.contentRepoId,
    isEditedFlag,
    categoryTitle,
    inputError,
    updateGroupOnContentSlideList,
    currentSelectedSlide,
    setFocusOnGroupDropdownChange,
    selectedParentELem,
    handleRepoSearch,
    searchQueryString,
    dropDownOptionsNew,
    editedSlide,
    isSlideUploading,
    fetchContentSlides,
    clearEditedSlide,
    fetchThemeData,
    clearThemeData,
    parentNode,
    selectedFilters,
    saveFilters,
    onChangeHandleFiltersCheck,
    selectedFilterCategory,
    filterOnDropChange,
    handleCheckBoxChange,
    displayFiltersList,
    resetRightPanelForm,
    resetFilters,
    activeTab,
    manageParentStates,
    isFilterTabEdited
  };

  return (
    <>
      {(contentSlideListLoading || isSlideUploading || isThemeFetching) && (
        <FullPageLoader layerIndex={21} />
      )}
      <RepoManager {...repoProps}></RepoManager>
    </>
  );
};

export default Container(BgWrapper(ContentRepo));
